/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  Col,
  Icon,
  MultiColumnList,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldArray } from 'react-final-form-arrays';
import css from './style.css';

const propTypes = {};

function FilePreview(props) {
  const intl = useIntl();

  const renderFilePreview = (fields) => {
    const ImportedContentData = [];

    for (const key in fields.value) {
      if (key) {
        const translationObj = {};
        translationObj.keyName = key;
        translationObj.keyValue = fields.value[key];
        ImportedContentData.push(translationObj);
      }
    }

    return (
      <Col xs={9}>
        <Card
          id="importTranslations-preview-card"
          cardStyle="positive"
          roundedBorder
          headerStart={
            <span className={css.cardHeader}>
              <Icon icon="preview">
                <FormattedMessage
                  id="ui-translations.importTranslations.cardHeader.preview"
                  defaultMessage="File Preview - {fileName}"
                  values={{ fileName: props.importFileName }}
                />
              </Icon>
            </span>
          }
          headerEnd={<FormattedMessage
            id="ui-translations.importTranslations.cardPreview.noOfTranslations"
            defaultMessage="# of translations: {count}"
            values={{ count: <Badge>{Object.keys(fields.value).length}</Badge> }}
          />}
        >
          <MultiColumnList
            // {...props}
            interactive={false}
            contentData={ImportedContentData}
            visibleColumns={['keyName', 'keyValue']}
            columnWidths={{ keyName: '50%', keyValue: '50%' }}
            columnMapping={{
              keyName: intl.formatMessage({
                id: 'ui-translations.settings.keyName',
              }),
              keyValue: intl.formatMessage({
                id: 'ui-translations.settings.keyValue',
              }),
            }}
            id="Apps-renderFilePreview-Multi-Column-List"
            // autosize
            maxHeight={500}
            virtualize
            pageAmount={100}
            totalCount={ImportedContentData.length}
            pagingType="click"
            isEmptyMessage={
              <FormattedMessage
                id="ui-translations.importTranslations.cardPreview.noFile"
                defaultMessage="No file selected"
              />
            }
          />
        </Card>
      </Col>
    );
  };

  return (
    <>
      <FieldArray name="importedTranslations">
        {({ fields }) => renderFilePreview(fields)}
      </FieldArray>
    </>
  );
}

FilePreview.propTypes = propTypes;

export default FilePreview;


// import { useMemo } from 'react';

// const useOptions = (data = [], value, label) => {
//   return useMemo(() => data.map(item => ({
//     value: item[value],
//     label: item[label],
//   })), [data, label, value]);
// };

// export default useOptions;
/** kware start editing */
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useOptions = (data = [], tableName = '', value, label) => {
  const intl = useIntl();
  return useMemo(() => data.map(item => ({
    value: item[value],
    label: intl.formatMessage({ id: `ui-inventory.${tableName}.name.${item[label]}`, defaultMessage: item[label] }),
  })), [data, intl, label, tableName, value]);
};

export default useOptions;
/** kware end editing */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@folio/stripes/components';
import css from './styles.css';

function ErrorMessage({ errorMessage, error }) {
  const [openMessage, setOpenMessage] = useState(false);

  return (
    <div className={css.messageContainer}>
      <div className={css.errorMessage}>
        {errorMessage || (
          <FormattedMessage
            id="ui-inventory.copycat.components.common.errorMessage.defaultMes"
            defaultMessage="Error executing request."
          />
        )}
      </div>
      <div
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          marginBottom0
          id="copycat-components-common-errorMessage-show-error-btn"
          buttonStyle="primary"
          onClick={() => setOpenMessage(!openMessage)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {!openMessage ? (
            <FormattedMessage
              id="ui-inventory.copycat.buttons.showError"
              defaultMessage="Show error"
            />
          ) : (
            <FormattedMessage
              id="ui-inventory.copycat.buttons.hideError"
              defaultMessage="Hide error"
            />
          )}
        </Button>
      </div>
      {openMessage && (
        <div
          style={{
            margin: '20px',
            textAlign: 'center',
            lineHeight: '1.4',
            border: '1.5px solid rgba(0, 0, 0, 0.62)',
            padding: '20px',
            background: 'rgb(246, 141, 117)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.object,
  error: PropTypes.string,
};

export default ErrorMessage;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useOkapiKy } from '@folio/stripes-core';
import { isEqual } from 'lodash';
import CopycatQueryBuilderForm from './CopycatQueryBuilderForm';
import useURLParams from '../../hooks/useURLParams';
import { SearchFieldsValues } from '../../utils';

const getExternalTargets = (targets, sourcesList) => {
  const externalTargets = [];
  targets.forEach((target) => {
    sourcesList.forEach((list) => {
      if (list.name === target) {
        externalTargets.push({
          name: list?.name,
          url: list?.url,
          targetOptions: list?.targetOptions,
          authentication: list?.authentication,
        });
      }
    });
  });
  return externalTargets;
};

const buildSearchQuery = (queryFields) => {
  let query = '';
  Object.keys(queryFields).forEach((element) => {
    const value = JSON.stringify(queryFields[element].trim());
    query += ` ${SearchFieldsValues[element]} ${value}`;
  });
  for (let i = 2; i <= Object.keys(queryFields).length; i++) {
    query = `@and ${query}`;
  }

  return query;
};

function CopycatQueryBuilder({
  setResultSets,
  setIsLoading,
  setError,
  showQueryPane,
  setShowQueryPane,
  sourcesList,
  currentPage,
  setCurrentPage,
  querySources,
  setQuerySources,
  isLoading,
  pageSize,
  setPageSize,
}) {
  const [queryFields, setQueryFields] = useURLParams(
    'query',
    { any: '' },
    'json'
  );

  const okapiKy = useOkapiKy();

  const doCopycatSearch = () => {
    setIsLoading(true);
    okapiKy
      .post('copycat/zsearch', {
        timeout: 30000,
        method: 'POST',
        json: {
          query: buildSearchQuery(queryFields),
          externalTargets: getExternalTargets(querySources, sourcesList),
          pageSize,
          page: currentPage,
        },
      })
      .json()
      .then((res) => {
        setResultSets(res);
        setIsLoading(false);
      })
      .catch(async (err) => {
        const res = err.response;
        const text = await res.text();

        let detail = text;
        if (res.headers.get('content-type') === 'application/json') {
          const obj = JSON.parse(text);
          detail = obj.errors[0].message;
        }
        setError(detail);
      });
  };

  useEffect(() => {
    if (
      !Object.values(queryFields).includes('') &&
      sourcesList.length &&
      querySources.length
    ) {
      doCopycatSearch();
    } else {
      setResultSets(null);
      setError(null);
      setIsLoading(false);
    }
  }, [currentPage, pageSize, querySources, queryFields, sourcesList]);

  const onSubmit = (formValues) => {
    setQuerySources(formValues.sourcesList.map(({ value }) => value));
    setQueryFields(
      formValues.searchOptions.reduce(
        (obj, item) => Object.assign(obj, { [item.field]: item.value }),
        {}
      )
    );
    setPageSize(formValues.pageSize);
    setCurrentPage(1);
  };

  const resetQueryForm = () => {
    setQuerySources(['Koha Demo', 'Library of Congress']);
    setQueryFields({ any: '' });
    setPageSize(50);
    setCurrentPage(1);
  };

  const isDisabledResetBtn = () => {
    const defaultValues = {
      querySources: ['Koha Demo', 'Library of Congress'],
      queryFields: { any: '' },
      pageSize: 50,
    };
    const currentValues = {
      querySources,
      queryFields,
      pageSize,
    };

    return isEqual(defaultValues, currentValues) || isLoading;
  };

  return (
    <CopycatQueryBuilderForm
      onSubmit={onSubmit}
      sourcesList={sourcesList}
      resetQueryForm={resetQueryForm}
      showQueryPane={showQueryPane}
      setShowQueryPane={setShowQueryPane}
      setCurrentPage={setCurrentPage}
      isLoading={isLoading}
      initialValues={{
        sourcesList: querySources?.map((source) => ({
          label: source,
          value: source,
        })),
        searchOptions: Object.keys(queryFields).map((key) => ({
          field: key,
          value: queryFields[key],
        })),
        pageSize,
      }}
      isDisabledResetBtn={isDisabledResetBtn()}
    />
  );
}

CopycatQueryBuilder.propTypes = {
  setResultSets: PropTypes.func,
  setIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  setError: PropTypes.func,
  showQueryPane: PropTypes.bool.isRequired,
  setShowQueryPane: PropTypes.func.isRequired,
  sourcesList: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  querySources: PropTypes.object,
  setQuerySources: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
};

export default CopycatQueryBuilder;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImportTranslationsManager from '../components/ImportTranslations/ImportTranslationsManager';

export default class ImportTranslationsRoute extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
    }).isRequired,
  };

  handleClose = () => {
    this.props.history.goBack();
  };

  render() {
    console.log(this.props.history);

    return (
      <ImportTranslationsManager
        handleClose={this.handleClose}
        // onSubmit={this.onSubmit}
      />
    );
  }
}

export const fieldsConfig = [
  /* Instance Admin data */
  {
    id: 'id',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.administrationData.field.id',
    categoryId: 'admin-data',
    value: 'instance.id',
    recordType: 'INSTANCE',
  },
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'admin-data',
    value: 'instance.metadata.updatedDate',
    recordType: 'INSTANCE',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'admin-data',
    value: 'instance.metadata.updatedByUsername',
    recordType: 'INSTANCE',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'admin-data',
    value: 'instance.metadata.createdDate',
    recordType: 'INSTANCE',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'admin-data',
    value: 'instance.metadata.createdByUsername',
    recordType: 'INSTANCE',
  }, {
    id: 'discoverySuppress',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.discoverySuppress',
    categoryId: 'admin-data',
    value: 'instance.discoverySuppress',
    recordType: 'INSTANCE',
  }, {
    id: 'staffSuppress',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.staffSuppress',
    categoryId: 'admin-data',
    value: 'instance.staffSuppress',
    recordType: 'INSTANCE',
  }, {
    id: 'previouslyHeld',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.previouslyHeld',
    categoryId: 'admin-data',
    value: 'instance.previouslyHeld',
    recordType: 'INSTANCE',
  }, {
    id: 'hrid',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.administrationData.field.hrid',
    categoryId: 'admin-data',
    value: 'instance.hrid',
    recordType: 'INSTANCE',
  }, {
    id: 'source',
    label: 'ui-data-import.settings.matchProfiles.source',
    categoryId: 'admin-data',
    value: 'instance.source',
    recordType: 'INSTANCE',
  }, {
    id: 'catalogedDate',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.administrationData.field.catalogedDate',
    categoryId: 'admin-data',
    value: 'instance.catalogedDate',
    recordType: 'INSTANCE',
  }, {
    id: 'statusId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.administrationData.field.statusId',
    categoryId: 'admin-data',
    value: 'instance.statusId',
    recordType: 'INSTANCE',
  }, {
    id: 'statusUpdatedDate',
    label: 'ui-data-import.settings.matchProfiles.statusUpdatedDate',
    categoryId: 'admin-data',
    value: 'instance.statusUpdatedDate',
    recordType: 'INSTANCE',
  }, {
    id: 'modeOfIssuanceId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.administrationData.field.modeOfIssuanceId',
    categoryId: 'admin-data',
    value: 'instance.modeOfIssuanceId',
    recordType: 'INSTANCE',
  }, {
    id: 'statisticalCodeIds',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.statisticalCode',
    categoryId: 'admin-data',
    value: 'instance.statisticalCodeIds[]',
    recordType: 'INSTANCE',
  },
  /* Instance Title data */
  {
    id: 'title',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.titleData.field.title',
    categoryId: 'title-data',
    value: 'instance.title',
    recordType: 'INSTANCE',
  }, {
    id: 'alternativeTitles.items.properties.alternativeTitleTypeId',
    label: 'ui-data-import.settings.matchProfiles.alternativeTitleType',
    categoryId: 'title-data',
    value: 'instance.alternativeTitles[].alternativeTitleTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'alternativeTitles.items.properties.alternativeTitle',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.alternativeTitles.field.alternativeTitle',
    categoryId: 'title-data',
    value: 'instance.alternativeTitles[].alternativeTitle',
    recordType: 'INSTANCE',
  }, {
    id: 'indexTitle',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.titleData.field.indexTitle',
    categoryId: 'title-data',
    value: 'instance.indexTitle',
    recordType: 'INSTANCE',
  }, {
    id: 'series',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.series.field.series',
    categoryId: 'title-data',
    value: 'instance.series',
    recordType: 'INSTANCE',
  }, {
    id: 'precedingInstanceId',
    label: 'ui-data-import.settings.matchProfiles.precedingTitle',
    categoryId: 'title-data',
    value: 'instanceprecedingsucceedingtitle.precedingInstanceId',
    recordType: 'INSTANCE',
  }, {
    id: 'succeedingInstanceId',
    label: 'ui-data-import.settings.matchProfiles.succeedingTitle',
    categoryId: 'title-data',
    value: 'instanceprecedingsucceedingtitle.succeedingInstanceId',
    recordType: 'INSTANCE',
  },
  /* Instance Identifier */
  {
    id: 'identifiers.items.properties.value',
    label: 'ui-data-import.settings.mappingProfiles.map.identifiers.section',
    categoryId: 'identifier',
    value: 'instance.identifiers[].value',
    recordType: 'INSTANCE',
    fromResources: {
      recordsName: 'identifierTypes',
      fieldToDisplay: 'name',
      labelToSend: 'identifierTypeId',
      fieldToSend: 'id',
    },
  },
  /* Instance Contributor */
  {
    id: 'contributors.items.properties.name',
    label: 'ui-data-import.name',
    categoryId: 'contributor',
    value: 'instance.contributors[].name',
    recordType: 'INSTANCE',
  }, {
    id: 'contributors.items.properties.contributorNameTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.contributors.field.contributorNameTypeId',
    categoryId: 'contributor',
    value: 'instance.contributors[].contributorNameTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'contributors.items.properties.contributorTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.identifiers.field.identifierTypeId',
    categoryId: 'contributor',
    value: 'instance.contributors[].contributorTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'contributors.items.properties.contributorTypeText',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.contributors.field.contributorTypeText',
    categoryId: 'contributor',
    value: 'instance.contributors[].contributorTypeText',
    recordType: 'INSTANCE',
  }, {
    id: 'contributors.items.properties.primary',
    label: 'ui-data-import.settings.matchProfiles.makePrimary',
    categoryId: 'contributor',
    value: 'instance.contributors[].primary',
    recordType: 'INSTANCE',
  },
  /* Instance Descriptive data */
  {
    id: 'publication.items.properties.publisher',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.publisher',
    categoryId: 'descriptive-data',
    value: 'instance.publication[].publisher',
    recordType: 'INSTANCE',
  }, {
    id: 'publication.items.properties.role',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.role',
    categoryId: 'descriptive-data',
    value: 'instance.publication[].role',
    recordType: 'INSTANCE',
  }, {
    id: 'publication.items.properties.place',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.place',
    categoryId: 'descriptive-data',
    value: 'instance.publication[].place',
    recordType: 'INSTANCE',
  }, {
    id: 'publication.items.properties.dateOfPublication',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.dateOfPublication',
    categoryId: 'descriptive-data',
    value: 'instance.publication[].dateOfPublication',
    recordType: 'INSTANCE',
  }, {
    id: 'editions',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.edition',
    categoryId: 'descriptive-data',
    value: 'instance.editions',
    recordType: 'INSTANCE',
  }, {
    id: 'physicalDescriptions',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.physicalDescription',
    categoryId: 'descriptive-data',
    value: 'instance.physicalDescriptions',
    recordType: 'INSTANCE',
  }, {
    id: 'instanceTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.instanceTypeId',
    categoryId: 'descriptive-data',
    value: 'instance.instanceTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'natureOfContentTermIds',
    label: 'ui-data-import.settings.matchProfiles.natureOfContent',
    categoryId: 'descriptive-data',
    value: 'instance.natureOfContentTermIds',
    recordType: 'INSTANCE',
  }, {
    id: 'instanceFormatIds',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.instanceFormatId',
    categoryId: 'descriptive-data',
    value: 'instance.instanceFormatIds',
    recordType: 'INSTANCE',
  }, {
    id: 'languages',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.languageId',
    categoryId: 'descriptive-data',
    value: 'instance.languages',
    recordType: 'INSTANCE',
  }, {
    id: 'publicationFrequency',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.publicationFrequency',
    categoryId: 'descriptive-data',
    value: 'instance.publicationFrequency',
    recordType: 'INSTANCE',
  }, {
    id: 'publicationRange',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.publicationRange',
    categoryId: 'descriptive-data',
    value: 'instance.publicationRange',
    recordType: 'INSTANCE',
  },
  /* Instance Instance notes */
  {
    id: 'notes.items.properties.instanceNoteTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.noteType',
    categoryId: 'instance-notes',
    value: 'instance.notes[].instanceNoteTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'notes.items.properties.note',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'instance-notes',
    value: 'instance.notes[].note',
    recordType: 'INSTANCE',
  }, {
    id: 'notes.items.properties.staffOnly',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.staffOnly',
    categoryId: 'instance-notes',
    value: 'instance.notes[].staffOnly',
    recordType: 'INSTANCE',
  },
  /* Instance Electronic access */
  {
    id: 'electronicAccess.items.properties.relationshipId',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.relationship',
    categoryId: 'electronic-access',
    value: 'instance.electronicAccess[].relationshipId',
    recordType: 'INSTANCE',
  }, {
    id: 'electronicAccess.items.properties.uri',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.uri',
    categoryId: 'electronic-access',
    value: 'instance.electronicAccess[].uri',
    recordType: 'INSTANCE',
  }, {
    id: 'electronicAccess.items.properties.linkText',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.linkText',
    categoryId: 'electronic-access',
    value: 'instance.electronicAccess[].linkText',
    recordType: 'INSTANCE',
  }, {
    id: 'electronicAccess.items.properties.materialsSpecification',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.materialsSpecified',
    categoryId: 'electronic-access',
    value: 'instance.electronicAccess[].materialsSpecification',
    recordType: 'INSTANCE',
  }, {
    id: 'electronicAccess.items.properties.publicNote',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.urlPublicNote',
    categoryId: 'electronic-access',
    value: 'instance.electronicAccess[].publicNote',
    recordType: 'INSTANCE',
  },
  /* Instance Subject */
  {
    id: 'subjects',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.subject.section',
    categoryId: 'subject',
    value: 'instance.subjects',
    recordType: 'INSTANCE',
  },
  /* Instance Classification */
  {
    id: 'classifications.items.properties.classificationTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.classificationTypeId',
    categoryId: 'classification',
    value: 'instance.classifications[].classificationTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'classifications.items.properties.classificationNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.classifications.section',
    categoryId: 'classification',
    value: 'instance.classifications[].classificationNumber',
    recordType: 'INSTANCE',
  },
  /* Instance Instance relationship */
  {
    id: 'superInstanceId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.parentInstances.field.superInstanceId',
    categoryId: 'instance-relationship',
    value: 'instancerelationship.superInstanceId',
    recordType: 'INSTANCE',
  }, {
    id: 'instanceRelationshipTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.childInstances.field.instanceRelationshipTypeId',
    categoryId: 'instance-relationship',
    value: 'instancerelationship.instanceRelationshipTypeId',
    recordType: 'INSTANCE',
  }, {
    id: 'subInstanceId',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.childInstances.field.subInstanceId',
    categoryId: 'instance-relationship',
    value: 'instancerelationship.subInstanceId',
    recordType: 'INSTANCE',
  }, {
    id: 'poLineNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.acquisitionsData.field.purchaseOrderLineNumber',
    categoryId: 'acquisitions-data',
    value: 'instance.purchaseOrderLineNumber',
    recordType: 'INSTANCE',
  }, {
    id: 'referenceNumbers',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.acquisitionsData.field.vendorReferenceNumber',
    categoryId: 'acquisitions-data',
    value: 'instance.vendorReferenceNumber',
    recordType: 'INSTANCE',
  },
  /* Holdings Admin data */
  {
    id: 'id',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.administrativeData.field.id',
    categoryId: 'admin-data',
    value: 'holdingsrecord.id',
    recordType: 'HOLDINGS',
  },
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'admin-data',
    value: 'holdingsrecord.metadata.updatedDate',
    recordType: 'HOLDINGS',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'admin-data',
    value: 'holdingsrecord.metadata.updatedByUsername',
    recordType: 'HOLDINGS',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'admin-data',
    value: 'holdingsrecord.metadata.createdDate',
    recordType: 'HOLDINGS',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'admin-data',
    value: 'holdingsrecord.metadata.createdByUsername',
    recordType: 'HOLDINGS',
  }, {
    id: 'discoverySuppress',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.discoverySuppress',
    categoryId: 'admin-data',
    value: 'holdingsrecord.discoverySuppress',
    recordType: 'HOLDINGS',
  }, {
    id: 'hrid',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.administrativeData.field.hrid',
    categoryId: 'admin-data',
    value: 'holdingsrecord.hrid',
    recordType: 'HOLDINGS',
  }, {
    id: 'formerIds',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.administrativeData.field.formerId',
    categoryId: 'admin-data',
    value: 'holdingsrecord.formerIds[]',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.administrativeData.field.holdingsTypeId',
    categoryId: 'admin-data',
    value: 'holdingsrecord.holdingsTypeId',
    recordType: 'HOLDINGS',
  }, {
    id: 'statisticalCodeIds',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.statisticalCode',
    categoryId: 'admin-data',
    value: 'holdingsrecord.statisticalCodeIds[]',
    recordType: 'HOLDINGS',
  },
  /* Holdings Location */
  {
    id: 'permanentLocationId',
    label: 'ui-data-import.settings.mappingProfiles.map.location.field.permanent',
    categoryId: 'location',
    value: 'holdingsrecord.permanentLocationId',
    recordType: 'HOLDINGS',
  }, {
    id: 'temporaryLocationId',
    label: 'ui-data-import.settings.mappingProfiles.map.location.field.temporary',
    categoryId: 'location',
    value: 'holdingsrecord.temporaryLocationId',
    recordType: 'HOLDINGS',
  }, {
    id: 'shelvingTitle',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.location.field.shelvingTitle',
    categoryId: 'location',
    value: 'holdingsrecord.shelvingTitle',
    recordType: 'HOLDINGS',
  }, {
    id: 'copyNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.field.copyNumber',
    categoryId: 'location',
    value: 'holdingsrecord.copyNumber',
    recordType: 'HOLDINGS',
  }, {
    id: 'callNumberTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberType',
    categoryId: 'location',
    value: 'holdingsrecord.callNumberTypeId',
    recordType: 'HOLDINGS',
  }, {
    id: 'callNumberPrefix',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberPrefix',
    categoryId: 'location',
    value: 'holdingsrecord.callNumberPrefix',
    recordType: 'HOLDINGS',
  }, {
    id: 'callNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumber',
    categoryId: 'location',
    value: 'holdingsrecord.callNumber',
    recordType: 'HOLDINGS',
  }, {
    id: 'callNumberSuffix',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberSuffix',
    categoryId: 'location',
    value: 'holdingsrecord.callNumberSuffix',
    recordType: 'HOLDINGS',
  },
  /* Holdings Holding details */
  {
    id: 'numberOfItems',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.details.field.numberOfItems',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.numberOfItems[]',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatements.items.properties.statement',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.statements.field.holdingsStatement',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatements[].statement',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatements.items.properties.note',
    label: 'ui-data-import.settings.matchProfiles.holdingsStatementNote',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatements[].note',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatementsForSupplements.items.properties.statement',
    label: 'ui-data-import.settings.matchProfiles.holdingsStatementForSupplements',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatementsForSupplements[].statement',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatementsForSupplements.items.properties.note',
    label: 'ui-data-import.settings.matchProfiles.holdingsStatementForSupplementsNote',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatementsForSupplements[].note',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatementsForIndexes.items.properties.statement',
    label: 'ui-data-import.settings.matchProfiles.holdingsStatementForIndexes',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatementsForIndexes[].statement',
    recordType: 'HOLDINGS',
  }, {
    id: 'holdingsStatementsForIndexes.items.properties.note',
    label: 'ui-data-import.settings.matchProfiles.holdingsStatementForIndexesNote',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.holdingsStatementsForIndexes[].note',
    recordType: 'HOLDINGS',
  }, {
    id: 'illPolicyId',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.field.illPolicy',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.illPolicyId',
    recordType: 'HOLDINGS',
  }, {
    id: 'digitizationPolicy',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.field.digitizationPolicy',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.digitizationPolicy',
    recordType: 'HOLDINGS',
  }, {
    id: 'retentionPolicy',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.field.retentionPolicy',
    categoryId: 'holdings-details',
    value: 'holdingsrecord.retentionPolicy',
    recordType: 'HOLDINGS',
  },
  /* Holdings Holdings notes */
  {
    id: 'notes.items.properties.holdingsNoteTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.noteType',
    categoryId: 'holdings-notes',
    value: 'holdingsrecord.notes[].holdingsNoteTypeId',
    recordType: 'HOLDINGS',
  }, {
    id: 'notes.items.properties.note',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'holdings-notes',
    value: 'holdingsrecord.notes[].note',
    recordType: 'HOLDINGS',
  }, {
    id: 'notes.items.properties.staffOnly',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.staffOnly',
    categoryId: 'holdings-notes',
    value: 'holdingsrecord.notes[].staffOnly',
    recordType: 'HOLDINGS',
  },
  /* Holdings Electronic access */
  {
    id: 'electronicAccess.items.properties.relationshipId',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.relationship',
    categoryId: 'electronic-access',
    value: 'holdingsrecord.electronicAccess[].relationshipId',
    recordType: 'HOLDINGS',
  }, {
    id: 'electronicAccess.items.properties.uri',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.uri',
    categoryId: 'electronic-access',
    value: 'holdingsrecord.electronicAccess[].uri',
    recordType: 'HOLDINGS',
  }, {
    id: 'electronicAccess.items.properties.linkText',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.linkText',
    categoryId: 'electronic-access',
    value: 'holdingsrecord.electronicAccess[].linkText',
    recordType: 'HOLDINGS',
  }, {
    id: 'electronicAccess.items.properties.materialsSpecification',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.materialsSpecified',
    categoryId: 'electronic-access',
    value: 'holdingsrecord.electronicAccess[].materialsSpecification',
    recordType: 'HOLDINGS',
  }, {
    id: 'electronicAccess.items.properties.publicNote',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.urlPublicNote',
    categoryId: 'electronic-access',
    value: 'holdingsrecord.electronicAccess[].publicNote',
    recordType: 'HOLDINGS',
  },
  /* Holdings Receiving history */
  {
    id: 'receivingHistory.properties.entries.items.properties.publicDisplay',
    label: 'ui-data-import.settings.mappingProfiles.map.field.publicDisplay',
    categoryId: 'receiving-history',
    value: 'holdingsrecord.receivingHistory[].entries[].publicDisplay',
    recordType: 'HOLDINGS',
  }, {
    id: 'receivingHistory.properties.entries.items.properties.enumeration',
    label: 'ui-data-import.settings.mappingProfiles.map.field.enumeration',
    categoryId: 'receiving-history',
    value: 'holdingsrecord.receivingHistory[].entries[].enumeration',
    recordType: 'HOLDINGS',
  }, {
    id: 'receivingHistory.properties.entries.items.properties.chronology',
    label: 'ui-data-import.settings.mappingProfiles.map.field.chronology',
    categoryId: 'receiving-history',
    value: 'holdingsrecord.receivingHistory[].entries[].chronology',
    recordType: 'HOLDINGS',
  },
  /* Holdings acquisitions data  */
  {
    id: 'poLineNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.acquisitionsData.field.purchaseOrderLineNumber',
    categoryId: 'acquisitions-data',
    value: 'holdingsrecord.purchaseOrderLineNumber',
    recordType: 'HOLDINGS',
  }, {
    id: 'referenceNumbers',
    label: 'ui-data-import.settings.mappingProfiles.map.holdings.acquisitionsData.field.vendorReferenceNumber',
    categoryId: 'acquisitions-data',
    value: 'holdingsrecord.vendorReferenceNumber',
    recordType: 'HOLDINGS',
  },
  /* Item Admin data */
  {
    id: 'id',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.id',
    categoryId: 'admin-data',
    value: 'item.id',
    recordType: 'ITEM',
  },
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'admin-data',
    value: 'item.metadata.updatedDate',
    recordType: 'ITEM',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'admin-data',
    value: 'item.metadata.updatedByUsername',
    recordType: 'ITEM',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'admin-data',
    value: 'item.metadata.createdDate',
    recordType: 'ITEM',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'admin-data',
    value: 'item.metadata.createdByUsername',
    recordType: 'ITEM',
  }, {
    id: 'discoverySuppress',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.discoverySuppress',
    categoryId: 'admin-data',
    value: 'item.discoverySuppress',
    recordType: 'ITEM',
  }, {
    id: 'hrid',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.hrid',
    categoryId: 'admin-data',
    value: 'item.hrid',
    recordType: 'ITEM',
  }, {
    id: 'barcode',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.barcode',
    categoryId: 'admin-data',
    value: 'item.barcode',
    recordType: 'ITEM',
  }, {
    id: 'accessionNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.accessionNumber',
    categoryId: 'admin-data',
    value: 'item.accessionNumber',
    recordType: 'ITEM',
  }, {
    id: 'id',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.itemIdentifier',
    categoryId: 'admin-data',
    value: 'item.id',
    recordType: 'ITEM',
  }, {
    id: 'formerIds',
    label: 'ui-data-import.settings.mappingProfiles.map.item.administrativeData.field.formerId',
    categoryId: 'admin-data',
    value: 'item.formerIds[]',
    recordType: 'ITEM',
  }, {
    id: 'statisticalCodeIds',
    label: 'ui-data-import.settings.mappingProfiles.map.administrativeData.field.statisticalCode',
    categoryId: 'admin-data',
    value: 'item.statisticalCodeIds[]',
    recordType: 'ITEM',
  },
  /* Item Item data */
  {
    id: 'materialTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemData.field.materialType',
    categoryId: 'item-data',
    value: 'item.materialTypeId',
    recordType: 'ITEM',
  }, {
    id: 'copyNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.field.copyNumber',
    categoryId: 'item-data',
    value: 'item.copyNumber',
    recordType: 'ITEM',
  }, {
    id: 'itemLevelCallNumberTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberType',
    categoryId: 'item-data',
    value: 'item.itemLevelCallNumberTypeId',
    recordType: 'ITEM',
  }, {
    id: 'itemLevelCallNumberPrefix',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberPrefix',
    categoryId: 'item-data',
    value: 'item.itemLevelCallNumberPrefix',
    recordType: 'ITEM',
  }, {
    id: 'itemLevelCallNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumber',
    categoryId: 'item-data',
    value: 'item.itemLevelCallNumber',
    recordType: 'ITEM',
  }, {
    id: 'itemLevelCallNumberSuffix',
    label: 'ui-data-import.settings.mappingProfiles.map.field.callNumberSuffix',
    categoryId: 'item-data',
    value: 'item.itemLevelCallNumberSuffix',
    recordType: 'ITEM',
  }, {
    id: 'numberOfPieces',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemData.field.numberOfPieces',
    categoryId: 'item-data',
    value: 'item.numberOfPieces',
    recordType: 'ITEM',
  }, {
    id: 'descriptionOfPieces',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemData.field.descriptionOfPieces',
    categoryId: 'item-data',
    value: 'item.descriptionOfPieces',
    recordType: 'ITEM',
  },
  /* Item Enumeration data */
  {
    id: 'enumeration',
    label: 'ui-data-import.settings.mappingProfiles.map.field.enumeration',
    categoryId: 'enumeration-data',
    value: 'item.enumeration',
    recordType: 'ITEM',
  }, {
    id: 'chronology',
    label: 'ui-data-import.settings.mappingProfiles.map.field.chronology',
    categoryId: 'enumeration-data',
    value: 'item.chronology',
    recordType: 'ITEM',
  }, {
    id: 'volume',
    label: 'ui-data-import.settings.mappingProfiles.map.item.enumerationData.field.volume',
    categoryId: 'enumeration-data',
    value: 'item.volume',
    recordType: 'ITEM',
  }, {
    id: 'yearCaption',
    label: 'ui-data-import.settings.mappingProfiles.map.item.enumerationData.field.yearCaption',
    categoryId: 'enumeration-data',
    value: 'item.yearCaption',
    recordType: 'ITEM',
  },
  /* Item Condition */
  {
    id: 'numberOfMissingPieces',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemCondition.field.numberOfMissingPieces',
    categoryId: 'condition',
    value: 'item.numberOfMissingPieces',
    recordType: 'ITEM',
  }, {
    id: 'missingPieces',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemCondition.field.missingPieces',
    categoryId: 'condition',
    value: 'item.missingPieces',
    recordType: 'ITEM',
  }, {
    id: 'missingPiecesDate',
    label: 'ui-data-import.settings.matchProfiles.missingDate',
    categoryId: 'condition',
    value: 'item.missingPiecesDate',
    recordType: 'ITEM',
  }, {
    id: 'itemDamagedStatusId',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemCondition.field.itemDamagedStatus',
    categoryId: 'condition',
    value: 'item.itemDamagedStatusId',
    recordType: 'ITEM',
  }, {
    id: 'itemDamagedStatusDate',
    label: 'ui-data-import.settings.matchProfiles.damagedDate',
    categoryId: 'condition',
    value: 'item.itemDamagedStatusDate',
    recordType: 'ITEM',
  },
  /* Item Item notes */
  {
    id: 'notes.items.properties.itemNoteTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.noteType',
    categoryId: 'item-notes',
    value: 'item.notes[].itemNoteTypeId',
    recordType: 'ITEM',
  }, {
    id: 'notes.items.properties.note',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'item-notes',
    value: 'item.notes[].note',
    recordType: 'ITEM',
  }, {
    id: 'notes.items.properties.staffOnly',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.staffOnly',
    categoryId: 'item-notes',
    value: 'item.notes[].staffOnly',
    recordType: 'ITEM',
  },
  /* Item Loan and availability */
  {
    id: 'permanentLoanTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemLoans.field.loanTypePermanentRequired',
    categoryId: 'loan-and-availability',
    value: 'item.permanentLoanTypeId',
    recordType: 'ITEM',
  }, {
    id: 'temporaryLoanTypeId',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemLoans.field.loanTypeTemporary',
    categoryId: 'loan-and-availability',
    value: 'item.temporaryLoanTypeId',
    recordType: 'ITEM',
  }, {
    id: 'status.properties.name',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemLoans.field.loanStatus',
    categoryId: 'loan-and-availability',
    value: 'item.status.name',
    recordType: 'ITEM',
  }, {
    id: 'circulationNotes.items.properties.noteType',
    label: 'ui-data-import.settings.matchProfiles.checkInOutNoteType',
    categoryId: 'loan-and-availability',
    value: 'item.circulationNotes[].noteType',
    recordType: 'ITEM',
  }, {
    id: 'circulationNotes.items.properties.note',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'loan-and-availability',
    value: 'item.circulationNotes[].note',
    recordType: 'ITEM',
  }, {
    id: 'circulationNotes.items.properties.staffOnly',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.staffOnly',
    categoryId: 'loan-and-availability',
    value: 'item.circulationNotes[].staffOnly',
    recordType: 'ITEM',
  },
  /* Item Location */
  {
    id: 'permanentLocationId',
    label: 'ui-data-import.settings.mappingProfiles.map.location.field.permanent',
    categoryId: 'location',
    value: 'item.permanentLocationId',
    recordType: 'ITEM',
  }, {
    id: 'temporaryLocationId',
    label: 'ui-data-import.settings.mappingProfiles.map.location.field.temporary',
    categoryId: 'location',
    value: 'item.temporaryLocationId',
    recordType: 'ITEM',
  },
  /* Item Electronic access */
  {
    id: 'electronicAccess.items.properties.relationshipId',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.relationship',
    categoryId: 'electronic-access',
    value: 'item.electronicAccess[].relationshipId',
    recordType: 'ITEM',
  }, {
    id: 'electronicAccess.items.properties.uri',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.uri',
    categoryId: 'electronic-access',
    value: 'item.electronicAccess[].uri',
    recordType: 'ITEM',
  }, {
    id: 'electronicAccess.items.properties.linkText',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.linkText',
    categoryId: 'electronic-access',
    value: 'item.electronicAccess[].linkText',
    recordType: 'ITEM',
  }, {
    id: 'electronicAccess.items.properties.materialsSpecification',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.materialsSpecified',
    categoryId: 'electronic-access',
    value: 'item.electronicAccess[].materialsSpecification',
    recordType: 'ITEM',
  }, {
    id: 'electronicAccess.items.properties.publicNote',
    label: 'ui-data-import.settings.mappingProfiles.map.EAccess.field.urlPublicNote',
    categoryId: 'electronic-access',
    value: 'item.electronicAccess[].publicNote',
    recordType: 'ITEM',
  },
  /* Item acquisitions data */
  {
    id: 'poLineNumber',
    label: 'ui-data-import.settings.mappingProfiles.map.item.acquisitionsData.field.purchaseOrderLineNumber',
    categoryId: 'acquisitions-data',
    value: 'item.purchaseOrderLineNumber',
    recordType: 'ITEM',
  }, {
    id: 'referenceNumbers',
    label: 'ui-data-import.settings.mappingProfiles.map.item.acquisitionsData.field.vendorReferenceNumber',
    categoryId: 'acquisitions-data',
    value: 'item.vendorReferenceNumber',
    recordType: 'ITEM',
  },
  /* Order PO admin data */
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'po-admin-data',
    value: 'purchase_order.metadata.updatedDate',
    recordType: 'ORDER',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'po-admin-data',
    value: 'purchase_order.metadata.updatedByUsername',
    recordType: 'ORDER',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'po-admin-data',
    value: 'purchase_order.metadata.createdDate',
    recordType: 'ORDER',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'po-admin-data',
    value: 'purchase_order.metadata.createdByUsername',
    recordType: 'ORDER',
  },
  /* Order PO purchase order */
  {
    id: 'poNumber',
    label: 'ui-data-import.settings.matchProfiles.poNumber',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.poNumber',
    recordType: 'ORDER',
  }, {
    id: 'vendor',
    label: 'ui-data-import.settings.matchProfiles.vendor',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.vendor',
    recordType: 'ORDER',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdBy',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.metadata.createdByUsername',
    recordType: 'ORDER',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdOn',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.metadata.createdDate',
    recordType: 'ORDER',
  }, {
    id: 'assignedTo',
    label: 'ui-data-import.settings.matchProfiles.assignedTo',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.assignedTo',
    recordType: 'ORDER',
  }, {
    id: 'manualPo',
    label: 'ui-data-import.settings.matchProfiles.manual',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.manualPo',
    recordType: 'ORDER',
  }, {
    id: 'reEncumber',
    label: 'ui-data-import.settings.matchProfiles.reEncumber',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.reEncumber',
    recordType: 'ORDER',
  }, {
    id: 'orderType',
    label: 'ui-data-import.settings.matchProfiles.orderType',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.orderType',
    recordType: 'ORDER',
  }, {
    id: 'acqUnitIds',
    label: 'ui-data-import.settings.matchProfiles.acquisitionUnits',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.acqUnitIds[]',
    recordType: 'ORDER',
  }, {
    id: 'billTo',
    label: 'ui-data-import.settings.matchProfiles.billTo',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.billTo',
    recordType: 'ORDER',
  }, {
    id: 'shipTo',
    label: 'ui-data-import.settings.matchProfiles.shipTo',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.shipTo',
    recordType: 'ORDER',
  }, {
    id: 'notes',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'po-purchase-order',
    value: 'purchase_order.notes[]',
    recordType: 'ORDER',
  },
  /* Order PO renewal information */
  {
    id: 'interval',
    label: 'ui-data-import.settings.matchProfiles.renewalInterval',
    categoryId: 'po-renewal-information',
    value: 'purchase_order.renewal.interval',
    recordType: 'ORDER',
  }, {
    id: 'renewalDate',
    label: 'ui-data-import.settings.matchProfiles.renewalDate',
    categoryId: 'po-renewal-information',
    value: 'purchase_order.renewal.renewalDate',
    recordType: 'ORDER',
  }, {
    id: 'reviewPeriod',
    label: 'ui-data-import.settings.matchProfiles.renewalPeriod',
    categoryId: 'po-renewal-information',
    value: 'purchase_order.renewal.reviewPeriod',
    recordType: 'ORDER',
  }, {
    id: 'manualRenewal',
    label: 'ui-data-import.settings.matchProfiles.manualRenewal',
    categoryId: 'po-renewal-information',
    value: 'purchase_order.renewal.manualRenewal',
    recordType: 'ORDER',
  },
  /* Order PO summary */
  {
    id: 'approved',
    label: 'ui-data-import.settings.matchProfiles.approved',
    categoryId: 'po-summary',
    value: 'purchase_order.approved',
    recordType: 'ORDER',
  }, {
    id: 'workflowStatus',
    label: 'ui-data-import.settings.matchProfiles.workflowStatus',
    categoryId: 'po-summary',
    value: 'purchase_order.workflowStatus',
    recordType: 'ORDER',
  },
  /* Order PO tags */
  {
    id: 'tags',
    label: 'ui-data-import.settings.matchProfiles.tag',
    categoryId: 'po-tags',
    value: 'purchase_order.tags.tagList[]',
    recordType: 'ORDER',
  },
  /* Order POL admin data */
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'pol-admin-data',
    value: 'po_line.metadata.updatedDate',
    recordType: 'ORDER',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'pol-admin-data',
    value: 'po_line.metadata.updatedByUsername',
    recordType: 'ORDER',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'pol-admin-data',
    value: 'po_line.metadata.createdDate',
    recordType: 'ORDER',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'pol-admin-data',
    value: 'po_line.metadata.createdByUsername',
    recordType: 'ORDER',
  },
  /* Order POL item details */
  {
    id: 'title',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.precedingTitles.field.precedingTitlesTitle',
    categoryId: 'pol-item-details',
    value: 'po_line.title',
    recordType: 'ORDER',
  }, {
    id: 'instanceId',
    label: 'ui-data-import.settings.matchProfiles.instanceId',
    categoryId: 'pol-item-details',
    value: 'po_line.instanceId',
    recordType: 'ORDER',
  }, {
    id: 'publisher',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.publisher',
    categoryId: 'pol-item-details',
    value: 'po_line.publisher',
    recordType: 'ORDER',
  }, {
    id: 'publicationDate',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.dateOfPublication',
    categoryId: 'pol-item-details',
    value: 'po_line.publicationDate',
    recordType: 'ORDER',
  }, {
    id: 'edition',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.descriptiveData.field.edition',
    categoryId: 'pol-item-details',
    value: 'po_line.edition',
    recordType: 'ORDER',
  }, {
    id: 'subscriptionFrom',
    label: 'ui-data-import.settings.matchProfiles.subscriptionFrom',
    categoryId: 'pol-item-details',
    value: 'po_line.details.subscriptionFrom',
    recordType: 'ORDER',
  }, {
    id: 'subscriptionTo',
    label: 'ui-data-import.settings.matchProfiles.subscriptionTo',
    categoryId: 'pol-item-details',
    value: 'po_line.details.subscriptionTo',
    recordType: 'ORDER',
  }, {
    id: 'subscriptionInterval',
    label: 'ui-data-import.settings.matchProfiles.subscriptionInterval',
    categoryId: 'pol-item-details',
    value: 'po_line.details.subscriptionInterval',
    recordType: 'ORDER',
  }, {
    id: 'contributor',
    label: 'ui-data-import.settings.mappingProfiles.map.instance.contributors.section',
    categoryId: 'pol-item-details',
    value: 'po_line.contributors[].contributor',
    recordType: 'ORDER',
  }, {
    id: 'contributorNameTypeId',
    label: 'ui-data-import.settings.matchProfiles.contributorType',
    categoryId: 'pol-item-details',
    value: 'po_line.contributors[].contributorNameTypeId',
    recordType: 'ORDER',
  }, {
    id: 'productId',
    label: 'ui-data-import.settings.matchProfiles.productId',
    categoryId: 'pol-item-details',
    value: 'po_line.details.productIds[].productId',
    recordType: 'ORDER',
  }, {
    id: 'qualifier',
    label: 'ui-data-import.settings.matchProfiles.qualifier',
    categoryId: 'pol-item-details',
    value: 'po_line.details.productIds[].qualifier',
    recordType: 'ORDER',
  }, {
    id: 'productIdType',
    label: 'ui-data-import.settings.matchProfiles.productIdType',
    categoryId: 'pol-item-details',
    value: 'po_line.details.productIds[].productIdType',
    recordType: 'ORDER',
  }, {
    id: 'receivingNote',
    label: 'ui-data-import.settings.matchProfiles.receivingNote',
    categoryId: 'pol-item-details',
    value: 'po_line.details.receivingNote',
    recordType: 'ORDER',
  }, {
    id: 'description',
    label: 'ui-data-import.settings.matchProfiles.internalNote',
    categoryId: 'pol-item-details',
    value: 'po_line.description',
    recordType: 'ORDER',
  },
  /* Order POL details */
  {
    id: 'poLineNumber',
    label: 'ui-data-import.settings.matchProfiles.polNumber',
    categoryId: 'pol-details',
    value: 'po_line.poLineNumber',
    recordType: 'ORDER',
  }, {
    id: 'acquisitionMethod',
    label: 'ui-data-import.settings.mappingProfiles.map.field.acquisitionMethod',
    categoryId: 'pol-details',
    value: 'po_line.acquisitionMethod',
    recordType: 'ORDER',
  }, {
    id: 'orderFormat',
    label: 'ui-data-import.settings.mappingProfiles.map.field.orderFormat',
    categoryId: 'pol-details',
    value: 'po_line.orderFormat',
    recordType: 'ORDER',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdOn',
    categoryId: 'pol-details',
    value: 'po_line.metadata.createdDate',
    recordType: 'ORDER',
  }, {
    id: 'receiptDate',
    label: 'ui-data-import.settings.matchProfiles.receiptDate',
    categoryId: 'pol-details',
    value: 'po_line.receiptDate',
    recordType: 'ORDER',
  }, {
    id: 'source',
    label: 'ui-data-import.settings.matchProfiles.source',
    categoryId: 'pol-details',
    value: 'po_line.source',
    recordType: 'ORDER',
  }, {
    id: 'donor',
    label: 'ui-data-import.settings.matchProfiles.donor',
    categoryId: 'pol-details',
    value: 'po_line.donor',
    recordType: 'ORDER',
  }, {
    id: 'paymentStatus',
    label: 'ui-data-import.settings.matchProfiles.paymentStatus',
    categoryId: 'pol-details',
    value: 'po_line.paymentStatus',
    recordType: 'ORDER',
  }, {
    id: 'receiptStatus',
    label: 'ui-data-import.settings.mappingProfiles.map.field.receiptStatus',
    categoryId: 'pol-details',
    value: 'po_line.receiptStatus',
    recordType: 'ORDER',
  }, {
    id: 'selector',
    label: 'ui-data-import.settings.matchProfiles.selector',
    categoryId: 'pol-details',
    value: 'po_line.selector',
    recordType: 'ORDER',
  }, {
    id: 'requester',
    label: 'ui-data-import.settings.matchProfiles.requester',
    categoryId: 'pol-details',
    value: 'po_line.requester',
    recordType: 'ORDER',
  }, {
    id: 'cancellationRestriction',
    label: 'ui-data-import.settings.matchProfiles.cancellationRestriction',
    categoryId: 'pol-details',
    value: 'po_line.cancellationRestriction',
    recordType: 'ORDER',
  }, {
    id: 'rush',
    label: 'ui-data-import.settings.matchProfiles.rush',
    categoryId: 'pol-details',
    value: 'po_line.rush',
    recordType: 'ORDER',
  }, {
    id: 'collection',
    label: 'ui-data-import.settings.matchProfiles.collection',
    categoryId: 'pol-details',
    value: 'po_line.collection',
    recordType: 'ORDER',
  }, {
    id: 'checkinItems',
    label: 'ui-data-import.settings.matchProfiles.checkinItems',
    categoryId: 'pol-details',
    value: 'po_line.checkinItems',
    recordType: 'ORDER',
  }, {
    id: 'cancellationRestrictionNote',
    label: 'ui-data-import.settings.matchProfiles.cancellationRestriction',
    categoryId: 'pol-details',
    value: 'po_line.cancellationRestrictionNote',
    recordType: 'ORDER',
  }, {
    id: 'poLineDescription',
    label: 'ui-data-import.settings.matchProfiles.linaDescription',
    categoryId: 'pol-details',
    value: 'po_line.poLineDescription',
    recordType: 'ORDER',
  },
  /* Order POL cost details */
  {
    id: 'listUnitPrice',
    label: 'ui-data-import.settings.matchProfiles.listUnitPrice',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.listUnitPrice',
    recordType: 'ORDER',
  }, {
    id: 'currency',
    label: 'ui-data-import.settings.matchProfiles.currency',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.currency',
    recordType: 'ORDER',
  }, {
    id: 'quantityPhysical',
    label: 'ui-data-import.settings.matchProfiles.quantityPhysical',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.quantityPhysical',
    recordType: 'ORDER',
  }, {
    id: 'additionalCost',
    label: 'ui-data-import.settings.matchProfiles.additionalCost',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.additionalCost',
    recordType: 'ORDER',
  }, {
    id: 'listUnitPriceElectronic',
    label: 'ui-data-import.settings.matchProfiles.unitPriceOfElectronic',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.listUnitPriceElectronic',
    recordType: 'ORDER',
  }, {
    id: 'discount',
    label: 'ui-data-import.settings.matchProfiles.discount',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.discount',
    recordType: 'ORDER',
  }, {
    id: 'quantityElectronic',
    label: 'ui-data-import.settings.matchProfiles.quantityElectronic',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.quantityElectronic',
    recordType: 'ORDER',
  }, {
    id: 'poLineEstimatedPrice',
    label: 'ui-data-import.settings.matchProfiles.estimatedPrice',
    categoryId: 'pol-cost-details',
    value: 'po_line.cost.poLineEstimatedPrice',
    recordType: 'ORDER',
  },
  /* Order POL fund distribution */
  {
    id: 'fundId',
    label: 'ui-data-import.settings.matchProfiles.fundIdentifier',
    categoryId: 'pol-fund-distribution',
    value: 'po_line.fundDistribution[].fundId',
    recordType: 'ORDER',
  }, {
    id: 'value',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.identifiers.field.value',
    categoryId: 'pol-fund-distribution',
    value: 'po_line.fundDistribution[].value',
    recordType: 'ORDER',
  }, {
    id: 'distributionType',
    label: 'ui-data-import.settings.matchProfiles.selectAdjustment',
    categoryId: 'pol-fund-distribution',
    value: 'po_line.fundDistribution[].distributionType',
    recordType: 'ORDER',
  }, {
    id: 'poLineEstimatedPrice',
    label: 'ui-data-import.settings.matchProfiles.amount',
    categoryId: 'pol-fund-distribution',
    value: 'po_line.fundDistribution[].poLineEstimatedPrice',
    recordType: 'ORDER',
  },
  /* Order POL location */
  {
    id: 'locationId',
    label: 'ui-data-import.settings.mappingProfiles.map.location.section',
    categoryId: 'pol-location',
    value: 'po_line.locations[].locationId',
    recordType: 'ORDER',
  }, {
    id: 'quantityPhysical',
    label: 'ui-data-import.settings.matchProfiles.quantityPhysical',
    categoryId: 'pol-location',
    value: 'po_line.locations[].quantityPhysical',
    recordType: 'ORDER',
  }, {
    id: 'quantityElectronic',
    label: 'ui-data-import.settings.matchProfiles.quantityElectronic',
    categoryId: 'pol-location',
    value: 'po_line.locations[].quantityElectronic',
    recordType: 'ORDER',
  },
  /* Order POL physical resource details */
  {
    id: 'materialSupplier',
    label: 'ui-data-import.settings.matchProfiles.materialSupplier',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.materialSupplier',
    recordType: 'ORDER',
  }, {
    id: 'receiptDue',
    label: 'ui-data-import.settings.matchProfiles.receiptDate',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.receiptDue',
    recordType: 'ORDER',
  }, {
    id: 'expectedReceiptDate',
    label: 'ui-data-import.settings.matchProfiles.expectedReceiptDate',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.expectedReceiptDate',
    recordType: 'ORDER',
  }, {
    id: 'createInventory',
    label: 'ui-data-import.settings.matchProfiles.createInventory',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.createInventory',
    recordType: 'ORDER',
  }, {
    id: 'materialType',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemData.field.materialType',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.materialType',
    recordType: 'ORDER',
  }, {
    id: 'volumes',
    label: 'ui-data-import.settings.mappingProfiles.map.item.enumerationData.field.volume',
    categoryId: 'pol-physical-resource-details',
    value: 'po_line.physical.volumes[]',
    recordType: 'ORDER',
  },
  /* Order POL e-resource details */
  {
    id: 'accessProvider',
    label: 'ui-data-import.settings.matchProfiles.accessProvider',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.accessProvider',
    recordType: 'ORDER',
  }, {
    id: 'activated',
    label: 'ui-data-import.settings.matchProfiles.activationStatus',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.activated',
    recordType: 'ORDER',
  }, {
    id: 'activationDue',
    label: 'ui-data-import.settings.matchProfiles.activationDue',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.activationDue',
    recordType: 'ORDER',
  }, {
    id: 'createInventory',
    label: 'ui-data-import.settings.matchProfiles.createInventory',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.createInventory',
    recordType: 'ORDER',
  }, {
    id: 'materialType',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemData.field.materialType',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.materialType',
    recordType: 'ORDER',
  }, {
    id: 'trial',
    label: 'ui-data-import.settings.matchProfiles.trial',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.trial',
    recordType: 'ORDER',
  }, {
    id: 'expectedActivation',
    label: 'ui-data-import.settings.matchProfiles.expectedActivation',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.expectedActivation',
    recordType: 'ORDER',
  }, {
    id: 'userLimit',
    label: 'ui-data-import.settings.matchProfiles.userLimit',
    categoryId: 'pol-e-resource-details',
    value: 'po_line.eresource.userLimit',
    recordType: 'ORDER',
  },
  /* Order POL vendor */
  {
    id: 'refNumber',
    label: 'ui-data-import.settings.matchProfiles.vendorRefNumber',
    categoryId: 'pol-vendor',
    value: 'po_line.vendorDetail.refNumber',
    recordType: 'ORDER',
  }, {
    id: 'refNumberType',
    label: 'ui-data-import.settings.matchProfiles.vendorRefType',
    categoryId: 'pol-vendor',
    value: 'po_line.vendorDetail.refNumberType',
    recordType: 'ORDER',
  }, {
    id: 'vendorAccount',
    label: 'ui-data-import.settings.matchProfiles.accountNumber',
    categoryId: 'pol-vendor',
    value: 'po_line.vendorDetail.vendorAccount',
    recordType: 'ORDER',
  }, {
    id: 'instructions',
    label: 'ui-data-import.settings.matchProfiles.instructionsToVendor',
    categoryId: 'pol-vendor',
    value: 'po_line.vendorDetail.instructions',
    recordType: 'ORDER',
  },
  /* Order POL notes */
  {
    id: 'createdDate',
    label: 'ui-data-import.settings.mappingProfiles.map.item.itemCondition.field.missingPiecesDate',
    categoryId: 'pol-notes',
    value: 'notes.metadata.createdDate',
    recordType: 'ORDER',
  }, {
    id: 'type',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.identifiers.field.identifierTypeId',
    categoryId: 'pol-notes',
    value: 'notes.type',
    recordType: 'ORDER',
  }, {
    id: 'title',
    label: 'ui-data-import.settings.mappingProfiles.map.titleData.precedingTitles.field.precedingTitlesTitle',
    categoryId: 'pol-notes',
    value: 'notes.title',
    recordType: 'ORDER',
  }, {
    id: 'content',
    label: 'ui-data-import.details',
    categoryId: 'pol-notes',
    value: 'notes.content',
    recordType: 'ORDER',
  },
  /* Order POL tags */
  {
    id: 'tags',
    label: 'ui-data-import.settings.matchProfiles.tag',
    categoryId: 'pol-tags',
    value: 'po_line.tags.tagList[]',
    recordType: 'ORDER',
  },
  /* Invoice Invoice admin data */
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'invoice-admin-data',
    value: 'invoice.metadata.updatedDate',
    recordType: 'INVOICE',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'invoice-admin-data',
    value: 'invoice.metadata.updatedByUsername',
    recordType: 'INVOICE',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'invoice-admin-data',
    value: 'invoice.metadata.createdDate',
    recordType: 'INVOICE',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'invoice-admin-data',
    value: 'invoice.metadata.createdByUsername',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice information */
  {
    id: 'invoiceDate',
    label: 'ui-data-import.settings.matchProfiles.invoiceDate',
    categoryId: 'invoice-information',
    value: 'invoice.invoiceDate',
    recordType: 'INVOICE',
  }, {
    id: 'paymentDue',
    label: 'ui-data-import.settings.matchProfiles.paymentDue',
    categoryId: 'invoice-information',
    value: 'invoice.paymentDue',
    recordType: 'INVOICE',
  }, {
    id: 'paymentTerms',
    label: 'ui-data-import.settings.matchProfiles.terms',
    categoryId: 'invoice-information',
    value: 'invoice.paymentTerms',
    recordType: 'INVOICE',
  }, {
    id: 'status',
    label: 'ui-data-import.settings.matchProfiles.status',
    categoryId: 'invoice-information',
    value: 'invoice.status',
    recordType: 'INVOICE',
  }, {
    id: 'approvalDate',
    label: 'ui-data-import.settings.matchProfiles.approvalDate',
    categoryId: 'invoice-information',
    value: 'invoice.approvalDate',
    recordType: 'INVOICE',
  }, {
    id: 'approvedBy',
    label: 'ui-data-import.settings.matchProfiles.approvedBy',
    categoryId: 'invoice-information',
    value: 'invoice.approvedBy',
    recordType: 'INVOICE',
  }, {
    id: 'acqUnitIds',
    label: 'ui-data-import.settings.matchProfiles.acquisitionUnits',
    categoryId: 'invoice-information',
    value: 'invoice.acqUnitIds[]',
    recordType: 'INVOICE',
  }, {
    id: 'subTotal',
    label: 'ui-data-import.settings.matchProfiles.subTotal',
    categoryId: 'invoice-information',
    value: 'invoice.subTotal',
    recordType: 'INVOICE',
  }, {
    id: 'adjustmentsTotal',
    label: 'ui-data-import.settings.matchProfiles.totalAdjustments',
    categoryId: 'invoice-information',
    value: 'invoice.adjustmentsTotal',
    recordType: 'INVOICE',
  }, {
    id: 'total',
    label: 'ui-data-import.settings.matchProfiles.totalAmount',
    categoryId: 'invoice-information',
    value: 'invoice.total',
    recordType: 'INVOICE',
  }, {
    id: 'billTo',
    label: 'ui-data-import.settings.matchProfiles.billToName',
    categoryId: 'invoice-information',
    value: 'invoice.billTo',
    recordType: 'INVOICE',
  }, {
    id: 'note',
    label: 'ui-data-import.settings.mappingProfiles.map.field.notes.note',
    categoryId: 'invoice-information',
    value: 'invoice.note',
    recordType: 'INVOICE',
  }, {
    id: 'lockTotal',
    label: 'ui-data-import.settings.matchProfiles.lockTotal',
    categoryId: 'invoice-information',
    value: 'invoice.lockTotal',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice adjustments */
  {
    id: 'adjustmentId',
    label: 'ui-data-import.settings.matchProfiles.presetAdjustment',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].adjustmentId',
    recordType: 'INVOICE',
  }, {
    id: 'description',
    label: 'ui-data-import.description',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].description',
    recordType: 'INVOICE',
  }, {
    id: 'value',
    label: 'ui-data-import.settings.matchProfiles.amount',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].value',
    recordType: 'INVOICE',
  }, {
    id: 'type',
    label: 'ui-data-import.settings.matchProfiles.type',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].type',
    recordType: 'INVOICE',
  }, {
    id: 'prorate',
    label: 'ui-data-import.settings.matchProfiles.prorate',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].prorate',
    recordType: 'INVOICE',
  }, {
    id: 'relationToTotal',
    label: 'ui-data-import.settings.matchProfiles.relationToTotal',
    categoryId: 'invoice-adjustments',
    value: 'invoice.adjustments[].relationToTotal',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice vendor information */
  {
    id: 'vendorInvoiceNo',
    label: 'ui-data-import.settings.matchProfiles.vendorInvoiceNo',
    categoryId: 'invoice-vendor-information',
    value: 'invoice.vendorInvoiceNo',
    recordType: 'INVOICE',
  }, {
    id: 'vendorId',
    label: 'ui-data-import.settings.matchProfiles.vendorName',
    categoryId: 'invoice-vendor-information',
    value: 'invoice.vendorId',
    recordType: 'INVOICE',
  }, {
    id: 'accountingCode',
    label: 'ui-data-import.settings.matchProfiles.accountingCode',
    categoryId: 'invoice-vendor-information',
    value: 'invoice.accountingCode',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice extended information */
  {
    id: 'folioInvoiceNo',
    label: 'ui-data-import.settings.matchProfiles.folioInvoiceNo',
    categoryId: 'invoice-extended-information',
    value: 'invoice.folioInvoiceNo',
    recordType: 'INVOICE',
  }, {
    id: 'chkSubscriptionOverlap',
    label: 'ui-data-import.settings.matchProfiles.checkSubscriptionOverlap',
    categoryId: 'invoice-extended-information',
    value: 'invoice.chkSubscriptionOverlap',
    recordType: 'INVOICE',
  }, {
    id: 'currency',
    label: 'ui-data-import.settings.matchProfiles.currency',
    categoryId: 'invoice-extended-information',
    value: 'invoice.currency',
    recordType: 'INVOICE',
  }, {
    id: 'paymentMethod',
    label: 'ui-data-import.settings.matchProfiles.paymentMethod',
    categoryId: 'invoice-extended-information',
    value: 'invoice.paymentMethod',
    recordType: 'INVOICE',
  }, {
    id: 'exportToAccounting',
    label: 'ui-data-import.settings.matchProfiles.exportToAccounting',
    categoryId: 'invoice-extended-information',
    value: 'invoice.exportToAccounting',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice links & documents */
  {
    id: 'name',
    label: 'ui-data-import.settings.matchProfiles.linkName',
    categoryId: 'invoice-links-and-documents',
    value: 'document_metadata.name',
    recordType: 'INVOICE',
  }, {
    id: 'url',
    label: 'ui-data-import.settings.matchProfiles.externalUrl',
    categoryId: 'invoice-links-and-documents',
    value: 'document_metadata.url',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice tags */
  {
    id: 'tags',
    label: 'ui-data-import.settings.matchProfiles.tag',
    categoryId: 'invoice-tags',
    value: 'invoice.tags.tagList[]',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice line admin data */
  {
    id: 'updatedDate',
    label: 'ui-data-import.settings.matchProfiles.updatedDateAndTime',
    categoryId: 'invoice-line-admin-data',
    value: 'invoice_line.metadata.updatedDate',
    recordType: 'INVOICE',
  }, {
    id: 'updatedByUsername',
    label: 'ui-data-import.settings.matchProfiles.updatedSource',
    categoryId: 'invoice-line-admin-data',
    value: 'invoice_line.metadata.updatedByUsername',
    recordType: 'INVOICE',
  }, {
    id: 'createdDate',
    label: 'ui-data-import.settings.matchProfiles.createdDateAndTime',
    categoryId: 'invoice-line-admin-data',
    value: 'invoice_line.metadata.createdDate',
    recordType: 'INVOICE',
  }, {
    id: 'createdByUsername',
    label: 'ui-data-import.settings.matchProfiles.createdSource',
    categoryId: 'invoice-line-admin-data',
    value: 'invoice_line.metadata.createdByUsername',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice line information */
  {
    id: 'description',
    label: 'ui-data-import.description',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.description',
    recordType: 'INVOICE',
  }, {
    id: 'invoiceLineNumber',
    label: 'ui-data-import.settings.matchProfiles.invoiceLineNumber',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.invoiceLineNumber',
    recordType: 'INVOICE',
  }, {
    id: 'invoiceLineStatus',
    label: 'ui-data-import.settings.matchProfiles.invoiceLineStatus',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.invoiceLineStatus',
    recordType: 'INVOICE',
  }, {
    id: 'poLineId',
    label: 'ui-data-import.settings.matchProfiles.poLineId',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.poLineId',
    recordType: 'INVOICE',
  }, {
    id: 'subscriptionInfo',
    label: 'ui-data-import.settings.matchProfiles.subscriptionInfo',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.subscriptionInfo',
    recordType: 'INVOICE',
  }, {
    id: 'subscriptionStart',
    label: 'ui-data-import.settings.matchProfiles.subscriptionStartDate',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.subscriptionStart',
    recordType: 'INVOICE',
  }, {
    id: 'subscriptionEnd',
    label: 'ui-data-import.settings.matchProfiles.subscriptionEndDate',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.subscriptionEnd',
    recordType: 'INVOICE',
  }, {
    id: 'quantity',
    label: 'ui-data-import.settings.matchProfiles.quantity',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.quantity',
    recordType: 'INVOICE',
  }, {
    id: 'subTotal',
    label: 'ui-data-import.settings.matchProfiles.subTotal',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.subTotal',
    recordType: 'INVOICE',
  }, {
    id: 'vendorRefNo',
    label: 'ui-data-import.settings.matchProfiles.vendorRefNumber',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.vendorRefNo',
    recordType: 'INVOICE',
  }, {
    id: 'accountNumber',
    label: 'ui-data-import.settings.matchProfiles.accountNumber',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.accountNumber',
    recordType: 'INVOICE',
  }, {
    id: 'accountingCode',
    label: 'ui-data-import.settings.matchProfiles.accountingCode',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.accountingCode',
    recordType: 'INVOICE',
  }, {
    id: 'comment',
    label: 'ui-data-import.settings.matchProfiles.comment',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.comment',
    recordType: 'INVOICE',
  }, {
    id: 'releaseEncumbrance',
    label: 'ui-data-import.settings.matchProfiles.releaseEncumbrance',
    categoryId: 'invoice-line-information',
    value: 'invoice_line.releaseEncumbrance',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice line fund distribution */
  {
    id: 'fundId',
    label: 'ui-data-import.settings.matchProfiles.fundIdentifier',
    categoryId: 'invoice-line-fund-distribution',
    value: 'invoice_line.fundDistributions[].fundId',
    recordType: 'INVOICE',
  }, {
    id: 'value',
    label: 'ui-data-import.settings.matchProfiles.value',
    categoryId: 'invoice-line-fund-distribution',
    value: 'invoice_line.fundDistributions[].value',
    recordType: 'INVOICE',
  }, {
    id: 'distributionType',
    label: 'ui-data-import.settings.matchProfiles.adjustment',
    categoryId: 'invoice-line-fund-distribution',
    value: 'invoice_line.fundDistributions[].distributionType',
    recordType: 'INVOICE',
  }, {
    id: 'total',
    label: 'ui-data-import.settings.matchProfiles.amount',
    categoryId: 'invoice-line-fund-distribution',
    value: 'invoice_line.total',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice line adjustments */
  {
    id: 'adjustmentId',
    label: 'ui-data-import.settings.matchProfiles.presetAdjustment',
    categoryId: 'invoice-line-adjustments',
    value: 'invoice_line.adjustments[].adjustmentId',
    recordType: 'INVOICE',
  }, {
    id: 'description',
    label: 'ui-data-import.description',
    categoryId: 'invoice-line-adjustments',
    value: 'invoice_line.adjustments[].description',
    recordType: 'INVOICE',
  }, {
    id: 'value',
    label: 'ui-data-import.settings.matchProfiles.amount',
    categoryId: 'invoice-line-adjustments',
    value: 'invoice_line.adjustments[].value',
    recordType: 'INVOICE',
  }, {
    id: 'type',
    label: 'ui-data-import.settings.matchProfiles.type',
    categoryId: 'invoice-line-adjustments',
    value: 'invoice_line.adjustments[].type',
    recordType: 'INVOICE',
  }, {
    id: 'relationToTotal',
    label: 'ui-data-import.settings.matchProfiles.relationToTotal',
    categoryId: 'invoice-line-adjustments',
    value: 'invoice_line.adjustments[].relationToTotal',
    recordType: 'INVOICE',
  },
  /* Invoice Invoice line tags */
  {
    id: 'tags',
    label: 'ui-data-import.settings.matchProfiles.tag',
    categoryId: 'invoice-line-tags',
    value: 'invoice_line.tags.tagList[]',
    recordType: 'INVOICE',
  },
];

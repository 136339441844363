// eslint-disable-next-line import/prefer-default-export
export function toAddressTypeOptions(addressTypes, intl) {
  if (!addressTypes) return [];
  return addressTypes.map((at) => ({
    // label: at.addressType,
    /** kware start edit */
    label: intl.formatMessage({
      id: `ui-users.addressTypes.addressType.${at.addressType}`,
      defaultMessage: at.addressType,
    }),
    /** kware end edit */
    value: at.id,
  }));
}

/* kware added new */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Callout } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import EmailSettingsForm from './EmailSettingsForm';

const manifest = Object.freeze({
  recordId: {},
  settings: {
    type: 'okapi',
    records: 'configs',
    path: 'configurations/entries?query=(module=SMTP_SERVER and configName=email)',
    // accumulate: true,
    POST: {
      path: 'configurations/entries',
    },
    PUT: {
      path: 'configurations/entries',
    },
    DELETE: {
      path: 'configurations/entries',
    },
  },
});
const propTypes = {
  stripes: PropTypes.shape({
    connect: PropTypes.func.isRequired,
  }).isRequired,
  resources: PropTypes.shape({
    settings: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  mutator: PropTypes.shape({
    recordId: PropTypes.shape({
      replace: PropTypes.func,
    }),
    settings: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
      DELETE: PropTypes.func.isRequired,
    }),
  }),
};

function EmailSettingsManager(props) {
  const [emailSetting, setEmailSetting] = useState({});
  let callout;
  useEffect(() => {
    const emaildata = props.resources.settings.records || [];
    const emailObj = {};
    emaildata.forEach((data) => {
      switch (data.code) {
        case 'EMAIL_SMTP_HOST':
          emailObj.EMAIL_SMTP_HOST = data.value;
          break;
        case 'EMAIL_SMTP_PORT':
          emailObj.EMAIL_SMTP_PORT = data.value;
          break;
        case 'EMAIL_USERNAME':
          emailObj.EMAIL_USERNAME = data.value;
          break;
        case 'EMAIL_PASSWORD':
          emailObj.EMAIL_PASSWORD = data.value;
          break;
        case 'EMAIL_SMTP_SSL':
          emailObj.EMAIL_SMTP_SSL = data.value;
          break;
        case 'EMAIL_SMTP_LOGIN_OPTION':
          emailObj.EMAIL_SMTP_LOGIN_OPTION = data.value;
          break;
        case 'EMAIL_TRUST_ALL':
          emailObj.EMAIL_TRUST_ALL = data.value;
          break;
        case 'EMAIL_START_TLS_OPTIONS':
          emailObj.EMAIL_START_TLS_OPTIONS = data.value;
          break;
        case 'EMAIL_FROM':
          emailObj.EMAIL_FROM = data.value;
          break;
        default:
          break;
      }
    });
    setEmailSetting(emailObj);
  }, [props.resources]);

  const onShowCallout = (message) => {
    if (callout) {
      callout.sendCallout({ message });
    }
  };

  const onCreateNewEmailSettings = (newSettings, values) => {
    const res = newSettings.forEach((newSetting) => {
      props.mutator.settings.POST({
        module: 'SMTP_SERVER',
        configName: 'email',
        code: newSetting,
        description: 'email smtp settings',
        default: true,
        enabled: true,
        value: values[newSetting],
      });
    });

    Promise.resolve(res).then(() => {
      onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-tenant-settings.settings.emailSettings.callout.newSettings.success" />
          </strong>
        </div>
      );
    });
  };

  const onUpdateEmailSettings = (updatedSettings, values) => {
    const emaildata = props.resources.settings.records || [];

    const res = updatedSettings.forEach((updatedSet) => {
      const record = emaildata.filter((rec) => rec.code === updatedSet)[0];
      if (record) {
        props.mutator.recordId.replace(record.id);
        record.value = values[updatedSet];
        props.mutator.settings.PUT(record);
      }
    });

    Promise.resolve(res).then(() => {
      onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-tenant-settings.settings.emailSettings.callout.updatedSettings.success" />
          </strong>
        </div>
      );
    });
  };

  const onDeleteEmailSettings = () => {
    const emaildata = props.resources.settings.records || [];
    if (emaildata && emaildata.length !== 0) {
      const res = emaildata.map((loc) => props.mutator.settings.DELETE({ id: loc.id }));
      Promise.resolve(res).then(() => {
        onShowCallout(
          <div>
            <strong>
              <FormattedMessage id="ui-tenant-settings.settings.emailSettings.callout.deletedSettings.success" />
            </strong>
          </div>
        );
      });
    }
  };

  const onFormSubmit = (values) => {
    const newSettings = Object.keys(values).filter((value) => {
      return !Object.keys(emailSetting).some((email) => {
        return value === email;
      });
    });

    const updatedSettings = Object.keys(values).filter((value) => {
      return Object.keys(emailSetting).some((email) => {
        return value === email && emailSetting[email] !== values[value];
      });
    });

    if (updatedSettings.length) onUpdateEmailSettings(updatedSettings, values);

    if (newSettings.length) onCreateNewEmailSettings(newSettings, values);
  };

  return (
    <>
      <EmailSettingsForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={emailSetting}
        onDeleteEmailSettings={onDeleteEmailSettings}
      />
      <Callout
        ref={(ref) => {
          callout = ref;
        }}
      />
    </>
  );
}

EmailSettingsManager.propTypes = propTypes;
EmailSettingsManager.manifest = manifest;

export default EmailSettingsManager;

const itemStatuses = [
  { label: 'ui-inventory.item.status.agedToLost', value: 'Aged to lost' },
  { label: 'ui-inventory.item.status.available', value: 'Available' },
  {
    label: 'ui-inventory.item.status.awaitingPickup',
    value: 'Awaiting pickup',
  },
  {
    label: 'ui-inventory.item.status.awaitingDelivery',
    value: 'Awaiting delivery',
  },
  { label: 'ui-inventory.item.status.checkedOut', value: 'Checked out' },
  {
    label: 'ui-inventory.item.status.claimedReturned',
    value: 'Claimed returned',
  },
  { label: 'ui-inventory.item.status.declaredLost', value: 'Declared lost' },
  { label: 'ui-inventory.item.status.inProcess', value: 'In process' },
  {
    label: 'ui-inventory.item.status.inProcessNonRequestable',
    value: 'In process (non-requestable)',
  },
  { label: 'ui-inventory.item.status.inTransit', value: 'In transit' },
  {
    label: 'ui-inventory.item.status.intellectualItem',
    value: 'Intellectual item',
  },
  { label: 'ui-inventory.item.status.longMissing', value: 'Long missing' },
  { label: 'ui-inventory.item.status.lostAndPaid', value: 'Lost and paid' },
  { label: 'ui-inventory.item.status.missing', value: 'Missing' },
  { label: 'ui-inventory.item.status.onOrder', value: 'On order' },
  { label: 'ui-inventory.item.status.orderClosed', value: 'Order closed' },
  { label: 'ui-inventory.item.status.paged', value: 'Paged' },
  { label: 'ui-inventory.item.status.restricted', value: 'Restricted' },
  { label: 'ui-inventory.item.status.unavailable', value: 'Unavailable' },
  { label: 'ui-inventory.item.status.unknown', value: 'Unknown' },
  { label: 'ui-inventory.item.status.withdrawn', value: 'Withdrawn' },
];

/** kware start editing */
export default function HandleItemStatus(value) {
  return itemStatuses.find((item) => item.value === value)?.label;
}
/** kware end editing */

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Callout } from '@folio/stripes-components';
import PersonalImageForm from './PersonalImageForm';

function PersonalImageManager(props) {
  let callout;

  const [userImg, setUserImg] = useState(null);

  const fetchImage = async () => {
    const res = await fetch(`${props.stripes.okapi.url}/files/${props.stripes.user.user.id}.jpg`, {
      headers: {
        'X-Okapi-Tenant': props.stripes.okapi.tenant,
        'X-Okapi-Token': props.stripes.okapi.token,
        'Content-Type': 'application/json; charset=utf-8',
      },
    });

    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    setUserImg(imageObjectURL);
    props.stripes.setUserProfileImage(imageObjectURL);
  };

  useEffect(() => {
    fetchImage();
  }, [props.stripes.okapi, props.stripes.user.user.id]);

  const onShowCallout = (message) => {
    if (callout) {
      callout.sendCallout({ message });
    }
  };

  // return a promise that resolves with a File instance
  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const onFormSubmit = (values) => {
    return urltoFile(values.personalImage, `${props.stripes.user.user.id}.jpg`, 'image/jpg').then((file) => {
      const formData = new FormData();

      formData.append('file', file);

      return fetch(`${props.stripes.okapi.url}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'X-Okapi-Tenant': props.stripes.okapi.tenant,
          'X-Okapi-Token': props.stripes.okapi.token,
          // 'Module_Name': 'users',
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          fetchImage();
          onShowCallout(<div>{data.message}</div>);
        })
        .catch((err) => {
          onShowCallout(<div>{err.message}</div>);
        });
    });
  };

  return (
    <>
      <PersonalImageForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={{
          personalImage: userImg,
        }}
      />
      <Callout
        ref={(ref) => {
          callout = ref;
        }}
      />
    </>
  );
}

PersonalImageManager.propTypes = {
  stripes: PropTypes.object.isRequired,
};

export default PersonalImageManager;

const displayMethods = [
  {
    name: 'Table',
    value: 'table',
    translationKey: {
      id: 'ui-reports.displayMethods.table',
      defaultMessage: 'Table'
    },
    settings: {
      columnWidths: {},
      columnMapping: {},
      visibleColumns: [],
      translatableFields: [
        {
          fieldName: '',
          keyPattern: ''
        }
      ]
    }
  },
  {
    name: 'Numeric label',
    value: 'numLabel',
    translationKey: {
      id: 'ui-reports.displayMethods.numLabel',
      defaultMessage: 'Numeric label'
    },
    settings: {}
  },
  {
    name: 'Bar chart',
    value: 'barChart',
    translationKey: {
      id: 'ui-reports.displayMethods.barChart',
      defaultMessage: 'Bar chart'
    },
    settings: {}
  },
  {
    name: 'Pie chart',
    value: 'pieChart',
    translationKey: {
      id: 'ui-reports.displayMethods.pieChart',
      defaultMessage: 'Pie chart'
    },
    settings: {}
  },
  {
    name: 'Line chart',
    value: 'lineChart',
    translationKey: {
      id: 'ui-reports.displayMethods.lineChart',
      defaultMessage: 'Line chart'
    },
    settings: {}
  },
  {
    name: 'Area chart',
    value: 'areaChart',
    translationKey: {
      id: 'ui-reports.displayMethods.areaChart',
      defaultMessage: 'Area chart'
    },
    settings: {}
  }
];

export default displayMethods;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Settings } from '@folio/stripes/smart-components';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import CategoriesSettings from './categoriesSettings/CategoriesSettings';
import DashboardsSettings from './dashboardsSettings/DashboardsSettings';
import DefaultDashboardManager from './dashboardsSettings/DefaultDashboardManager';

function ReportsSettings(props) {
  const sections = [
    {
      label: (
        <FormattedMessage
          id="ui-reports.settings.sections.general.label"
          defaultMessage="General"
        />
      ),
      pages: [
        {
          route: 'general',
          label: (
            <FormattedMessage
              id="ui-reports.settings.sections.general.pages.generalSettings.lable"
              defaultMessage="General Settings"
            />
          ),
          component: GeneralSettings
          // perm: 'ui-reports.create',
        }
      ]
    },
    {
      label: (
        <FormattedMessage
          id="ui-reports.settings.sections.categories.label"
          defaultMessage="Categories"
        />
      ),
      pages: [
        {
          route: 'categories',
          label: (
            <FormattedMessage
              id="ui-reports.settings.sections.categories.pages.categoriesList.lable"
              defaultMessage="Reports Categories"
            />
          ),
          component: CategoriesSettings
          // perm: 'ui-reports.create',
        }
      ]
    },
    {
      label: (
        <FormattedMessage
          id="ui-reports.settings.sections.dashboards.label"
          defaultMessage="Dashboards"
        />
      ),
      pages: [
        {
          route: 'dashboards',
          label: (
            <FormattedMessage
              id="ui-reports.settings.sections.dashboards.pages.dashboardsList.lable"
              defaultMessage="Dashboards list"
            />
          ),
          component: DashboardsSettings
          // perm: 'ui-reports.create',
        },
        {
          route: 'default-dashboard',
          label: (
            <FormattedMessage
              id="ui-reports.settings.sections.dashboards.defaultDashboard.label"
              defaultMessage="Default dashboard"
            />
          ),
          component: DefaultDashboardManager
          // perm: 'ui-reports.create',
        }
      ]
    }
  ];

  return (
    <Settings
      {...props}
      sections={sections}
      paneTitle={
        <FormattedMessage id="ui-reports.meta.title" defaultMessage="Reports" />
      }
      navPaneWidth="20%"
    />
  );
}

ReportsSettings.propTypes = {};

export default ReportsSettings;

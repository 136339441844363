import React from 'react';
import PropTypes from 'prop-types';
/** kware start edit */
import { FormattedMessage, injectIntl } from 'react-intl';
/** kware end edit */
import { Col, KeyValue, NoValue, Row } from '@folio/stripes/components';
/** kware start edit */
import { HandleUserName } from '@folio/stripes-core';
/** kware end edit */
import { getFullName, userHighlightBox, getPatronGroup } from './utils';

class UserDetail extends React.Component {
  static propTypes = {
    deliveryAddress: PropTypes.node,
    patronGroups: PropTypes.arrayOf(PropTypes.object),
    pickupServicePoint: PropTypes.string,
    proxy: PropTypes.object,
    request: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    selectedDelivery: PropTypes.bool,
    /** kware start edit */
    intl: PropTypes.object,
    /** kware end edit */
  };

  static defaultProps = {
    pickupServicePoint: '',
    proxy: {},
    selectedDelivery: false,
  };

  /** kware start edit */
  convertStringToCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  };
  /** kware end edit */

  render() {
    const {
      user,
      proxy,
      request,
      patronGroups,
      deliveryAddress,
      pickupServicePoint,
      selectedDelivery,
    } = this.props;

    const id = user?.id ?? request.requesterId;
    // const name = getFullName(user);
    /** kware start edit */
    const name = <HandleUserName user={user} />;
    /** kware end edit */
    const barcode = user ? user.barcode : '';
    const patronGroup = getPatronGroup(user, patronGroups) || {};

    let proxyName;
    let proxyBarcode;
    let proxyId;
    if (proxy) {
      // proxyName = getFullName(proxy);
      /** kware start edit */
      proxyName = <HandleUserName user={proxy} />;
      /** kware end edit */
      proxyBarcode = proxy?.barcode || '-';
      proxyId = proxy.id || request.proxyUserId;
    }

    const proxySection = proxyId
      ? userHighlightBox(<FormattedMessage id="ui-requests.requester.proxy" />, proxyName, proxyId, proxyBarcode)
      : null;

    return (
      <div>
        {userHighlightBox(<FormattedMessage id="ui-requests.requester.requester" />, name, id, barcode)}
        <Row>
          <Col xs={4}>
            <KeyValue
              label={
                <FormattedMessage id="ui-requests.requester.patronGroup.group" />
              }
              // value={patronGroup.group || '-'}
              /** kware start edit */
              value={
                patronGroup?.group
                  ? this.props.intl.formatMessage({
                    id: `ui-users.patronGroups.group.${patronGroup.group}`,
                    defaultMessage: patronGroup.group,
                  })
                  : '-'
              }
              /** kware end edit */
            />
          </Col>
          <Col xs={4}>
            <KeyValue
              label={
                <FormattedMessage id="ui-requests.requester.fulfilmentPref" />
              }
              // value={request?.fulfilmentPreference || '-'}
              /** kware start edit */
              value={
                request?.fulfilmentPreference
                  ? this.props.intl.formatMessage({
                    id: `ui-users.requests.${this.convertStringToCamelCase(
                      request.fulfilmentPreference
                    )}`,
                    defaultMessage: request?.fulfilmentPreference,
                  })
                  : '-'
              }
              /** kware end edit */
            />
          </Col>
          <Col xs={4}>
            {selectedDelivery ? (
              <KeyValue
                label={<FormattedMessage id="ui-requests.deliveryAddress" />}
                value={deliveryAddress || <NoValue />}
              />
            ) : (
              <KeyValue
                label={
                  <FormattedMessage id="ui-requests.pickupServicePoint.name" />
                }
                // value={pickupServicePoint || '-'}
                /** kware start edit */
                value={
                  pickupServicePoint
                    ? this.props.intl.formatMessage({
                      id: `ui-tenant-settings.ServicePoint.name.${pickupServicePoint}`,
                      defaultMessage: pickupServicePoint,
                    })
                    : '-'
                }
                /** kware end edit */
              />
            )}
          </Col>
        </Row>
        {proxySection}
      </div>
    );
  }
}

// export default UserDetail;
/** kware start edit */
export default injectIntl(UserDetail);
/** kware end edit */

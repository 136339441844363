import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { IntlProvider } from 'react-intl';
import queryString from 'query-string';
import { QueryClientProvider } from 'react-query';
import { ApolloProvider } from '@apollo/client';

import { ErrorBoundary } from '@folio/stripes-components';
import { metadata, icons } from 'stripes-config';

/* ConnectContext - formerly known as RootContext, now comes from stripes-connect, so stripes-connect
 * is providing the infrastructure for store connectivity to the system. This eliminates a circular
 * dependency between stripes-connect and stripes-core. STCON-76
 */
import { ConnectContext } from '@folio/stripes-connect';
import localforage from 'localforage'; /* kware start editing */
import initialReducers from '../../initialReducers';
import enhanceReducer from '../../enhanceReducer';
import createApolloClient from '../../createApolloClient';
import createReactQueryClient from '../../createReactQueryClient';
import {
  setSinglePlugin,
  setBindings,
  setOkapiToken,
  setTimezone,
  setCurrency,
  updateCurrentUser,
  /* kware start editing */
  setTenant,
  setUserNumbersShape,
  setDateformat,
  setTenantLocales,
  setUserLocales,
  setUserPreferredLocale,
  setTenantDefaultLocale,
  setShowLocaleIcon,
  setdefaultTheme,
  setTimeformat,
  setPrimaryInputLanguage,
  setUserPreferredApps,
  setUserProfileImage,
  setTranslations
  /* kware end editing */
} from '../../okapiActions';
import { loadTranslations, checkOkapiSession } from '../../loginServices';
import { getQueryResourceKey, getCurrentModule } from '../../locationService';
import Stripes from '../../Stripes';
import RootWithIntl from '../../RootWithIntl';
import SystemSkeleton from '../SystemSkeleton';

import './Root.css';

import { withModules } from '../Modules';

if (!metadata) {
  // eslint-disable-next-line no-console
  console.error(
    'No metadata harvested from package files, so you will not get app icons. Probably the stripes-core in your Stripes CLI is too old. Try `yarn global upgrade @folio/stripes-cli`'
  );
}

/* kware start editing */
// The login page always appears in American English, because the default value for the locale state is always equal to 'en-US',
// so we need to store the user's last login language (usually it will be his preferred language or the tenant’s default language)
// in the browser and give it as a default value for the locale state.
const LoginLocale = localStorage.getItem('LoginLocale');
/* kware end editing */

class Root extends Component {
  constructor(...args) {
    super(...args);

    const { modules, history, okapi } = this.props;

    this.reducers = { ...initialReducers };
    this.epics = {};
    this.withOkapi = okapi.withoutOkapi !== true;

    const appModule = getCurrentModule(modules, history.location);
    this.queryResourceStateKey = appModule
      ? getQueryResourceKey(appModule)
      : null;
    this.defaultRichTextElements = {
      b: (chunks) => <b>{chunks}</b>,
      i: (chunks) => <i>{chunks}</i>,
      em: (chunks) => <em>{chunks}</em>,
      strong: (chunks) => <strong>{chunks}</strong>,
      span: (chunks) => <span>{chunks}</span>,
      div: (chunks) => <div>{chunks}</div>,
      p: (chunks) => <p>{chunks}</p>,
      ul: (chunks) => <ul>{chunks}</ul>,
      ol: (chunks) => <ol>{chunks}</ol>,
      li: (chunks) => <li>{chunks}</li>,
      code: (chunks) => <code>{chunks}</code>
    };

    this.apolloClient = createApolloClient(okapi);
    this.reactQueryClient = createReactQueryClient();
  }

  getChildContext() {
    return { addReducer: this.addReducer, addEpic: this.addEpic };
  }

  componentDidMount() {
    const { okapi, store, locale, defaultTranslations } = this.props;
    // console.log(store);
    // if (this.withOkapi) checkOkapiSession(okapi.url, store, okapi.tenant);
    if (this.withOkapi)
      checkOkapiSession(okapi.url, store, store.getState().okapi.tenant);
    // TODO: remove this after we load locale and translations at start from a public endpoint
    // loadTranslations(store, locale, defaultTranslations);
    /* kware start editing */
    // Edit: not need to do this all the time, to avoid to go to english locale then switched to the selected locale.
    //  we need to do this if only in login page, So if okapi sess is opened, We will already have locale and translations
    localforage.getItem('okapiSess').then((sess) => {
      if (sess === null) loadTranslations(store, locale, defaultTranslations);
    });
    /* kware end editing */
  }

  shouldComponentUpdate(nextProps) {
    return !this.withOkapi || nextProps.okapiReady || nextProps.serverDown;
  }

  addReducer = (key, reducer) => {
    if (this.queryResourceStateKey === key) {
      const originalReducer = reducer;
      const initialQueryObject = queryString.parse(window.location.search);
      // eslint-disable-next-line no-param-reassign
      reducer = (
        state = Object.values(initialQueryObject).length
          ? initialQueryObject
          : undefined,
        action
      ) => originalReducer(state, action);
    }

    if (this.reducers[key] === undefined) {
      this.reducers[key] = reducer;
      this.props.store.replaceReducer(
        enhanceReducer(combineReducers({ ...this.reducers }))
      );
      return true;
    }
    return false;
  };

  addEpic = (key, epic) => {
    if (this.epics[key] === undefined) {
      this.epics[key] = epic;
      this.props.epics.add(epic);
      return true;
    }
    return false;
  };

  /* kware start editing */
  setLocaleTranslations = (store, newTrans) => {
    if (store.getState().okapi.token !== undefined) {
      store.dispatch(
        setTranslations(
          Object.assign(store.getState().okapi.translations, newTrans)
        )
      );
    }
  };
  /* kware end editing */

  render() {
    const {
      logger,
      store,
      epics,
      config,
      okapi,
      actionNames,
      token,
      disableAuth,
      currentUser,
      currentPerms,
      locale,
      defaultTranslations,
      timezone,
      currency,
      plugins,
      bindings,
      discovery,
      translations,
      history,
      serverDown,
      /* kware start editing */
      tenant,
      tenantLocales,
      userLocales,
      userPreferredLocale,
      tenantDefaultLocale,
      userNumbersShape,
      dateformat,
      showLocaleIcon,
      defaultTheme,
      timeformat,
      primaryInputLanguage,
      userPreferredApps,
      userProfileImage
      /* kware end editing */
    } = this.props;

    if (serverDown) {
      return <div>Error: server is down.</div>;
    }

    if (!translations) {
      // We don't know the locale, so we use English as backup
      return <SystemSkeleton />;
    }

    const stripes = new Stripes({
      logger,
      store,
      epics,
      config,
      okapi,
      withOkapi: this.withOkapi,
      setToken: (val) => {
        store.dispatch(setOkapiToken(val));
      },
      actionNames,
      locale,
      timezone,
      currency,
      metadata,
      icons,
      /* kware start editing */
      tenant,
      tenantLocales,
      userLocales,
      userPreferredLocale,
      tenantDefaultLocale,
      userNumbersShape,
      dateformat,
      showLocaleIcon,
      defaultTheme,
      timeformat,
      primaryInputLanguage,
      userPreferredApps,
      userProfileImage,
      setShowLocaleIcon: (localeIcon) => {
        store.dispatch(setShowLocaleIcon(localeIcon));
      },
      setdefaultTheme: (theme) => {
        store.dispatch(setdefaultTheme(theme));
      },
      setTranslations: (newTrans) => {
        this.setLocaleTranslations(store, newTrans);
      },
      setUserNumbersShape: (numbersShape) => {
        store.dispatch(setUserNumbersShape(numbersShape));
      },
      settenant: (tenName) => {
        store.dispatch(setTenant(tenName));
      },
      setUserLocales: (userLocalesValues) => {
        store.dispatch(setUserLocales(userLocalesValues));
      },
      setTenantLocales: (TenantLocalesValues) => {
        store.dispatch(setTenantLocales(TenantLocalesValues));
      },
      setUserPreferredLocale: (userPreferredLocaleValue) => {
        store.dispatch(setUserPreferredLocale(userPreferredLocaleValue));
      },
      setTenantDefaultLocale: (tenantDefaultLocaleValue) => {
        store.dispatch(setTenantDefaultLocale(tenantDefaultLocaleValue));
      },
      setDateformat: (dateformatValue) => {
        store.dispatch(setDateformat(dateformatValue));
      },
      setTimeformat: (timeformatValue) => {
        store.dispatch(setTimeformat(timeformatValue));
      },
      setPrimaryInputLanguage: (inputLanguage) => {
        store.dispatch(setPrimaryInputLanguage(inputLanguage));
      },
      setUserPreferredApps: (apps) => {
        store.dispatch(setUserPreferredApps(apps));
      },
      setUserProfileImage: (img) => {
        store.dispatch(setUserProfileImage(img));
      },
      /* kware end editing */
      setLocale: (localeValue) => {
        loadTranslations(store, localeValue, defaultTranslations);
      },
      setTimezone: (timezoneValue) => {
        store.dispatch(setTimezone(timezoneValue));
      },
      setCurrency: (currencyValue) => {
        store.dispatch(setCurrency(currencyValue));
      },
      updateUser: (userValue) => {
        store.dispatch(updateCurrentUser(userValue));
      },
      plugins: plugins || {},
      setSinglePlugin: (key, value) => {
        store.dispatch(setSinglePlugin(key, value));
      },
      bindings,
      setBindings: (val) => {
        store.dispatch(setBindings(val));
      },
      discovery,
      user: {
        user: currentUser,
        perms: currentPerms
      },
      connect(X) {
        return X;
      }
    });

    return (
      <ErrorBoundary>
        <ConnectContext.Provider
          value={{ addReducer: this.addReducer, addEpic: this.addEpic, store }}
        >
          <ApolloProvider client={this.apolloClient}>
            <QueryClientProvider client={this.reactQueryClient}>
              <IntlProvider
                locale={locale}
                /* kware start editing */
                // After switching locale from the top navbar while we are in the editing form, we will lose all the values ​that we entered
                // in this form and notice that the page is completely reloaded because the IntlProvider has key = {locale}
                // as it reloads the page after changing locale so that it can show Translations corresponding to this locale.
                // In fact, all we really need when switching locale is to re-display the translations corresponding to this locale,
                // and since the IntlProvider always takes the message props from the translation global state, so we are always sure to change
                // the translations when switching locale, so we don't need to re-display. Download the entire page after switching locale.
                // Note: This modification will have an effect in most applications, for example the appearance of application names in the top navbar
                // and also in the settings.
                /* kware end editing */
                // key={locale}
                timeZone={timezone}
                currency={currency}
                messages={translations}
                textComponent={Fragment}
                onError={config?.suppressIntlErrors ? () => {} : undefined}
                onWarn={config?.suppressIntlWarnings ? () => {} : undefined}
                defaultRichTextElements={this.defaultRichTextElements}
              >
                <RootWithIntl
                  stripes={stripes}
                  token={token}
                  disableAuth={disableAuth}
                  history={history}
                />
              </IntlProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </ConnectContext.Provider>
      </ErrorBoundary>
    );
  }
}

Root.childContextTypes = {
  addReducer: PropTypes.func,
  addEpic: PropTypes.func
};

Root.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    replaceReducer: PropTypes.func.isRequired
  }),
  token: PropTypes.string,
  disableAuth: PropTypes.bool.isRequired,
  logger: PropTypes.object.isRequired,
  currentPerms: PropTypes.object,
  currentUser: PropTypes.object,
  epics: PropTypes.object,
  locale: PropTypes.string,
  defaultTranslations: PropTypes.object,
  timezone: PropTypes.string,
  currency: PropTypes.string,
  translations: PropTypes.object,
  modules: PropTypes.shape({
    app: PropTypes.arrayOf(PropTypes.object)
  }),
  plugins: PropTypes.object,
  bindings: PropTypes.object,
  config: PropTypes.object,
  okapi: PropTypes.shape({
    url: PropTypes.string,
    tenant: PropTypes.string,
    withoutOkapi: PropTypes.bool
  }),
  actionNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  discovery: PropTypes.shape({
    modules: PropTypes.object,
    interfaces: PropTypes.object,
    isFinished: PropTypes.bool
  }),
  history: PropTypes.shape({
    action: PropTypes.string.isRequired,
    length: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }),
  okapiReady: PropTypes.bool,
  serverDown: PropTypes.bool,
  /* kware start editing */
  tenant: PropTypes.string,
  tenantLocales: PropTypes.arrayOf(PropTypes.string),
  userLocales: PropTypes.arrayOf(PropTypes.string),
  userPreferredLocale: PropTypes.string,
  tenantDefaultLocale: PropTypes.string,
  userNumbersShape: PropTypes.string,
  dateformat: PropTypes.string,
  showLocaleIcon: PropTypes.bool,
  defaultTheme: PropTypes.string,
  timeformat: PropTypes.string,
  primaryInputLanguage: PropTypes.string,
  userPreferredApps: PropTypes.string,
  userProfileImage: PropTypes.string
  /* kware end editing */
};

Root.defaultProps = {
  history: createBrowserHistory(),
  // TODO: remove after locale is accessible from a global config / public url
  locale: 'en-US',
  timezone: 'UTC',
  currency: 'USD',
  okapiReady: false,
  serverDown: false,

  /* kware start editing */
  locale: LoginLocale ? JSON.parse(LoginLocale).LoginLocale : 'en-US',
  tenantDefaultLocale: 'en-US',
  dateformat: 'MM/DD/YYYY',
  showLocaleIcon: false,
  defaultTheme: 'kwareTheme',
  timeformat: 'h:mm A',
  primaryInputLanguage: 'en'
  /* kware end editing */
};

function mapStateToProps(state) {
  return {
    bindings: state.okapi.bindings,
    currency: state.okapi.currency,
    currentPerms: state.okapi.currentPerms,
    currentUser: state.okapi.currentUser,
    discovery: state.discovery,
    locale: state.okapi.locale,
    okapi: state.okapi,
    okapiReady: state.okapi.okapiReady,
    plugins: state.okapi.plugins,
    serverDown: state.okapi.serverDown,
    timezone: state.okapi.timezone,
    token: state.okapi.token,
    translations: state.okapi.translations,
    /* kware start editing */
    tenant: state.okapi.tenant,
    userNumbersShape: state.okapi.userNumbersShape,
    dateformat: state.okapi.dateformat,
    tenantLocales: state.okapi.tenantLocales,
    userLocales: state.okapi.userLocales,
    userPreferredLocale: state.okapi.userPreferredLocale,
    tenantDefaultLocale: state.okapi.tenantDefaultLocale,
    showLocaleIcon: state.okapi.showLocaleIcon,
    defaultTheme: state.okapi.defaultTheme,
    timeformat: state.okapi.timeformat,
    primaryInputLanguage: state.okapi.primaryInputLanguage,
    userPreferredApps: state.okapi.userPreferredApps,
    userProfileImage: state.okapi.userProfileImage
    /* kware start editing */
  };
}

export default connect(mapStateToProps)(withModules(Root));

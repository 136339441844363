export const ACQUISITION_METHODS_API = 'orders/acquisition-methods';
export const ACQUISITIONS_UNITS_API = 'acquisitions-units/units';
export const ACQUISITIONS_UNIT_MEMBERSHIPS_API = 'acquisitions-units/memberships';
export const BATCH_GROUPS_API = 'batch-groups';
export const BUDGETS_API = 'finance/budgets';
export const CONFIG_API = 'configurations/entries';
export const CONTRIBUTOR_NAME_TYPES_API = 'contributor-name-types';
export const EXCHANGE_RATE_API = 'finance/exchange-rate';
export const EXPENSE_CLASSES_API = 'finance/expense-classes';
export const FUNDS_API = 'finance/funds';
export const INVOICES_API = 'invoice/invoices';
export const INVOICE_LINE_API = 'invoice/invoice-lines';
export const ITEMS_API = 'inventory/items';
export const IDENTIFIER_TYPES_API = 'identifier-types';
export const LINES_API = 'orders/order-lines';
export const LOAN_TYPES_API = 'loan-types';
export const LOCATIONS_API = 'locations';
export const MATERIAL_TYPE_API = 'material-types';
export const ORDERS_API = 'orders/composite-orders';
export const ORDER_PIECES_API = 'orders/pieces';
export const PREFIXES_API = 'orders/configuration/prefixes';
export const REQUESTS_API = 'circulation/requests';
export const SUFFIXES_API = 'orders/configuration/suffixes';
export const TAGS_API = 'tags';
export const TRANSACTIONS_API = 'finance/transactions';
export const USERS_API = 'users';
export const VENDORS_API = 'organizations/organizations';
export const VOUCHERS_API = 'voucher/vouchers';
export const VOUCHER_LINES_API = 'voucher/voucher-lines';

/* eslint-disable react/prop-types */
import React from 'react';
import Avatar from 'react-avatar-edit';
import { FormattedMessage } from 'react-intl';

function ImageUploader(props) {
  return (
    <div>
      {props.open && (
      <Avatar
        width="100%"
        height={250}
        cropRadius={80}
        onCrop={props.onCrop}
        onClose={props.onClose}
        label={
          <FormattedMessage
            id="ui-myprofile.settings.personalImage.dropzoneMessage"
            defaultMessage="Click to select image"
          />
        }
        img={props.src}
      />
      )}
    </div>
  );
}

ImageUploader.propTypes = {};

export default ImageUploader;

/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  FormattedMessage,
  FormattedTime,
  /* Kware Start Editing */
  injectIntl,
  /* Kware end Editing */
} from 'react-intl';
import { defer, isEqual, get } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import {
  Row,
  Col,
  Datepicker,
  LayoutHeader,
  Select,
} from '@folio/stripes/components';

/* Kware Start Editing */
import { getFullNameValue, withStripes } from '@folio/stripes-core';
import { getFullName, handleDateTimeFormat } from '../../util';
/* Kware end Editing */
import getWarning from '../../util/getProxySponsorWarning';
import validateMinDate from '../../validators/validateMinDate';

import css from './ProxyEditItem.css';

class ProxyEditItem extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    record: PropTypes.object,
    namespace: PropTypes.string, // sponsors or proxies
    name: PropTypes.string,
    onDelete: PropTypes.func,
    change: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    /* Kware Start Editing */
    stripes: PropTypes.object,
    /* Kware end Editing */
  };

  constructor() {
    super();
    this.state = { statusDisabled: false };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { formValues } = nextProps;
    if (!isEqual(prevState.formValues, formValues)) {
      return { formValues };
    }
    return null;
  }

  componentDidMount() {
    defer(() => this.updateStatus());
  }

  componentDidUpdate(prevProps, prevState) {
    const { namespace, index } = this.props;
    const prevProxyRel = (prevState.formValues[namespace] || [])[index] || {};
    const proxyRel = this.state.formValues[namespace][index] || {};

    if (
      !isEqual(this.state.formValues, prevState.formValues) &&
      prevProxyRel.proxy.status === proxyRel.proxy.status
    ) {
      this.updateStatus();
    }
  }

  toggleStatus(isActive) {
    const { name, change } = this.props;
    const status = isActive ? 'Active' : 'Inactive';
    change(`${name}.proxy.status`, status);
    this.setState({ statusDisabled: !isActive });
  }

  /**
   * updateStatus
   * `status` does double-duty on this form:
   *
   * 1. it is a user-settable toggle indicating if the relationship
   *    is active or inactive
   * 2. it is indication of whether the relationship is active based
   *    on the values in other fields, i.e. the relationship cannot
   *    be active if it is expired or if one of the parties is expired.
   *
   * Note: an expired relationship is not a validation error.
   *
   * Here, we're dealing with part-2: forcing the status to `inactive`
   * based on the values in other fields. We can coopt `props.getWarning`
   * to do that: if there's a warning, it will NOT be active.
   *
   */
  updateStatus() {
    const { index, namespace } = this.props;

    const formValues = this.state.formValues;

    this.toggleStatus(!getWarning(formValues, namespace, index));
  }

  optionsFor = (list) => {
    return list.map((option) => (
      <FormattedMessage id={`ui-users.${option}`} key={option}>
        {(optionTranslated) => (
          <option value={option}>{optionTranslated}</option>
        )}
      </FormattedMessage>
    ));
  };

  render() {
    const { name, record, onDelete } = this.props;

    const activeOptions = this.state.statusDisabled
      ? ['inactive']
      : ['active', 'inactive'];
    const relationStatusOptions = this.optionsFor(activeOptions);

    const requestForSponsorOptions = this.optionsFor(['yes', 'no']);
    const notificationsToOptions = this.optionsFor(['proxy', 'sponsor']);
    const proxyLinkMsg = (
      <FormattedMessage id="ui-users.proxy.relationshipCreated" />
    );
    const proxyCreatedValue = get(record, 'proxy.metadata.createdDate', null);
    // const proxyCreatedDate = proxyCreatedValue ? <FormattedTime
    //   value={proxyCreatedValue}
    //   day="numeric"
    //   month="numeric"
    //   year="numeric"
    // /> : '-';
    /* Kware Start Editing */
    const proxyCreatedDate = proxyCreatedValue
      ? handleDateTimeFormat(
        proxyCreatedValue,
        this.props.stripes,
        this.props.intl,
        'datetime'
      )
      : '-';
    /* Kware End Editing */
    const proxyLink = (
      <div>
        {/* <Link to={`/users/view/${record.user.id}`}>{getFullName(record.user)}</Link> */}
        {/* Kware Start Editing */}
        <Link to={`/users/view/${record.user.id}`}>
          {getFullNameValue(record.user, this.props.intl)}
        </Link>
        {/* Kware end Editing */}
        {proxyCreatedValue && (
          <span className={css.creationLabel}>
            ({proxyLinkMsg} {proxyCreatedDate})
          </span>
        )}
      </div>
    );

    return (
      <div className={css.item}>
        <LayoutHeader
          level={3}
          title={proxyLink}
          onDelete={() => onDelete(record)}
        />
        <div className={css.content}>
          <Row>
            <Col xs={4}>
              <Row>
                <Col xs={12} data-test-proxy-relationship-status>
                  <Field
                    disabled={this.state.statusDisabled}
                    name={`${name}.proxy.status`}
                    fullWidth
                  >
                    {({ input, meta }) => {
                      return (
                        <Select
                          {...input}
                          error={
                            !meta?.data?.warning && meta?.data?.error
                              ? meta.data.error
                              : undefined
                          }
                          warning={
                            meta?.data?.warning
                              ? meta?.data?.warning
                              : undefined
                          }
                          label={
                            <FormattedMessage id="ui-users.proxy.relationshipStatus" />
                          }
                        >
                          {relationStatusOptions}
                        </Select>
                      );
                    }}
                  </Field>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={12} data-test-proxy-expiration-date>
                  <Field
                    component={Datepicker}
                    label={<FormattedMessage id="ui-users.expirationDate" />}
                    dateFormat="YYYY-MM-DD"
                    name={`${name}.proxy.expirationDate`}
                    validate={validateMinDate('ui-users.errors.expirationDate')}
                  />
                </Col>
                <OnChange name={`${name}.proxy.expirationDate`}>
                  {() => defer(() => this.updateStatus())}
                </OnChange>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <Row>
                <Col xs={12} data-test-proxy-can-request-for-sponsor>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.proxy.requestForSponsor" />
                    }
                    name={`${name}.proxy.requestForSponsor`}
                    component={Select}
                    fullWidth
                  >
                    {requestForSponsorOptions}
                  </Field>
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={12} data-test-proxy-notifications-sent-to>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.proxy.notificationsTo" />
                    }
                    name={`${name}.proxy.notificationsTo`}
                    component={Select}
                    fullWidth
                  >
                    {notificationsToOptions}
                  </Field>
                </Col>
              </Row>
            </Col>
            {/*
            Accrue-to functionality not yet available on backend
            <Col xs={4}>
              <Row>
                <Col xs={12}>
                  <Field
                    label={<FormattedMessage id="ui-users.proxy.accrueTo" />}
                    name={`${name}.proxy.accrueTo`}
                    component={Select}
                    dataOptions={[...accrueToOptions]}
                    fullWidth
                  />
                </Col>
              </Row>
            </Col>
            */}
          </Row>
        </div>
      </div>
    );
  }
}

// export default ProxyEditItem;
/* Kware Start Editing */
export default injectIntl(withStripes(ProxyEditItem));
/* Kware end Editing */

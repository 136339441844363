import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NavList,
  NavListItem,
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
} from '@folio/stripes-components';

const propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  handleAddToCartListBtn: PropTypes.func,
  setOpenViewSourceModal: PropTypes.func,
  showCartList: PropTypes.bool,
};

const ResultsActionsMenu = ({
  selectedItems,
  handleAddToCartListBtn,
  setOpenViewSourceModal,
  showCartList,
}) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const renderSelectedItemsActions = () => {
    return (
      <>
        <NavListItem
          id="copycat-results-preview-pane-action-menu-addToCart-btn"
          buttonStyle="slim"
          onClick={() => {
            handleAddToCartListBtn();
            toggleDropdown();
          }}
        >
          <Icon icon="cart">
            {!showCartList ? (
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.addToCart"
                defaultMessage="Add to cart"
              />
            ) : (
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.removeFromCart"
                defaultMessage="Remove from cart"
              />
            )}
          </Icon>
        </NavListItem>
        {selectedItems?.length === 2 && (
          <NavListItem
            id="copycat-results-preview-pane-action-menu-compare-btn"
            buttonStyle="slim"
            onClick={() => {
              setOpenViewSourceModal(true);
              toggleDropdown();
            }}
          >
            <Icon icon="replace">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.paneActionMenu.button.compare"
                defaultMessage="Compare"
              />
            </Icon>
          </NavListItem>
        )}
        <hr />
      </>
    );
  };

  const getDropdownContent = () => {
    return (
      <div>
        <NavList>
          {selectedItems.length !== 0 && renderSelectedItemsActions()}
          <NavListItem
            id="copycat-results-preview-pane-action-menu-export"
            buttonStyle="slim"
            onClick={() => {
              toggleDropdown();
            }}
            disabled
          >
            <Icon icon="download">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.paneActionMenu.button.export"
                defaultMessage="Export"
              />
            </Icon>
          </NavListItem>
          <NavListItem
            id="copycat-results-preview-pane-action-menu-print"
            buttonStyle="slim"
            onClick={() => {
              toggleDropdown();
            }}
            disabled
          >
            <Icon icon="print">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.paneActionMenu.button.print"
                defaultMessage="Print"
              />
            </Icon>
          </NavListItem>
        </NavList>
      </div>
    );
  };

  const trigger = ({ getTriggerProps, ariaProps }) => {
    return (
      <Button
        buttonStyle="primary"
        marginBottom0
        {...ariaProps}
        {...getTriggerProps()}
      >
        <Icon icon="ellipsis" size="large" />
      </Button>
    );
  };

  const renderMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <Dropdown
      id="copycat-results-preview-pane-action-menu"
      renderTrigger={trigger}
      renderMenu={renderMenu}
      open={DropdownOpen}
      onToggle={toggleDropdown}
      placement="bottom-end"
      relativePosition
      focusHandlers={{ open: () => null }}
    />
  );
};

ResultsActionsMenu.propTypes = propTypes;

export default ResultsActionsMenu;

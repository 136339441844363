import React from 'react';
import { Button, Icon } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import SupportedLocales from '../../utils/SupportedLocales';
import { getLocaleLabel } from '../../utils/utils';
import AppFilter from './FiltersItems/AppFilter';
import LocaleFilter from './FiltersItems/LocaleFilter';
import SourceFilter from './FiltersItems/SourceFilter';
import TableFilter from './FiltersItems/TableFilter';
import TranslationStatusFilter from './FiltersItems/TranslationStatusFilter';
import UserFilter from './FiltersItems/UserFilter';

function ActiveFilters({
  currentLocale,
  setCurrentLocale,
  currentApp,
  setCurrentApp,
  currentTable,
  setCurrentTable,
  currentStatus,
  setCurrentStatus,
  source,
  setSource,
  currentUser,
  setCurrentUser,
  resetAllFilters,
  apps,
  tables,
  activeFilters,
  languages,
  originTranslations
}) {
  const intl = useIntl();

  return (
    <>
      <div>
        <br />
        <Button
          buttonStyle="none"
          id="clickable-reset-all"
          onClick={resetAllFilters}
          disabled={currentLocale === ''}
        >
          <Icon icon="times-circle-solid">
            <FormattedMessage id="stripes-smart-components.resetAll" />
          </Icon>
        </Button>
      </div>
      {activeFilters.includes('locale') &&
        <LocaleFilter
          currentLocale={currentLocale}
          setCurrentLocale={setCurrentLocale}
          dataOptions={languages}
          disabled={false}
        />
      }
      {activeFilters.includes('app') &&
        <AppFilter
          currentApp={currentApp}
          setCurrentApp={setCurrentApp}
          dataOptions={apps}
          originTranslations={originTranslations}
          disabled={currentLocale === ''}
        />
      }
      {activeFilters.includes('table') &&
        <TableFilter
          currentTabel={currentTable}
          setCurrentTabel={setCurrentTable}
          dataOptions={tables}
          disabled={currentLocale === '' || currentApp === 'allApps'}
        />
      }
      {activeFilters.includes('status') &&
        <TranslationStatusFilter
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
          disabled={currentLocale === ''}
        />
      }
      {activeFilters.includes('source') &&
        <SourceFilter
          source={source}
          setSource={setSource}
          disabled={currentLocale === ''}
        />
      }
      {activeFilters.includes('user') &&
        <UserFilter
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          disabled={currentLocale === ''}
        />
      }
    </>
  );
}

export default ActiveFilters;

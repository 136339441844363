import React, { useState } from 'react';
import {
  Accordion,
  Col,
  FilterAccordionHeader,
  RepeatableField,
  Row,
  Select,
  TextField,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { SearchFields, required } from '../../../utils';

function SearchOptionsFields() {
  const [filterToggle, setFilterToggle] = useState(true);
  const [canRemove, setCanRemove] = useState(false);
  const intl = useIntl();

  const renderFiltersList = (field, index) => {
    if (index !== 0) {
      setCanRemove(true);
    } else {
      setCanRemove(false);
    }

    return (
      <>
        <Row>
          <Col xs={5}>
            <Field
              id={`copycat-query-builder-form-${index}-select-field`}
              component={Select}
              label={
                <FormattedMessage
                  id="ui-inventory.copycat.queryBuilder.form.fields.searchOptions.fieldRow.label"
                  defaultMessage="Field"
                />
              }
              name={`${field}.field`}
              placeholder={intl.formatMessage({
                id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.fieldRow.placeholder',
                defaultMessage: 'Choose field',
              })}
              dataOptions={SearchFields.map((item) => ({
                label: intl.formatMessage({
                  id: item.id,
                  defaultMessage: item.label,
                }),
                value: item.value,
              }))}
              required
              validate={required}
            />
          </Col>
          <Col xs={7}>
            <Field
              id={`copycat-query-builder-form-${index}-select-value`}
              component={TextField}
              label={
                <FormattedMessage
                  id="ui-inventory.copycat.queryBuilder.form.fields.searchOptions.fieldRow.value"
                  defaultMessage="Value"
                />
              }
              name={`${field}.value`}
              required
              validate={required}
              // autoFocus
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Accordion
        id="copycat-query-builder-form-search-options-accordion"
        label={
          <FormattedMessage
            id="ui-inventory.copycat.queryBuilder.form.searchOptions.accordion.label"
            defaultMessage="Search options"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator
        header={FilterAccordionHeader}
      >
        <FieldArray
          addLabel={
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.form.searchOptions.fieldArray.addLabel"
              defaultMessage="Add Field"
            />
          }
          component={RepeatableField}
          name="searchOptions"
          onAdd={(fields) => fields.push('')}
          renderField={renderFiltersList}
          canRemove={canRemove}
        />
      </Accordion>
    </>
  );
}

SearchOptionsFields.propTypes = {};

export default SearchOptionsFields;

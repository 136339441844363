const MAIN_FILTER_PANE_CONFIG = {
  id: 'oa-main-filter-pane'
};

const MAIN_PANE_ID = 'oa-main-pane';

const MAIN_PANESET_CONFIG = {
  id: 'oa-main-paneset'
};

export {
  MAIN_FILTER_PANE_CONFIG,
  MAIN_PANESET_CONFIG,
  MAIN_PANE_ID
};

import React, { useState } from 'react';
import {
  Accordion,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { pageSizeOptions, required } from '../../../utils';

function SearchConfigsFields() {
  const [filterToggle, setFilterToggle] = useState(true);

  return (
    <>
      <Accordion
        id="copycat-query-builder-form-search-configs-accordion"
        label={
          <FormattedMessage
            id="ui-inventory.copycat.queryBuilder.form.searchConfigs.accordion.label"
            defaultMessage="Search Configs"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator
        header={FilterAccordionHeader}
      >
        <Field
          id="copycat-query-builder-form-pageSize-select-field"
          name="pageSize"
          component={Select}
          label={
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.form.fields.pageSize.label"
              defaultMessage="Page size"
            />
          }
          dataOptions={pageSizeOptions.map((option) => ({
            label: option.label,
            value: option.value,
          }))}
          required
          validate={required}
        />
      </Accordion>
    </>
  );
}

SearchConfigsFields.propTypes = {};

export default SearchConfigsFields;

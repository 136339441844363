import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, ButtonGroup } from '@folio/stripes/components';

const ResultsTabs = ({ currentTab, setcurrentTab, tabsList }) => (
  <ButtonGroup fullWidth>
    {tabsList.map((tab) => (
      <Button
        key={`${tab.serverName}`}
        onClick={() => setcurrentTab(tab.serverName)}
        onMouseDown={(e) => e.preventDefault()}
        buttonStyle={`${currentTab === tab.serverName ? 'primary' : 'default'}`}
        aria-selected={currentTab === tab.serverName}
        id={`copycat-results-preview-search-tabs-${tab.serverName}`}
      >
        {tab.serverName !== 'All' ? (
          <FormattedMessage
            id={`ui-inventory.targetProfiles.name.${tab.serverName}`}
            defaultMessage={tab.serverName}
          />
        ) : (
          <FormattedMessage
            id="ui-inventory.copycat.resultsPreview.resultsTabs.name.all"
            defaultMessage="All"
          />
        )}
        <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</div>
        <Badge>{tab.totalRecords}</Badge>
      </Button>
    ))}
  </ButtonGroup>
);

ResultsTabs.propTypes = {
  currentTab: PropTypes.string,
  setcurrentTab: PropTypes.func,
  tabsList: PropTypes.arrayOf(PropTypes.object),
};

export default ResultsTabs;

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';

const WithUserLocales = WrappedComponent => class WithUserLocalesComponent extends React.Component {
    static manifest = {
      ...WrappedComponent.manifest,
      recordId: {},
      userLocales: {
        type: 'okapi',
        path: 'userLocales?limit=1000',
        // path: 'userLocales?query=(userId==:{id})',
        records: 'userLocales',
        POST: {
          path: 'userLocales',
        },
        PUT: {
          path: 'userLocales',
        },
      },
    };

    static propTypes = {
      parentResources: PropTypes.object,
      resources: PropTypes.shape({
        userLocales: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
        recordId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }).isRequired,
      }).isRequired,
      mutator: PropTypes.shape({
        recordId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
        userLocales: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
          POST: PropTypes.func.isRequired,
          PUT: PropTypes.func.isRequired,
        }),
      }),
      stripes: PropTypes.object.isRequired,
    };

    state = {
      currentUser: [],
    };

    static getDerivedStateFromProps(props, state) {
      const { stripes: { user: { user } } } = props;
      const currentUser = get(props.resources.userLocales, ['records'], [])
        .filter(userLoc => userLoc.userId === user.id);

      if (!isEqual(currentUser, state.currentUser)) {
        return {
          currentUser,
        };
      }

      return null;
    }

    getUserLocales = () => {
      const userLocalesIds = get(this.state.currentUser, [0, 'localesIds'], []);

      return userLocalesIds
        .map(uul => this.props.stripes.tenantLocales.find(ul => ul.id === uul))
        .sort(((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
    };

    getUserPreferredLocale = () => {
      return get(this.state.currentUser, [0, 'defaultUserLocaleId'], undefined);
    };

    getUserNumbersShape = () => {
      return get(this.state.currentUser, [0, 'numbersShape'], undefined);
    };

    getUserDateformat = () => {
      return get(this.state.currentUser, [0, 'dateformat'], undefined);
    };

    getUserTimeformat = () => {
      return get(this.state.currentUser, [0, 'timeformat'], undefined);
    };

    updateUserLocales = (userLocalesIds, userPreferredLocaleId, userNumbersShape, dateformat, timeformat) => {
      const { stripes: { user: { user } } } = this.props;

      const record = this.state.currentUser[0];

      if (record) {
        this.props.mutator.recordId.replace(record.id);
        record.localesIds = userLocalesIds;
        record.defaultUserLocaleId = userPreferredLocaleId;
        record.numbersShape = userNumbersShape;
        record.dateformat = dateformat;
        record.timeformat = timeformat;
        this.props.mutator.userLocales.PUT(record);
      } else {
        this.props.mutator.userLocales.POST({
          userId: user.id,
          localesIds: userLocalesIds,
          defaultUserLocaleId: userPreferredLocaleId,
          numbersShape: userNumbersShape,
          dateformat,
          timeformat,
        });
      }
    };

    render() {
      return (
        <>
          <WrappedComponent
            getUserLocales={this.getUserLocales}
            getUserPreferredLocale={this.getUserPreferredLocale}
            getUserNumbersShape={this.getUserNumbersShape}
            getUserDateformat={this.getUserDateformat}
            getUserTimeformat={this.getUserTimeformat}
            updateUserLocales={this.updateUserLocales}
            {...this.props}
          />
        </>
      );
    }
};

export default WithUserLocales;

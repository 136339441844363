/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* kware start editing */
import _, { sortBy } from 'lodash';
/* kware end editing */
import localforage from 'localforage';
import { translations } from 'stripes-config';
import rtlDetect from 'rtl-detect';
import moment from 'moment';

import { discoverServices } from './discoverServices';

import {
  clearCurrentUser,
  setCurrentPerms,
  setLocale,
  setTimezone,
  setCurrency,
  setPlugins,
  setBindings,
  setTranslations,
  clearOkapiToken,
  setAuthError,
  checkSSO,
  setOkapiReady,
  setServerDown,
  setSessionData,
  setLoginData,
  updateCurrentUser,
  /* kware start editing */
  setUserNumbersShape,
  setDateformat,
  setTenantLocales,
  setUserLocales,
  setUserPreferredLocale,
  setTenantDefaultLocale,
  setShowLocaleIcon,
  setdefaultTheme,
  setTimeformat,
  setPrimaryInputLanguage,
  setUserPreferredApps,
  setUserProfileImage,
  setTenant
  /* kware end editing */
} from './okapiActions';
import processBadResponse from './processBadResponse';
import { resetStore } from './mainActions';

// export supported locales, i.e. the languages we provide translations for
export const supportedLocales = [
  'ar', // arabic
  'cs-CZ', // czech, czechia
  'da-DK', // danish, denmark
  'de-DE', // german, germany
  'en-GB', // british english
  'en-SE', // english, sweden
  'en-US', // american english
  'es-419', // latin american spanish
  'es-ES', // european spanish
  'es', // spanish
  'fr-FR', // french, france
  'he', // hebrew
  'hi-IN', // hindi, india
  'hu-HU', // hugarian, hungry
  'it-IT', // italian, italy
  'ja', // japanese
  'ko', // korean
  'nb', // norwegian bokmål"
  'nn', // norwegian nynorsk
  'pl', // polish
  'pt-BR', // portuguese, brazil
  'pt-PT', // portuguese, portugal
  'ru', // russian
  'sv', // swedish
  'ur', // urdu
  'zh-CN', // chinese, china
  'zh-TW' // chinese, taiwan
];

// export supported numbering systems, i.e. the systems tenants may chose
// for numeral display
export const supportedNumberingSystems = [
  'latn', // Arabic (0 1 2 3 4 5 6 7 8 9)
  'arab' // Arabic-Hindi (٠ ١ ٢ ٣ ٤ ٥ ٦ ٧ ٨ ٩)
];

// export config values for storing user locale
export const userLocaleConfig = {
  configName: 'localeSettings',
  module: '@folio/stripes-core'
};

function getHeaders(tenant, token) {
  return {
    'X-Okapi-Tenant': tenant,
    'X-Okapi-Token': token,
    'Content-Type': 'application/json'
  };
}

/**
 * canReadConfig
 * return true if the user has read-permission for configuration entries,
 * i.e. if the store contains the key
 * okapi.currentPerms['configuration.entries.collection.get'].
 *
 * @param {object} store a redux store
 *
 * @returns {boolean}
 */
function canReadConfig(store) {
  const perms = store.getState().okapi.currentPerms;
  return perms['configuration.entries.collection.get'];
}

/* kware start editing */
export const setLocaleTranslations = (
  store,
  locale,
  defaultTranslations = {},
  newTrans
) => {
  const parentLocale = locale.split('-')[0];
  const region = locale.replace('-', '_');
  const loadedLocale = locale.replace('-', '_').split('-')[0];

  return fetch(
    translations[region]
      ? translations[region]
      : translations[loadedLocale] || translations[[parentLocale]]
  ).then((response) => {
    if (response.ok) {
      response.json().then((stripesTranslations) => {
        if (store.getState().okapi.token !== undefined) {
          store.dispatch(
            setTranslations(
              Object.assign(stripesTranslations, defaultTranslations, newTrans)
            )
          );
        }
      });
    }
  });
};
/* kware end editing */

/**
 * loadTranslations
 * return a promise that fetches translations for the given locale and then
 * dispatches the locale and translations.
 * @param {redux store} store
 * @param {string} locale
 * @param {object} defaultTranslations
 *
 * @returns {Promise}
 */
export function loadTranslations(store, locale, defaultTranslations = {}) {
  const parentLocale = locale.split('-')[0];
  // Since moment.js don't support translations like it or it-IT-u-nu-latn
  // we need to build string like it_IT for fetch call
  const loadedLocale = locale.replace('-', '_').split('-')[0];
  const momentLocale = locale.split('-', 2).join('-');

  // react-intl provides things like pt-BR.
  // lokalise provides things like pt_BR.
  // so we have to translate '-' to '_' because the translation libraries
  // don't know how to talk to each other. sheesh.
  const region = locale.replace('-', '_');

  // Update dir- and lang-attributes on the HTML element
  // when the locale changes
  document.documentElement.setAttribute('lang', locale);
  document.documentElement.setAttribute(
    'dir',
    rtlDetect.getLangDir(parentLocale)
  );

  // Set locale for Moment.js (en is not importable as it is not stored separately)
  if (parentLocale === 'en') moment.locale(parentLocale);
  else {
    // For moment, we want to import and load the most-specific
    // locale possible without the numbering system suffix,
    // e.g. it-IT if available, falling back to it if that fails.
    import(`moment/locale/${momentLocale}`)
      .then(() => {
        moment.locale(momentLocale);
      })
      .catch(() => {
        import(`moment/locale/${parentLocale}`)
          .then(() => {
            moment.locale(parentLocale);
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(
              `Error loading locale ${parentLocale} for Moment.js`,
              e
            );
          });
      });
  }

  // Here we put additional condition because languages
  // like Japan we need to use like ja, but with numeric system
  // Japan language builds like ja_u, that incorrect. We need to be safe from that bug.
  // return fetch(
  //   translations[region]
  //     ? translations[region]
  //     : translations[loadedLocale] || translations[[parentLocale]]
  // ).then((response) => {
  //   if (response.ok) {
  //     response.json().then((stripesTranslations) => {
  //       store.dispatch(
  //         setTranslations(
  //           Object.assign(stripesTranslations, defaultTranslations)
  //         )
  //       );
  //       store.dispatch(setLocale(locale));
  //     });
  //   }
  // });

  /* kware start editing */
  return fetch(
    translations[region]
      ? translations[region]
      : translations[loadedLocale] || translations[[parentLocale]]
  ).then((response) => {
    if (response.ok) {
      response.json().then((stripesTranslations) => {
        if (store.getState().okapi.token) {
          fetch(
            `${
              store.getState().okapi.url
            }/translations?query=(localeValue==${locale})`,
            {
              headers: getHeaders(
                store.getState().okapi.tenant,
                store.getState().okapi.token
              )
            }
          ).then((translation) => {
            if (translation.status === 200) {
              translation.json().then((trans) => {
                const catTranslations = trans.translations.map(
                  (categoryName) => categoryName.messages
                );
                store.dispatch(
                  setTranslations(
                    Object.assign(
                      stripesTranslations,
                      defaultTranslations,
                      Object.assign({}, ...catTranslations)
                    )
                  )
                );
                store.dispatch(setLocale(locale));
              });
            }
          });
        } else {
          store.dispatch(
            setTranslations(
              Object.assign(stripesTranslations, defaultTranslations)
            )
          );
          store.dispatch(setLocale(locale));
        }
      });
    }
  });
  /* kware end editing */
}

// /**
//  * dispatchLocale
//  * Given a URL where locale, timezone, and currency information may be stored,
//  * request the data then dispatch appropriate actions for facet, if available.
//  *
//  * @param {string} url location of locale information
//  * @param {object} store redux store
//  * @param {string} tenant
//  * @returns {Promise}
//  */
// function dispatchLocale(url, store, tenant) {
//   return fetch(url, {
//     headers: getHeaders(tenant, store.getState().okapi.token),
//   }).then((response) => {
//     if (response.status === 200) {
//       response.json().then((json) => {
//         if (json.configs.length) {
//           const localeValues = JSON.parse(json.configs[0].value);
//           const { locale, timezone, currency } = localeValues;
//           if (locale) {
//             loadTranslations(store, locale);
//           }
//           if (timezone) store.dispatch(setTimezone(timezone));
//           if (currency) store.dispatch(setCurrency(currency));
//         }
//       });
//     }
//     return response;
//   });
// }

// /**
//  * getLocale
//  * return a promise that retrieves the tenant's locale-settings then
//  * loads the translations and dispatches the timezone and currency.
//  * @param {string} okapiUrl
//  * @param {redux store} store
//  * @param {string} tenant
//  *
//  * @returns {Promise}
//  */
// export function getLocale(okapiUrl, store, tenant) {
//   const query = [
//     'module==ORG',
//     'configName == localeSettings',
//     '(cql.allRecords=1 NOT userId="" NOT code="")',
//   ].join(' AND ');

//   return dispatchLocale(
//     `${okapiUrl}/configurations/entries?query=(${query})`,
//     store,
//     tenant
//   );
// }

// /**
//  * getUserLocale
//  * return a promise that retrieves the user's locale-settings then
//  * loads the translations and dispatches the timezone and currency.
//  * @param {*} okapiUrl
//  * @param {*} store
//  * @param {*} tenant
//  *
//  * @returns {Promise}
//  */
// export function getUserLocale(okapiUrl, store, tenant, userId) {
//   const query = Object.entries(userLocaleConfig)
//     .map(([k, v]) => `"${k}"=="${v}"`)
//     .join(' AND ');

//   return dispatchLocale(
//     `${okapiUrl}/configurations/entries?query=(${query} and userId=="${userId}")`,
//     store,
//     tenant
//   );
// }

/* kware start editing */
export function getUserLocales(
  okapiUrl,
  store,
  tenant,
  tenantAvailableLocales,
  tenantDefaultLocale,
  userId
) {
  fetch(`${okapiUrl}/userLocales?query=(userId==${userId})`, {
    headers: getHeaders(tenant, store.getState().okapi.token)
  }).then((userlocale) => {
    if (userlocale.status === 200) {
      userlocale.json().then((userLoc) => {
        if (userLoc.userLocales.length) {
          const userLocales = userLoc.userLocales[0].localesIds.map((uul) =>
            tenantAvailableLocales.find((ul) => ul.id === uul)
          );

          store.dispatch(setUserLocales(userLocales));
          store.dispatch(
            setUserNumbersShape(userLoc.userLocales[0].numbersShape)
          );
          if (userLoc.userLocales[0].defaultApps) {
            store.dispatch(
              setUserPreferredApps(userLoc.userLocales[0].defaultApps)
            );
          }

          const UserprefferdLocale = userLoc.userLocales.map((uul) =>
            userLocales.find((ul) => ul.id === uul.defaultUserLocaleId)
          );

          if (UserprefferdLocale && UserprefferdLocale[0] !== undefined) {
            loadTranslations(store, UserprefferdLocale[0].value);
            store.dispatch(setUserPreferredLocale(UserprefferdLocale[0].value));
            store.dispatch(
              setDateformat(UserprefferdLocale[0].defaultDateFormat)
            );
            localStorage.setItem(
              'LoginLocale',
              JSON.stringify({
                userId,
                LoginLocale: UserprefferdLocale[0].value
              })
            );
          } else {
            loadTranslations(store, tenantDefaultLocale.value);
            store.dispatch(
              setDateformat(tenantDefaultLocale.defaultDateFormat)
            );
            localStorage.setItem(
              'LoginLocale',
              JSON.stringify({
                userId,
                LoginLocale: tenantDefaultLocale.value
              })
            );
          }
        } else {
          loadTranslations(store, tenantDefaultLocale.value);
          store.dispatch(setUserLocales([]));
          store.dispatch(setUserPreferredLocale(undefined));
          store.dispatch(setUserNumbersShape(undefined));
          store.dispatch(setDateformat(tenantDefaultLocale.defaultDateFormat));
          localStorage.setItem(
            'LoginLocale',
            JSON.stringify({
              userId,
              LoginLocale: tenantDefaultLocale.value
            })
          );
        }
      });
    }
  });
}
/* kware end editing */

/* kware start editing */
export function getTenantAvailableLocales(
  okapiUrl,
  store,
  tenant,
  tenantDefaultLocaleValue
) {
  const userId = store.getState().okapi.currentUser.id;

  fetch(`${okapiUrl}/locales?limit=1000`, {
    headers: getHeaders(tenant, store.getState().okapi.token)
  }).then((locales) => {
    if (locales.status === 200) {
      locales.json().then((loc) => {
        if (loc.locales.length) {
          store.dispatch(setTenantLocales(sortBy(loc.locales, 'value')));
          const tenantDefaultLocale = loc.locales.find(
            (locale) => locale.value === tenantDefaultLocaleValue
          );
          const defaultLocale = {
            value: 'en-US',
            defaultDateFormat: 'MM/DD/YYYY'
          };
          getUserLocales(
            okapiUrl,
            store,
            tenant,
            loc.locales,
            tenantDefaultLocale || defaultLocale,
            userId
          );
        } else {
          loadTranslations(store, tenantDefaultLocaleValue);
          store.dispatch(setUserLocales([]));
          store.dispatch(setUserPreferredLocale(undefined));
          localStorage.setItem(
            'LoginLocale',
            JSON.stringify({
              userId,
              LoginLocale: tenantDefaultLocaleValue
            })
          );
        }
      });
    }
  });
}
/* kware end editing */

/* kware start editing */
/**
 * getLocale
 * return a promise that retrieves the tenant's locale-settings then
 * loads the translations and dispatches the timezone and currency.
 * @param {*} okapiUrl
 * @param {*} store
 * @param {*} tenant
 *
 * @return Promise
 */
export function getLocale(okapiUrl, store, tenant) {
  fetch(
    `${okapiUrl}/configurations/entries?query=(module=ORG and configName=localeSettings)`,
    { headers: getHeaders(tenant, store.getState().okapi.token) }
  ).then((response) => {
    if (response.status === 200) {
      response.json().then((json) => {
        if (json.configs.length) {
          const localeValues = JSON.parse(json.configs[0].value);
          const {
            locale,
            timezone,
            currency,
            showLocaleIcon,
            defaultTheme,
            timeformat,
            primaryInputLanguage,
            userPreferredApps
          } = localeValues;
          if (locale) {
            store.dispatch(setTenantDefaultLocale(locale));
            getTenantAvailableLocales(okapiUrl, store, tenant, locale);
          } else {
            store.dispatch(setTenantDefaultLocale('en-US'));
            getTenantAvailableLocales(okapiUrl, store, tenant, 'en-US');
          }
          if (showLocaleIcon) store.dispatch(setShowLocaleIcon(showLocaleIcon));
          if (timezone) store.dispatch(setTimezone(timezone));
          if (currency) store.dispatch(setCurrency(currency));
          if (defaultTheme) {
            store.dispatch(setdefaultTheme(defaultTheme));
          } else {
            store.dispatch(setdefaultTheme('kwareTheme'));
          }
          if (timeformat) store.dispatch(setTimeformat(timeformat));
          if (primaryInputLanguage) {
            store.dispatch(setPrimaryInputLanguage(primaryInputLanguage));
          }
          // if (userPreferredApps) store.dispatch(setUserPreferredApps(userPreferredApps));
        }
      });
    }
  });
}

export async function logoutUser(store) {
  await store.dispatch(clearOkapiToken());
  await store.dispatch(clearCurrentUser());
  await store.dispatch(resetStore());
  await localforage.removeItem('okapiSess');
}
/* kware end editing */

/**
 * getPlugins
 * return a promise that retrieves the tenant's plugins and dispatches them.
 * @param {} okapiUrl
 * @param {*} store
 * @param {*} tenant
 *
 * @returns {Promise}
 */
export function getPlugins(okapiUrl, store, tenant) {
  return fetch(`${okapiUrl}/configurations/entries?query=(module==PLUGINS)`, {
    headers: getHeaders(tenant, store.getState().okapi.token)
  }).then((response) => {
    if (response.status < 400) {
      response.json().then((json) => {
        const configs = json.configs.reduce(
          (acc, val) => ({
            ...acc,
            [val.configName]: val.value
          }),
          {}
        );
        store.dispatch(setPlugins(configs));
      });
    }
    return response;
  });
}

/**
 * getBindings
 * return a promise that retrieves the tenant's key-bindings and dispatches them
 * @param {} okapiUrl
 * @param {*} store
 * @param {*} tenant
 *
 * @returns {Promise}
 */
export function getBindings(okapiUrl, store, tenant) {
  return fetch(
    `${okapiUrl}/configurations/entries?query=(module==ORG and configName==bindings)`,
    { headers: getHeaders(tenant, store.getState().okapi.token) }
  ).then((response) => {
    let bindings = {};
    if (response.status >= 400) {
      store.dispatch(setBindings(bindings));
    } else {
      response.json().then((json) => {
        const configs = json.configs;
        if (configs.length > 0) {
          const string = configs[0].value;
          try {
            const tmp = JSON.parse(string);
            bindings = tmp; // only if no exception is thrown
          } catch (err) {
            // eslint-disable-next-line no-console
            console.log(
              `getBindings cannot parse key bindings '${string}':`,
              err
            );
          }
        }
        store.dispatch(setBindings(bindings));
      });
    }
    return response;
  });
}

/** kware start edit */
/**
 * getUserProfileImage
 * return a promise that retrieves the user profile image and dispatch it
 * @param {} okapiUrl
 * @param {*} store
 * @param {*} tenant
 */
export function getUserProfileImage(okapiUrl, store, tenant) {
  return fetch(
    `${okapiUrl}/files/${store.getState().okapi.currentUser.id}.jpg`,
    { headers: getHeaders(tenant, store.getState().okapi.token) }
  )
    .then((res) => res.blob())
    .then((imgBlob) =>
      store.dispatch(setUserProfileImage(URL.createObjectURL(imgBlob)))
    );
}
/** kware end edit */

/**
 * loadResources
 * return a promise that retrieves the tenant's locale, user's locale,
 * plugins, and key-bindings from mod-configuration.
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 * @param {string} userId user's UUID
 *
 * @returns {Promise}
 */
function loadResources(okapiUrl, store, tenant, userId) {
  let promises = [];

  // tenant's locale, plugin, bindings, and user's locale are all stored
  // in mod-configuration so we can only retrieve them if the user has
  // read-permission for configuration entries.
  if (canReadConfig(store)) {
    promises = [
      getLocale(okapiUrl, store, tenant),
      /** kware start edit */
      // getUserLocale(okapiUrl, store, tenant, userId),
      getUserProfileImage(okapiUrl, store, tenant),
      /** kware end edit */
      getPlugins(okapiUrl, store, tenant),
      getBindings(okapiUrl, store, tenant)
    ];
  }

  if (!store.getState().okapi.withoutOkapi) {
    promises.push(discoverServices(store));
  }

  return Promise.all(promises);
}

/**
 * createOkapiSession
 * Remap the given data into a session object shaped like:
 * {
 *   user: { id, username, personal }
 *   perms: { permNameA: true, permNameB: true, ... }
 *   token: token
 * }
 * Dispatch the session object, then return a Promise that fetches
 * and dispatches tenant resources.
 *
 * @param {*} okapiUrl
 * @param {*} store
 * @param {*} tenant
 * @param {*} token
 * @param {*} data
 *
 * @returns {Promise}
 */
export function createOkapiSession(okapiUrl, store, tenant, token, data) {
  const user = {
    id: data.user.id,
    username: data.user.username,
    ...data.user.personal
  };

  // clear any auth-n errors
  store.dispatch(setAuthError(null));

  // store the raw login response for future parsing by other modules
  // e.g. if optional values are returned, e.g. see UISP-32
  store.dispatch(setLoginData(data));

  // remap data's array of permission-names to set with
  // permission-names for keys and `true` for values
  const perms = Object.assign(
    {},
    ...data.permissions.permissions.map((p) => ({ [p.permissionName]: true }))
  );
  store.dispatch(setCurrentPerms(perms));

  const sessionTenant = data.tenant || tenant;
  const okapiSess = {
    token,
    user,
    perms,
    tenant: sessionTenant
  };

  return localforage
    .setItem('loginResponse', data)
    .then(() => localforage.setItem('okapiSess', okapiSess))
    .then(() => {
      store.dispatch(setSessionData(okapiSess));
      return loadResources(okapiUrl, store, sessionTenant, user.id);
    });
}

/**
 * validateUser
 * return a promise that fetches from bl-users/self.
 * if successful, dispatch the result to create a session
 * if not, clear the session and token.
 *
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 * @param {object} session
 *
 * @returns {Promise}
 */
export function validateUser(okapiUrl, store, tenant, session) {
  const { token, user, perms, tenant: sessionTenant = tenant } = session;

  return fetch(`${okapiUrl}/bl-users/_self`, {
    headers: getHeaders(sessionTenant, token)
  })
    .then((resp) => {
      if (resp.ok) {
        return resp.json().then((data) => {
          store.dispatch(setLoginData(data));
          store.dispatch(
            setSessionData({ token, user, perms, tenant: sessionTenant })
          );
          return loadResources(okapiUrl, store, sessionTenant, user.id);
        });
      } else {
        store.dispatch(clearCurrentUser());
        store.dispatch(clearOkapiToken());
        return localforage.removeItem('okapiSess');
      }
    })
    .catch((error) => {
      store.dispatch(setServerDown());
      return error;
    });
}

/**
 * getSSOEnabled
 * return a promise that fetches from /saml/check and dispatches checkSSO.
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 *
 * @returns {Promise}
 */
export function getSSOEnabled(okapiUrl, store, tenant) {
  return fetch(`${okapiUrl}/saml/check`, {
    headers: { 'X-Okapi-Tenant': tenant, Accept: 'application/json' }
  })
    .then((response) => {
      if (response.status >= 400) {
        store.dispatch(checkSSO(false));
        return null;
      } else {
        return response.json().then((json) => {
          store.dispatch(checkSSO(json.active));
        });
      }
    })
    .catch(() => {
      store.dispatch(checkSSO(false));
    });
}

/**
 * processSSOLoginResponse
 * handle the return from SSO:
 * for a POST binding, populate a form and submit it
 * for a GET binding, navigate to the requested location
 * @param {object} resp fetch Response
 */
function processSSOLoginResponse(resp) {
  if (resp.status < 400) {
    resp.json().then((json) => {
      const form = document.getElementById('ssoForm');
      if (json.bindingMethod === 'POST') {
        form.setAttribute('action', json.location);
        form.setAttribute('method', json.bindingMethod);

        const samlRequest = document.createElement('input');
        samlRequest.setAttribute('type', 'hidden');
        samlRequest.setAttribute('name', 'SAMLRequest');
        samlRequest.setAttribute('value', json.samlRequest);
        form.appendChild(samlRequest);

        const relayState = document.createElement('input');
        relayState.setAttribute('type', 'hidden');
        relayState.setAttribute('name', 'RelayState');
        relayState.setAttribute('value', json.relayState);
        form.appendChild(relayState);

        form.submit();
      } else {
        window.open(json.location, '_self');
      }
    });
  }
}

/**
 * handleLoginError
 * Clear out session data, dispatch authentication errors, then dispatch
 * okapi-ready to indicate that we're ready to start fresh. Return the
 * response-body, er, JSON, from the failed auth-n request.
 *
 * @param {function} dispatch store.dispatch
 * @param {Response} resp HTTP response from a failed auth-n request
 *
 * @returns {Promise} resolving to the response's JSON
 */
export function handleLoginError(dispatch, resp) {
  return localforage
    .removeItem('okapiSess')
    .then(() => processBadResponse(dispatch, resp))
    .then((responseBody) => {
      dispatch(setOkapiReady());
      return responseBody;
    });
}

/**
 * processOkapiSession
 * create a new okapi session with the response from either a username/password
 * authentication request or a bl-users/_self request.
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 * @param {Response} resp HTTP response
 * @param {string} ssoToken
 *
 * @returns {Promise} resolving with login response body, rejecting with, ummmmm
 */
export function processOkapiSession(okapiUrl, store, tenant, resp, ssoToken) {
  const token = resp.headers.get('X-Okapi-Token') || ssoToken;
  const { dispatch } = store;

  if (resp.ok) {
    return resp
      .json()
      .then((json) => {
        return createOkapiSession(okapiUrl, store, tenant, token, json).then(
          () => json
        );
      })
      .then((json) => {
        store.dispatch(setOkapiReady());
        return json;
      });
  } else {
    return handleLoginError(dispatch, resp);
  }
}

/**
 * checkOkapiSession
 * 1. Pull the session from local storage; if non-empty validate it, dispatching load-resources actions.
 * 2. Check if SSO (SAML) is enabled, dispatching check-sso actions
 * 3. dispatch set-okapi-ready.
 *
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 */
export function checkOkapiSession(okapiUrl, store, tenant) {
  localforage
    .getItem('okapiSess')
    .then((sess) => {
      return sess !== null ? validateUser(okapiUrl, store, tenant, sess) : null;
    })
    .then(() => {
      return getSSOEnabled(okapiUrl, store, tenant);
    })
    .finally(() => {
      store.dispatch(setOkapiReady());
    });
}

/**
 * requestLogin
 * authenticate with a username and password. return a promise that posts the values
 * and then processes the result to begin a session.
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 * @param {object} data
 *
 * @returns {Promise}
 */
export function requestLogin(okapiUrl, store, tenant, data) {
  return fetch(
    `${okapiUrl}/bl-users/login?expandPermissions=true&fullPermissions=true`,
    {
      method: 'POST',
      headers: { 'X-Okapi-Tenant': tenant, 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
  ).then((resp) => processOkapiSession(okapiUrl, store, tenant, resp));
}

/**
 * requestUserWithPerms
 * retrieve currently-authenticated user, then process the result to begin a session.
 * @param {string} okapiUrl
 * @param {redux store} store
 * @param {string} tenant
 * @param {string} token
 *
 * @returns {Promise} Promise resolving to the response-body (JSON) of the request
 */
export function requestUserWithPerms(okapiUrl, store, tenant, token) {
  // return fetch(
  //   `${okapiUrl}/bl-users/_self?expandPermissions=true&fullPermissions=true`,
  //   { headers: getHeaders(tenant, token) }
  // ).then((resp) => processOkapiSession(okapiUrl, store, tenant, resp, token));

  /** kware start edit */
  const storedTenant = localStorage.getItem('tenantName') || tenant;
  return fetch(
    `${okapiUrl}/bl-users/_self?expandPermissions=true&fullPermissions=true`,
    { headers: getHeaders(storedTenant, token) }
  ).then((resp) =>
    processOkapiSession(okapiUrl, store, storedTenant, resp, token)
  );
  /** kware end edit */
}

/**
 * requestSSOLogin
 * authenticate via SSO/SAML
 * @param {string} okapiUrl
 * @param {string} tenant
 *
 * @returns {Promise}
 */
export function requestSSOLogin(okapiUrl, tenant) {
  const stripesUrl =
    (window.location.origin ||
      `${window.location.protocol}//${window.location.host}`) +
    window.location.pathname;
  // the /_/invoke/... URL is used for systems that make call-backs
  // to okapi and don't handle setting of the tenant in the HTTP header.
  // i.e. ordinarily, we'd just call ${okapiUrl}/saml/login.
  // https://s3.amazonaws.com/foliodocs/api/okapi/p/okapi.html#invoke_tenant__id_
  return fetch(`${okapiUrl}/_/invoke/tenant/${tenant}/saml/login`, {
    method: 'POST',
    headers: { 'X-Okapi-tenant': tenant, 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ stripesUrl })
  }).then((resp) => processSSOLoginResponse(resp));
}

/**
 * updateUser
 * 1. concat the given data onto local-storage user and save it
 * 2. dispatch updateCurrentUser, concating given data onto the session user
 * @param {redux-store} store redux store
 * @param {object} data
 *
 * @returns {Promise}
 */
export function updateUser(store, data) {
  return localforage
    .getItem('okapiSess')
    .then((sess) => {
      sess.user = { ...sess.user, ...data };
      return localforage.setItem('okapiSess', sess);
    })
    .then(() => {
      store.dispatch(updateCurrentUser(data));
    });
}

/**
 * updateTenant
 * 1. concat the given data onto local-storage tenant and save it
 * 2. update full user info based on new tenant
 * @param {string} okapiUrl okapi url
 * @param {redux-store} store redux store
 * @param {object} data
 *
 * @returns {Promise}
 */
export async function updateTenant(okapi, store, tenant) {
  const okapiSess = await localforage.getItem('okapiSess');

  await localforage.setItem('okapiSess', { ...okapiSess, tenant });

  await requestUserWithPerms(okapi.url, store, tenant, okapi.token);
}

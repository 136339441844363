export default function okapiReducer(state = {}, action) {
  switch (action.type) {
    case 'SET_OKAPI_TOKEN':
      return Object.assign({}, state, { token: action.token });
    case 'CLEAR_OKAPI_TOKEN':
      return Object.assign({}, state, { token: null });
    case 'SET_CURRENT_USER':
      return Object.assign({}, state, { currentUser: action.currentUser });
    case 'SET_LOCALE':
      return Object.assign({}, state, { locale: action.locale });
    case 'SET_TIMEZONE':
      return Object.assign({}, state, { timezone: action.timezone });
    case 'SET_CURRENCY':
      return Object.assign({}, state, { currency: action.currency });
    case 'SET_PLUGINS':
      return Object.assign({}, state, { plugins: action.plugins });
    case 'SET_SINGLE_PLUGIN':
      return Object.assign({}, state, { plugins: Object.assign({}, state.plugins, { [action.name]: action.value }) });
    case 'SET_BINDINGS':
      return Object.assign({}, state, { bindings: action.bindings });
    case 'SET_CURRENT_PERMS':
      return Object.assign({}, state, { currentPerms: action.currentPerms });
    case 'SET_LOGIN_DATA':
      return Object.assign({}, state, { loginData: action.loginData });
    case 'CLEAR_CURRENT_USER':
      return Object.assign({}, state, { currentUser: {}, currentPerms: {} });
    case 'SET_SESSION_DATA': {
      const { perms, user, token, tenant } = action.session;
      const sessionTenant = tenant || state.tenant;

      return { ...state, currentUser: user, currentPerms: perms, token, tenant: sessionTenant };
    }
    case 'SET_AUTH_FAILURE':
      return Object.assign({}, state, { authFailure: action.message });
    case 'SET_TRANSLATIONS':
      return Object.assign({}, state, { translations: action.translations });
    case 'CHECK_SSO':
      return Object.assign({}, state, { ssoEnabled: action.ssoEnabled });
    case 'OKAPI_READY':
      return Object.assign({}, state, { okapiReady: true });
    case 'SERVER_DOWN':
      return Object.assign({}, state, { serverDown: true });
    case 'UPDATE_CURRENT_USER':
      return { ...state, currentUser: { ...state.currentUser, ...action.data } };
    /* kware start editing */
    case 'SET_TENANT':
      return Object.assign({}, state, {
        tenant: action.tenant,
      });
    case 'SET_USERNUMBERSSHAPE':
      return Object.assign({}, state, {
        userNumbersShape: action.userNumbersShape,
      });
    case 'SET_DATEFORMAT':
      return Object.assign({}, state, { dateformat: action.dateformat });
    case 'SHOW_LOCALE_ICON':
      return Object.assign({}, state, {
        showLocaleIcon: action.showLocaleIcon,
      });
    case 'DEFAULT_THEME':
      return Object.assign({}, state, { defaultTheme: action.defaultTheme });
    case 'SET_TENANT_LOCALES':
      return Object.assign({}, state, { tenantLocales: action.tenantLocales });
    case 'SET_USER_LOCALES':
      return Object.assign({}, state, { userLocales: action.userLocales });
    case 'SET_USER_PREFERRED_LOCALE':
      return Object.assign({}, state, {
        userPreferredLocale: action.userPreferredLocale,
      });
    case 'SET_TENANT_DEFAULT_LOCALE':
      return Object.assign({}, state, {
        tenantDefaultLocale: action.tenantDefaultLocale,
      });
    case 'SET_TIMEFORMAT':
      return Object.assign({}, state, {
        timeformat: action.timeformat,
      });
    case 'SET_PRIMARY_INPUT_LANGUAGE':
      return Object.assign({}, state, {
        primaryInputLanguage: action.primaryInputLanguage,
      });
    case 'SET_USER_PREFERRED_APPS':
      return Object.assign({}, state, {
        userPreferredApps: action.userPreferredApps,
      });
    case 'SET_USER_PROFILE_IMAGE':
      return Object.assign({}, state, {
        userProfileImage: action.userProfileImage,
      });
    /* kware end editing */
    default:
      return state;
  }
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './emptyData.css';

function EmptyData() {
  return (
    <div className={css.emptyContainer}>
      <div style={{ paddingBottom: '10px' }}>
        <FormattedMessage
          id="ui-inventory.copycat.components.common.emptyData.message"
          defaultMessage="No data to show!"
        />
      </div>
    </div>
  );
}
export default EmptyData;

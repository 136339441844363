import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from '@folio/stripes/core';
import { isEqual } from 'lodash';
import Settings from './settings';
import LibTranslationsRoute from './routes/LibTranslationsRoute';
import PermsTranslationsRoute from './routes/PermsTranslationsRoute';
import UserNamesRoute from './routes/UserNamesRoute';
import ImportTranslationsRoute from './routes/ImportTranslationsRoute';
import MultiEditRoute from './routes/MultiEditRoute';
import ExportTranslationsRoute from './routes/ExportTranslationsRoute';
import AppsTranslationRoute from './routes/AppsTranslationRoute';
import AssignedLanguagesProvider from './Context/AssignedLanguages/AssignedLanguagesProvider';
import AppConfigListProvider from './Context/AppConfigList/AppConfigListProvider';
import AppsTranslationsProvider from './Context/AppsTranslations/AppsTranslationsProvider';
import CurrentLocaleProvider from './Context/CurrentLocale/CurrentLocaleProvider';

function index(props) {
  // const [currentURL, setCurrentURL] = useState('');
  const {
    actAs,
    match: { path },
  } = props;
  if (actAs === 'settings') {
    return <Settings {...props} />;
  }
  console.log('Translation app started!!');
  // const lastURL = localStorage.getItem('currentURL');

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const { protocol, pathname, host, search } = window.location;
  //     if (window.location.href !== currentURL) {
  //       setCurrentURL(window.location.href);
  //     }
  //   }
  // }, [props.location]);

  // console.log('currentURL: ', currentURL);

  // useEffect(() => {
  //   const lastURL = localStorage.getItem('currentURL');
  //   if (lastURL) {
  //     props.history.push(lastURL);
  //   }
  //   // return () => {
  //   //   console.log('Translation app Exit!!');
  //   //   if (typeof window !== 'undefined') {
  //   //     const { protocol, pathname, host, search } = window.location;
  //   //     // if (pathname.startsWith('/translations')) {
  //   //     localStorage.setItem('translationAppURL', currentURL);
  //   //     // }
  //   //   }
  //   // };
  // }, []);

  // console.log('props.history: ', props.history);

  return (
    <AppConfigListProvider>
      <AppsTranslationsProvider>
        <AssignedLanguagesProvider>
          <CurrentLocaleProvider>
            <Switch>
              <Redirect exact from={path} to={`${path}/libTranslations`} />
              {/* <Redirect exact from={path} to={lastURL || `${path}/libTranslations`} /> */}
              <Route
                path={`${path}/libTranslations`}
                exact
                component={LibTranslationsRoute}
              />
              {/* <Route path={`${path}/stripesApps`} exact component={StripesAppsRoute} /> */}
              <Route
                path={`${path}/stripesApps`}
                exact
                component={AppsTranslationRoute}
              />
              <Route
                path={`${path}/permsNames`}
                exact
                component={PermsTranslationsRoute}
              />
              <Route
                path={`${path}/usersNames`}
                exact
                component={UserNamesRoute}
              />
              <Route
                path={`${path}/edit-translations`}
                exact
                component={MultiEditRoute}
              />
              <Route
                path={`${path}/export-translations`}
                exact
                component={ExportTranslationsRoute}
              />
              <Route
                path={`${path}/import-translations`}
                exact
                component={ImportTranslationsRoute}
              />
            </Switch>
          </CurrentLocaleProvider>
        </AssignedLanguagesProvider>
      </AppsTranslationsProvider>
    </AppConfigListProvider>
  );
}

index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  actAs: PropTypes.string.isRequired,
};

export default index;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translations } from 'stripes-config';
import { Button } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function StripesAppsCategory({ currentLocale, onLoadTranslations }) {
  const [validate, setValidate] = useState(false);

  const getOriginTranslations = () => {
    if (!currentLocale) setValidate(true);
    const parentLocale = currentLocale.split('-')[0];
    const region = currentLocale.replace('-', '_');

    return fetch(
      translations[region] ? translations[region] : translations[parentLocale]
    ).then((response) => {
      if (response.ok) {
        response.json().then((stripesTranslations) => {
          onLoadTranslations(stripesTranslations);
        });
      }
    });
  };

  return (
    <>
      {!currentLocale && validate && (
        <div style={{ color: 'red', margin: '5px', fontWeight: 'bold' }}>
          <FormattedMessage
            id="ui-translations.validateTranslations.chooseLocaleFirst"
            defaultMessage="Choose language first"
          />
        </div>
      )}
      <Button onClick={getOriginTranslations}>
        <FormattedMessage id="ui-translations.stripesAppsTranslations.chooseFromStripes" />
      </Button>
    </>
  );
}

StripesAppsCategory.propTypes = {};

export default StripesAppsCategory;

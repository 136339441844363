/* kware added new */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, CurrencySelect, Row, Select } from '@folio/stripes-components';
import { getLocaleLabel } from '@folio/stripes-core';
import timeZones from '../../../util/timezones';

const propTypes = {
  Locales: PropTypes.arrayOf(PropTypes.object),
};

const required = value => ((value) ? undefined :
<strong><FormattedMessage id="stripes-smart-components.TranslationsModal.validation.required" defaultMessage="required" /></strong>);

function TenantLocalizations({ Locales }) {
  const intl = useIntl();
  const timeZonesList = timeZones.map(timezone => (
    {
      value: timezone.value,
      label: timezone.value,
    }
  ));

  const localesDataOptions = [
    ...Locales.map(ul => ({
      label: getLocaleLabel(ul.value, intl),
      value: ul.value,
    }
    ))
  ];

  const timeformatList = [
    {
      value: 'h:mm A',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.timeformatList.12Hour',
        defaultMessage: '12 Hour',
      }),
    },
    {
      value: 'HH:mm',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.timeformatList.24Hour',
        defaultMessage: '24 Hour',
      }),
    },
  ];

  const primaryInputLanguageOptions = [
    {
      value: 'ar',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.primaryInputLanguage.ar',
        defaultMessage: 'Arabic',
      }),
    },
    {
      value: 'en',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.primaryInputLanguage.en',
        defaultMessage: 'English',
      }),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={12}>
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.locales.defaulttenantLocales" />}
            name="tenantdefualtLocale"
            id="tenantdefualtLocale"
            component={Select}
            placeholder="---"
            dataOptions={localesDataOptions}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="select-timezone">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.timeZonePicker" />}
            component={Select}
            id="timezone"
            name="timezone"
            placeholder="---"
            dataOptions={timeZonesList}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="select-currency">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.primaryCurrency" />}
            component={CurrencySelect}
            id="currency"
            name="currency"
            placeholder="---"
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="select-time-format">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.timeformat" defaultMessage="Time format:" />}
            component={Select}
            id="timeformat"
            name="timeformat"
            placeholder="---"
            dataOptions={timeformatList}
            // required
            // validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="select-primary-input-language">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.primaryInputLanguage" defaultMessage="Primary input language" />}
            component={Select}
            id="primaryInputLanguage"
            name="primaryInputLanguage"
            placeholder="---"
            dataOptions={primaryInputLanguageOptions}
            // required
            // validate={required}
          />
        </Col>
      </Row>
    </>
  );
}

TenantLocalizations.propTypes = propTypes;

export default TenantLocalizations;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Accordion, Badge, Card, FilterAccordionHeader } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { AppIcon } from '@folio/stripes-core';
import css from './Stats.css';

function CategoryStats({
  CategoryName,
  availableTranslations,
  totalApps,
}) {
  const [filterToggle, setFilterToggle] = useState(true);

  return (
    // <Card
    //   id="tenant-locales"
    //   cardStyle="default"
    //   roundedBorder
    //   headerStart={
    //     <div style={{ fontWeight: '530' }}>
    //       <AppIcon app="erm-usage" size="small">
    //         <FormattedMessage
    //           id="ui-translations.Statistics.categoryStats"
    //           values={{
    //             catName: (
    //               <FormattedMessage
    //                 id={`ui-translations.category.${CategoryName}`}
    //                 defaultMessage={CategoryName}
    //               />
    //             ),
    //           }}
    //         />
    //       </AppIcon>
    //     </div>
    //   }
    // >
    //   {availableTranslations.length ? (
    //     <div>
    //       <li>
    //         <FormattedMessage id="ui-translations.Statistics.total.Apps" />
    //         <Badge>{totalApps}</Badge>
    //       </li>
    //       <li className={css.li}>
    //         <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
    //         <Badge>{availableTranslations.length}</Badge>
    //       </li>
    //     </div>
    //   ) : (
    //     <FormattedMessage id="ui-translations.Statistics.localeStats.noLocales" />
    //   )}
    // </Card>
    <>
      <Accordion
        id="category-stats-accordion"
        label={
          <div style={{ fontWeight: '530' }}>
            <AppIcon app="erm-usage" size="small">
              <FormattedMessage
                id="ui-translations.Statistics.categoryStats"
                values={{
                  catName: (
                    <FormattedMessage
                      id={`ui-translations.category.${CategoryName}`}
                      defaultMessage={CategoryName}
                    />
                  ),
                }}
              />
            </AppIcon>
          </div>}
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        header={FilterAccordionHeader}
        separator={false}
      >
        <br />
        {availableTranslations.length ? (
          <div>
            <li>
              <FormattedMessage id="ui-translations.Statistics.total.Apps" />
              <Badge>{totalApps}</Badge>
            </li>
            <li className={css.li}>
              <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
              <Badge>{availableTranslations.length}</Badge>
            </li>
          </div>
        ) : (
          <FormattedMessage id="ui-translations.Statistics.localeStats.noLocales" />
        )}
      </Accordion>
      <br />
    </>
  );
}

export default CategoryStats;

/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Button,
  Row,
  Col,
  Icon,
  MultiColumnList,
} from '@folio/stripes/components';

import { getLocaleLabel, ImportLocalesIcones, withStripes } from '@folio/stripes-core';
import { FieldArray } from 'react-final-form-arrays';
import UserLocalesModal from './UserLocalesModal';

class UserLocales extends Component {
    static propTypes = {
      intl: PropTypes.object.isRequired,
      userPreferredLocale: PropTypes.string.isRequired,
      stripes: PropTypes.shape({
        showLocaleIcon: PropTypes.bool.isRequired,
        tenantLocales: PropTypes.arrayOf(object),
      }).isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        showUserLocalesModal: false,
      };

      this.UserLocales = [];
    }

    renderAddUserLocalesButton() {
      return (
        <Row end="xs">
          <Col>
            <br />
            <Button
              id="add-user-locales-btn"
              onClick={() => this.setState({ showUserLocalesModal: true })}
            >
              <Icon icon="plus-sign">
                <FormattedMessage id="ui-myprofile.settings.userLocales.addUserLocales" />
              </Icon>
            </Button>
          </Col>
        </Row>
      );
    }


    onAddNewUserLocales = (newUserLocales) => {
      return newUserLocales.map(locale => this.UserLocales.push(locale));
    }

    renderUserLocaleModal(fields) {
      const { stripes: { tenantLocales } } = this.props;

      return (
        <UserLocalesModal
          onClose={() => this.setState({ showUserLocalesModal: false })}
          open={this.state.showUserLocalesModal}
          onSave={this.onAddNewUserLocales}
          tenantLocales={tenantLocales.filter(all => !fields.value.find(loc => all.value === loc.value))}
        />
      );
    }

      renderUserLocaleFormatter = (tl) => {
        const flags = ImportLocalesIcones();

        return (
          <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: '0.30em' }}>
            <div>
              {this.props.stripes.showLocaleIcon ?
                <Icon icon={flags[tl.value] ? flags[tl.value] : 'flag'}>
                  {getLocaleLabel(tl.value, this.props.intl)}
                </Icon>
                : getLocaleLabel(tl.value, this.props.intl)}
            </div>
            {(this.props.userPreferredLocale && (tl.id === this.props.userPreferredLocale)) &&
            <div><Icon icon={flags.star} /></div>}
          </div>
        );
      }


      renderUserLocales = ({ fields }) => {
        this.UserLocales = fields;

        return (
          <>
            <MultiColumnList
              interactive={false}
              contentData={fields.value}
              visibleColumns={['name', 'Actions']}
              columnWidths={{ name: '85%', Actions: '15%' }}
              columnMapping={{
                name: <FormattedMessage id="ui-myprofile.settings.userLocales.column.localeName" />,
                Actions: <FormattedMessage id="ui-myprofile.settings.userLocales.column.Actions" />,
              }}
              formatter={{
                name: tl => this.renderUserLocaleFormatter(tl),
                Actions: tl => (
                  <Button
                    buttonStyle="fieldControl"
                    align="end"
                    type="button"
                    id={`clickable-remove-UserLocales-${tl.value}`}
                    disabled={tl.id === this.props.userPreferredLocale}
                    onClick={() => fields.remove(tl.rowIndex)}
                  >
                    <Icon icon="trash" />
                  </Button>),
              }}
            />
            {this.renderAddUserLocalesButton()}
            {this.renderUserLocaleModal(fields)}
          </>
        );
      }

      render() {
        return (
          <>
            <FieldArray
              name="userLocales"
              component={this.renderUserLocales}
            />
          </>
        );
      }
}

export default injectIntl(withStripes(UserLocales));

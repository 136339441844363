/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layout,
  Loading,
  Pane,
  PaneFooter,
  Tooltip,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import NewReportActions from '../actionsMenus/NewReportActions';
import DisplayMethodSwitcher from './DisplayMethodSwitcher';
import DisplayMethodsMenu from './DisplayMethodsMenu';
import useReportTypes from '../../hooks/useReportTypes';
import EmptyData from '../common/emptyData/EmptyData';
import useQuery from '../../hooks/useQuery';
import ErrorMessage from '../common/errorMessage/ErrorMessage';

function ResultsPreview(props) {
  const { reportTypeRecord, reportDisplayMethods } = useReportTypes(
    props.reportType
  );

  const [resultSet, error, isLoading] = useQuery(props.query);

  const filtereDataOptions = () => {
    return reportDisplayMethods?.map((option) => ({
      label: option.name,
      value: option.value,
    }));
  };

  const renderFirstMenu = () => {
    return (
      <>
        {!props.showQueryBuilderPane ? (
          <Tooltip
            id="report-preview-pane-tooltip-show-query-builder"
            text={
              <FormattedMessage
                id="ui-reports.newReport.reportPreviewPane.tooltip.showQueryBuilderPane"
                defaultMessage="Show query builder panel"
              />
            }
          >
            {({ ref, ariaIds }) => (
              <Button
                buttonStyle="slim"
                id="report-preview-pane-showQueryBuilder-btn"
                marginBottom0
                onClick={() =>
                  props.setShowQueryBuilderPane(!props.showQueryBuilderPane)
                }
                aria-labelledby={ariaIds.text}
                ref={ref}
              >
                <Icon icon="chevron-double-right" size="large" />
              </Button>
            )}
          </Tooltip>
        ) : (
          <div />
        )}
        {reportDisplayMethods?.length > 1 && (
          <DisplayMethodsMenu
            displayMethods={filtereDataOptions()}
            currentDisplayMethod={props.displayMethod}
            setCurrentDisplayMethod={props.setDisplayMethod}
          />
        )}
      </>
    );
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <>
            <Button
              // buttonStyle="primary"
              id="clickable-closeNoSaveBtn-reports-"
              marginBottom0
              onClick={props.handleClose}
              disabled={props.showSavePane}
            >
              <FormattedMessage
                id="ui-reports.newReport.reportPreviewPane.closeBtn"
                defaultMessage="Close"
              />
            </Button>
          </>
        }
        renderStart={
          <>
            <Button
              buttonStyle="primary"
              id="new-report-save-as-report-btn"
              marginBottom0
              onClick={() => {
                props.setShowQueryBuilderPane(false);
                props.setShowSavePane(true);
              }}
              disabled={!resultSet || props.showSavePane}
            >
              <FormattedMessage
                id="ui-reports.newReport.reportPreviewPane.saveAsReportBtn"
                defaultMessage="Save as report"
              />
            </Button>
          </>
        }
      />
    );
  };

  const renderResultSet = () => {
    if (error && !isLoading) {
      // return <pre>{error.toString()}</pre>;
      return <ErrorMessage errorMessage={error.toString()} />;
    }

    return (
      <>
        {isLoading ? (
          <Layout
            className="centered full"
            style={{ maxWidth: '15rem', height: '8rem' }}
          >
            &nbsp;
            <Loading size="xlarge" />
          </Layout>
        ) : (
          <DisplayMethodSwitcher
            displayMethod={props.displayMethod}
            resultSet={props.query ? resultSet : []}
            reportType={props.reportType}
          />
        )}
      </>
    );
  };

  const renderPaneContent = () => {
    if (props.query === '') {
      return <EmptyData />;
    }
    return <>{renderResultSet()}</>;
  };

  return (
    <>
      <Pane
        appIcon={<Icon icon="preview" size="large" />}
        fluidContentWidth
        noOverflow
        firstMenu={renderFirstMenu()}
        lastMenu={<NewReportActions />}
        footer={renderPaneFooter()}
        id="pane-report-preview"
        centeredContent
        paneTitle={
          <FormattedMessage
            id="ui-reports.newReport.reportPreviewPane.paneTitle"
            defaultMessage="Results Prewiew"
          />
        }
        paneSub={
          <FormattedMessage
            id="stripes-smart-components.searchResultsCountHeader"
            values={{
              count:
                !isLoading && resultSet
                  ? resultSet?.loadResponses[0]?.data?.length
                  : 0,
            }}
          />
        }
      >
        {renderPaneContent()}
      </Pane>
    </>
  );
}

ResultsPreview.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setShowSavePane: PropTypes.func.isRequired,
  showSavePane: PropTypes.bool.isRequired,
  showQueryBuilderPane: PropTypes.bool.isRequired,
  setShowQueryBuilderPane: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
  displayMethod: PropTypes.string.isRequired,
  setDisplayMethod: PropTypes.func.isRequired,
};

export default ResultsPreview;

import React from 'react';
import PropTypes from 'prop-types';
import { withModules } from '@folio/stripes-core';
import { Callout } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import UserPreferredAppsForm from './UserPreferredAppsForm';
import WithPreferredApps from './Wrappers/WithPreferredApps';

function UserPreferredAppsManager(props) {
  let callout;

  const onShowCallout = message => {
    if (callout) {
      callout.sendCallout({ message });
    }
  };

  const onFormSubmit = values => {
    const res = props.updateUserDefaultApps(values.userPreferredApps);

    Promise.resolve(res).then(() => {
      onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-myprofile.settings.userPreferredApps.callout.success" />
          </strong>
        </div>
      );
      props.stripes.setUserPreferredApps(values.userPreferredApps);
    });
  };

  return (
    <>
      <UserPreferredAppsForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={{
          userPreferredApps: props.getUserPreferredApps() ? props.getUserPreferredApps() : [],
        }}
        apps={props.modules.app}
      />
      <Callout
        ref={ref => {
          callout = ref;
        }}
      />
    </>
  );
}

UserPreferredAppsManager.propTypes = {
  stripes: PropTypes.object.isRequired,
  modules: PropTypes.shape({
    app: PropTypes.arrayOf(PropTypes.object),
  }),
  getUserPreferredApps: PropTypes.func.isRequired,
  updateUserDefaultApps: PropTypes.func.isRequired,
};

export default withModules(WithPreferredApps(UserPreferredAppsManager));

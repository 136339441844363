/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  Button,
  Icon,
  IconButton,
  Pane,
  PaneFooter,
  PaneMenu,
  Paneset,
  Row,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import {
  AppIcon,
  stripesConnect,
  withStripes,
} from '@folio/stripes-core';
import stripesFinalForm from '@folio/stripes/final-form';
// import css from './style.css';
import FilePreview from './components/FilePreview';
import ImportOptions from './components/ImportOptions';

const propTypes = {
  stripes: PropTypes.shape({
    tenantLocales: PropTypes.arrayOf(object),
  }).isRequired,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

function ImportTranslationsForm({
  form,
  handleClose,
  handleSubmit,
  pristine,
  submitting,
}) {
  const [importFileName, setImportFileName] = useState('');

  const renderFirstMenu = () => {
    return (
      <PaneMenu>
        <FormattedMessage id="ui-translations.importTranslations.close">
          {(ariaLabel) => (
            <IconButton
              aria-label={ariaLabel}
              icon="times"
              id="close-importTranslations-form-button"
              onClick={handleClose}
            />
          )}
        </FormattedMessage>
      </PaneMenu>
    );
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-importTranslations-btn"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="ui-translations.import.button" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            id="clickable-cancel"
            marginBottom0
            onClick={() => {
              form.reset();
              setImportFileName('');
            }}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          appIcon={<AppIcon app="translations" />}
          fluidContentWidth
          noOverflow
          firstMenu={renderFirstMenu()}
          footer={renderPaneFooter()}
          id="pane-import-translations-form"
          paneTitle={
            <FormattedMessage id="ui-translations.importTranslations" />
          }
        >
          <form id="form-import-translations" onSubmit={handleSubmit}>
            <Row>
              <ImportOptions
                onLoadTranslations={
                  form.mutators.changeImportedTranslationsData
                }
                onChangeLocaleValue={
                  form.mutators.changeLocaleValue
                }
                currentCategory={form.getState().values.categoryValue}
                currentLocale={form.getState().values.localeValue}
                setImportFileName={setImportFileName}
              />
              <FilePreview importFileName={importFileName} />
            </Row>
          </form>
        </Pane>
      </Paneset>
    </>
  );
}

ImportTranslationsForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    changeImportedTranslationsData: (args, state, { setIn, changeValue }) => {
      const field = state.fields.importedTranslations;
      field.change(args[0]);
      state.formState.submitFailed = true;
    },
    changeLocaleValue: (args, state, { setIn, changeValue }) => {
      const field = state.fields.localeValue;
      field.change(args[0]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(
  withStripes(stripesConnect(ImportTranslationsForm))
);

import React from 'react';
import PropTypes from 'prop-types';
import { Paneset } from '@folio/stripes/components';
import ReactGridLayout from 'react-grid-layout';
import RoutesTabs from './RoutesTabs';
import DashboardWidgets from '../components/dashboards/dashboardWidgets/DashboardWidgets';

function DashboardsRouteTest(props) {
  const style = { background: 'red', padding: '10px' };

  const layout = [
    { i: 'blue-eyes-dragon', x: 0, y: 0, w: 1, h: 1, static: true },
    { i: 'dark-magician', x: 1, y: 1, w: 1, h: 1, static: true },
    { i: 'kuriboh', x: 2, y: 2, w: 1, h: 1, static: true },
    { i: 'spell-caster', x: 3, y: 3, w: 1, h: 1, static: true },
    { i: 'summoned-skull', x: 4, y: 4, w: 1, h: 1, static: true },
  ];
  return (
    // <ReactGridLayout className="layout" cols={12} rowHeight={30} width={1200}>
    //   <div
    //     style={style}
    //     key="a"
    //     data-grid={{ x: 0, y: 0, w: 1, h: 2, static: true }}
    //   >
    //     a
    //   </div>
    //   <div
    //     style={style}
    //     key="b"
    //     data-grid={{ x: 1, y: 0, w: 3, h: 2, minW: 2, maxW: 4, static: true }}
    //   >
    //     b
    //   </div>
    //   <div
    //     style={style}
    //     key="c"
    //     data-grid={{ x: 4, y: 0, w: 1, h: 2, static: true }}
    //   >
    //     c
    //   </div>
    // </ReactGridLayout>
    <ReactGridLayout layout={layout} cols={5} rowHeight={300} width={1000}>
      <div key="blue-eyes-dragon">
        <div style={style}>Blue Eyes Dragon</div>
      </div>
      <div key="dark-magician">
        <div style={style}>Dark Magician</div>
      </div>
      <div key="kuriboh">
        <div style={style}>Kuriboh</div>
      </div>
      <div key="spell-caster">
        <div style={style}>Spell Caster</div>
      </div>
      <div key="summoned-skull">
        <div style={style}>Summoned Skull</div>
      </div>
    </ReactGridLayout>
  );
}

DashboardsRouteTest.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  // location: PropTypes.object,
};

export default DashboardsRouteTest;

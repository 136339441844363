import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Badge,
  Card,
  FilterAccordionHeader,
  Headline,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppIcon } from '@folio/stripes-core';
import css from './Stats.css';
import TranslationRate from './TranslationRate';

const propTypes = {
  appStats: PropTypes.object,
  // type: PropTypes.string,
};

function AppStats(props) {
  const [filterToggle, setFilterToggle] = useState(false);

  const untranslated = props.appStats.availableTranslations.filter(
    (trans) => trans.localeTranslation
  );
  const translated = props.appStats.availableTranslations.filter(
    (trans) => !trans.localeTranslation
  );

  const completed = Math.floor(
    (100 * untranslated.length) / props.appStats.availableTranslations.length
  );
  const {
    appStats: { totalTables, appName, availableTranslations },
  } = props;
  const intl = useIntl();

  return appName !== 'allApps' ? (
    // <Card
    //   id="tenant-locales"
    //   cardStyle="default"
    //   roundedBorder
    //   headerStart={
    //     <div style={{ fontWeight: '550' }}>
    //       <AppIcon size="small" app={appName.replace('ui-', '')}>
    //         <FormattedMessage
    //           id="ui-translations.Statistics.appStatistics"
    //           values={{
    //             appName: intl.formatMessage({
    //               id: `${appName}.meta.title`,
    //               defaultMessage: `${appName.replace('ui-', '')}`,
    //             }),
    //           }}
    //         />
    //       </AppIcon>
    //     </div>
    //   }
    // >
    //   {/* {props.type === 'lib' ?
    //       renderLibAppStats() :
    //       renderStripesAppStats()} */}
    //   <div>
    //     <li className={css.li}>
    //       <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
    //       <Badge>{availableTranslations.length}</Badge>
    //     </li>
    //     <li className={css.li}>
    //       <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.translated" />
    //       <Badge>{availableTranslations.length - translated.length}</Badge>
    //     </li>
    //     <li className={css.li}>
    //       <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.notTranslated" />
    //       <Badge>{translated.length}</Badge>
    //     </li>
    //     <TranslationRate
    //       ContentData={availableTranslations}
    //       completed={completed}
    //       bgcolor="#6a1b9a"
    //     />
    //   </div>
    // </Card>
    <>
      <Accordion
        id="app-stats-accordion"
        label={
          <div style={{ fontWeight: '550' }}>
            <AppIcon size="small" app={appName.replace('ui-', '')}>
              <FormattedMessage
                id="ui-translations.Statistics.appStatistics"
                values={{
                  appName: intl.formatMessage({
                    id: `${appName}.meta.title`,
                    defaultMessage: `${appName.replace('ui-', '')}`,
                  }),
                }}
              />
            </AppIcon>
          </div>
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        // separator
        header={FilterAccordionHeader}
      >
        <br />
        <div>
          <li className={css.li}>
            <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
            <Badge>{availableTranslations.length}</Badge>
          </li>
          <li className={css.li}>
            <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.translated" />
            <Badge>{availableTranslations.length - translated.length}</Badge>
          </li>
          <li className={css.li}>
            <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.notTranslated" />
            <Badge>{translated.length}</Badge>
          </li>
          <TranslationRate
            ContentData={availableTranslations}
            completed={completed < 1 ? 1 : completed}
            bgcolor="#6a1b9a"
          />
        </div>
      </Accordion>
      <br />
    </>
  ) : (
    <div />
  );
}

AppStats.propTypes = propTypes;

export default AppStats;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Modal, ModalFooter } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function ConfirmDeleteCartListModal({ onClose, open, onConfirmDeleteCartList }) {
  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="copycat-results-preview-confirm-delete-cart-list-btn"
          buttonStyle="primary"
          onClick={() => onConfirmDeleteCartList()}
        >
          <FormattedMessage
            id="ui-inventory.copycat.buttons.confirm"
            defaultMessage="Confirm"
          />
        </Button>
        <Button
          id="copycat-results-preview-confirm-delete-cart-list-modal-close-btn"
          buttonStyle="slim"
          onClick={() => onClose()}
        >
          <FormattedMessage id="stripes-core.button.cancel" />
        </Button>
      </ModalFooter>
    );
  };

  return (
    <Modal
      id="copycat-results-preview-confirm-delete-cart-list-modal"
      footer={renderFooter()}
      open={open}
      label={
        <Icon icon="info" size="large">
          <FormattedMessage
            id="ui-inventory.copycat.resultsPreview.confirmDeleteCartListModal.view.label"
            defaultMessage="Confirm delete cart list"
          />
        </Icon>
      }
      size="small"
    >
      <FormattedMessage
        id="ui-inventory.copycat.resultsPreview.confirmDeleteCartListModal.view.bodyMsg"
        defaultMessage="Are you sure to delete the cart?"
      />
    </Modal>
  );
}

ConfirmDeleteCartListModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirmDeleteCartList: PropTypes.func,
};

export default ConfirmDeleteCartListModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Paneset } from '@folio/stripes/components';
import RoutesTabs from './RoutesTabs';
import DashboardWidgets from '../components/dashboards/dashboardWidgets/DashboardWidgets';

function DashboardsRoute(props) {
  return (
    <Paneset>
      <RoutesTabs currentTab="dashboard" />
      <DashboardWidgets {...props} />
    </Paneset>
  );
}

DashboardsRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default DashboardsRoute;

export const ACQUISITIONS_UNITS_API = 'acquisitions-units/units';
export const ALLOCATIONS_API = 'finance/allocations';
export const BUDGETS_API = 'finance/budgets';
export const ENCUMBRANCES_API = 'finance/encumbrances';
export const FISCAL_YEARS_API = 'finance/fiscal-years';
export const FUND_CODES_EXPENSE_CLASSES_API = 'finance/fund-codes-expense-classes';
export const FUND_TYPES_API = 'finance/fund-types';
export const GROUP_FUND_FISCAL_YEARS_API = 'finance/group-fund-fiscal-years';
export const GROUP_SUMMARIES_API = 'finance/group-fiscal-year-summaries';
export const GROUPS_API = 'finance/groups';
export const LEDGERS_API = 'finance/ledgers';
export const LEDGER_CURRENT_FISCAL_YEAR_API = `${LEDGERS_API}/:{id}/current-fiscal-year`;
export const LEDGER_ROLLOVER_API = 'finance/ledger-rollovers';
export const LEDGER_ROLLOVER_BUDGETS_API = 'finance/ledger-rollovers-budgets';
export const LEDGER_ROLLOVER_ERRORS_API = 'finance/ledger-rollovers-errors';
export const LEDGER_ROLLOVER_LOGS_API = 'finance/ledger-rollovers-logs';
export const LEDGER_ROLLOVER_PROGRESS_API = 'finance/ledger-rollovers-progress';
export const RELEASE_ENCUMBRANCE_API = 'finance/release-encumbrance/:{id}';
export const TRANSACTIONS_API = 'finance/transactions';
export const TRANSFERS_API = 'finance/transfers';

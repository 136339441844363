import React from 'react';
import PropTypes from 'prop-types';
import Copycat from '../components/Copycat/Copycat';

function CopycatRoute(props) {
  return <Copycat {...props} />;
}

CopycatRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default CopycatRoute;

/* kware added new */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import { parseNumbersToArabic } from './util';
import WithLocaleDateFormat from './WithLocaleDateFormat';
import WithUserNumbersShape from './WithUserNumbersShape';

function HandleDateTime({
  dateTimeValue,
  getUserNumbersShape,
  getDefaultDateFormat,
  handleType,
}) {
  const intl = useIntl();

  const handleDateTimeFormat = () => {
    const numbersShape = getUserNumbersShape() || intl.locale;
    const dateformat = getDefaultDateFormat(intl.locale) || 'YYYY/MM/DD';

    switch (handleType) {
      case 'date':
        return intl.locale === 'ar'
          ? moment(dateTimeValue)
            .locale(numbersShape)
            .format(dateformat)
          : moment(dateTimeValue)
            .locale(intl.locale)
            .format(dateformat);
      case 'time':
        return intl.locale === 'ar' && numbersShape === 'ar'
          ? parseNumbersToArabic(intl.formatTime(dateTimeValue))
          : intl.formatTime(dateTimeValue);
      case 'datetime':
        return intl.locale === 'ar'
          ? numbersShape === 'ar'
            ? `${moment(dateTimeValue)
              .locale(numbersShape)
              .format(dateformat)} ، ${parseNumbersToArabic(
              intl.formatTime(dateTimeValue)
            )}`
            : `${moment(dateTimeValue)
              .locale(numbersShape)
              .format(dateformat)} , ${intl.formatTime(dateTimeValue)}`
          : `${moment(dateTimeValue)
            .locale(intl.locale)
            .format(dateformat)} , ${intl.formatTime(dateTimeValue)}`;
      default:
        return moment(dateTimeValue)
          .locale(numbersShape)
          .format(dateformat);
    }
  };

  return <div>{handleDateTimeFormat()}</div>;
}

export default withStripes(
  stripesConnect(WithLocaleDateFormat(WithUserNumbersShape(HandleDateTime)))
);

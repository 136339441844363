/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect } from 'react';
import { Button, SearchField } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

function SearchBox({
  ContentData,
  currentLocale,
  searchTerm,
  selectedIndex,
  setSelectedIndex,
  onActivateSearch,
  onChangeSearchResults,
  onChangeSearchTerm,
  setShowStats,
  searchActive,
}) {
  const intl = useIntl();

  // useEffect(() => {
  //   const getSearchResultsByValues = (array, string, index) => {
  //     const filterdData = array.filter((obj) => {
  //       const str = obj[index] ? obj[index].toLowerCase() : '';
  //       return str.includes(string.toLowerCase());
  //     });
  //     return filterdData;
  //   };
  //   onChangeSearchResults(getSearchResultsByValues(ContentData, searchTerm, selectedIndex));
  // }, [selectedIndex, searchActive]);

  const searchableIndexes = [
    // { label: intl.formatMessage({ id: 'ui-translations.localeTranslations.allFields' }), value: 'allFields' },
    { label: intl.formatMessage({ id: 'ui-translations.localeTranslations.originTranslation' }), value: 'originTranslation' },
    { label: intl.formatMessage({ id: 'ui-translations.localeTranslations.localeTranslation' }), value: 'localeTranslation' },
    { label: intl.formatMessage({ id: 'ui-translations.settings.keyName' }), value: 'translationKey' },
  ];

  const clearValue = () => {
    onChangeSearchTerm('');
    onActivateSearch('false');
    setShowStats(true);
  };

  const onHandleChangeSearchTerm = (e) => {
    onChangeSearchTerm(e.target.value);
    onActivateSearch('false');
    setShowStats(true);
  };

  const onChangeSelectedIndex = (e) => {
    setSelectedIndex(e.target.value);
  };

  const getSearchResultsByValues = (array, string, index) => {
    const filterdData = array.filter((obj) => {
      // const str = obj[index] ? obj[index].toLowerCase() : '';
      // const match = new RegExp(string.replace(), 'gi');
      // return str.includes(string.toLowerCase());
      const str = obj[index] ? obj[index] : '';
      return str.match(`/${string}/i`);
    });

    return filterdData;
  };

  const onHandleSearchBox = () => {
    setShowStats(false);
    onActivateSearch('true');
    // onChangeSearchResults(getSearchResultsByValues(ContentData, searchTerm, selectedIndex));
  };

  return (
    <div>
      <SearchField
        onClear={clearValue}
        value={searchTerm}
        onChange={onHandleChangeSearchTerm}
        placeholder={currentLocale === '' ? intl.formatMessage({ id: 'ui-translations.search.noLangaugeFound' }) :
          intl.formatMessage({ id: 'ui-translations.search.enterSearchTerm' })}
        aria-label="Search for translations."
        clearSearchId="translations-search-button"
        id="clear-translations-sesrch-field"
        searchableIndexes={searchableIndexes}
        onChangeIndex={onChangeSelectedIndex}
        selectedIndex={selectedIndex}
        disabled={currentLocale === ''}
      />
      <Button
        buttonStyle="primary"
        disabled={searchTerm === '' || currentLocale === ''}
        fullWidth
        id="clickable-search-translations"
        marginBottom0
        onClick={() => onHandleSearchBox()}
      >
        <FormattedMessage id="stripes-smart-components.search" />
      </Button>
    </div>);
}

export default SearchBox;

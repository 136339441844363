import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  NavListItem,
  Pane,
  PaneFooter,
  Tooltip
} from '@folio/stripes/components';
import { FcBarChart, FcClock, FcViewDetails } from 'react-icons/fc';
import { MdOutlineFavorite } from 'react-icons/md';
import { BsPinFill } from 'react-icons/bs';
import { GoThreeBars } from 'react-icons/go';
import { FormattedMessage } from 'react-intl';
import css from './style.css';

const tabs = [
  {
    tabName: 'dashboard',
    tabLink: '/reports/dashboards',
    tabIcon: <FcBarChart size="20px" />,
    label: 'Dashboard'
  },
  {
    tabName: 'reportsList',
    tabLink: '/reports/reports-list',
    tabIcon: <FcViewDetails size="20px" />,
    label: 'Reports'
  },
  // {
  //   tabName: 'models',
  //   tabLink: '/reports/models',
  //   tabIcon: <FcViewDetails size="20px" />,
  //   label: 'Models',
  // },
  // {
  //   tabName: 'usersStats',
  //   tabLink: '/reports/users-stats',
  //   tabIcon: <FcViewDetails size="20px" />,
  //   label: 'Users Stats',
  // },
  {
    tabName: 'prefferedReports',
    tabLink: '/reports/preffered-reports',
    tabIcon: <MdOutlineFavorite fill="white" size="20px" />,
    label: 'Preffered'
  },
  {
    tabName: 'scheduledReports',
    tabLink: '/reports/scheduled-reports',
    tabIcon: <FcClock size="20px" />,
    label: 'Scheduled'
  }
];

function RoutesTabs({ currentTab }) {
  const [showLabel, setShowLabel] = useState(false);

  return (
    <>
      <Pane
        id="pane-routes-tabs"
        defaultWidth="5%"
        paneTitle={<GoThreeBars fill="white" size="20px" />}
        // fluidContentWidth
        // noOverflow
        className={css.sideMenu}
        // centered
        padContent={false}
        footer={
          <PaneFooter>
            <NavListItem
              id="routes-tabs-settings-btn"
              type="button"
              className={css.settingsBtn}
              onClick={() => setShowLabel(!showLabel)}
            >
              <BsPinFill fill="white" size="18px" />
            </NavListItem>
          </PaneFooter>
        }
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {tabs.map(tab => (
            <div>
              <Tooltip
                id={`routes-tabs-${tab.tabName}-tooltip`}
                text={tab.label}
              >
                {({ ref, ariaIds }) => (
                  <NavListItem
                    id={`routes-tabs-${tab.tabName}-btn`}
                    type="button"
                    to={tab.tabLink}
                    isActive={currentTab === tab.tabName}
                    className={css.tabBtn}
                    aria-labelledby={ariaIds.text}
                    ref={ref}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white'
                      }}
                    >
                      {tab.tabIcon}
                      {showLabel && tab.label}
                    </div>
                  </NavListItem>
                )}
              </Tooltip>
            </div>
          ))}
        </div>
      </Pane>
    </>
  );
}

RoutesTabs.propTypes = {
  currentTab: PropTypes.string.isRequired
};

export default RoutesTabs;

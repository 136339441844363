export const circulationNoteTypes = [
  {
    value: 'Check in',
    id: 'ui-plugin-create-inventory-records.circulationNotes.checkInNote',
  },
  {
    value: 'Check out',
    id: 'ui-plugin-create-inventory-records.circulationNotes.checkOutNote',
  },
];

export default {};

import React from 'react';
import PropTypes from 'prop-types';
import LineChartPreview from './components/chartPreview/LineChartPreview';
import BarChartPreview from './components/chartPreview/BarChartPreview';
import AreaChartPreview from './components/chartPreview/AreaChartPreview';
import PieChartPreview from './components/chartPreview/PieChartPreview';
import NumericalLabel from './components/numericalLabel/NumericalLabel';
import ListPreview from './components/listPreview/ListPreview';

function DisplayMethodSwitcher({ displayMethod, resultSet, reportType }) {
  const renderModelSwitcher = () => {
    switch (displayMethod) {
      case 'numLabel':
        return <NumericalLabel resultSet={resultSet} reportType={reportType} />;
      case 'table':
        return <ListPreview resultSet={resultSet} />;
      case 'barChart':
        return <BarChartPreview resultSet={resultSet} />;
      case 'pieChart':
        return <PieChartPreview resultSet={resultSet} />;
      case 'lineChart':
        return <LineChartPreview resultSet={resultSet} />;
      case 'areaChart':
        return <AreaChartPreview resultSet={resultSet} />;
      default:
        return <div />;
    }
  };

  return <>{renderModelSwitcher()}</>;
}

DisplayMethodSwitcher.propTypes = {
  displayMethod: PropTypes.string.isRequired,
  resultSet: PropTypes.object.isRequired,
  reportType: PropTypes.string,
};

export default DisplayMethodSwitcher;

const PANE_DEFAULT_WIDTH = '50%';
export { PANE_DEFAULT_WIDTH };

const MAX_CHAR_SHORT = 36;
const MAX_CHAR_LONG = 255;
export { MAX_CHAR_LONG, MAX_CHAR_SHORT };

const MAX_PRINT_IDENTIFIERS = 2;
const MAX_ELECTRONIC_IDENTIFIERS = 3;
export { MAX_ELECTRONIC_IDENTIFIERS, MAX_PRINT_IDENTIFIERS };

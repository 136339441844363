/* eslint-disable react/prop-types */
import React from 'react';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import EmptyData from '../../../common/emptyData/EmptyData';

export const COLORS = [
  '#7DB3FF',
  '#49457B',
  '#FF7C78',
  '#FED3D0',
  '#6F76D9',
  '#9ADFB4',
  '#2E7987',
];

const AreaChartPreview = ({ resultSet }) => {
  if (Object.keys(resultSet).length !== 0) {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={resultSet?.chartPivot()} style={{ direction: 'rtl' }}>
          <XAxis dataKey="x" minTickGap={20} />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {resultSet.seriesNames().map((s, i) => (
            <Area
              key={s.key}
              dataKey={s.key}
              name={s.title}
              stroke={COLORS[i % COLORS.length]}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  return <EmptyData />;
};

export default AreaChartPreview;

import React from 'react';
import PropTypes from 'prop-types';
/* Kware start Editing */
import { FormattedMessage, useIntl } from 'react-intl';
/* Kware End Editing */
import { noop } from 'lodash';

import {
  HandleItemStatus,
  HandleUserName,
  stripesShape,
} from '@folio/stripes/core';
import {
  Button,
  Col,
  /* Kware start Editing */
  Icon,
  /* Kware End Editing */
  Modal,
  Row,
} from '@folio/stripes/components';

import {
  BACKEND_ERROR_CODES,
  OVERRIDABLE_BACKEND_ERROR_CODES,
  ERROR_MESSAGE_TRANSLATION_ID_BY_BACKEND_ERROR_CODE,
  BACKEND_ERRORS_CODES_TO_HIDE,
  ITEM_LIMIT_KEY,
} from '../../constants';
/* Kware Start Editing */
import { getFullName } from '../../util';
/* Kware End Editing */

function ErrorModal(props) {
  const {
    open,
    onClose,
    errors,
    openOverrideModal,
    stripes,
    // item: {
    //   title,
    //   barcode,
    //   materialType: { name: materialType } = {},
    // } = {},
    /* Kware Start Editing */
    item: {
      title,
      barcode,
      status: { name: itemStatus } = {},
      materialType: { name: materialType } = {},
    } = {},
    patron,
    /* Kware End Editing */
  } = props;
  const handleOverrideClick = () => {
    onClose();
    openOverrideModal();
  };

  /* Kware Start Editing */
  const intl = useIntl();
  /* Kware End Editing */

  /* Kware Start Editing */
  const getTranslatedMessage = (message) => {
    const user = patron
      ? `${patron.personal.lastName}, ${patron.personal.firstName}`
      : '';
    switch (message) {
      case 'Item is already checked out':
        return (
          <Icon icon="info" size="large">
            <FormattedMessage
              id="ui-checkout.errorModal.itemCheckedOut"
              defaultMessage="Item is already checked out"
            />
          </Icon>
        );
      case 'Calendar timetable is absent for requested date':
        return (
          <Icon icon="info" size="large">
            <FormattedMessage
              id="ui-checkout.errorModal.calendarTimetableAbsent"
              defaultMessage="Calendar timetable is absent for requested date"
            />
          </Icon>
        );
      case 'Cannot check out to inactive user':
        return (
          <Icon icon="info" size="large">
            <FormattedMessage
              id="ui-checkout.errorModal.inactiveUser"
              defaultMessage="Cannot check out to inactive user"
            />
          </Icon>
        );
      case `${title} (${materialType}) (Barcode:${barcode}) has the item status ${itemStatus} and cannot be checked out`:
        return (
          <Icon icon="info" size="large">
            <FormattedMessage
              id="ui-checkout.errorModal.itemStatus.errorMessage"
              values={{
                title,
                barcode,
                materialType: materialType ? (
                  <FormattedMessage
                    id={`ui-inventory.materialTypes.name.${materialType}`}
                  />
                ) : (
                  '-'
                ),
                itemStatus: <strong>{HandleItemStatus(itemStatus)}</strong>,
              }}
              defaultMessage="حالة المادة [{title} ({materialType}) - باركود:{barcode}]: {itemStatus} ولا يمكن إعارتها حتى تكون متاحه"
            />
          </Icon>
        );
      case `${title} (Barcode: ${barcode}) cannot be checked out to user ${user} because it has been requested by another patron`:
        return (
          <Icon icon="info" size="large">
            <FormattedMessage
              id="ui-checkout.errorModal.itemRequested.errorMessage"
              values={{
                title,
                barcode,
                patron: (
                  <strong>
                    <HandleUserName user={patron} />
                  </strong>
                ),
              }}
              defaultMessage="{title} (Barcode: {barcode}) cannot be checked out to user {patron} because it has been requested by another patron"
            />
          </Icon>
        );
      case `No item with barcode ${errors[0]?.parameters[0]?.value} could be found`:
        return (
          <Icon icon="info" size="large">
            {' '}
            <FormattedMessage
              id="ui-checkout.errorModal.noItemFound.errorMessage"
              values={{
                barcode: <strong>{errors[0]?.parameters[0]?.value}</strong>,
              }}
              defaultMessage="No item with barcode {barcode} could be found"
            />
          </Icon>
        );
      default:
        return message;
    }
  };
  /* Kware End Editing */

  const errorsToDisplay = [];
  let containsOverrideErrorMessage = false;

  errors.forEach((error, index) => {
    const { code, message, parameters } = error;
    let messageToDisplay;

    if (code) {
      if (
        !containsOverrideErrorMessage &&
        OVERRIDABLE_BACKEND_ERROR_CODES.includes(code)
      ) {
        containsOverrideErrorMessage = true;
      }

      if (!BACKEND_ERRORS_CODES_TO_HIDE.includes(code)) {
        const translationId =
          ERROR_MESSAGE_TRANSLATION_ID_BY_BACKEND_ERROR_CODE[code];

        if (translationId) {
          let values = {};

          switch (code) {
            case BACKEND_ERROR_CODES.itemNotLoanable:
              values = {
                title,
                barcode,
                materialType,
                loanPolicy: error?.parameters[0]?.value,
              };
              break;
            case BACKEND_ERROR_CODES.userHasNoBarcode:
              values = {
                br: () => <br />,
              };
              break;
            default: {
              values.itemLimit =
                parameters.find((item) => item.key === ITEM_LIMIT_KEY)?.value ||
                0;
              break;
            }
          }

          messageToDisplay = (
            <FormattedMessage
              id={translationId}
              data-testid="messageToDisplay"
              values={values}
            />
          );
        } else {
          // messageToDisplay = message;
          /** kware start edit */
          messageToDisplay = getTranslatedMessage(message);
          /** kware end edit */
        }
      }
    } else {
      // messageToDisplay = message;
      /** kware start edit */
      messageToDisplay = getTranslatedMessage(message);
      /** kware end edit */
    }

    if (messageToDisplay) {
      errorsToDisplay.push(
        <p data-test-error-item data-testid="errorItem" key={`error-${index}`}>
          {messageToDisplay}
        </p>
      );
    }
  });

  const canBeOverridden =
    stripes.hasPerm('ui-users.overrideItemBlock') &&
    containsOverrideErrorMessage;

  return (
    <Modal
      onClose={onClose}
      data-test-error-modal
      data-testid="errorModal"
      open={open}
      size="small"
      label={<FormattedMessage id="ui-checkout.itemNotCheckedOut" />}
      dismissible
    >
      {errorsToDisplay}
      <Col xs={12}>
        <Row end="xs">
          {canBeOverridden && (
            <Button
              data-test-override-button
              data-testid="overrideButton"
              onClick={handleOverrideClick}
            >
              <FormattedMessage id="ui-checkout.override" />
            </Button>
          )}
          <Button
            data-test-close-button
            data-testid="closeButton"
            buttonStyle="primary"
            onClick={onClose}
          >
            <FormattedMessage id="ui-checkout.close" />
          </Button>
        </Row>
      </Col>
    </Modal>
  );
}

ErrorModal.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool.isRequired,
  stripes: stripesShape.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  openOverrideModal: PropTypes.func,
  /* Kware Start Editing */
  patron: PropTypes.object,
  /* Kware End Editing */
};

ErrorModal.defaultProps = {
  item: {},
  openOverrideModal: noop,
};

export default ErrorModal;

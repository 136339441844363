const contentTypes = {
  AGGREGATED: 'aggregated',
  ABSTRACT: 'abstract',
  EBOOK: 'ebook',
  EJOURNAL: 'ejournal',
  MIXED_CONTENT: 'mixedContent',
  ONLINE_REFERENCE: 'onlineReference',
  PRINT: 'print',
  STREAMING_MEDIA: 'streamingMedia',
  UNKNOWN: 'unknown',
};

export default contentTypes;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stripesConnect } from '@folio/stripes-core';
import { useIntl } from 'react-intl';
import { sortBy } from 'lodash';
import MultiEditForm from './MultiEditForm';

function MultiEditManager(props) {
  // const [MTSuggestions, setMTSuggestions] = useState({});

  // useEffect(() => {
  //   const biuldFieldsMTSuggestionsStates = () => {
  //     const fields = {};
  //     props.contentData.forEach((trans) => {
  //       fields[trans.translationKey] = false;
  //     });
  //     return fields;
  //   };
  //   setMTSuggestions(biuldFieldsMTSuggestionsStates());
  // }, []);

  const intl = useIntl();

  const onFormSubmit = (values) => {
    const newTrans = {};
    Object.keys(values).forEach((key) => {
      newTrans[key.replaceAll('__', '.')] = values[key];
    });

    const translations = sortBy(
      (props.resources.translations || {}).records || [],
      ['localeValue']
    );
    const record = translations.filter(
      (curTrans) => curTrans.localeValue === props.currentLocale &&
        curTrans.categoryName === props.paneTitle
    );
    if (record && record.length !== 0) {
      props.mutator.translationId.replace(record[0].id);
      record[0].messages = Object.assign(record[0].messages, newTrans);
      props.mutator.translations.PUT(record[0]).then(() => {
        props.handleClose();
        if (intl.locale === props.currentLocale) {
          props.stripes.setTranslations(newTrans);
        }
      });
    } else {
      props.mutator.translations
        .POST({
          categoryName: props.paneTitle,
          localeValue: props.currentLocale,
          messages: newTrans,
        })
        .then(() => {
          props.handleClose();
          if (intl.locale === props.currentLocale) {
            props.stripes.setTranslations(newTrans);
          }
        });
    }
  };

  const getFormInitialValues = () => {
    const { contentData } = props;
    const fields = {};

    contentData.forEach((trans) => {
      fields[trans.translationKey.replaceAll('.', '__')] =
        trans.localeTranslation;
    });
    return fields;
  };

  return (
    <>
      <MultiEditForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={getFormInitialValues()}
        selectedTranslations={props.contentData}
      />
    </>
  );
}

MultiEditManager.propTypes = {
  stripes: PropTypes.object,
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentLocale: PropTypes.string,
  category: PropTypes.string.isRequired,
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
  mutator: PropTypes.shape({
    translations: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
};

MultiEditManager.manifest = {
  translations: {
    type: 'okapi',
    path: 'translations?limit=1000',
    records: 'translations',
    // resourceShouldRefresh: true,
    POST: {
      path: 'translations',
    },
    PUT: {
      path: 'translations',
    },
  },
  translationId: '',
};

export default stripesConnect(MultiEditManager);

/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
/* Kware Start Editing */
import {
  FormattedMessage,
  FormattedTime,
  FormattedDate,
  useIntl,
} from 'react-intl';
/* Kware end Editing */
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  KeyValue,
  LayoutHeader,
  NoValue,
} from '@folio/stripes/components';
/* Kware Start Editing */
import { getFullNameValue } from '@folio/stripes/core';
import { getFullName, handleDateTimeFormat } from '../../util';
/* Kware end Editing */
import css from './ProxyItem.css';

// const ProxyItem = ({ record }) => {
//   const creationDateTime = <FormattedTime
//     value={record.proxy.metadata.createdDate}
//     day="numeric"
//     month="numeric"
//     year="numeric"
//   />;
/* Kware Start Editing */
const ProxyItem = ({ record, stripes }) => {
  /* Kware Start Editing */
  const intl = useIntl();
  const creationDateTime = record.proxy.metadata.createdDate
    ? handleDateTimeFormat(
      record.proxy.metadata.createdDate,
      stripes,
      intl,
      'datetime'
    )
    : '-';
  /* Kware End Editing */
  const relationshipCreatedMsg = (
    <FormattedMessage id="ui-users.proxy.relationshipCreated" />
  );
  const link = (
    <div>
      {/* <Link to={`/users/view/${record.user.id}`}>{getFullName(record.user)}</Link> */}
      {/* Kware Start Editing */}
      <Link to={`/users/view/${record.user.id}`}>
        {getFullNameValue(record.user, intl)}
      </Link>
      {/* Kware Start Editing */}
      {record.proxy &&
        record.proxy.metadata &&
        record.proxy.metadata.createdDate && (
          <span className={css.creationLabel}>
            ({relationshipCreatedMsg} {creationDateTime})
          </span>
      )}
    </div>
  );

  // const expirationDate = (record.proxy && record.proxy.expirationDate) ?
  //   <FormattedDate value={record.proxy.expirationDate} /> : '-';

  /* Kware Start Editing */
  const expirationDate =
    record.proxy && record.proxy.expirationDate
      ? handleDateTimeFormat(record.proxy.expirationDate, stripes, intl, 'date')
      : <NoValue />;

  const getTranslatedValue = (value) => {
    if (!value) return <NoValue />;
    return (
      <FormattedMessage
        id={`ui-users.${value.toLowerCase()}`}
        defaultMessage={value}
      />
    );
  };
  /* Kware End Editing */

  return (
    <div className={css.item}>
      <LayoutHeader level={3} title={link} noActions />
      <div className={css.content}>
        <Row>
          <Col xs={4}>
            <Row>
              <Col xs={12}>
                <KeyValue
                  data-testid="status"
                  label={
                    <FormattedMessage id="ui-users.proxy.relationshipStatus" />
                  }
                  /* Kware Start Editing */
                  value={getTranslatedValue(record?.proxy?.status)}
                  /* Kware End Editing */
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Row>
              <Col xs={12}>
                <KeyValue
                  data-testid="expirationDate"
                  label={<FormattedMessage id="ui-users.expirationDate" />}
                  value={expirationDate}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Row>
              <Col xs={12}>
                <KeyValue
                  data-testid="requestForSponsor"
                  label={
                    <FormattedMessage id="ui-users.proxy.requestForSponsor" />
                  }
                  /* Kware Start Editing */
                  value={getTranslatedValue(record?.proxy?.requestForSponsor)}
                  /* Kware End Editing */
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <Row>
              <Col xs={12}>
                <KeyValue
                  data-testid="notificationsTo"
                  label={
                    <FormattedMessage id="ui-users.proxy.notificationsTo" />
                  }
                  /* Kware Start Editing */
                  value={getTranslatedValue(record?.proxy?.notificationsTo)}
                  /* Kware End Editing */
                />
              </Col>
            </Row>
          </Col>
          {/*
          Accrue-to functionality not yet available on backend
          <Col xs={4}>
            <Row>
              <Col xs={12}>
                <KeyValue
                  data-testid="accrueTo"
                  label={<FormattedMessage id="ui-users.proxy.accrueTo" />}
                  value={_.get(record, ['proxy', 'accrueTo'], <NoValue />)}
                />
              </Col>
            </Row>
          </Col>
          */}
        </Row>
      </div>
    </div>
  );
};

ProxyItem.propTypes = {
  record: PropTypes.object,
};

export default ProxyItem;

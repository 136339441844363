/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row, Col, Icon } from '@folio/stripes/components';
import DroppableList from './DroppableList';
import { move, reorder } from './utils';
import css from './style.css';

export default function DndContext({ apps, userApps, totalInstalledApps, onChangeUserPreferredApps }) {
  const [installedApps, setInstalledApps] = useState(apps);
  const [userPreferredApps, setUserPreferredApps] = useState(userApps);

  const getList = id => {
    switch (id) {
      case 'installedApps':
        return installedApps;
      case 'userPreferredApps':
        return userPreferredApps;
      default:
        return [];
    }
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reorderApps = reorder(getList(source.droppableId), source.index, destination.index);

      if (source.droppableId === 'userPreferredApps') {
        setUserPreferredApps(reorderApps);
        onChangeUserPreferredApps(reorderApps);
      }
    } else {
      const moveApps = move(getList(source.droppableId), getList(destination.droppableId), source, destination);

      setInstalledApps(moveApps.installedApps);
      setUserPreferredApps(moveApps.userPreferredApps);
      onChangeUserPreferredApps(moveApps.userPreferredApps);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row>
        <Col xs={5}>
          <DroppableList apps={installedApps} totalApps={totalInstalledApps} droppableId="installedApps" />
        </Col>
        <Col xs={2}>
          <div className={css.arrowIcons}>
            <Icon icon="arrow-right" size="large" />
            <Icon icon="arrow-left" size="large" />
          </div>
        </Col>
        <Col xs={5}>
          <DroppableList
            apps={userPreferredApps}
            totalApps={userPreferredApps.length}
            droppableId="userPreferredApps"
          />
        </Col>
      </Row>
    </DragDropContext>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  Button,
  Icon,
  IconButton,
  Pane,
  PaneFooter,
  PaneMenu,
  Paneset,
  Row,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { AppIcon, stripesConnect, withStripes } from '@folio/stripes-core';
import stripesFinalForm from '@folio/stripes/final-form';
// import css from './style.css';
import ExportOptions from './components/ExportOptions';
import ExportPreview from './components/ExportPreview';

const propTypes = {
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

function ExportTranslationsForm({
  form,
  handleClose,
  handleSubmit,
  pristine,
  submitting,
  contentData,
  selectedTranslations,
  currentLocale,
  categoryName
}) {
  // const [PreviewColumns, setPreviewColumns] = useState([
  //   'translationKey',
  //   'localeTranslation',
  // ]);

  // useEffect(() => {
  //   switch (form.getState().values.exportFields) {
  //     case 'localeTrans':
  //       setPreviewColumns(['translationKey', 'localeTranslation']);
  //       break;

  //     case 'originTrans':
  //       setPreviewColumns(['translationKey', 'originTranslation']);
  //       break;

  //     case 'localeOrigin':
  //       setPreviewColumns(['originTranslation', 'localeTranslation']);
  //       break;

  //     default:
  //       setPreviewColumns(['translationKey', 'localeTranslation']);
  //   }
  // }, [form.getState().values.exportFields]);

  const renderFirstMenu = () => {
    return (
      <PaneMenu>
        <FormattedMessage id="ui-translations.exportTranslations.close">
          {(ariaLabel) => (
            <IconButton
              aria-label={ariaLabel}
              icon="times"
              id="close-importTranslations-form-button"
              onClick={handleClose}
            />
          )}
        </FormattedMessage>
      </PaneMenu>
    );
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            // disabled={pristine || submitting}
            id="clickable-exportTranslations-btn"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="ui-translations.export.button" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            id="clickable-cancel"
            marginBottom0
            onClick={handleClose}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <form id="form-export-translations" onSubmit={handleSubmit}>
        <Paneset>
          <Pane
            appIcon={<AppIcon app="translations" />}
            fluidContentWidth
            noOverflow
            firstMenu={renderFirstMenu()}
            footer={renderPaneFooter()}
            id="pane-export-translations-form"
            paneTitle={
              <FormattedMessage id="ui-translations.exportTranslations" />
          }
          >
            <Row>
              <ExportOptions
                currentExportedFields={form.getState().values.exportFields}
                currentLocale={currentLocale}
                categoryName={categoryName}
              />
              <ExportPreview
                contentData={
                  selectedTranslations.length === 0
                    ? contentData
                    : selectedTranslations
                }
                // PreviewColumns={PreviewColumns}
                PreviewColumns={form.getState().values.exportFields}
              />
            </Row>
          </Pane>
        </Paneset>
      </form>
    </>
  );
}

ExportTranslationsForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  validateOnBlur: true,
})(ExportTranslationsForm);

import { FormattedMessage } from 'react-intl';

const buildQuery = (pars, qryBuild) => {
  let zquery = '';
  let nterms = 0;

  const keys = Object.keys(pars).sort();
  for (const k of keys) {
    const val = pars[k];
    if (val && qryBuild[k]) {
      qryBuild[k] = qryBuild[k].replace(/#term/g, val);
      zquery += qryBuild[k];
      nterms++;
    }
  }

  for (let i = 2; i <= nterms; i++) {
    zquery = `@and ${zquery}`;
  }

  return zquery;
};

const bibBuildQuery = (pars) => {
  const qryBuild = {
    isbn: '@attr 1=7 @attr 5=1 "#term" ',
    issn: '@attr 1=8 @attr 5=1 "#term" ',
    title: '@attr 1=4 "#term" ',
    author: '@attr 1=1003 "#term" ',
    dewey: '@attr 1=16 "#term" ',
    subject: '@attr 1=21 "#term" ',
    lccall:
      '@attr 1=16 @attr 2=3 @attr 3=1 @attr 4=1 @attr 5=1 ' +
      '@attr 6=1 "#term" ',
    controlnumber: '@attr 1=12 "#term" ',
    srchany: '@attr 1=1016 "#term" ',
    stdid: '@attr 1=1007 "#term" ',
    publicationyear: '@attr 1=31 "#term" ',
  };

  return buildQuery(pars, qryBuild);
};

export const SearchFieldsValues = {
  any: '@attr 1=1016',
  title: '@attr 1=4',
  author: '@attr 1=1003',
  isbn: '@attr 1=7 @attr 5=1',
  issn: '@attr 1=8 @attr 5=1',
  subject_heading: '@attr 1=21',
  dewey: '@attr 1=13',
  lc_call_number: '@attr 1=16 @attr 2=3 @attr 3=1 @attr 4=1 @attr 5=1 ' +
  '@attr 6=1',
  control_number: '@attr 1=12',
  publication_year: '@attr 1=31',
  standard_id: '@attr 1=1007',
};

export const SearchFields = [
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.any',
    label: 'any',
    value: 'any',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.title',
    label: 'Title',
    value: 'title',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.author',
    label: 'Author',
    value: 'author',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.ISBN',
    label: 'ISBN',
    value: 'isbn',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.ISSN',
    label: 'ISSN',
    value: 'issn',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.subjectHeading',
    label: 'Subject heading',
    value: 'subject_heading',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.dewey',
    label: 'Dewey',
    value: 'dewey',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.LCCallNumber',
    label: 'LC call number',
    value: 'lc_call_number',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.controlNumber',
    label: 'Control number',
    value: 'control_number',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.publicationYear',
    label: 'Publication year',
    value: 'publication_year',
  },
  {
    id: 'ui-inventory.copycat.queryBuilder.form.fields.searchOptions.standardID',
    label: 'Standard ID',
    value: 'standard_id',
  },
];

export const filtersOperators = [
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'Not equals',
    value: 'notEquals',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Not contains',
    value: 'notContains',
  },
  {
    label: 'Starts with',
    value: 'startsWith',
  },
  {
    label: 'Ends with',
    value: 'endsWith',
  },
  {
    label: 'Greater than',
    value: 'gt',
  },
  {
    label: 'Greater than or equal to',
    value: 'gte',
  },
  {
    label: 'Less than',
    value: 'lt',
  },
  {
    label: 'Less than or equal to',
    value: 'lte',
  },
  {
    label: 'Set',
    value: 'set',
  },
  {
    label: 'Not set',
    value: 'notSet',
  },
  {
    label: 'In date range',
    value: 'inDateRange',
  },
  {
    label: 'Not in date range',
    value: 'notInDateRange',
  },
  {
    label: 'Before date',
    value: 'beforeDate',
  },
  {
    label: 'After date',
    value: 'afterDate',
  },
];

export const pageSizeOptions = [
  {
    label: 20,
    value: 20,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 70,
    value: 70,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 120,
    value: 120,
  },
  {
    label: 150,
    value: 150,
  },
  {
    label: 170,
    value: 170,
  },
  {
    label: 200,
    value: 200,
  },
];

export const sortedVisibleColumns = [
  'author',
  'ISBN',
  'edition',
  'publisher',
  'publicationYear',
];

export const columnMapping = {
  rowIndex: '#',
  serverName: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.serverName"
      defaultMessage="Server"
    />
  ),
  title: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.title"
      defaultMessage="Title"
    />
  ),
  author: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.author"
      defaultMessage="Author"
    />
  ),
  ISBN: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.ISBN"
      defaultMessage="ISBN"
    />
  ),
  edition: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.edition"
      defaultMessage="Edition"
    />
  ),
  publisher: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.publisher"
      defaultMessage="Publisher"
    />
  ),
  publicationYear: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.publicationYear"
      defaultMessage="Year"
    />
  ),
  Actions: (
    <FormattedMessage
      id="ui-inventory.copycat.resultsPreview.columns.Actions"
      defaultMessage="Actions"
    />
  ),
};

export const required = (value) => (value ? undefined : (
  <strong>
    <FormattedMessage
      id="ui-reports.validation.required"
      defaultMessage="required"
    />
  </strong>
));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Col,
  FilterAccordionHeader,
  RepeatableField,
  Row,
  TextField,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import FieldFilter from './components/FieldFilter';
import OperatorField from './components/OperatorField';

function DimFilters({ currentCubeMembers, disabled }) {
  const [filterToggle, setFilterToggle] = useState(true);

  const renderFiltersList = (field, index) => {
    return (
      <>
        <Row>
          <Col xs={4}>
            <FieldFilter
              field={field}
              index={index}
              currentCubeMembers={currentCubeMembers}
              disabled={disabled}
              fieldType="dimensions"
            />
          </Col>
          <Col xs={4}>
            <OperatorField field={field} index={index} disabled={disabled} />
          </Col>
          <Col xs={4}>
            <Field
              component={TextField}
              label="Value"
              name={`${field}.values`}
              disabled={disabled}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Accordion
        id="query-builder-form-dim-filters-accordion"
        label={
          <FormattedMessage
            id="ui-reports.queryBuilder.dimFilters.Accordion.header"
            defaultMessage="Fields Filters"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator
        header={FilterAccordionHeader}
      >
        <FieldArray
          addLabel="Add filter"
          component={RepeatableField}
          name="dimFilters"
          onAdd={(fields) => fields.push('')}
          renderField={renderFiltersList}
        />
      </Accordion>
    </>
  );
}

DimFilters.propTypes = {
  currentCubeMembers: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default DimFilters;

/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* kware added new */

import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { Field } from 'react-final-form';
import {
  Button,
  Pane,
  PaneFooter,
  Paneset,
  Row,
  Col,
  TextField,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

const propTypes = {
  onDeleteEmailSettings: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

const required = (value) =>
  value ? (
    undefined
  ) : (
    <strong>
      <FormattedMessage
        id="ui-tenant-settings.settings.validation.required"
        defaultMessage="Please fill this in to continue"
      />
    </strong>
  );

const pattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

const email = (value) =>
  pattern.test(value) ? (
    undefined
  ) : (
    <strong>
      <FormattedMessage
        id="ui-tenant-settings.settings.validation.email"
        defaultMessage="Please enter valid email address."
      />
    </strong>
  );

function EmailSettingsForm({
  pristine,
  submitting,
  handleSubmit,
  form,
  onDeleteEmailSettings,
}) {
  const intl = useIntl();
  const loginOptions = [
    {
      value: 'DISABLED',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.loginOptions.disabled',
        defaultMessage: 'Disabled',
      }),
    },
    {
      value: 'OPTIONAL',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.loginOptions.optional',
        defaultMessage: 'Optional',
      }),
    },
    {
      value: 'REQUIRED',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.loginOptions.required',
        defaultMessage: 'Required',
      }),
    },
  ];
  const SSLOptions = [
    {
      value: 'true',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.SSLOptions.true',
        defaultMessage: 'Yes',
      }),
    },
    {
      value: 'false',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.SSLOptions.false',
        defaultMessage: 'No',
      }),
    },
  ];

  const TrustOptions = [
    {
      value: 'true',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.TrustOptions.trustAll',
        defaultMessage: 'Trust all',
      }),
    },
    {
      value: 'false',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.TrustOptions.trustNo',
        defaultMessage: 'Not trust',
      }),
    },
  ];

  const TLSOptions = [
    {
      value: 'NONE',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.TLSOptions.none',
        defaultMessage: 'None',
      }),
    },
    {
      value: 'OPTIONAL',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.TLSOptions.optional',
        defaultMessage: 'Optional',
      }),
    },
    {
      value: 'REQUIRED',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.emailSettings.TLSOptions.required',
        defaultMessage: 'Required',
      }),
    },
  ];
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-tenantLocales"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-email-settings-form"
          paneTitle={
            <FormattedMessage id="ui-tenant-settings.settings.emailSettings.label" />
          }
          lastMenu={
            <>
              <Button
                id="clickable-delete-email-settings"
                onClick={() => onDeleteEmailSettings()}
                marginBottom0
                buttonStyle="primary"
              >
                <FormattedMessage
                  id="ui-tenant-settings.settings.emailSettings.Delete"
                  defaultMessage="Delete"
                />
              </Button>
            </>
          }
        >
          <form
            id="form-email-settings"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Row>
              <Col xs={12} id="email_host_name">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.hostName"
                      defaultMessage="Email host name:"
                    />
                  }
                  component={TextField}
                  id="EMAIL_SMTP_HOST"
                  name="EMAIL_SMTP_HOST"
                  required
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_from">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.emailSender"
                      defaultMessage="Sender name:"
                    />
                  }
                  component={TextField}
                  id="EMAIL_FROM"
                  name="EMAIL_FROM"
                  type="email"
                  required
                  validate={email}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_port">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.portNumber"
                      defaultMessage="Port number:"
                    />
                  }
                  component={TextField}
                  id="EMAIL_SMTP_PORT"
                  name="EMAIL_SMTP_PORT"
                  required
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_user_name">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.userName"
                      defaultMessage="User name:"
                    />
                  }
                  component={TextField}
                  id="EMAIL_USERNAME"
                  name="EMAIL_USERNAME"
                  type="email"
                  required
                  // validate={email}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_password">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.password"
                      defaultMessage="Password:"
                    />
                  }
                  component={TextField}
                  id="EMAIL_PASSWORD"
                  name="EMAIL_PASSWORD"
                  type="password"
                  autoFocus={false}
                  autocomplete="new-password"
                  required
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_ssl_options">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.SSLOptions"
                      defaultMessage="SSL options:"
                    />
                  }
                  component={Select}
                  id="EMAIL_SMTP_SSL"
                  name="EMAIL_SMTP_SSL"
                  placeholder="---"
                  dataOptions={SSLOptions}
                  required
                  validate={required}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_login_options">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.loginOptions"
                      defaultMessage="Login options:"
                    />
                  }
                  component={Select}
                  id="EMAIL_SMTP_LOGIN_OPTION"
                  name="EMAIL_SMTP_LOGIN_OPTION"
                  placeholder="---"
                  dataOptions={loginOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_trust_all_options">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.trustAll"
                      defaultMessage="Trust options:"
                    />
                  }
                  component={Select}
                  id="EMAIL_TRUST_ALL"
                  name="EMAIL_TRUST_ALL"
                  placeholder="---"
                  dataOptions={TrustOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} id="email_tls_options">
                <Field
                  label={
                    <FormattedMessage
                      id="ui-tenant-settings.settings.emailSettings.TLSOptions"
                      defaultMessage="TLS options:"
                    />
                  }
                  component={Select}
                  id="EMAIL_START_TLS_OPTIONS"
                  name="EMAIL_START_TLS_OPTIONS"
                  placeholder="---"
                  dataOptions={TLSOptions}
                />
              </Col>
            </Row>
          </form>
        </Pane>
      </Paneset>
    </>
  );
}

EmailSettingsForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  validateOnBlur: true,
})(EmailSettingsForm);

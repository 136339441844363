/* kware added new */

const SupportedLocales = [
  { name: 'Arabic', value: 'ar', defaultDateFormat: 'YYYY/MM/DD' },
  { name: 'English - United States', value: 'en-US', defaultDateFormat: 'MM/DD/YYYY' },
  { name: 'English - Great Britain', value: 'en-GB', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'English - Sweden', value: 'en-SE', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'German - Germany', value: 'de-DE', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Italian - Italy', value: 'it-IT', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Spanish', value: 'es', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Spanish - Latin America', value: 'es-419', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Spanish - Spain', value: 'es-ES', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Portuguese - Brazil', value: 'pt-BR', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Portuguese - Portugal', value: 'pt-PT', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Danish', value: 'da-DK', defaultDateFormat: 'DD-MM-YYYY' },
  { name: 'Hungarian', value: 'hu-HU', defaultDateFormat: 'YYYY-MM-DD' },
  { name: 'Chinese Simplified', value: 'zh-CN', defaultDateFormat: 'MM/DD/YYYY' },
  { name: 'Chinese Traditional', value: 'zh-TW', defaultDateFormat: 'MM/DD/YYYY' },
  { name: 'French - France', value: 'fr-FR', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Hebrew', value: 'he', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Japanese', value: 'ja', defaultDateFormat: 'MM/DD/YYYY' },
  { name: 'Russian', value: 'ru', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Urdu', value: 'ur', defaultDateFormat: 'DD/MM/YYYY' },
  { name: 'Hindi, india', value: 'hi-IN', defaultDateFormat: 'd/M/yyyy' },
  { name: 'Korean', value: 'ko', defaultDateFormat: 'yyyy. M. d' },
  { name: 'Norwegian bokmål', value: 'nb', defaultDateFormat: 'dd.mm.yy' },
  { name: 'Norwegian nynorsk', value: 'nn', defaultDateFormat: 'dd.mm.yy' },
  { name: 'Polish', value: 'pl', defaultDateFormat: 'dd.MM.yyyy' },
  { name: 'Swedish', value: 'sv', defaultDateFormat: 'yyyy-mm-dd' },
];

export default SupportedLocales;

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';

const WithPreferredApps = WrappedComponent => class WithPreferredAppsComponent extends React.Component {
    static manifest = {
      ...WrappedComponent.manifest,
      recordId: {},
      userLocales: {
        type: 'okapi',
        path: 'userLocales?limit=1000',
        records: 'userLocales',
        POST: {
          path: 'userLocales',
        },
        PUT: {
          path: 'userLocales',
        },
      },
    };

    static propTypes = {
      parentResources: PropTypes.object,
      resources: PropTypes.shape({
        userLocales: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
        recordId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
      }),
      match: PropTypes.shape({
        params: PropTypes.shape({
          id: PropTypes.string,
        }).isRequired,
      }).isRequired,
      mutator: PropTypes.shape({
        recordId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
        userLocales: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
          POST: PropTypes.func.isRequired,
          PUT: PropTypes.func.isRequired,
        }),
      }),
      stripes: PropTypes.object.isRequired,
    };

    state = {
      currentUser: [],
    };

    // static getDerivedStateFromProps(props, state) {
    //   const {
    //     stripes: {
    //       user: { user },
    //     },
    //   } = props;
    //   const currentUser = get(props.resources.userLocales, ['records'], []).filter(
    //     userLoc => userLoc.userId === user.id
    //   );

    //   if (!isEqual(currentUser, state.currentUser)) {
    //     return {
    //       currentUser,
    //     };
    //   }

    //   return null;
    // }

    getUserPreferredApps = () => {
      return get(this.state.currentUser, [0, 'defaultApps'], []);
    };

    updateUserDefaultApps = (defaultApps) => {
      const {
        stripes: {
          user: { user },
        },
      } = this.props;

      const record = this.state.currentUser[0];

      if (record) {
        this.props.mutator.recordId.replace(record.id);
        record.defaultApps = defaultApps;

        this.props.mutator.userLocales.PUT(record);
      } else {
        this.props.mutator.userLocales.POST({
          userId: user.id,
          localesIds: [],
          defaultUserLocaleId: '',
          numbersShape: '',
          defaultApps,
        });
      }
    };

    render() {
      return (
        <>
          <WrappedComponent
            getUserPreferredApps={this.getUserPreferredApps}
            updateUserDefaultApps={this.updateUserDefaultApps}
            {...this.props}
          />
        </>
      );
    }
};

export default WithPreferredApps;

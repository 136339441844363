import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NavList,
  NavListItem,
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
} from '@folio/stripes-components';
import { omit } from 'lodash';

const propTypes = {
  item: PropTypes.object,
  setOpenViewSourceModal: PropTypes.func,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.object,
  setOpenImportRecordModal: PropTypes.func,
  cartList: PropTypes.arrayOf(PropTypes.object),
  setCartList: PropTypes.func,
  showCartList: PropTypes.bool,
  setShowCartList: PropTypes.func,
  disabled: PropTypes.bool,
};

const ItemActionsMenu = ({
  item,
  setOpenViewSourceModal,
  setOpenImportRecordModal,
  cartList,
  setCartList,
  showCartList,
  setShowCartList,
  disabled,
  setSelectedItem,
  selectedItem
}) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const handleCartList = (isExists) => {
    if (!showCartList && !isExists) {
      setCartList([...cartList, selectedItem]);
    } else if (showCartList && !isExists && cartList.length === 1) {
      setCartList([]);
      setShowCartList(false);
    } else {
      setCartList(cartList.filter((cartItem) => cartItem.id !== item.id));
    }
  };

  const getDropdownContent = () => {
    const isExists = cartList.find((cartItem) => cartItem.id === selectedItem.id);
    return (
      <div>
        <NavList>
          <NavListItem
            id="copycat-results-preview-item-actions-menu-add-to-cart"
            buttonStyle="slim"
            onClick={() => {
              handleCartList(isExists);
              toggleDropdown();
            }}
          >
            <Icon icon="cart">
              {!showCartList && !isExists ? (
                <FormattedMessage
                  id="ui-inventory.copycat.resultsPreview.itemActionsMenu.addToCart"
                  defaultMessage="Add to cart"
                />
              ) : (
                <FormattedMessage
                  id="ui-inventory.copycat.resultsPreview.itemActionsMenu.removeFromCart"
                  defaultMessage="Remove from cart"
                />
              )}
            </Icon>
          </NavListItem>
          <NavListItem
            id="copycat-results-preview-item-actions-menu-view-source"
            buttonStyle="slim"
            onClick={() => {
              setOpenViewSourceModal(true);
              toggleDropdown();
            }}
          >
            <Icon icon="source">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.viewSource"
                defaultMessage="View source"
              />
            </Icon>
          </NavListItem>
          <NavListItem
            id="copycat-results-preview-item-actions-menu-edit"
            buttonStyle="slim"
            onClick={() => {
              toggleDropdown();
            }}
            disabled
          >
            <Icon icon="edit">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.editInQuickMARC"
                defaultMessage="Edit in marc editor"
              />
            </Icon>
          </NavListItem>
          <NavListItem
            id="copycat-results-preview-item-actions-menu-import"
            buttonStyle="slim"
            onClick={() => {
              setOpenImportRecordModal(true);
              toggleDropdown();
            }}
          >
            <Icon icon="import">
              <FormattedMessage
                id="ui-inventory.copycat.resultsPreview.itemActionsMenu.import"
                defaultMessage="Import to inventory"
              />
            </Icon>
          </NavListItem>
        </NavList>
      </div>
    );
  };

  const trigger = ({ getTriggerProps, ariaProps }) => {
    const style = selectedItem.id === item.id ? { backgroundColor: 'white' } : null;
    return (
      <Button
        buttonStyle="default"
        marginBottom0
        {...ariaProps}
        {...getTriggerProps()}
        style={style}
        onMouseDown={() => setSelectedItem(omit(item, 'rowIndex'))}
      >
        <Icon icon="ellipsis" size="large" />
      </Button>
    );
  };

  const renderMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <Dropdown
      id="copycat-results-preview-item-actions-menu"
      renderTrigger={trigger}
      renderMenu={renderMenu}
      open={DropdownOpen}
      onToggle={toggleDropdown}
      placement="bottom-end"
      relativePosition
      focusHandlers={{ open: () => null }}
      disabled={disabled}
    />
  );
};

ItemActionsMenu.propTypes = propTypes;

export default ItemActionsMenu;

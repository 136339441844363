import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, FilterAccordionHeader, Select } from '@folio/stripes-components';

function SourceFilter({ source, setSource, disabled }) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);

  const onChangeSource = (event) => {
    setSource(event.target.value);
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const renderFilterBySource = () => {
    const dataOptions = [
      {
        value: 'merged',
        label: intl.formatMessage({
          id: 'ui-translations.filters.source.all',
        }),
        disabled: false,
      },
      {
        value: 'static',
        label: intl.formatMessage({
          id: 'ui-translations.filters.source.folio',
        }),
        disabled: false,
      },
      {
        value: 'local',
        label: intl.formatMessage({
          id: 'ui-translations.filters.source.translationApp',
        }),
        disabled: false,
      },
    ];

    return (
      <div>
        <Select
          id="source-filter-select"
          name="source-filter"
          value={source}
          dataOptions={dataOptions}
          onChange={onChangeSource}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <>
      <Accordion
        id="translation-source-filter-accordion"
        label={
          <FormattedMessage id="ui-translations.filters.sourceFilter.Accordion" />
        }
        onToggle={handleFilterToggle}
        open={filterToggle}
        // separator={false}
        header={FilterAccordionHeader}
      >
        {renderFilterBySource()}
      </Accordion>
    </>
  );
}

export default SourceFilter;

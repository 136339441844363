import React from 'react';
import { okapi } from "stripes-config";

// eslint-disable-next-line import/prefer-default-export
export const fetchTranslationsFromGoogle = (item, locale, mutators, appConfigContext, stripes) => {
  return fetch(
    `${okapi.url}/google-translate?text=${item.originTranslation.toLowerCase()}&from=en&to=${
      locale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((key) => {
      mutators.renderMachineTranslator(
        item.translationKey.replaceAll('.', '__'),
        key.data.translatedText
      );
    });
};

export const fetchTranslationsFromMyMemoAPI = (item, locale, mutators, appConfigContext, stripes) => {
  return fetch(
    `${okapi.url}/mymemo-translate?text=${item.originTranslation.toLowerCase()}&from=en&to=${
      locale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((key) => {
      setTimeout(() => {
        mutators.renderMachineTranslator(
          item.translationKey.replaceAll('.', '__'),
          key?.data?.translations?.responseData?.translatedText
        );
      }, 1000);
    });
};

export const fetchTranslationsFromLibre = (item, locale, mutators, appConfigContext, stripes) => {
  return fetch(
    `${okapi.url}/libre-translate?text=${item.originTranslation.toLowerCase()}&from=en&to=${
      locale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((key) => mutators.renderMachineTranslator(
      item.translationKey.replaceAll('.', '__'),
      key.data.translatedText
    ));
};

export const fetchTranslationsFromBing = (item, locale, mutators, appConfigContext, stripes) => {
  return fetch(
    `${okapi.url}/bing-translate?text=${item.originTranslation.toLowerCase()}&from=en&to=${
      locale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((key) => {
    //   setTimeout(() => {
      mutators.renderMachineTranslator(
        item.translationKey.replaceAll('.', '__'),
          key?.data?.translatedText
      );
    //   }, 2000);
    });
};

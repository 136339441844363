import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Col,
  Pane,
  PaneFooter,
  Paneset,
  Row,
  Select,
} from '@folio/stripes-components';
import { Field } from 'react-final-form';

function ThemeSwitcherForm({ pristine, submitting, handleSubmit, form }) {
  const intl = useIntl();

  const dataOptions = [
    {
      value: 'kwareTheme',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.NavbarThemeSettings.defaultTheme.kwareTheme',
        defaultMessage: 'Kware theme',
      }),
    },
    {
      value: 'standardTheme',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.NavbarThemeSettings.defaultTheme.standardTheme',
        defaultMessage: 'FOLIO standard theme',
      }),
    },
  ];

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-theme-switcher-form-submit"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-theme-switcher-form-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-theme-switcher-form"
          paneTitle={
            <FormattedMessage
              id="ui-tenant-settings.settings.NavbarThemeSettings.label"
              defaultMessage="Themes Switcher"
            />
          }
        >
          <form
            id="theme-switcher-form"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <Row>
              <Col xs={12} id="select-theme">
                <Field
                  label={
                    <FormattedMessage id="ui-tenant-settings.settings.NavbarThemeSettings.selectTheme" />
                  }
                  component={Select}
                  id="defaultTheme"
                  name="defaultTheme"
                  placeholder="---"
                  dataOptions={dataOptions}
                />
              </Col>
            </Row>
          </form>
        </Pane>
      </Paneset>
    </>
  );
}

ThemeSwitcherForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  validateOnBlur: true,
})(ThemeSwitcherForm);

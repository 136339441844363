import React from 'react';
import PropTypes from 'prop-types';
import CurrentLocaleContext from './CurrentLocaleContext';
import useQueryParams from '../../Hooks/useQueryParams';

function CurrentLocaleProvider(props) {
  const [currentLocale, setCurrentLocale] = useQueryParams('locale', '');

  return (
    <CurrentLocaleContext.Provider value={{ currentLocale, setCurrentLocale }}>
      {props.children}
    </CurrentLocaleContext.Provider>
  );
}

CurrentLocaleProvider.propTypes = {
  children: PropTypes.node,
};

export default CurrentLocaleProvider;

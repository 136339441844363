/* eslint-disable no-useless-concat */
import React from 'react';
import {
  Button,
  Card,
  Col,
  Icon,
  ModalFooter,
  Row,
  TextField,
  Tooltip,
} from '@folio/stripes-components';
import stripesFinalForm from '@folio/stripes/final-form';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import googleIcon from '../../../icons/google-translate.svg';
import useFetch from '../../Hooks/useFetch';

function EditUserNamesForm({
  currentLocale,
  pristine,
  submitting,
  handleSubmit,
  form,
  invalid,
  item,
  onClose,
}) {
  const intl = useIntl();

  const translatedLastName = item.originData.lastName
    ? useFetch(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
          'en' +
          '&tl=' +
          currentLocale +
          '&dt=t&q=' +
          encodeURI(item.originData.lastName)
    )
    : '';

  const translatedFirstName = item.originData.firstName
    ? useFetch(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
          'en' +
          '&tl=' +
          currentLocale +
          '&dt=t&q=' +
          encodeURI(item.originData.firstName)
    )
    : '';

  const translatedMiddleName = item.originData.middleName
    ? useFetch(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
          'en' +
          '&tl=' +
          currentLocale +
          '&dt=t&q=' +
          encodeURI(item.originData.middleName)
    )
    : '';

  const translatedPreferredFirstName = item.originData.preferredFirstName
    ? useFetch(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
          'en' +
          '&tl=' +
          currentLocale +
          '&dt=t&q=' +
          encodeURI(item.originData.preferredFirstName)
    )
    : '';

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          buttonStyle="primary"
          type="submit"
          disabled={submitting || pristine || invalid}
        >
          <Icon icon="save" size="large">
            <FormattedMessage id="stripes-core.button.save" />
          </Icon>
        </Button>
        <Button buttonStyle="slim" onClick={onClose}>
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.cancel" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const getGoogleTranslate = (fieldName) => {
    switch (fieldName) {
      case 'lastName':
        form.mutators.renderGoogleTranslate(
          fieldName,
          translatedLastName ? translatedLastName[0][0][0][0] : ''
        );
        break;
      case 'firstName':
        form.mutators.renderGoogleTranslate(
          fieldName,
          translatedFirstName ? translatedFirstName[0][0][0][0] : ''
        );
        break;
      case 'middleName':
        form.mutators.renderGoogleTranslate(
          fieldName,
          translatedMiddleName ? translatedMiddleName[0][0][0][0] : ''
        );
        break;
      case 'preferredFirstName':
        form.mutators.renderGoogleTranslate(
          fieldName,
          translatedPreferredFirstName
            ? translatedPreferredFirstName[0][0][0][0]
            : ''
        );
        break;
      default:
        break;
    }
  };

  const renderGoogleTranslateButton = (fieldName) => {
    return (
      <Tooltip
        id="google-translate-button-tooltip"
        text={
          <FormattedMessage
            id="ui-translations.googleTranslatebutton.tooltip"
            defaultMessage="Fetch translation from google translate"
          />
        }
      >
        {({ ref, ariaIds }) => (
          <div style={{ marginTop: '22px' }}>
            <Button
              aria-labelledby={ariaIds.text}
              ref={ref}
              buttonStyle="slim"
              onClick={() => {
                getGoogleTranslate(fieldName);
              }}
              onMouseDown={(e) => e.preventDefault()}
              marginBottom0
            >
              <img
                src={googleIcon}
                alt="googleIcon"
                width="25px"
                height="25px"
              />
            </Button>
          </div>
        )}
      </Tooltip>
    );
  };

  return (
    <>
      <form id="form-edit-user-names-translations" onSubmit={handleSubmit}>
        <Card
          id="edit-user-names-translations-form-card"
          headerStart={
            <span>
              <strong>
                <FormattedMessage
                  id="stripes-smart-components.TranslationsForm.card.Titel"
                  values={{ name: item.originTranslation }}
                />
              </strong>
            </span>
          }
          headerEnd={
            <Tooltip
              id="undo-tooltip"
              text={
                <FormattedMessage
                  id="ui-translations.buttons.tooltip.undo"
                  defaultMessage="Undo"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                  buttonStyle="slim"
                  marginBottom0
                  id={`clickable-undo-translate-user-name-${item.translationKey}`}
                  onClick={() => form.reset()
                  }
                  // onMouseDown={(e) => e.preventDefault()}
                  disabled={pristine || submitting}
                >
                  <Icon icon="undo" />
                </Button>
              )}
            </Tooltip>
          }
        >
          <>
            {item.originData.lastName && (
              <Row>
                <Col xs={11}>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.information.lastName" />
                    }
                    component={TextField}
                    name="lastName"
                    placeholder={intl.formatMessage({
                      id: 'ui-translations.translationEditForm.transText',
                    })}
                    marginBottom0
                  />
                </Col>
                <Col xs={1}>{renderGoogleTranslateButton('lastName')}</Col>
              </Row>
            )}
            <br />
            {item.originData.firstName && (
              <Row>
                <Col xs={11}>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.information.firstName" />
                    }
                    component={TextField}
                    name="firstName"
                    placeholder={intl.formatMessage({
                      id: 'ui-translations.translationEditForm.transText',
                    })}
                    marginBottom0
                  />
                </Col>
                <Col xs={1}>{renderGoogleTranslateButton('firstName')}</Col>
              </Row>
            )}
            <br />
            {item.originData.middleName && (
              <Row>
                <Col xs={11}>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.information.middleName" />
                    }
                    component={TextField}
                    name="middleName"
                    placeholder={intl.formatMessage({
                      id: 'ui-translations.translationEditForm.transText',
                    })}
                    marginBottom0
                  />
                </Col>
                <Col xs={1}>{renderGoogleTranslateButton('middleName')}</Col>
              </Row>
            )}
            <br />
            {item.originData.preferredFirstName && (
              <Row>
                <Col xs={11}>
                  <Field
                    label={
                      <FormattedMessage id="ui-users.information.preferredName" />
                    }
                    component={TextField}
                    name="preferredFirstName"
                    placeholder={intl.formatMessage({
                      id: 'ui-translations.translationEditForm.transText',
                    })}
                    marginBottom0
                  />
                </Col>
                <Col xs={1}>
                  {renderGoogleTranslateButton('preferredFirstName')}
                </Col>
              </Row>
            )}
          </>
        </Card>
        {renderFooter()}
      </form>
    </>
  );
}

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    renderGoogleTranslate: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(EditUserNamesForm);

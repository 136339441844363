/* eslint-disable no-useless-concat */
import {
  Button,
  Card,
  Col,
  Icon,
  ModalFooter,
  Row,
  TextArea,
  Tooltip,
} from '@folio/stripes-components';
import stripesFinalForm from '@folio/stripes/final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import TranslationSuggestions from './components/TranslationSuggestions';

function EditTranslationsForm({
  currentLocale,
  pristine,
  submitting,
  handleSubmit,
  form,
  invalid,
  item,
  onClose,
}) {
  const intl = useIntl();

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          buttonStyle="primary"
          type="submit"
          disabled={submitting || pristine || invalid}
        >
          <Icon icon="save" size="large">
            <FormattedMessage id="stripes-core.button.save" />
          </Icon>
        </Button>
        <Button buttonStyle="slim" onClick={onClose}>
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.cancel" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  return (
    <>
      <form id="form-one-by-one-translations" onSubmit={handleSubmit}>
        <Card
          id="one-by-one-edit-form-card"
          headerStart={
            <span>
              <strong>
                <FormattedMessage
                  id="ui-translations.translationsForm.card.Titel"
                  values={{ originText: item.originTranslation }}
                />
              </strong>
            </span>
          }
          headerEnd={
            <Tooltip
              id="undo-tooltip"
              text={
                <FormattedMessage
                  id="ui-translations.buttons.tooltip.undo"
                  defaultMessage="Undo"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                  buttonStyle="slim"
                  marginBottom0
                  id={`clickable-undo-translate-single-item-${item.translationKey}`}
                  onClick={() => form.mutators.resetFieldTranslations(
                    'localeTranslation',
                    item.localeTranslation
                  )
                  }
                  // onMouseDown={(e) => e.preventDefault()}
                  disabled={pristine || submitting}
                >
                  <Icon icon="undo" />
                </Button>
              )}
            </Tooltip>
          }
        >
          <div>
            <Row>
              <Col xs={12}>
                <Field
                  component={TextArea}
                  name="localeTranslation"
                  // label={intl.formatMessage({
                  //   id: 'ui-translations.translationEditForm.transText',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'ui-translations.translationEditForm.transText',
                  })}
                  marginBottom0
                />
              </Col>
            </Row>
            <br />
            <TranslationSuggestions
              originText={item.originTranslation}
              currentLocale={currentLocale}
              handleUseTranslation={form.mutators.renderUseTranslations}
            />
          </div>
        </Card>
        {renderFooter()}
      </form>
    </>
  );
}

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    renderUseTranslations: (args, state) => {
      const field = state.fields.localeTranslation;
      field.change(args[0]);
      state.formState.submitFailed = true;
    },
    resetFieldTranslations: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(EditTranslationsForm);

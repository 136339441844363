export const INVOICE_STATUS = {
  open: 'Open',
  approved: 'Approved',
  reviewed: 'Reviewed',
  paid: 'Paid',
  cancelled: 'Cancelled',
};

export const EXPORT_INVOICE_FIELDS = {
  vendorInvoiceNo: 'Vendor invoice number',
  vendorCode: 'Vendor code',
  status: 'Status',
  invoiceDate: 'Invoice date',
  paymentDue: 'Payment due',
  approvalDate: 'Approved date',
  approvedBy: 'ApprovedBy',
  paymentTerms: 'Terms',
  acqUnitIds: 'Acquisitions units',
  note: 'Note',
  billTo: 'Bill to',
  batchGroup: 'Batch group',
  paymentDate: 'Payment date',
  totalUnits: 'Total units',
  subTotal: 'Sub total',
  adjustmentsTotal: 'Total adjustments',
  totalAmount: 'Total amount',
  lockTotal: 'Lock total amount',
  invoiceFundDistributions: 'Invoice fund distributions',
  invoiceAdjustments: 'Invoice adjustments',
  accountingCode: 'Accounting code',
  vendorAddress: 'Vendor address',
  paymentMethod: 'Payment method',
  chkSubscriptionOverlap: 'Check subscription overlap',
  exportToAccounting: 'Export to accounting',
  enclosureNeeded: 'Enclosure needed',
  currency: 'Currency',
  exchangeRate: 'Exchange rate',
  invoiceTags: 'Invoice tags',
};

export const EXPORT_INVOICE_LINE_FIELDS = {
  invoiceLineNumber: 'Invoice line number',
  description: 'Description (Title)',
  poLineNumber: 'POLine number',
  subscriptionInfo: 'Subscription info',
  subscriptionStart: 'Subscription start',
  subscriptionEnd: 'Subscription end',
  comment: 'Comment',
  accountNumber: 'Account number',
  lineAccountingCode: 'Accounting code',
  quantity: 'Quantity',
  lineSubTotal: 'Sub total',
  lineAdjustments: 'Invoice line adjustments',
  total: 'Total',
  lineFundDistributions: 'Invoice line fund distributions',
  externalAccountNumber: 'External account number',
  referenceNumbers: 'Vendor reference number, reference type',
  lineTags: 'Invoice line tags',
  voucherNumber: 'Voucher number',
  voucherStatus: 'Voucher status',
  voucherDate: 'Voucher date',
  disbursementNumber: 'Disbursement number',
  disbursementDate: 'Disbursement date',
};

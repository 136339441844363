/* kware added new */

import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { Button, Pane, PaneFooter, Paneset } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import TenantAvailableLocales from './components/TenantAvailableLocales';
import TenantLocalizations from './components/TenantLocalizations';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired
};

function TenantLocalesForm({ pristine, submitting, handleSubmit, form }) {
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-tenantLocales"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <Paneset>
      <Pane
        fluidContentWidth
        footer={renderPaneFooter()}
        id="pane-tenant-locales-form"
        paneTitle={
          <FormattedMessage id="ui-tenant-settings.settings.tenantLocales.label" />
        }
      >
        <form id="form-tenant-locales" onSubmit={handleSubmit}>
          <TenantAvailableLocales
            tenantDefualtLocale={form.getState().values.tenantdefualtLocale}
            showLocaleIcon={form.getState().values.showLocaleIcon}
          />
          {/* <TenantDefaultLocale
              Locales={form.getState().values.tenantLocales}
            /> */}
          <TenantLocalizations Locales={form.getState().values.tenantLocales} />
        </form>
      </Pane>
    </Paneset>
  );
}

TenantLocalesForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true
  },
  validateOnBlur: true
})(TenantLocalesForm);

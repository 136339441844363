export const GROUPS_ROUTE = '/finance/groups';
export const FISCAL_YEAR_ROUTE = '/finance/fiscalyear';
export const FUNDS_ROUTE = '/finance/fund';
export const LEDGERS_ROUTE = '/finance/ledger';
export const LEDGER_CREATE_ROUTE = `${LEDGERS_ROUTE}/create`;
export const LEDGER_EDIT_ROUTE = `${LEDGERS_ROUTE}/edit/`;
export const LEDGER_ROLLOVER_SETTINGS_ROUTE = `${LEDGERS_ROUTE}/:id/rollover-settings/:rolloverId`;
export const BUDGET_ROUTE = '/finance/budget/';
export const BUDGET_VIEW_ROUTE = '/view/';
export const TRANSACTIONS_ROUTE = '/finance/transactions';

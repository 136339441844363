// TokenDatePicker
const RADIO_VALUE_DATE = 'date';
const RADIO_VALUE_TODAY = 'today';
const RADIO_VALUE_OFFSET = 'offset';

// TokenUserPicker
const RADIO_VALUE_ME = 'me';
const RADIO_VALUE_USER = 'user';

// Errors
const ERROR_INVALID_USER_FIELD = 'ERROR_INVALID_USER_FIELD';
const ERROR_INVALID_DATE_FIELD = 'ERROR_INVALID_DATE_FIELD';
const ERROR_INVALID_OFFSET = 'ERROR_INVALID_OFFSET';

// KeyCodes
const TAB = 'Tab';

export {
  RADIO_VALUE_DATE,
  RADIO_VALUE_TODAY,
  RADIO_VALUE_OFFSET,

  RADIO_VALUE_ME,
  RADIO_VALUE_USER,

  ERROR_INVALID_USER_FIELD,
  ERROR_INVALID_DATE_FIELD,
  ERROR_INVALID_OFFSET,
  TAB
};

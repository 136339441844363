import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Select } from '@folio/stripes/components';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocaleLabel } from '@folio/stripes-core';

const propTypes = {
  userLocales: PropTypes.arrayOf(PropTypes.object),
};

function UserPreferredLocale({ userLocales }) {
  const required = value => ((value) ? undefined :
  <strong><FormattedMessage id="stripes-smart-components.TranslationsModal.validation.required" defaultMessage="required" /></strong>);

  const intl = useIntl();
  const renderUserPreferredLocale = () => {
    if (!userLocales || userLocales.length === 0) return null;

    const dataOptions = [
      ...userLocales.map(ul => ({
        label: getLocaleLabel(ul.value, intl),
        value: ul.id,
      }
      ))
    ];

    return (
      <div>
        <Row>
          <Col xs={12}>
            <Field
              label={<FormattedMessage id="ui-myprofile.settings.userPreferredLocale.label" />}
              name="userPreferredLocale"
              id="userPreferredLocale"
              component={Select}
              placeholder="---"
              dataOptions={dataOptions}
              required
              validate={required}
            />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      {renderUserPreferredLocale()}
    </>
  );
}

UserPreferredLocale.propTypes = propTypes;

export default UserPreferredLocale;

/* kware added new */
/* eslint-disable import/prefer-default-export */
import moment from 'moment';

/* Kware Start Editing */
export function parseNumbersToArabic(string) {
  if (typeof string === 'string' && string !== '') {
    const ArabicNumbersShape = [
      '٠',
      '١',
      '٢',
      '٣',
      '٤',
      '٥',
      '٦',
      '٧',
      '٨',
      '٩',
    ];
    return string.replace(/[0-9]/g, (str) => {
      return ArabicNumbersShape[+str];
    });
  }
  return string;
}
/* Kware Editing  End */

/* Kware Start Editing */
export function parseNumbersToEnglish(string) {
  if (typeof string === 'string' && string !== '') {
    return string
      .replace(/[\u0660-\u0669]/g, (c) => {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, (c) => {
        return c.charCodeAt(0) - 0x06f0;
      });
  }
  return string;
}
/* Kware End Editing */

/* Kware Start Editing */
export function handleDateTimeFormat(dateTimeValue, stripes, intl, handleType) {
  const numbersShape = stripes.userNumbersShape || stripes.locale;
  switch (handleType) {
    case 'date':
      return stripes.locale === 'ar'
        ? moment(dateTimeValue).locale(numbersShape).format(stripes.dateformat)
        : moment(dateTimeValue)
          .locale(stripes.locale)
          .format(stripes.dateformat);
    case 'time':
      return stripes.locale === 'ar' && numbersShape === 'ar'
        ? parseNumbersToArabic(intl.formatTime(dateTimeValue))
        : intl.formatTime(dateTimeValue);
    case 'datetime':
      return stripes.locale === 'ar'
        ? numbersShape === 'ar'
          ? `${moment(dateTimeValue)
            .locale(numbersShape)
            .format(stripes.dateformat)} ، ${parseNumbersToArabic(
            intl.formatTime(dateTimeValue)
          )}`
          : `${moment(dateTimeValue)
            .locale(numbersShape)
            .format(stripes.dateformat)} , ${intl.formatTime(dateTimeValue)}`
        : `${moment(dateTimeValue)
          .locale(stripes.locale)
          .format(stripes.dateformat)} , ${intl.formatTime(dateTimeValue)}`;
    default:
      return moment(dateTimeValue)
        .locale(numbersShape)
        .format(stripes.dateformat);
  }
}
/* Kware End Editing */

import React from 'react';
import PropTypes from 'prop-types';
/* Kware Start Editing */
import { FormattedMessage, useIntl } from 'react-intl';
import { HandleUserName } from '@folio/stripes/core';
/* Kware End Editing */
import { Row, Col } from '@folio/stripes/components';
import { Link } from 'react-router-dom';
import { getFullName } from '../../util';
import css from './index.css';

const UserInfo = (props) => {
  const user = props.user || {};
  /* Kware Start Editing */
  const intl = useIntl();
  /* Kware End Editing */

  return (
    <div className={css.root}>
      <Row>
        <Col mdOffset={2} />
        <Col>
          <FormattedMessage id="ui-users.charge.patron" />
          :
        </Col>
        <Col className={css.userInfoLink} md>
          <Link to={`/users/view/${user.id}`}>
            {/* {`${getFullName(user)}`} */}
            {/* Kware Start Editing */}
            {user.personal ? <HandleUserName user={user} /> : '-'}
            {/* Kware End Editing */}
          </Link>
        </Col>
        <Col className={css.userInfoLink} md>
          <FormattedMessage id="ui-users.charge.barcode" />
          :
        </Col>
        <Col md>
          <Link to={`/users/view/${user.id}`}>
            {user.barcode}
          </Link>
        </Col>
        <Col mdOffset={2} />
      </Row>
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object,
};

export default UserInfo;

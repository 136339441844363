/* eslint-disable react/sort-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  MultiColumnList,
  ModalFooter,
  Button,
  Icon,
  Layout,
  Label,
  KeyValue
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

const propTypes = {
  columnMapping: PropTypes.object,
  contentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  visibleFields: PropTypes.arrayOf(PropTypes.string),
  dataFormat: PropTypes.string.isRequired
};

const defaultProps = {
  visibleFields: []
};

const ImportConfirmationModal = props => {
  const renderFilePreview = () => {
    const { visibleFields, contentData, columnMapping, dataFormat } = props;
    const columnWidths = {};
    const columnWidth = 90 / visibleFields.length;
    visibleFields.forEach(column => {
      columnWidths[column] = `${columnWidth}%`;
    });

    const dataWithIndex = contentData.map((item, index) => {
      item.id = index + 1;
      return item;
    });

    switch (dataFormat) {
      case 'table':
        return (
          <MultiColumnList
            interactive={false}
            contentData={dataWithIndex}
            visibleColumns={['id', ...visibleFields]}
            columnWidths={{ id: '10%', ...columnWidths }}
            columnMapping={columnMapping}
          />
        );
      case 'pre':
        return (
          contentData.map(data => {
            // return (
            //   Object.keys(data).map(key => {
            //     return (
            //       <KeyValue label={key} value={data[key]} />
            //     // <div>
            //     //   <Label>{key}</Label>
            //     //   <div>{data[key]}</div>
            //     // </div>
            //     );
            //   }));
            return (
              <>
                <pre>{JSON.stringify(data, null, 2)}</pre>
                <hr />
              </>
            );
          })
        );

      default:
        return <div />;
    }
  };

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button buttonStyle="primary" onClick={props.onSave}>
          <Icon icon="save" size="large">
            <FormattedMessage id="stripes-core.button.save" />
          </Icon>
        </Button>
        <Button buttonStyle="slim" onClick={props.onClose}>
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.cancel" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const renderConfirmationModal = () => {
    const { contentData, open, onClose, fileName } = props;

    return (
      <Modal
        footer={renderFooter()}
        open={open}
        onClose={onClose}
        label={
          <Icon icon="preview" size="large">
            <FormattedMessage
              id="stripes-smart-components.ImportConfirmationModal.label"
              defaultMessage="Preview file - {name}"
              values={{ name: fileName }}
            />
          </Icon>
        }
      >
        <Layout className="textCentered">
          <FormattedMessage
            id="stripes-smart-components.ImportedFile.totalRecords"
            defaultMessage="Total records: {count}"
            values={{ count: contentData.length }}
          />
        </Layout>
        {renderFilePreview()}
      </Modal>
    );
  };

  return <>{renderConfirmationModal()}</>;
};

ImportConfirmationModal.propTypes = propTypes;
ImportConfirmationModal.defaultProps = defaultProps;

export default ImportConfirmationModal;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, MultiColumnList } from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import EmptyData from './emptyData/EmptyData';
import ResultsTabs from './ResultsTabs';
import ViewSourceModal from './ViewSourceModal/ViewSourceModal';
import ImportSingleRecordModal from './ImportSingleRecord/ImportSingleRecordModal';
import { columnMapping, sortedVisibleColumns } from '../../../utils';
import ItemActionsMenu from './ItemActionsMenu';

function ResultsPreview({
  selectedSources,
  sourcesList,
  importedList,
  setImportedList,
  visibleColumns,
  cartList,
  setCartList,
  showCartList,
  setShowCartList,
  contentData,
  currentTab,
  selection,
  setSelection,
  setcurrentTab,
  openViewSourceModal,
  setOpenViewSourceModal,
  selectedItems,
  currentPage,
  pageSize,
}) {
  const [selectedItem, setSelectedItem] = useState({});
  const [sortedColumn, setSortedColumn] = useState('serverName');
  const [allColumns, setAllColumns] = useState([]);
  const [openImportRecordModal, setOpenImportRecordModal] = useState(false);

  useEffect(() => {
    if (selectedSources.length > 1) {
      if (currentTab === 'All') {
        setAllColumns([
          'rowIndex',
          'selected',
          'serverName',
          'title',
          ...visibleColumns.sort(
            (a, b) => sortedVisibleColumns.indexOf(a) - sortedVisibleColumns.indexOf(b)
          ),
          'Actions',
        ]);
      } else {
        setAllColumns([
          'rowIndex',
          'selected',
          'title',
          ...visibleColumns.sort(
            (a, b) => sortedVisibleColumns.indexOf(a) - sortedVisibleColumns.indexOf(b)
          ),
          'Actions',
        ]);
      }
    } else {
      setAllColumns([
        'rowIndex',
        'selected',
        'title',
        ...visibleColumns.sort(
          (a, b) => sortedVisibleColumns.indexOf(a) - sortedVisibleColumns.indexOf(b)
        ),
        'Actions',
      ]);
    }
  }, [currentTab, selectedSources, visibleColumns]);

  useEffect(() => {
    if (contentData.length !== Object.values(selection).length) {
      const selectionData = {};
      contentData.forEach((item) => {
        selectionData[item.id] = false;
      });
      setSelection(selectionData);
    }
  }, [contentData, cartList]);

  useEffect(() => {
    if (Object.values(selection).includes(true)) {
      setSelectedItem({});
    }
  }, [selection]);

  const onToggleBulkSelection = () => {
    const select = Object.values(selection).includes(false);
    const selectionData = {};

    contentData.forEach((item) => {
      selectionData[item.id] = select;
    });
    setSelection(selectionData);
  };

  const onToggleSelection = (item) => {
    setSelection({
      ...selection,
      [item.id]: !selection[item.id],
    });
  };

  const actionsFormatter = (item) => {
    return (
      <ItemActionsMenu
        item={item}
        setOpenViewSourceModal={setOpenViewSourceModal}
        setOpenImportRecordModal={setOpenImportRecordModal}
        setCartList={setCartList}
        cartList={cartList}
        showCartList={showCartList}
        setShowCartList={setShowCartList}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
        disabled={Object.values(selection).includes(true)}
      />
    );
  };

  return (
    <>
      {selectedSources.length > 1 && !showCartList && (
        <ResultsTabs
          currentTab={currentTab}
          setcurrentTab={setcurrentTab}
          tabsList={selectedSources}
        />
      )}
      <MultiColumnList
        key={currentTab}
        id="copycat-results-preview-multi-column-list"
        interactive={false}
        sortedColumn={sortedColumn}
        sortDirection="ascending"
        selectedRow={selectedItem}
        onHeaderClick={(e, { name }) => setSortedColumn(name)}
        visibleColumns={allColumns}
        contentData={contentData}
        columnMapping={{
          ...columnMapping,
          selected: (
            <Checkbox
              name="selected-all"
              checked={Object.values(selection).includes(false) !== true}
              onChange={onToggleBulkSelection}
              onMouseDown={(e) => e.preventDefault()}
            />
          ),
        }}
        columnWidths={{
          rowIndex: '5%',
          selected: '3%',
          serverName: '10%',
          title: '24%',
          author: '17%',
          ISBN: '10%',
          edition: '8%',
          publisher: '8%',
          publicationYear: '8%',
          Actions: '7%',
        }}
        formatter={{
          rowIndex: (item) => currentPage * pageSize - pageSize + (item.rowIndex + 1),
          Actions: (item) => actionsFormatter(item),
          serverName: (item) => (
            <FormattedMessage
              id={`ui-inventory.targetProfiles.name.${item.serverName}`}
              defaultMessage={item.serverName}
            />
          ),
          selected: (item) => (
            <Checkbox
              name="selected-all"
              checked={!!selection[item.id]}
              onChange={() => {
                onToggleSelection(item);
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
          ),
        }}
        autosize
        virtualize={false}
        isEmptyMessage={<EmptyData />}
      />
      <ViewSourceModal
        open={openViewSourceModal}
        onClose={() => setOpenViewSourceModal(false)}
        itemToView={selectedItem}
        selectedItems={selectedItems}
      />
      <ImportSingleRecordModal
        open={openImportRecordModal}
        onClose={() => setOpenImportRecordModal(false)}
        itemToImport={selectedItem}
        sourcesList={sourcesList}
        importedList={importedList}
        setImportedList={setImportedList}
      />
    </>
  );
}

ResultsPreview.propTypes = {
  selectedSources: PropTypes.arrayOf(PropTypes.object),
  sourcesList: PropTypes.arrayOf(PropTypes.object),
  importedList: PropTypes.arrayOf(PropTypes.object),
  setImportedList: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  cartList: PropTypes.arrayOf(PropTypes.object),
  setCartList: PropTypes.func,
  showCartList: PropTypes.bool,
  setShowCartList: PropTypes.func,
  contentData: PropTypes.arrayOf(PropTypes.object),
  currentTab: PropTypes.string,
  setSelection: PropTypes.func,
  selection: PropTypes.object,
  setcurrentTab: PropTypes.func,
  openViewSourceModal: PropTypes.bool,
  setOpenViewSourceModal: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};

export default ResultsPreview;

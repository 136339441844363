export const ORGANIZATION_STATUS_ACTIVE = 'Active';

export const PRODUCT_ID_TYPE = {
  isbn: 'ISBN',
};
export const QUALIFIER_SEPARATOR = ' ';

export const ORDER_TYPE = {
  oneTime: 'One-Time',
  ongoing: 'Ongoing',
};

export const ORDERS_DOMAIN = 'orders';

export const NOTE_TYPES = {
  poLine: 'poLine',
};

export const CONFIG_INSTANCE_STATUS = 'inventory-instanceStatusCode';
export const CONFIG_INSTANCE_TYPE = 'inventory-instanceTypeCode';

export const CLOSING_REASONS_SOURCE = {
  system: 'System',
  user: 'User',
};

export const RESULT_COUNT_INCREMENT = 30;

export const VALIDATION_ERRORS = {
  duplicateLines: 'duplicateLines',
  differentAccount: 'differentAccount',
};

export const CANCEL_ORDER_REASON = 'Cancelled';

export const REEXPORT_SOURCES = {
  order: 'order',
  orderLine: 'orderLine',
};

export const UNOPEN_ORDER_ABANDONED_HOLDINGS_TYPES = {
  synchronized: 'synchronized',
  independent: 'independent',
  defaultType: 'withoutPieces',
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Col,
  Icon,
  Pane,
  PaneFooter,
  Paneset,
  Row,
  Select,
  TextArea,
  TextField,
} from '@folio/stripes/components';
import stripesFinalForm from '@folio/stripes/final-form';
import { Field } from 'react-final-form';

import { FcBarChart } from 'react-icons/fc';
import { AppIcon } from '@folio/stripes-core';
import WidgetsList from './components/WidgetsList';
import WidgetReportData from './components/WidgetReportData';
import { required } from '../../../helpers/Validations';

function WidgetForm({
  pristine,
  submitting,
  handleSubmit,
  form,
  handleClose,
  editMode,
  initialValues,
  currentDashName,
  currentReport,
  setCurrentReport,
}) {
  const intl = useIntl();

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        id="widget-form-pane-footer"
        renderEnd={
          <Button
            buttonStyle="primary"
            disabled={pristine || submitting}
            id="widget-form-pane-save-btn"
            marginBottom0
            type="submit"
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            <FormattedMessage
              id="ui-reports.dashboards.widgetForm.save.btn"
              defaultMessage="Save & Close"
            />
          </Button>
        }
        renderStart={
          <>
            <Button
              buttonStyle="default"
              id="widget-form-pane-cancel-btn"
              marginBottom0
              onClick={() => handleClose()}
            >
              <FormattedMessage
                id="ui-reports.dashboards.widgetForm.cancel.btn"
                defaultMessage="Cancel"
              />
            </Button>
          </>
        }
      />
    );
  };
  return (
    <Paneset>
      <Pane
        appIcon={<AppIcon app="reports" />}
        dismissible
        onClose={() => handleClose()}
        footer={renderPaneFooter()}
        id="widget-form-pane"
        centerContent
        paneTitle={
          editMode ? (
            <FormattedMessage
              id="ui-reports.dashboards.widgetForm.paneTitle.editMode"
              values={{ widgetName: initialValues.widgetName }}
              defaultMessage="Edit {widgetName}"
            />
          ) : (
            <FormattedMessage
              id="ui-reports.dashboards.widgets.addNewWidget.btn"
              defaultMessage="Create new widget"
            />
          )
        }
      >
        <form id="widget-form" onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <TextField
                id="form-widget-name-textField"
                // name="widgetName"
                label={
                  <FormattedMessage
                    id="ui-reports.dashboards.widgetForm.dashNameField.label"
                    defaultMessage="Dashboard name"
                  />
                }
                value={intl.formatMessage({
                  id: `ui-reports.dashboards.name.${currentDashName}`,
                  defaultMessage: currentDashName,
                })}
                disabled
              />
            </Col>
            <Col xs={6}>
              <Field
                id="form-widget-name-textField"
                name="widgetName"
                component={TextField}
                label={
                  <FormattedMessage
                    id="ui-reports.dashboards.widgetForm.widgetNameField.label"
                    defaultMessage="Widget name"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-reports.dashboards.widgetForm.widgetNameField.placeholder',
                  defaultMessage: 'Enter widget name',
                })}
                required
                validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Field
                id="form-widget-desc-textField"
                name="widgetDesc"
                component={TextArea}
                label={
                  <FormattedMessage
                    id="ui-reports.dashboards.widgetForm.widgetDescField.label"
                    defaultMessage="Widget description"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-reports.dashboards.widgetForm.widgetDescField.placeholder',
                  defaultMessage: 'Enter widget desc',
                })}
                required
                validate={required}
              />
            </Col>
            <Col xs={6}>
              <Field
                id="form-widget-type-textField"
                name="widgetType"
                component={Select}
                label={
                  <FormattedMessage
                    id="ui-reports.dashboards.widgetForm.widgetTypeField.label"
                    defaultMessage="Widget type"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-reports.dashboards.widgetForm.widgetTypeField.placeholder',
                  defaultMessage: 'Select widget type',
                })}
                // required
                // validate={required}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <WidgetReportData
                currentReport={currentReport}
                setCurrentReport={setCurrentReport}
              />
            </Col>
          </Row>
        </form>
      </Pane>
    </Paneset>
  );
}

WidgetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  currentDashName: PropTypes.string.isRequired,
  currentReport: PropTypes.object.isRequired,
  setCurrentReport: PropTypes.func.isRequired,
};

export default stripesFinalForm({
  mutators: {},
  validateOnBlur: true,
  navigationCheck: true,
  subscription: {
    values: true,
  },
})(WidgetForm);

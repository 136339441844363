import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  Button,
  Icon,
  Row,
  Col,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MarcRecord } from '@natlibfi/marc-record';
import SourceView from './SourceView';

function ViewSourceModal({ onClose, open, itemToView, selectedItems }) {
  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="copycat-results-preview-print-record-source-btn"
          buttonStyle="primary"
        >
          <Icon icon="print">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.print"
              defaultMessage="Print"
            />
          </Icon>
        </Button>
        <Button
          id="copycat-results-preview-view-source-modal-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const renderViewSelecteditems = () => {
    return (
      <Row>
        <Col xs={6}>
          <SourceView itemToView={selectedItems[0]} />
        </Col>
        <Col xs={6}>
          <SourceView itemToView={selectedItems[1]} />
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      id="copycat-results-preview-view-source-modal"
      footer={renderFooter()}
      open={open}
      onClose={onClose}
      dismissible
      label={
        <Icon icon="info" size="large">
          {selectedItems.length ? (
            <FormattedMessage
              id="ui-inventory.copycat.resultsPreview.viewSourceModal.compare.label"
              defaultMessage="Compare source of two records"
            />
          ) : (
            <FormattedMessage
              id="ui-inventory.copycat.resultsPreview.viewSourceModal.view.label"
              defaultMessage="View record source"
            />
          )}
        </Icon>
      }
      size="large"
    >
      {selectedItems.length ? (
        renderViewSelecteditems()
      ) : (
        <SourceView itemToView={itemToView} />
      )}
    </Modal>
  );
}

ViewSourceModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemToView: PropTypes.object,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
};

export default ViewSourceModal;

/* eslint-disable react/sort-prop-types */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, sortBy } from 'lodash';

const WithTranslations = (WrappedComponent) => class WithTranslationsComponent extends Component {
    static manifest = Object.freeze({
      ...WrappedComponent.manifest,
      translations: {
        type: 'okapi',
        path: 'translations?limit=1000',
        records: 'translations',
        accumulate: true,
        fetch: false,
        // resourceShouldRefresh: true,
        POST: {
          path: 'translations',
        },
        PUT: {
          path: 'translations/%{translationId}',
        },
      },
      languages: {
        type: 'okapi',
        path: 'languages?limit=1000',
        records: 'languages',
        accumulate: true,
        fetch: false,
        // resourceShouldRefresh: true,
        POST: {
          path: 'languages',
        },
        PUT: {
          path: 'languages/%{languageId}',
        },
      },
      languageId: '',
      translationId: '',
      // languageTranslators: {
      //   type: 'okapi',
      //   path: 'languageTranslators?limit=1000',
      //   records: 'languageTranslators',
      //   accumulate: true,
      //   fetch: false,
      //   // resourceShouldRefresh: true,
      // },
    });

    static propTypes = {
      stripes: PropTypes.shape({
        connect: PropTypes.func.isRequired,
        okapi: PropTypes.object,
        locale: PropTypes.string,
      }).isRequired,
      resources: PropTypes.shape({
        // languageTranslators: PropTypes.shape({
        //   records: PropTypes.arrayOf(PropTypes.object),
        // }),
        translations: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
        translationId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
        languageId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
      }),
      mutator: PropTypes.shape({
        // languageTranslators: PropTypes.shape({
        //   GET: PropTypes.func.isRequired,
        //   reset: PropTypes.func.isRequired,
        // }),
        translations: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
          POST: PropTypes.func.isRequired,
          PUT: PropTypes.func.isRequired,
        }),
        languages: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
          POST: PropTypes.func.isRequired,
          PUT: PropTypes.func.isRequired,
        }),
        translationId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
        languageId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
      }),
    };

    state = {
      // languageTranslators: [],
      assignedLocales: [],
      translations: [],
      languages: [],
    };

    static getDerivedStateFromProps(nextProps, state) {
      // const languageTranslators = sortBy(
      //   (nextProps.resources.languageTranslators || {}).records || [],
      //   ['localeValue']
      // );

      // const assignedLocales = [];
      // languageTranslators.forEach((loc) => {
      //   assignedLocales.push(loc.localeValue);
      // });

      const translations = sortBy(
        (nextProps.resources.translations || {}).records || [],
        ['localeValue']
      );

      const languages = sortBy(
        (nextProps.resources.languages || {}).records || [],
        ['localeValue']
      );

      if (!isEqual(translations, state.translations)) {
        return {
          translations,
        };
      }

      if (!isEqual(languages, state.languages)) {
        return {
          languages,
        };
      }

      // if (!isEqual(languageTranslators, state.languageTranslators)) {
      //   return {
      //     languageTranslators,
      //   };
      // }

      // if (!isEqual(assignedLocales, state.assignedLocales)) {
      //   return {
      //     assignedLocales,
      //   };
      // }

      return null;
    }

    componentDidMount() {
      this.props.mutator.translations.GET();
      this.props.mutator.translations.reset();
      this.props.mutator.languages.GET();
      this.props.mutator.languages.reset();
      // this.props.mutator.languageTranslators.reset();
      // this.props.mutator.languageTranslators.GET();
    }

    // componentWillUnmount() {
    //   this.props.mutator.translations.reset();
    //   this.props.mutator.languageTranslators.reset();
    // }

    getLanguagesList = () => {
      // return sortBy(
      //   (this.props.resources.languages || {}).records || [],
      //   ['localeValue']
      // );
      return this.state.languages;
    }

    getLocaleTranslators = () => {
      return this.state.languageTranslators;
    };

    getAssignedLocales = () => {
      // const assignedLocales = [];
      // this.getLocaleTranslators().forEach((loc) => {
      //   assignedLocales.push(loc.localeValue);
      // });
      // return assignedLocales;
      return this.state.assignedLocales;
    };

    ifAssignedLocale = (localeValue) => {
      return this.getLocaleTranslators().some(
        (loc) => loc.localeValue === localeValue
      );
    };

    getLocaleTranslatorsByValue = (localeValue) => {
      const locTranslators = this.getLocaleTranslators().filter(
        (loc) => loc.localeValue === localeValue
      );
      const translators = [];
      locTranslators.forEach((trans) => {
        trans.translators.forEach((element) => {
          translators.push(element.value);
        });
      });
      return translators;
    };

    getSavedLocales = () => {
      const Translations = sortBy(
        (this.props.resources.translations || {}).records || [],
        ['localeValue']
      );
      return Translations.map((loc) => loc.localeValue);
    };


    getTranslations = (localeValue, categoryName) => {
      const translations = this.state.translations.find(
        (curTrans) => curTrans.localeValue === localeValue &&
          curTrans.categoryName === categoryName
      );
      return get(translations, ['messages'], {});
    };

    getTranslationsMetadata = (localeValue, categoryName) => {
      const metadata = sortBy(
        (this.props.resources.translations || {}).records || [],
        ['localeValue']
      ).find(
        (curTrans) => curTrans.localeValue === localeValue &&
          curTrans.categoryName === categoryName
      );
      return get(metadata, ['metadata'], {});
    };

    getCategoryLocales = (categoryName) => {
      return sortBy(
        (this.props.resources.translations || {}).records || [],
        ['localeValue']
      ).filter(
        (curTrans) => curTrans.categoryName === categoryName
      );
    };

    addNewLanguages = (newLangs) => {
      newLangs.forEach((lang) => {
        this.props.mutator.languages.POST({
          name: lang.name,
          localeValue: lang.value,
          status: lang.status,
        });
      });
    }

    updateLanguagesList = (newLangs) => {
      const languages = sortBy(
        (this.props.resources.languages || {}).records || [],
        ['localeValue']
      );
      newLangs.forEach((newLang) => {
        const record = languages.filter((lang) => lang.localeValue === newLang.localeValue);
        if (record && record.length !== 0) {
          this.props.mutator.languageId.replace(record[0].id);
          record[0].status = newLang.status;
          this.props.mutator.languages.PUT(record[0]);
        } else {
          this.props.mutator.languages.POST({
            name: newLang.name,
            localeValue: newLang.localeValue,
            status: newLang.status,
          });
        }
      });
    };

    updateTranslations = (localeValue, categoryName, newTranslations) => {
      const translations = sortBy(
        (this.props.resources.translations || {}).records || [],
        ['localeValue']
      );
      const record = translations.filter(
        (curTrans) => curTrans.localeValue === localeValue &&
          curTrans.categoryName === categoryName
      );
      if (record && record.length !== 0) {
        console.log('update');
        this.props.mutator.translationId.replace(record[0].id);
        record[0].messages = Object.assign(record[0].messages, newTranslations);
        this.props.mutator.translations.PUT(record[0]);
      } else {
        console.log('create');
        this.props.mutator.translations.POST({
          categoryName,
          localeValue,
          messages: newTranslations,
        });
      }
    };
    // updateTranslations = (localeValue, categoryName, newTranslations) => {
    //   const translations = sortBy(
    //     (this.props.resources.translations || {}).records || [],
    //     ['localeValue']
    //   );
    //   const record = translations.filter(
    //     (curTrans) => curTrans.localeValue === localeValue &&
    //       curTrans.categoryName === categoryName
    //   );
    //   if (record && record.length !== 0) {
    //     this.props.mutator.translationId.replace(record[0].id);
    //     record[0].messages = Object.assign(record[0].messages, newTranslations);
    //     this.props.mutator.translations.PUT(record[0]);
    //   }
    // };

    render() {
      return (
        <WrappedComponent
          getLocaleTranslators={this.getLocaleTranslators}
          getAssignedLocales={this.getAssignedLocales}
          getLocaleTranslatorsByValue={this.getLocaleTranslatorsByValue}
          ifAssignedLocale={this.ifAssignedLocale}
          getSavedLocales={this.getSavedLocales}
          getTranslationsByLocaleValue={this.getTranslationsByLocaleValue}
          updateTranslationsByLocaleValue={this.updateTranslationsByLocaleValue}
          getTranslationsMetadata={this.getTranslationsMetadata}
          getLanguagesList={this.getLanguagesList}
          updateLanguagesList={this.updateLanguagesList}
          getTranslations={this.getTranslations}
          getCategoryLocales={this.getCategoryLocales}
          updateTranslations={this.updateTranslations}
          {...this.props}
        />
      );
    }
};

export default WithTranslations;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalFooter,
  Button,
  Icon,
  MultiColumnList,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import ImportedListActionsMenu from './ImportedListActionsMenu';
import { columnMapping } from '../../../../utils';

function ImportedListModal({ onClose, open, importedList }) {
  const actionsFormatter = (item) => {
    return <ImportedListActionsMenu item={item} />;
  };

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button
          id="copycat-results-preview-print-imported-list-btn"
          buttonStyle="primary"
        >
          <Icon icon="print">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.print"
              defaultMessage="Print"
            />
          </Icon>
        </Button>
        <Button
          id="copycat-results-preview-imported-list-modal-close-btn"
          buttonStyle="slim"
          onClick={onClose}
        >
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.close" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  return (
    <Modal
      id="copycat-results-preview-imported-list-modal"
      footer={renderFooter()}
      open={open}
      onClose={onClose}
      label={
        <Icon icon="download" size="large" color="red">
          <FormattedMessage
            id="ui-inventory.copycat.resultsPreview.importedListModal.label"
            defaultMessage="Imported Records List"
          />
        </Icon>
      }
      size="large"
    >
      <MultiColumnList
        id="copycat-results-preview-imported-list-multi-column-list"
        interactive={false}
        visibleColumns={[
          'rowIndex',
          'serverName',
          'title',
          'author',
          'ISBN',
          'edition',
          'publisher',
          'publicationYear',
          'Actions',
        ]}
        contentData={importedList}
        columnMapping={columnMapping}
        columnWidths={{
          rowIndex: '5%',
          serverName: '10%',
          title: '25%',
          author: '17%',
          ISBN: '10%',
          edition: '8%',
          publisher: '8%',
          publicationYear: '10%',
          Actions: '7%',
        }}
        formatter={{
          rowIndex: (item) => item.rowIndex + 1,
          serverName: (item) => (
            <FormattedMessage
              id={`ui-inventory.targetProfiles.name.${item.serverName}`}
              defaultMessage={item.serverName}
            />
          ),
          Actions: (item) => actionsFormatter(item),
        }}
      />
    </Modal>
  );
}

ImportedListModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  importedList: PropTypes.arrayOf(PropTypes.object),
};

export default ImportedListModal;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Select } from '@folio/stripes/components';
import { Field } from 'react-final-form';

const propTypes = {
  userLocales: PropTypes.arrayOf(PropTypes.object),
};

function UserNumbersShape({ userLocales }) {
  const required = value => ((value) ? undefined :
  <strong><FormattedMessage id="stripes-smart-components.TranslationsModal.validation.required" defaultMessage="required" /></strong>);

  const intl = useIntl();
  const renderUserNumbersShape = () => {
    const dataOptions = [
      {
        label: intl.formatMessage({ id: 'ui-myprofile.settings.NumbersShape.default' }),
        value: 'default',
      },
      {
        label: intl.formatMessage({ id: 'ui-myprofile.settings.NumbersShape.hindu' }),
        value: 'ar',
      },
      {
        label: intl.formatMessage({ id: 'ui-myprofile.settings.NumbersShape.arabic' }),
        value: 'en',
      },
    ];

    return (
      <div>
        {userLocales.some(ul => ul.value === 'ar') &&
        <Row>
          <Col xs={12}>
            <Field
              label={<FormattedMessage id="ui-myprofile.settings.userNumbersShape.label" />}
              name="userNumbersShape"
              id="userNumbersShape"
              component={Select}
              placeholder="---"
              dataOptions={dataOptions}
              required
              validate={required}
            />
          </Col>
        </Row>}
      </div>
    );
  };

  return (
    <>
      {renderUserNumbersShape()}
    </>
  );
}

UserNumbersShape.propTypes = propTypes;

export default UserNumbersShape;

/* eslint-disable react-hooks/exhaustive-deps */
import { useOkapiKy } from '@folio/stripes-core';
import React, { useEffect, useState } from 'react';

function useCheckForDuplicates(queryOptions) {
  const okapiKy = useOkapiKy();

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const doFetch = async () => {
    setIsLoading(true);
    try {
      const res = await okapiKy
        .post('copycat/checkForDuplicates', {
          timeout: 30000,
          method: 'POST',
          json: {
            matchingValues: queryOptions,
          },
        })
        .json();
      if (!signal.aborted) {
        setResponse(res);
      }
    } catch (err) {
      if (!signal.aborted) {
        const res = err.response;
        const text = await res.text();

        let detail = text;
        if (res.headers.get('content-type') === 'application/json') {
          const obj = JSON.parse(text);
          detail = obj.errors[0].message;
        }
        setError(detail);
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(queryOptions).length &&
      Object.keys(queryOptions).includes('title')
    ) {
      doFetch();
    } else {
      setResponse(null);
      setError(null);
      setIsLoading(false);
    }
    return () => {
      abortController.abort();
    };
  }, [queryOptions]);

  return [response, error, isLoading];
}

export default useCheckForDuplicates;

const HOLDINGS = {
  name: 'holdings',
  recordType: 'HOLDINGS',
  mappingFields: [{
    name: 'discoverySuppress',
    enabled: true,
    path: 'holdings.discoverySuppress',
    value: '',
    subfields: [],
  }, {
    name: 'hrid',
    enabled: false,
    path: 'holdings.discoverySuppress',
    value: '',
    subfields: [],
  }, {
    name: 'formerIds',
    enabled: true,
    path: 'holdings.formerIds[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.formerIds[]',
      fields: [{
        name: 'formerId',
        enabled: true,
        path: 'holdings.formerIds[]',
        value: '',
      }],
    }],
  }, {
    name: 'holdingsTypeId',
    enabled: true,
    path: 'holdings.holdingsTypeId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'statisticalCodeIds',
    enabled: true,
    path: 'holdings.statisticalCodeIds[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.statisticalCodeIds[]',
      fields: [{
        name: 'statisticalCodeId',
        enabled: true,
        path: 'holdings.statisticalCodeIds[]',
        value: '',
        acceptedValues: {},
      }],
    }],
  }, {
    name: 'administrativeNotes',
    enabled: true,
    path: 'holdings.administrativeNotes[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.administrativeNotes[]',
      fields: [{
        name: 'administrativeNote',
        enabled: true,
        path: 'holdings.administrativeNotes[]',
        value: '',
      }],
    }],
  }, {
    name: 'permanentLocationId',
    enabled: true,
    path: 'holdings.permanentLocationId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'temporaryLocationId',
    enabled: true,
    path: 'holdings.temporaryLocationId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'shelvingOrder',
    enabled: true,
    path: 'holdings.shelvingOrder',
    value: '',
    subfields: [],
  }, {
    name: 'shelvingTitle',
    enabled: true,
    path: 'holdings.shelvingTitle',
    value: '',
    subfields: [],
  }, {
    name: 'copyNumber',
    enabled: true,
    path: 'holdings.copyNumber',
    value: '',
    subfields: [],
  }, {
    name: 'callNumberTypeId',
    enabled: true,
    path: 'holdings.callNumberTypeId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'callNumberPrefix',
    enabled: true,
    path: 'holdings.callNumberPrefix',
    value: '',
    subfields: [],
  }, {
    name: 'callNumber',
    enabled: true,
    path: 'holdings.callNumber',
    value: '',
    subfields: [],
  }, {
    name: 'callNumberSuffix',
    enabled: true,
    path: 'holdings.callNumberSuffix',
    value: '',
    subfields: [],
  }, {
    name: 'numberOfItems',
    enabled: true,
    path: 'holdings.numberOfItems',
    value: '',
    subfields: [],
  }, {
    name: 'holdingsStatements',
    enabled: true,
    path: 'holdings.holdingsStatements[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.holdingsStatements[]',
      fields: [{
        name: 'statement',
        enabled: true,
        path: 'holdings.holdingsStatements[].statement',
        value: '',
      }, {
        name: 'note',
        enabled: true,
        path: 'holdings.holdingsStatements[].note',
        value: '',
      }, {
        name: 'staffNote',
        enabled: true,
        path: 'holdings.holdingsStatements[].staffNote',
        value: '',
      }],
    }],
  }, {
    name: 'holdingsStatementsForSupplements',
    enabled: true,
    path: 'holdings.holdingsStatementsForSupplements[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.holdingsStatementsForSupplements[]',
      fields: [{
        name: 'statement',
        enabled: true,
        path: 'holdings.holdingsStatementsForSupplements[].statement',
        value: '',
      }, {
        name: 'note',
        enabled: true,
        path: 'holdings.holdingsStatementsForSupplements[].note',
        value: '',
      }, {
        name: 'staffNote',
        enabled: true,
        path: 'holdings.holdingsStatementsForSupplements[].staffNote',
        value: '',
      }],
    }],
  }, {
    name: 'holdingsStatementsForIndexes',
    enabled: true,
    path: 'holdings.holdingsStatementsForIndexes[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.holdingsStatementsForIndexes[]',
      fields: [{
        name: 'statement',
        enabled: true,
        path: 'holdings.holdingsStatementsForIndexes[].statement',
        value: '',
      }, {
        name: 'note',
        enabled: true,
        path: 'holdings.holdingsStatementsForIndexes[].note',
        value: '',
      }, {
        name: 'staffNote',
        enabled: true,
        path: 'holdings.holdingsStatementsForIndexes[].staffNote',
        value: '',
      }],
    }],
  }, {
    name: 'illPolicyId',
    enabled: true,
    path: 'holdings.illPolicyId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'digitizationPolicy',
    enabled: true,
    path: 'holdings.digitizationPolicy',
    value: '',
    subfields: [],
  }, {
    name: 'retentionPolicy',
    enabled: true,
    path: 'holdings.retentionPolicy',
    value: '',
    subfields: [],
  }, {
    name: 'notes',
    enabled: true,
    path: 'holdings.notes[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.notes[]',
      fields: [{
        name: 'noteType',
        enabled: true,
        path: 'holdings.notes[].holdingsNoteTypeId',
        value: '',
        acceptedValues: {},
      }, {
        name: 'note',
        enabled: true,
        path: 'holdings.notes[].note',
        value: '',
      }, {
        name: 'staffOnly',
        enabled: true,
        path: 'holdings.notes[].staffOnly',
        value: null,
      }],
    }],
  }, {
    name: 'electronicAccess',
    enabled: true,
    path: 'holdings.electronicAccess[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.electronicAccess[]',
      fields: [{
        name: 'relationshipId',
        enabled: true,
        path: 'holdings.electronicAccess[].relationshipId',
        value: '',
        acceptedValues: {},
      }, {
        name: 'uri',
        enabled: true,
        path: 'holdings.electronicAccess[].uri',
        value: '',
      }, {
        name: 'linkText',
        enabled: true,
        path: 'holdings.electronicAccess[].linkText',
        value: '',
      }, {
        name: 'materialsSpecification',
        enabled: true,
        path: 'holdings.electronicAccess[].materialsSpecification',
        value: '',
      }, {
        name: 'publicNote',
        enabled: true,
        path: 'holdings.electronicAccess[].publicNote',
        value: '',
      }],
    }],
  }, {
    name: 'receivingHistory.entries',
    enabled: true,
    path: 'holdings.receivingHistory.entries[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'holdings.receivingHistory.entries[]',
      fields: [{
        name: 'publicDisplay',
        enabled: true,
        path: 'holdings.receivingHistory.entries[].publicDisplay',
        value: null,
      }, {
        name: 'enumeration',
        enabled: true,
        path: 'holdings.receivingHistory.entries[].enumeration',
        value: '',
      }, {
        name: 'chronology',
        enabled: true,
        path: 'holdings.receivingHistory.entries[].chronology',
        value: '',
      }],
    }],
  }],
};

export default HOLDINGS;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function ImportSingleRecordForm({
  jobProfiles,
  handleSubmit,
  recordTitle,
  recordServer,
}) {
  return (
    <form id="copycat-import-single-record-form" onSubmit={handleSubmit}>
      <FormattedMessage
        id="ui-inventory.copycat.importSingleRecord.form.bodyMsg"
        values={{
          title: <strong>{recordTitle}</strong>,
          server: (
            <strong>
              <FormattedMessage
                id={`ui-inventory.targetProfiles.name.${recordServer}`}
                defaultMessage={recordServer}
              />
            </strong>
          ),
          br: <br />,
        }}
        // defaultMessage="أنت على وشك القيام باستيراد العنوان: {title} {br} من خادم: {server} {br} اذا قمت بالضغط علي زر <strong> استيراد </strong> لن تتمكن من التراجع عن الامر."
        defaultMessage="You are about to import the title: {title} {br} from a server: {server}. {br} If you click on the <strong> Import </strong> button, you will not be able to undo the command."
      />
      <br />
      &nbsp; &nbsp;
      <Field
        id="copycat-import-single-record-form-jobProfile-field"
        name="selectedJobProfileId"
        component={Select}
        label={
          <FormattedMessage
            id="ui-inventory.copycat.importSingleRecord.form.jobProfileField.label"
            defaultMessage="Job profile"
          />
        }
        dataOptions={jobProfiles}
        required
      />
    </form>
  );
}

ImportSingleRecordForm.propTypes = {
  jobProfiles: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  recordTitle: PropTypes.string,
  recordServer: PropTypes.string,
};

export default ImportSingleRecordForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
  MenuSection,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function ImportedListActionsMenu({ item }) {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const getDropdownContent = () => {
    return (
      <MenuSection>
        <Button
          id="copycat-imported-list-action-menu-openInNewTab-btn"
          buttonStyle="dropdownItem"
          to={`/inventory/view/${item.internalIdentifier}`}
          target="_blank"
          onMouseDown={(e) => e.preventDefault()}
        >
          <Icon icon="preview" size="medium">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.openInNewTab"
              defaultMessage="Open in new tab"
            />
          </Icon>
        </Button>
        <Button
          id="copycat-imported-list-action-menu-editInInventory-btn"
          buttonStyle="dropdownItem"
          to={`/inventory/edit/${item.internalIdentifier}/instance`}
          target="_blank"
          onMouseDown={(e) => e.preventDefault()}
        >
          <Icon icon="edit" size="medium">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.editInInventory"
              defaultMessage="Edit in inventory"
            />
          </Icon>
        </Button>
        <Button
          id="copycat-imported-list-action-menu-editInMarcEditor-btn"
          buttonStyle="dropdownItem"
          to={`/inventory/quick-marc/edit-bib/${item.internalIdentifier}`}
          target="_blank"
          onMouseDown={(e) => e.preventDefault()}
        >
          <Icon icon="lightning" size="medium">
            <FormattedMessage
              id="ui-inventory.copycat.buttons.editInMarcEditor"
              defaultMessage="Edit in marc editor"
            />
          </Icon>
        </Button>
      </MenuSection>
    );
  };

  const trigger = ({ getTriggerProps, ariaProps }) => {
    return (
      <Button
        buttonStyle="primary"
        marginBottom0
        {...ariaProps}
        {...getTriggerProps()}
      >
        <Icon icon="ellipsis" size="large" />
      </Button>
    );
  };

  const renderMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <Dropdown
      id="copycat-results-preview-imported-list-action-menu"
      renderTrigger={trigger}
      renderMenu={renderMenu}
      open={DropdownOpen}
      onToggle={toggleDropdown}
      placement="bottom-end"
      relativePosition
      focusHandlers={{ open: () => null }}
    />
  );
}

ImportedListActionsMenu.propTypes = {
  item: PropTypes.object,
};

export default ImportedListActionsMenu;

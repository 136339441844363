import React, {
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
/** kware start edit */
import { FormattedMessage, useIntl } from 'react-intl';
/** kware end edit */
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Accordion,
  Row,
  Col,
  KeyValue,
  Highlighter
} from '@folio/stripes/components';
import {
  ViewMetaData,
  ClipCopy,
} from '@folio/stripes/smart-components';

/** kware start edit */
import HandleDateTime from '@folio/stripes-core/util/kware-utils/HandleDateTime';
/** kware end edit */
import {
  getDateWithTime,
  checkIfElementIsEmpty,
} from '../../../utils';
import {
  WarningMessage,
  AdministrativeNoteList,
} from '../../../components';

import StatisticalCodesList from './StatisticalCodesList';
import { QUERY_INDEXES } from '../../../constants';

const InstanceAdministrativeView = ({
  id,
  instance,
  instanceStatuses,
  issuanceModes,
  statisticalCodes,
  statisticalCodeTypes,
}) => {
  /** kware start editing */
  const intl = useIntl();
  /** kware end editing */
  const { search } = useLocation();

  const instanceStatus = useMemo(() => {
    return instanceStatuses.find(status => status.id === instance.statusId) || {};
  }, [instance, instanceStatuses]);

  const issuanceMode = useMemo(() => {
    return issuanceModes.find(mode => mode.id === instance.modeOfIssuanceId) || {};
  }, [instance, issuanceModes]);

  const formattedStatisticalCodes = useMemo(() => {
    const statisticalCodeTypesMap = statisticalCodeTypes.reduce((acc, type) => {
      // acc[type.id] = type.name;
      /** kware start editing */
      acc[type.id] = intl.formatMessage({ id: `ui-inventory.statisticalCodeTypes.name.${type.name}`, defaultMessage: `${type.name || '-'}` });
      /** kware end editing */
      return acc;
    }, {});

    const statisticalCodesMap = statisticalCodes.reduce((acc, code) => {
      acc[code.id] = {
        ...code,
        type: statisticalCodeTypesMap[code.statisticalCodeTypeId],
      };

      return acc;
    }, {});

    return (instance.statisticalCodeIds || []).map(codeId => statisticalCodesMap[codeId]);
  // }, [instance, statisticalCodeTypes, statisticalCodes]);
    /** kware start editing */
  }, [instance, statisticalCodeTypes, statisticalCodes, intl.locale]);
  /** kware end editing */

  const queryHRID = queryString.parse(search)?.query;
  const isQueryByHRID = queryString.parse(search)?.qindex === QUERY_INDEXES.INSTANCE_HRID;

  const highlightableInstanceHRID = isQueryByHRID ? <Highlighter searchWords={[queryHRID]} text={String(instance.hrid)} /> : instance.hrid;

  return (
    <Accordion
      id={id}
      label={<FormattedMessage id="ui-inventory.instanceData" />}
    >
      {instance.metadata && <ViewMetaData metadata={instance.metadata} />}
      <Row>
        {
          instance.discoverySuppress &&
          <Col xs={5}>
            <WarningMessage id="ui-inventory.discoverySuppressed" />
          </Col>
        }
        {
          instance.staffSuppress &&
          <Col xs={4}>
            <WarningMessage id="ui-inventory.staffSuppressed" />
          </Col>
        }
        {
          instance.previouslyHeld &&
          <Col xs={3}>
            <WarningMessage id="ui-inventory.previouslyHeld" />
          </Col>
        }
      </Row>

      {(instance.discoverySuppress || instance.staffSuppress || instance.previouslyHeld) && <br />}

      <Row>
        <Col xs={3}>
          <KeyValue label={<FormattedMessage id="ui-inventory.instanceHrid" />}>
            {
              Boolean(instance.hrid) && (
                <>
                  {highlightableInstanceHRID}
                  <ClipCopy text={instance.hrid} />
                </>
              )
            }
          </KeyValue>
        </Col>

        <Col xs={3}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.metadataSource" />}
            // value={checkIfElementIsEmpty(instance.source)}
            /** kware start editing */
            value={checkIfElementIsEmpty(intl.formatMessage({ id: `ui-inventory.${instance.source.toLowerCase()}` }))}
            /** kware end editing */
          />
        </Col>

        <Col xs={3}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.catalogedDate" />}
            // value={checkIfElementIsEmpty(instance.catalogedDate)}
            /** kware start editing */
            value={checkIfElementIsEmpty(instance.catalogedDate) !== '-' ? <HandleDateTime dateTimeValue={checkIfElementIsEmpty(instance.catalogedDate)} handleType="date" /> : '-'}
            /** kware end editing */
          />
        </Col>
      </Row>
      {(instance.matchKey) &&
        <Row>
          <Col xs={12}>
            <KeyValue
              label={<FormattedMessage id="ui-inventory.instanceMatchKey" />}
              value={instance.matchKey}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col xs={3}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.instanceStatusTerm" />}
            // value={checkIfElementIsEmpty(instanceStatus.name)}
            /** kware start editing */
            value={checkIfElementIsEmpty(intl.formatMessage({ id: `ui-inventory.instanceStatuses.name.${instanceStatus.name}`, defaultMessage: `${instanceStatus.name || '-'}` }))}
            /** kware end editing */
            subValue={
              <FormattedMessage
                id="ui-inventory.item.status.statusUpdatedLabel"
                // values={{ statusDate: getDateWithTime(instance.statusUpdatedDate) }}
                /** kware start editing */
                values={{ statusDate: instance.statusUpdatedDate ? <HandleDateTime dateTimeValue={instance.statusUpdatedDate} handleType="datetime" /> : '' }}
                /** kware end editing */
              />
            }
          />
        </Col>

        <Col xs={3}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.instanceStatusCode" />}
            value={checkIfElementIsEmpty(instanceStatus.code)}
          />
        </Col>

        <Col xs={3}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.instanceStatusSource" />}
            // value={checkIfElementIsEmpty(instanceStatus.source)}
            /** kware start editing */
            value={checkIfElementIsEmpty(intl.formatMessage({ id: `ui-inventory.${instanceStatus.source}`, defaultMessage: `${instanceStatus.source || '-'}` }))}
            /** kware end editing */
          />
        </Col>
      </Row>

      <Row>
        <Col xs={6}>
          <KeyValue
            label={<FormattedMessage id="ui-inventory.modeOfIssuance" />}
            // value={checkIfElementIsEmpty(issuanceMode.name)}
            /** kware start editing */
            value={checkIfElementIsEmpty(intl.formatMessage({ id: `ui-inventory.issuanceModes.name.${issuanceMode.name}`, defaultMessage: `${issuanceMode.name || '-'}` }))}
            /** kware end editing */
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <StatisticalCodesList statisticalCodes={formattedStatisticalCodes} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <AdministrativeNoteList administrativeNotes={instance.administrativeNotes} />
        </Col>
      </Row>
    </Accordion>
  );
};

InstanceAdministrativeView.propTypes = {
  id: PropTypes.string.isRequired,
  instance: PropTypes.object,
  instanceStatuses: PropTypes.arrayOf(PropTypes.object),
  issuanceModes: PropTypes.arrayOf(PropTypes.object),
  statisticalCodes: PropTypes.arrayOf(PropTypes.object),
  statisticalCodeTypes: PropTypes.arrayOf(PropTypes.object),
};

InstanceAdministrativeView.defaultProps = {
  instance: {},
  instanceStatuses: [],
  issuanceModes: [],
  statisticalCodes: [],
  statisticalCodeTypes: [],
};

export default InstanceAdministrativeView;

/* kware added new */

/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { stripesShape } from '@folio/stripes-core';

const WithUserLocales = WrappedComponent => class WithUserLocalesComponent extends Component {
    static manifest = Object.freeze({
      ...WrappedComponent.manifest,
      userLocaleId: {},
      userLocales: {
        type: 'okapi',
        records: 'userLocales',
        path: 'userLocales?limit=1000',
        accumulate: true,
        fetch: false,
        POST: {
          path: 'userLocales',
        },
        PUT: {
          path: 'userLocales',
        },
      },
    });

    static propTypes = {
      stripes: stripesShape.isRequired,
      resources: PropTypes.shape({
        userLocales: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
        userLocaleId: PropTypes.shape({
          replace: PropTypes.func,
        }),
      }),
      mutator: PropTypes.shape({
        userLocales: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
          POST: PropTypes.func.isRequired,
          PUT: PropTypes.func.isRequired,
        }),
        userLocaleId: PropTypes.shape({
          replace: PropTypes.func.isRequired,
        }),
      }),
    };

    componentDidMount() {
      if (this.props.stripes.hasPerm('language-switcher.tenant-locales.view')) {
        this.props.mutator.userLocales.reset();
        this.props.mutator.userLocales.GET();
      }
    }

    getLocalesInUse = () => {
      let userLocalesIds = [];
      ((this.props.resources.userLocales || {}).records || [])
        .map(userlocId => userlocId.localesIds)
        .forEach(array => {
          userLocalesIds = [...userLocalesIds, ...array];
        });
      return [...new Set(userLocalesIds)].filter(e => { return e; });
    }

    onUpdateUserLocales = (loc) => {
      const userlocales = sortBy((this.props.resources.userLocales || {}).records || [], ['localesIds']);

      userlocales.map(ul => {
        const userlocalesIds = ul.localesIds;
        const UserprefferdLocales = ul.defaultUserLocaleId;

        if (userlocalesIds) {
          if (UserprefferdLocales === loc.id) ul.defaultUserLocaleId = undefined;
          const locIds = userlocalesIds.filter(uId => uId !== loc.id);
          this.props.mutator.userLocaleId.replace(ul.id);
          ul.localesIds = locIds;
          this.props.mutator.userLocales.PUT(ul);
        }
        return null;
      });
    }

    render() {
      return (
        <WrappedComponent
          getLocalesInUse={this.getLocalesInUse}
          onUpdateUserLocales={this.onUpdateUserLocales}
          {...this.props}
        />
      );
    }
};

export default WithUserLocales;

import React, { useState } from 'react';
import {
  Accordion,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

function TableFilter({ currentTabel, setCurrentTabel, dataOptions, disabled }) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);

  const onChangeTable = (event) => {
    setCurrentTabel(event.target.value);
    // onActivateSearch(false);
    // onChangeSearchTerm('');
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const renderFilterByTable = () => {
    const options = [
      {
        value: 'allTables',
        label: intl.formatMessage({
          id: 'ui-translations.filters.TableFilter.allTables',
        }),
        disabled: false,
      },
      { value: '-', label: '--------------------------', disabled: true },
      ...dataOptions.map((table) => ({
        label: intl.formatMessage({
          id: `ui-translations.filters.TableFilter.${table}`,
        }),
        value: table,
        disabled: false,
      })),
    ];

    return (
      <div>
        <Select
          id="table-filter-select"
          name="table-filter"
          value={currentTabel}
          dataOptions={options}
          onChange={onChangeTable}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <>
      <Accordion
        id="table-filter-accordion"
        label={
          <FormattedMessage id="ui-translations.filters.TableFilter.Accordion" />
        }
        onToggle={handleFilterToggle}
        open={filterToggle}
        // separator={false}
        header={FilterAccordionHeader}
      >
        {renderFilterByTable()}
      </Accordion>
    </>
  );
}

export default TableFilter;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual, sortBy } from 'lodash';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import AppConfigListContext from './AppConfigListContext';

function AppConfigListProvider(props) {
  const [appConfigList, setAppConfigList] = useState([]);

  useEffect(() => {
    const configsList = sortBy((props.resources.configs || {}).records || [], [
      'configName',
    ]);
    if (!isEqual(configsList, appConfigList)) {
      setAppConfigList(configsList);
    }
  }, [props.resources.configs]);

  const getMTGeneralConfigs = () => {
    return appConfigList.find((config) => config.configName === 'MtGeneral');
  };

  const getLibreTranslateConfigs = () => {
    return appConfigList.find(
      (config) => config.configName === 'libreTranslate'
    );
  };

  return (
    <AppConfigListContext.Provider
      value={{
        libreTranslate: getLibreTranslateConfigs(),
        MtGeneral: getMTGeneralConfigs(),
      }}
    >
      {props.children}
    </AppConfigListContext.Provider>
  );
}

AppConfigListProvider.propTypes = {
  children: PropTypes.node,
  resources: PropTypes.shape({
    configs: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};
AppConfigListProvider.manifest = {
  configs: {
    type: 'okapi',
    path: 'configs?limit=1000',
    records: 'configs',
    resourceShouldRefresh: true,
  },
};
export default withStripes(stripesConnect(AppConfigListProvider));

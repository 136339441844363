import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '@folio/stripes/components';
import { FormattedNumber } from 'react-intl';
import css from './styles.css';
import EmptyData from '../../../common/emptyData/EmptyData';

function NumericalLabel({ resultSet, reportType }) {
  const [cardMeta, setCardMeta] = useState([]);

  useEffect(() => {
    const data = [];
    if (
      resultSet &&
      resultSet.length !== 0 &&
      resultSet.loadResponses.length !== 0
    ) {
      // resultSet.loadResponses[0]?.data.map((result) => {
      //   for (const key in result) {
      //     if (key) {
      //       if (reportType === 'numMetric') {
      //         data.push({
      //           header: key.replace('.', '_'),
      //           value: result[key],
      //           unit: key.split('.')[0],
      //         });
      //       } else {
      //         data.push({
      //           header: result[key],
      //           value: key,
      //           unit: key.split('.')[0],
      //         });
      //       }
      //     }
      //   }
      //   return null;
      // });
      resultSet.loadResponses[0]?.data.forEach((result) => {
        if (reportType === 'numMetric') {
          for (const key in result) {
            if (key) {
              data.push({
                header: key.replace('.', '_'),
                value: result[key],
                unit: key.split('.')[0],
              });
            }
          }
        } else if (Object.keys(result).length === 2) {
          const keys = Object.keys(result);
          data.push({
            header: result[keys[0]],
            value: result[keys[1]],
            unit: keys[0].split('.')[0],
          });
        }
      });
      setCardMeta(data);
    }
  }, [reportType, resultSet]);

  console.log(resultSet, cardMeta);

  if (resultSet && Object.keys(resultSet).length !== 0) {
    return (
      <Row>
        {cardMeta.map((card) => (
          <Col xs={10 / cardMeta.length} className={css.card}>
            <div className={css.cardHeader}>{card.header}</div>
            <div className={css.cardBody}>
              <FormattedNumber value={card.value} />
            </div>
            <div className={css.cardFooter}>{card.unit}</div>
          </Col>
        ))}
      </Row>
    );
  }

  return <EmptyData />;
}

NumericalLabel.propTypes = {
  resultSet: PropTypes.object.isRequired,
  reportType: PropTypes.string,
};

export default NumericalLabel;

import React from 'react';
import PropTypes from 'prop-types';
import MultiEditManager from '../components/MultiEditTranslations/MultiEditManager';

const propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

function MultiEditRoute(props) {
  const handleClose = () => {
    props.history.goBack();
  };

  return (
    <MultiEditManager
      handleClose={handleClose}
      contentData={props.location.state.contentData}
      currentLocale={props.location.state.currentLocale}
      paneTitle={props.location.state.paneTitle}
      // onUpdateResults={() => props.location.props.onUpdateResults}
      //   onSubmit={this.onSubmit}
    />
  );
}

MultiEditRoute.propTypes = propTypes;

export default MultiEditRoute;

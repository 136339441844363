import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
  /** kware start editing */
import { FormattedMessage, useIntl } from 'react-intl';
  /** kware end editing */
import {
  orderBy,
  isEmpty,
} from 'lodash';

import {
  Accordion,
} from '@folio/stripes/components';

import {
  getSortedNotes,
} from '../../../utils';

import InstanceNotesList from './InstanceNotesList';

const InstanceNotesView = ({
  id,
  instance,
  noteTypes,
}) => {
  /** kware start editing */
  const intl = useIntl();
  /** kware end editing */
  const notesGroups = useMemo(() => {
    if (isEmpty(instance.notes)) {
      return [{ key: -1, noteType: { name: <FormattedMessage id="ui-inventory.note" /> } }];
    }

    return orderBy(
      getSortedNotes(instance, 'instanceNoteTypeId', noteTypes),
      ['noteType.name'],
      ['asc'],
    );
  // }, [instance, noteTypes]);
  /** kware start editing */
  }, [instance, noteTypes, intl.locale]);
  /** kware end editing */

  return (
    <Accordion
      id={id}
      label={<FormattedMessage id="ui-inventory.instanceNotes" />}
    >
      {
        notesGroups.map((group, idx) => {
          /* const groupName = group.noteType?.name || <FormattedMessage id="ui-inventory.unknownNoteType" />; */
          /** kware start editing */
          const groupName = group.noteType ? intl.formatMessage({ id: `ui-inventory.instanceNoteTypes.name.${group.noteType.name}`, defaultMessage: `${group.noteType.name}` }) : <FormattedMessage id="ui-inventory.unknownNoteType" />;
          /** kware end editing */
          return (
            <InstanceNotesList
              id={`list-instance-notes-${idx}`}
              key={group.key}
              notes={group.notes}
              notesType={groupName}
            />
          );
        })
      }
    </Accordion>
  );
};

InstanceNotesView.propTypes = {
  id: PropTypes.string,
  instance: PropTypes.object,
  noteTypes: PropTypes.arrayOf(PropTypes.object),
};

InstanceNotesView.defaultProps = {
  instance: {},
  noteTypes: [],
};

export default InstanceNotesView;

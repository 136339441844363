export const VISIBLE_PATRON_ID_ROUTE = 'visiblePatronId';

export const VISIBLE_PATRON_ID_FIELDS = {
  BARCODE: 'BARCODE',
  EXTERNAL_SYSTEM_ID: 'EXTERNAL_SYSTEM_ID',
  FOLIO_RECORD_NUMBER: 'FOLIO_RECORD_NUMBER',
  USERNAME: 'USERNAME',
  USER_CUSTOM_FIELDS: 'USER_CUSTOM_FIELDS',
  IDENTIFIERS: 'identifiers',
  CUSTOM: 'custom',
};

export const PAYLOAD_FIELDS_FOR_VISIBLE_PATRON_ID = {
  USER_C_FIELDS: 'userCustomFields',
  FIELDS: 'fields',
};

const {
  BARCODE,
  EXTERNAL_SYSTEM_ID,
  FOLIO_RECORD_NUMBER,
  USERNAME,
} = VISIBLE_PATRON_ID_FIELDS;

export const DEFAULT_PATRON_IDENTIFIERS = [
  BARCODE,
  EXTERNAL_SYSTEM_ID,
  FOLIO_RECORD_NUMBER,
  USERNAME,
];

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NavList,
  NavListItem,
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
} from '@folio/stripes-components';

const propTypes = {
  stripes: PropTypes.shape({
    setLocale: PropTypes.func,
    setDateformat: PropTypes.func,
    okapi: PropTypes.object,
    locale: PropTypes.string,
    userPreferredLocale: PropTypes.string,
    tenantDefaultLocale: PropTypes.string,
    userLocales: PropTypes.arrayOf(object),
  }).isRequired,
};

const CategoryMenu = (props) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const getDropdownContent = () => {
    const categories = [
      'libTranslations',
      'stripesApps',
      'usersNames',
      'permsNames',
    ];

    return (
      <div>
        <NavList>
          {categories.map((catName) => (
            <NavListItem
              // key={`${catName}`}
              id={`translations-tabs-${catName}`}
              to={`/translations/${catName}`}
              buttonStyle={`${props.categoryName === catName ? 'primary' : 'default'}`}
              selected={props.categoryName === catName}
              // onClick={() => {
              //   props.setCategory(catName);
              //   toggleDropdown();
              // }}
            >
              <FormattedMessage
                id={`ui-translations.categoryMenu.${catName}`}
                defaultMessage={catName}
              />
            </NavListItem>
          ))}
        </NavList>
      </div>
    );
  };

  const renderLocalesTrigger = ({ getTriggerProps, open }) => {
    return (
      <FormattedMessage id="ui-translations.categoryMenu">
        {(label) => (
          <Button
            data-role="toggle"
            ariaLabel={label}
            buttonStyle="slim"
            selected={open}
            marginBottom0
            {...getTriggerProps()}
          >
            <div>
              <svg
                width="20px"
                height="20px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M8.4 2.4H5.1c-1.5 0-2.7 1.2-2.7 2.7v3.3c0 1.5 1.2 2.7 2.7 2.7h3.3c1.5 0 2.7-1.2 2.7-2.7V5.1c0-1.5-1.2-2.7-2.7-2.7zm.7 6c0 .4-.3.7-.7.7H5.1c-.4 0-.7-.3-.7-.7V5.1c0-.4.3-.7.7-.7h3.3c.4 0 .7.3.7.7v3.3zM18.9 2.4h-3.3c-1.5 0-2.7 1.2-2.7 2.7v3.3c0 1.5 1.2 2.7 2.7 2.7h3.3c1.5 0 2.7-1.2 2.7-2.7V5.1c0-1.5-1.2-2.7-2.7-2.7zm.7 6c0 .4-.3.7-.7.7h-3.3c-.4 0-.7-.3-.7-.7V5.1c0-.4.3-.7.7-.7h3.3c.4 0 .7.3.7.7v3.3zM8.4 12.9H5.1c-1.5 0-2.7 1.2-2.7 2.7v3.3c0 1.5 1.2 2.7 2.7 2.7h3.3c1.5 0 2.7-1.2 2.7-2.7v-3.3c0-1.5-1.2-2.7-2.7-2.7zm.7 6c0 .4-.3.7-.7.7H5.1c-.4 0-.7-.3-.7-.7v-3.3c0-.4.3-.7.7-.7h3.3c.4 0 .7.3.7.7v3.3zM18.9 12.9h-3.3c-1.5 0-2.7 1.2-2.7 2.7v3.3c0 1.5 1.2 2.7 2.7 2.7h3.3c1.5 0 2.7-1.2 2.7-2.7v-3.3c0-1.5-1.2-2.7-2.7-2.7zm.7 6c0 .4-.3.7-.7.7h-3.3c-.4 0-.7-.3-.7-.7v-3.3c0-.4.3-.7.7-.7h3.3c.4 0 .7.3.7.7v3.3z" />
              </svg>
            </div>
            {'  '}
            <Icon iconPosition="end" icon={open ? 'caret-up' : 'caret-down'}>
              {
                <FormattedMessage
                  id={`ui-translations.categoryMenu.${props.categoryName}`}
                  defaultMessage={props.categoryName}
                />
              }
            </Icon>
          </Button>
        )}
      </FormattedMessage>
    );
  };

  const renderLocalesMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <>
      <Dropdown
        id="userLanguageSwitcherDropdown"
        renderTrigger={renderLocalesTrigger}
        renderMenu={renderLocalesMenu}
        open={DropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        // usePortal={false}
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    </>
  );
};

CategoryMenu.propTypes = propTypes;

export default CategoryMenu;

import React from 'react';
import AppStats from './components/AppStats';
import CategoryStats from './components/CategoryStats';
import LocaleStats from './components/LocaleStats';
import UserStats from './components/UserStats';

function TranslationsStats({
  currentLocale,
  currentApp,
  originTranslations,
  currentLocaleTranslations,
  appTranslations,
  apps,
  // type,
  showAppStats,
  CategoryName,
  totalLanguages,
  totalTables,
  setCurrentStatus,
  setContentData,
  ViewMetaData
}) {
  const getAvailableTranslations = () => {
    const contentData = [];

    for (const key in originTranslations) {
      if (key && currentLocale) {
        contentData.push({
          originTranslation: originTranslations[key],
          localeTranslation: currentLocaleTranslations[key] || undefined,
          translationKey: key,
        });
      }
    }
    return contentData;
  };

  const getAppTranslations = () => {
    const contentData = [];

    for (const key in appTranslations) {
      if (key && currentLocale) {
        contentData.push({
          originTranslation: appTranslations[key],
          localeTranslation: currentLocaleTranslations[key],
          translationKey: key,
        });
      }
    }
    return contentData;
  };

  return (
    <>
      <CategoryStats
        CategoryName={CategoryName}
        totalLanguages={totalLanguages}
        availableTranslations={Object.keys(originTranslations)}
        totalApps={apps.length}
        totalTables={totalTables}
      />
      {currentLocale !== '' && (
        <LocaleStats
          localeStats={{
            localeValue: currentLocale,
            availableTranslations: currentLocaleTranslations ? getAvailableTranslations() : [],
            totalApps: apps.length,
            totalTranslators: 0,
          }}
          ViewMetaData={ViewMetaData}
          /** for making untranslated as a hyper link */
          // setCurrentStatus={setCurrentStatus}
          // currentLocaleTranslations={currentLocaleTranslations}
          // setContentData={setContentData}
        />
      )}
      {showAppStats && (
        <AppStats
          appStats={{
            totalTables: 0,
            appName: currentApp,
            availableTranslations: currentLocaleTranslations ? getAppTranslations() : [],
          }}
          /** for making untranslated as a hyper link */
          // setCurrentStatus={setCurrentStatus}
          // appTranslations={appTranslations}
          // setContentData={setContentData}
        />
      )}
      <UserStats />
    </>
  );
}

export default TranslationsStats;

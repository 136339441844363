/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { isFunction, kebabCase } from 'lodash';
/** kware start editing */
import get from 'lodash/get';
/** kware end editing */
import { compose } from 'redux';
import { withRouter } from 'react-router';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Avatar,
  Dropdown,
  DropdownMenu,
  Icon,
  List,
  NavList,
  NavListItem,
  NavListSection,
} from '@folio/stripes-components';

import NavButton from '../NavButton';
import css from './ProfileDropdown.css';
import { withModules } from '../../Modules';
import { getEventHandler } from '../../../handlerService';
import validations from '../../../userDropdownLinksService';
import IntlConsumer from '../../IntlConsumer';
import ChooseLocalesModal from '../UserLocalesSwitcher/ChooseLocalesModal'; /** kware start editing */

class ProfileDropdown extends Component {
  static propTypes = {
    modules: PropTypes.shape({
      app: PropTypes.arrayOf(PropTypes.object),
    }),
    onLogout: PropTypes.func.isRequired,
    stripes: PropTypes.shape({
      config: PropTypes.shape({
        showPerms: PropTypes.bool,
        showHomeLink: PropTypes.bool,
      }),
      hasPerm: PropTypes.func,
      okapi: PropTypes.object,
      user: PropTypes.shape({
        user: PropTypes.object,
        perms: PropTypes.object,
      }),
      /** kware start editing */
      userProfileImage: PropTypes.string,
      /** kware end editing */
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.object,
    /** kware start editing */
    defaultTheme: PropTypes.string,
    setLocale: PropTypes.func,
    setDateformat: PropTypes.func,
    locale: PropTypes.string,
    userPreferredLocale: PropTypes.string,
    tenantDefaultLocale: PropTypes.string,
    userLocales: PropTypes.arrayOf(object),
    tenantLocales: PropTypes.arrayOf(object),
    /** kware end editing */
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.getDropdownContent = this.getDropdownContent.bind(this);
    this.getUserData = this.getUserData.bind(this);
    this.getProfileImage = this.getProfileImage.bind(this);
    this.createHandlerComponent = this.createHandlerComponent.bind(this);
    this.navigateByUrl = this.navigateByUrl.bind(this);

    this.userLinks = this.getDropdownMenuLinks();
  }

  setInitialState(callback) {
    this.setState(
      {
        HandlerComponent: null,
      },
      callback
    );
  }

  getDropdownMenuLinks = () => {
    const modulesWithLinks = this.getModulesWithLinks();

    return modulesWithLinks.reduce((acc, m) => {
      const links = m.links.userDropdown.map((link, index) => this.createLink(link, index, m));
      return acc.concat(links);
    }, []);
  };

  getModulesWithLinks = () => {
    const { modules } = this.props;
    return Object.values(modules)
      .flat()
      .filter((module, index, self) => {
        return index === self.findIndex((m) => m.module === module.module);
      })
      .filter(({ links }) => links && Array.isArray(links.userDropdown));
  };

  createHandlerComponent(link, module) {
    const { stripes } = this.props;
    const HandlerComponent = getEventHandler(link.event, stripes, module);

    // forces to recreate a handler component
    this.setInitialState(() => this.setState({ HandlerComponent }));
  }

  // createLink(link, index, module) {
  //   const { stripes } = this.props;
  //   const { check, route } = link;
  //   const isLocalLoginCheck = module.getModule()[check] || validations[check];
  //   let checkfn;

  //   if (route === '/settings/myprofile/password') {
  //     checkfn = check
  //       ? stripes.hasPerm('ui-myprofile.settings.change-password') &&
  //         isLocalLoginCheck
  //       : null;
  //   } else {
  //     checkfn = check ? isLocalLoginCheck : null;
  //   }

  //   if (!check || (isFunction(checkfn) && checkfn(stripes))) {
  //     return this.renderNavLink(link, index, module);
  //   }

  //   return null;
  // }

  /** kware start edit
  *checkfn return false if ssoEnabled, So we need to force enables links either if we are on local or sso login
  */
  createLink(link, index, module) {
    return this.renderNavLink(link, index, module);
  }
  /** kware end edit */

  onNavItemClicked(link, module) {
    const handler = link.event
      ? this.createHandlerComponent
      : this.navigateByUrl;
    this.toggleDropdown();
    handler(link, module);
  }

  renderNavLink(link, index, module) {
    const buttonId = `${kebabCase(
      module.displayName
    )}-clickable-menuItem${index}`;
    return (
      <NavListItem
        id={buttonId}
        key={buttonId}
        type="button"
        onClick={() => this.onNavItemClicked(link, module)}
      >
        <FormattedMessage id={link.caption} />
      </NavListItem>
    );
  }

  toggleDropdown() {
    // Get items after rechecking for item visibility
    this.userLinks = this.getDropdownMenuLinks();

    this.setState(({ dropdownOpen }) => ({
      dropdownOpen: !dropdownOpen,
    }));
  }

  getUserData() {
    const {
      stripes: { user },
    } = this.props;

    if (user.user) {
      return user.user;
    }
    return {};
  }

  getProfileImage() {
    const user = this.getUserData();

    /* Note: This is not yet available - only prepared for here */
    return (
      // <Avatar
      //   alt={user.name}
      //   ariaLabel={user.name}
      //   className={css.avatar}
      // />
      /** kware start edit */
      <Avatar
        src={this.props.stripes.userProfileImage}
        alt={user.name}
        ariaLabel={user.name}
        className={css.avatar}
      />
      /** kware end edit */
    );
  }

  navigateByUrl(link) {
    this.props.history.push(link.route);
  }

  onHome = () => {
    this.toggleDropdown();
    this.navigateByUrl({ route: '/' });
  };

  /** kware start editing */
  closeModal() {
    this.setState({ open: false });
  }

  setLocale = (locale) => {
    if (locale) this.props.stripes.setLocale(locale.value);
    this.props.stripes.setDateformat(locale.defaultDateFormat);
    this.closeModal();
  };

  renderUserLocaleSwitcherModal = () => {
    const {
      stripes: { userLocales, tenantLocales },
    } = this.props;

    return (
      <div>
        <NavListSection type="button">
          <ChooseLocalesModal
            open={this.state.open}
            userLocales={userLocales}
            onClose={() => this.closeModal()}
            setLocale={this.setLocale}
          />
        </NavListSection>
      </div>
    );
  };

  renderSwitchLocales = () => {
    return (
      <NavListSection>
        <NavListItem
          id="choose-user-locales-btn"
          type="button"
          onClick={() => this.setState({ open: true })}
        >
          <Icon icon="replace" iconPosition="start">
            <FormattedMessage id="stripes-core.ul.button.ChooseUserLocales" />
          </Icon>
        </NavListItem>
      </NavListSection>
    );
  };
  /** kware end editing */

  getDropdownContent() {
    const { stripes, onLogout } = this.props;
    const user = this.getUserData();
    const currentPerms = stripes.user ? stripes.user.perms : undefined;
    const messageId = stripes.okapi.ssoEnabled
      ? 'stripes-core.logoutKeepSso'
      : 'stripes-core.logout';

    /** kware start editing */
    const servicePointName = get(
      this.getUserData(),
      'curServicePoint.name',
      null
    );
    const translatedSPName = this.props.intl.formatMessage({
      id: `ui-tenant-settings.ServicePoint.name.${servicePointName}`,
      defaultMessage: `${servicePointName}`,
    });

    const firstName = user.firstName
      ? this.props.intl.formatMessage({
        id: `ui-users.userInfo.firstName.${user.firstName}`,
        defaultMessage: `${user.firstName}`,
      })
      : null;
    const lastName = user.lastName
      ? this.props.intl.formatMessage({
        id: `ui-users.userInfo.lastName.${user.lastName}`,
        defaultMessage: `${user.lastName}`,
      })
      : null;
    /** kware end editing */

    /**
     * Show perms, locale etc.
     * if setting is active in stripes config
     */
    let perms = null;
    if (stripes.config?.showPerms) {
      perms = (
        <IntlConsumer>
          {(intl) => {
            const items = [
              `${intl.formatMessage({
                id: 'stripes-core.mainnav.profileDropdown.locale',
              })}: ${intl.locale}`,
              `${intl.formatMessage({
                id: 'stripes-core.mainnav.profileDropdown.permissions',
              })}: ${Object.keys(currentPerms || {})
                .sort()
                .join(', ')}`,
            ];

            return (
              <section>
                <hr className={css.divider} />
                <List
                  items={items}
                  itemFormatter={(item, index) => <li key={index}>{item}</li>}
                  marginBottom0
                />
              </section>
            );
          }}
        </IntlConsumer>
      );
    }

    return (
    // <div>
    //   <div className={css.header}>
    //     <FormattedMessage
    //       id='stripes-core.loggedInAs'
    //       values={{ firstName: user.firstName, lastName: user.lastName }}
    //     />
    //     <br />
    //     {user.curServicePoint ? (
    //       <FormattedMessage
    //         id='stripes-core.currentServicePoint'
    //         values={{ name: user.curServicePoint.name }}
    //       />
    //     ) : (
    //       <FormattedMessage id='stripes-core.currentServicePointNotSelected' />
    //     )}
    //   </div>
    //   <hr className={css.divider} />
    //   <NavList>
    //     <NavListSection>
    //       {!stripes.config || !stripes.config.showHomeLink ? null : (
    //         <NavListItem
    //           id='clickable-home'
    //           type='button'
    //           onClick={this.onHome}
    //         >
    //           <FormattedMessage id='stripes-core.front.home' />
    //         </NavListItem>
    //       )}
    //       {this.userLinks}
    //       <NavListItem id='clickable-logout' type='button' onClick={onLogout}>
    //         <FormattedMessage id={messageId} />
    //       </NavListItem>
    //     </NavListSection>
    //   </NavList>
    //   {perms}
    // </div>

      /** kware start editing */
      <div style={{ padding: '0.30em' }}>
        {this.props.stripes.defaultTheme === 'standardTheme' && (
          <div className={css.header}>
            <FormattedMessage
              id="stripes-core.loggedInAs"
              values={{ firstName, lastName }}
            />
            <br />
            {user.curServicePoint ? (
              <FormattedMessage
                id="stripes-core.currentServicePoint"
                values={{ name: translatedSPName }}
              />
            ) : (
              <FormattedMessage id="stripes-core.currentServicePointNotSelected" />
            )}
          </div>
        )}
        <NavList>
          <NavListSection>
            {/* {
              (!stripes.config || !stripes.config.showHomeLink) ?
                null :
                <NavListItem id="clickable-home" type="button" onClick={this.onHome}>
                  <FormattedMessage id="stripes-core.front.home" />
                </NavListItem>
          } */}
            {this.props.stripes.defaultTheme === 'standardTheme' ? (
              !stripes.config || !stripes.config.showHomeLink ? null : (
                <NavListItem
                  id="clickable-home"
                  type="button"
                  onClick={this.onHome}
                >
                  <Icon icon="house">
                    <FormattedMessage id="stripes-core.front.home" />
                  </Icon>
                </NavListItem>
              )
            ) : null}
            {this.userLinks}
            {this.props.stripes.defaultTheme === 'standardTheme' &&
              this.renderSwitchLocales()}
            <hr className={css.divider} />
            <NavListItem id="clickable-logout" type="button" onClick={onLogout}>
              <Icon icon="logout">
                <FormattedMessage id="stripes-core.logout" />
              </Icon>
            </NavListItem>
          </NavListSection>
        </NavList>
        {perms}
      </div>
      /** kware end editing */
    );
  }

  renderProfileTrigger = ({ getTriggerProps, open }) => {
    const { intl } = this.props;
    const servicePointName = get(
      this.getUserData(),
      'curServicePoint.name',
      null
    );

    /** kware start editing */
    const translatedSPName = (
      <FormattedMessage
        id={`ui-tenant-settings.ServicePoint.name.${servicePointName}`}
        defaultMessage={`${servicePointName}`}
      />
    );
    const user = this.getUserData();
    const firstName = user.firstName
      ? this.props.intl.formatMessage({
        id: `ui-users.userInfo.firstName.${user.firstName}`,
        defaultMessage: `${user.firstName}`,
      })
      : '';
    const lastName = user.lastName
      ? this.props.intl.formatMessage({
        id: `ui-users.userInfo.lastName.${user.lastName}`,
        defaultMessage: `${user.lastName}`,
      })
      : '';
    const CurrentUser = lastName ? firstName.concat(' ', lastName) : '';
    /** kware end editing */

    return (
      <NavButton
        ariaLabel={intl.formatMessage({
          id: 'stripes-core.mainnav.myProfileAriaLabel',
        })}
        selected={open}
        className={css.button}
        icon={this.getProfileImage()}
        /** kware start editing */
        navbar={
          this.props.stripes.defaultTheme === 'kwareTheme' ? 'lib' : 'apps'
        }
        /** kware end editing */
        // label={
        //   servicePointName ? (
        //     <>
        //       <span className={css.button__label}>{servicePointName}</span>
        //       <Icon icon={open ? "caret-up" : "caret-down"} />
        //     </>
        //   ) : null
        // }
        /** kware start editing */
        label={
          servicePointName || CurrentUser ? (
            <>
              {this.props.stripes.defaultTheme === 'kwareTheme' && CurrentUser}
              {servicePointName ? (
                <div className={css.button__label}>
                  {this.props.stripes.defaultTheme === 'kwareTheme' && ' | '}
                  {translatedSPName}
                </div>
              ) : null}
              <Icon icon={open ? 'caret-up' : 'caret-down'} />
            </>
          ) : null
        }
        /** kware end editing */
        {...getTriggerProps()}
      />
    );
  };

  renderProfileTriggerLabel = ({ open }) => {
    const { okapi } = this.props.stripes;
    const userData = this.getUserData();
    const servicePointName = userData?.curServicePoint?.name;
    const tenantName = userData?.tenants?.find(
      ({ id }) => id === okapi.tenant
    )?.name;

    const hasLabel = Boolean(servicePointName || tenantName);

    return hasLabel ? (
      <>
        <span className={css.button__label}>
          {tenantName && <span>{tenantName}</span>}
          {servicePointName && <span>{servicePointName}</span>}
        </span>
        <Icon icon={open ? 'caret-up' : 'caret-down'} />
      </>
    ) : null;
  };

  renderProfileMenu = ({ open }) => (
    <DropdownMenu open={open}>{this.getDropdownContent()}</DropdownMenu>
  );

  render() {
    const { HandlerComponent } = this.state;

    return (
      <>
        {HandlerComponent && <HandlerComponent stripes={this.props.stripes} />}
        {/** kware start editing */}
        {this.renderUserLocaleSwitcherModal()}
        {/** kware edit editing */}
        <Dropdown
          id="profileDropdown"
          renderTrigger={this.renderProfileTrigger}
          renderMenu={this.renderProfileMenu}
          open={this.state.dropdownOpen}
          onToggle={this.toggleDropdown}
          placement="bottom-end"
          relativePosition
          usePortal={false}
          focusHandlers={{ open: () => null }} /** kware start editing */
        />
      </>
    );
  }
}

export default compose(withRouter, withModules, injectIntl)(ProfileDropdown);

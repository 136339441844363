/* kware added new */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocaleLabel, stripesConnect } from '@folio/stripes-core';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Callout,
  ConfirmationModal,
  Icon,
  List,
} from '@folio/stripes-components';
import { isEqual, sortBy } from 'lodash';
import { compose } from 'redux';
import TenantLocalesForm from './TenantLocalesForm';
import WithTenantDefualtLocale from './Wrappers/WithTenantDefualtLocale';
import WithUserLocales from './Wrappers/WithUserLocales';

class TenantLocalesManager extends Component {
  // eslint-disable-next-line react/sort-comp
  static manifest = Object.freeze({
    recordId: {},
    entries: {
      type: 'okapi',
      records: 'locales',
      path: 'locales?limit=1000',
      // resourceShouldRefresh: true,
      POST: {
        path: 'locales',
      },
      PUT: {
        path: 'locales',
      },
      DELETE: {
        path: 'locales',
      },
    },
  });

  static propTypes = {
    resources: PropTypes.shape({
      entries: PropTypes.shape({
        records: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    mutator: PropTypes.shape({
      recordId: PropTypes.shape({
        replace: PropTypes.func,
      }),
      entries: PropTypes.shape({
        POST: PropTypes.func,
        PUT: PropTypes.func,
        DELETE: PropTypes.func,
      }),
    }).isRequired,
    stripes: PropTypes.object.isRequired,
    getLocalesInUse: PropTypes.func,
    getTenantDefualtLocale: PropTypes.func,
    onChangeTenantDefualtLocale: PropTypes.func,
    getTenantTimeZone: PropTypes.func,
    getTenantCurrency: PropTypes.func,
    geLocaleIconStatus: PropTypes.func,
    onUpdateUserLocales: PropTypes.func,
    getLocales: PropTypes.func,
    intl: PropTypes.object.isRequired,
    getLocaleSettings: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      openLocalesInUseModal: false,
      tenantLocales: [],
      tenantDefaultLocale: props.getTenantDefualtLocale(),
      localesInUse: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    const tenantLocales = sortBy(
      (props.resources.entries || {}).records || [],
      ['value']
    );
    const tenantDefaultLocale = props.getTenantDefualtLocale();

    if (!isEqual(tenantLocales, state.tenantLocales)) {
      return {
        tenantLocales,
      };
    }

    if (!isEqual(state.tenantDefaultLocale, tenantDefaultLocale)) {
      return {
        tenantDefaultLocale,
      };
    }
    return null;
  }

  onConfirmDeleteUserLocale = () => {
    const { localesInUse } = this.state;
    const {
      stripes: { userLocales, tenantLocales, setUserLocales, setTenantLocales },
    } = this.props;

    const response = localesInUse.forEach((loc) => {
      Promise.resolve(this.props.onUpdateUserLocales(loc)).then(() => {
        this.props.mutator.entries.DELETE({ id: loc.id });
      });
    });

    // filter user locales global state after delete locales in use
    const filterdUserLocales = userLocales
      ? userLocales.filter((usrLoc) => {
        return !localesInUse.some((delLoc) => {
          return usrLoc.value === delLoc.value;
        });
      })
      : [];

    // filter tenant locales global state after delete locales in use
    const filterdTenantLocales = tenantLocales
      ? tenantLocales.filter((tenLoc) => {
        return !localesInUse.some((delLoc) => {
          return tenLoc.value === delLoc.value;
        });
      })
      : [];

    Promise.resolve(response).then(() => {
      setUserLocales(filterdUserLocales);
      setTenantLocales(filterdTenantLocales);
      this.setState({
        openLocalesInUseModal: false,
      });
      this.onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-tenant-settings.callout.deletedLocales.success" />
          </strong>
          <List
            listStyle="bullets"
            items={localesInUse}
            itemFormatter={(item) => (
              <li>{getLocaleLabel(item.value, this.props.intl)}</li>
            )}
          />
        </div>
      );
    });
  };

  onCancelConfirmDeleteUserLocale = () => {
    const { localesInUse } = this.state;
    const {
      stripes: { tenantLocales, setTenantLocales },
    } = this.props;
    setTenantLocales([...tenantLocales, ...localesInUse]);
    this.setState({
      openLocalesInUseModal: false,
    });
  };

  tenantLocalesInUseModal() {
    const { intl } = this.props;
    return (
      <ConfirmationModal
        id="delete-user-locale-confirmation-modal"
        open={this.state.openLocalesInUseModal}
        onConfirm={this.onConfirmDeleteUserLocale}
        onCancel={this.onCancelConfirmDeleteUserLocale}
        confirmLabel={
          <FormattedMessage id="ui-tenant-settings.settings.locales.button.confirmDeleteLocale" />
        }
        heading={
          <Icon icon="exclamation-circle" size="large">
            <FormattedMessage id="ui-tenant-settings.settings.locales.heading.confirmDeleteLocaleModal" />
          </Icon>
        }
        message={
          <div>
            <div>
              <FormattedMessage id="ui-tenant-settings.settings.locales.message.confirmDeleteLocale" />
              <List
                listStyle="bullets"
                items={this.state.localesInUse}
                itemFormatter={(item) => (
                  <strong>
                    <li>{getLocaleLabel(item.value, intl)}</li>
                  </strong>
                )}
              />
            </div>
          </div>
        }
      />
    );
  }

  onDeleteTenantLocale = (deletedLocales) => {
    const localesInUse = deletedLocales.filter((delLoc) => {
      return this.props.getLocalesInUse().some((locInUse) => {
        return delLoc.id === locInUse;
      });
    });

    const localesNotUsed = deletedLocales.filter((delLoc) => {
      return !localesInUse.some((locInUse) => {
        return delLoc.id === locInUse.id;
      });
    });

    if (localesNotUsed && localesNotUsed.length !== 0) {
      const res = localesNotUsed.map((loc) => this.props.mutator.entries.DELETE({ id: loc.id }));
      Promise.resolve(res).then(() => {
        this.onShowCallout(
          <div>
            <strong>
              <FormattedMessage id="ui-tenant-settings.callout.deletedLocales.success" />
            </strong>
            <List
              listStyle="bullets"
              items={localesNotUsed}
              itemFormatter={(item) => (
                <li>{getLocaleLabel(item.value, this.props.intl)}</li>
              )}
            />
          </div>
        );
      });
    }

    if (localesInUse && localesInUse.length !== 0) {
      this.setState({
        openLocalesInUseModal: true,
        localesInUse,
      });
    }
  };

  onCreateNewTenantLocale = (newLocales) => {
    const res = newLocales.forEach((newLoc) => {
      this.props.mutator.entries.POST({
        name: newLoc.name,
        value: newLoc.value,
        defaultDateFormat: newLoc.defaultDateFormat,
      });
    });

    Promise.resolve(res).then(() => {
      this.onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-tenant-settings.callout.newLocales.success" />
          </strong>
          <List
            listStyle="bullets"
            items={newLocales}
            itemFormatter={(item) => (
              <li>{getLocaleLabel(item.value, this.props.intl)}</li>
            )}
          />
        </div>
      );
    });
  };

  onUpdateTenantLocale = (updatedLocales) => {
    const {
      props: {
        stripes: { locale, setDateformat },
      },
    } = this;

    const res = updatedLocales.forEach((updatedLoc) => {
      delete updatedLoc.rowIndex;
      this.props.mutator.recordId.replace(updatedLoc.id);
      this.props.mutator.entries.PUT(updatedLoc);
      if (updatedLoc.value === locale) {
        setDateformat(updatedLoc.defaultDateFormat);
      }
    });

    Promise.resolve(res).then(() => {
      this.onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-tenant-settings.callout.updatedLocales.success" />
          </strong>
          <List
            listStyle="bullets"
            items={updatedLocales}
            itemFormatter={(item) => (
              <li>{getLocaleLabel(item.value, this.props.intl)}</li>
            )}
          />
        </div>
      );
    });
  };

  onUpdateLocaleSettings = (localeSetting) => {
    console.log(
      'localeSetting: ',
      localeSetting,
      localeSetting.tenantdefualtLocale === undefined
    );
    const {
      stripes: {
        setLocale,
        setTenantDefaultLocale,
        setTimezone,
        setCurrency,
        setShowLocaleIcon,
      },
    } = this.props;

    const res = this.props.onChangeTenantDefualtLocale(localeSetting);

    Promise.resolve(res).then(() => {
      setTenantDefaultLocale(localeSetting.tenantdefualtLocale);
      setTimezone(localeSetting.timezone);
      setCurrency(localeSetting.currency);
      setShowLocaleIcon(localeSetting.showLocaleIcon);
      if (localeSetting.tenantdefualtLocale === undefined) {
        // setLocale(localeSetting.locale);
      }
      this.onShowCallout(
        <FormattedMessage id="ui-tenant-settings.callout.setTenantDefaultLocale.success" />
      );
    });
  };

  onFormSubmit = (values) => {
    const {
      state: { tenantLocales },
      props: {
        stripes: { setTenantLocales },
      },
    } = this;

    const deletedLocales = tenantLocales.filter((tenantloc) => {
      return !values.tenantLocales.some((availableLoc) => {
        return tenantloc.value === availableLoc.value;
      });
    });

    const newLocales = values.tenantLocales.filter((availableLoc) => {
      return !tenantLocales.some((tenantloc) => {
        return availableLoc.value === tenantloc.value;
      });
    });

    const updatedLocales = values.tenantLocales.filter((availableLoc) => {
      return tenantLocales.some((tenantloc) => {
        return (
          availableLoc.defaultDateFormat !== tenantloc.defaultDateFormat &&
          availableLoc.value === tenantloc.value
        );
      });
    });

    this.onUpdateLocaleSettings({
      locale: values.tenantdefualtLocale,
      timezone: values.timezone,
      currency: values.currency,
      showLocaleIcon: values.showLocaleIcon,
      timeformat: values.timeformat,
      primaryInputLanguage: values.primaryInputLanguage,
    });

    if (updatedLocales.length) this.onUpdateTenantLocale(updatedLocales);

    if (newLocales.length) this.onCreateNewTenantLocale(newLocales);

    if (deletedLocales.length) this.onDeleteTenantLocale(deletedLocales);

    setTenantLocales(values.tenantLocales);
    localStorage.setItem(
      'TenantLocales',
      JSON.stringify({
        useDefaults: false,
        locales: values.tenantLocales,
      })
    );
  };

  onShowCallout = (message) => {
    if (this.callout) {
      this.callout.sendCallout({ message });
    }
  };

  render() {
    return (
      <>
        <TenantLocalesForm
          {...this.props}
          onSubmit={this.onFormSubmit}
          initialValues={{
            tenantLocales: sortBy(this.state.tenantLocales, ['value']),
            tenantdefualtLocale: this.props.getTenantDefualtLocale(),
            timezone: this.props.getTenantTimeZone(),
            currency: this.props.getTenantCurrency(),
            showLocaleIcon: this.props.geLocaleIconStatus(),
            // eslint-disable-next-line react/prop-types
            timeformat: this.props.getLocaleSettings().timeformat,
            // eslint-disable-next-line react/prop-types
            primaryInputLanguage:
              this.props.getLocaleSettings().primaryInputLanguage,
          }}
        />
        <Callout
          ref={(ref) => {
            this.callout = ref;
          }}
        />
        {this.tenantLocalesInUseModal()}
      </>
    );
  }
}
export default stripesConnect(
  compose(
    WithUserLocales,
    WithTenantDefualtLocale
  )(injectIntl(TenantLocalesManager))
);

import { groupBy } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
 /** kware start edit */
import { FormattedMessage, useIntl } from 'react-intl';
/** kware end edit */

import { KeyValue } from '@folio/stripes/components';

import useReferenceData from '../../../hooks/useReferenceData';
import SubInstanceList from '../SubInstanceList';

const SubInstanceGroup = ({
  titles,
  id,
  titleKey,
  label,
}) => {
  const { instanceRelationshipTypes } = useReferenceData();
  const titlesByInstanceRelationshipTypeId = groupBy(titles, 'instanceRelationshipTypeId');


  /** kware start edit */
  const intl = useIntl();
  /** kware end edit */

  return (
    <KeyValue id={id} label={label}>
      {
        instanceRelationshipTypes.map(({ id: typeId, name }) => (
          titlesByInstanceRelationshipTypeId[typeId] &&
          <div key={`${id}-${typeId}`}>
            <FormattedMessage
              id="ui-inventory.instances.typeOfRelation"
              // values={{ name }}
              /** kware start edit */
              values={{ name: intl.formatMessage({ id: `ui-inventory.instanceRelationshipTypes.name.${name}`, defaultMessage: name }) }}
              /** kware end edit */
              tagName="p"
            />
            <SubInstanceList
              id={`${id}-${typeId}`}
              titles={titlesByInstanceRelationshipTypeId[typeId]}
              titleKey={titleKey}
              label={name}
            />
          </div>
        ))
      }
    </KeyValue>
  );
};

SubInstanceGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  titles: PropTypes.arrayOf(PropTypes.object).isRequired,
  titleKey: PropTypes.string.isRequired,
};

export default SubInstanceGroup;

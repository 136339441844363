/* kware added new */

import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import {
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import {
  MultiColumnList,
  Layout,
  Checkbox,
  Modal,
  ModalFooter,
  Button,
  Icon,
} from '@folio/stripes/components';
import { getLocaleLabel, ImportLocalesIcones } from '@folio/stripes-core';

class TenantLocalesModal extends Component {
static propTypes = {
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tenantLocales: PropTypes.arrayOf(PropTypes.object),
  intl: object.isRequired,
};

  state = {
    selection: {},
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tenantLocales.length !== Object.values(state.selection).length) {
      const selection = {};
      props.tenantLocales.forEach((ul) => { selection[ul.value] = false; });

      return { selection };
    }

    return null;
  }

  onSaveAndClose = () => {
    const tenantLocales = this.props.tenantLocales.filter(ul => this.state.selection[ul.value]);
    this.props.onSave(tenantLocales);
    this.props.onClose();
  }

onCancel = () => {
  this.props.onClose();
  this.setState({
    selection: {},
  });
}

onToggleBulkSelection = () => {
  const select = Object.values(this.state.selection).includes(false);
  const selection = {};

  this.props.tenantLocales.forEach((ul) => { selection[ul.value] = select; });

  this.setState({ selection });
}

onToggleSelection = (ul) => {
  this.setState(({ selection }) => ({
    selection: {
      ...selection,
      [ul.value]: !(selection[ul.value])
    }
  }));
}

renderModalFooter() {
  const isSelected = Object.values(this.state.selection).includes(true);

  return (
    <ModalFooter>
      <Button
        buttonStyle="primary"
        id="save-tenantLocales-btn"
        onClick={this.onSaveAndClose}
        disabled={!isSelected}
      >
        <FormattedMessage id="stripes-core.button.addAndClose" />
      </Button>
      <Button
        onClick={this.onCancel}
      >
        <FormattedMessage id="stripes-core.button.cancel" />
      </Button>
    </ModalFooter>
  );
}

render() {
  const {
    open,
    onClose,
    intl,
    tenantLocales,
  } = this.props;

  const flags = ImportLocalesIcones();

  return (
    <Modal
      footer={this.renderModalFooter()}
      open={open}
      onClose={onClose}
      dismissible
      label={<FormattedMessage id="ui-tenant-settings.settings.locales.addTenantLocales" />}
    >
      <Layout className="textCentered">
        <FormattedMessage
          id="ui-tenant-settings.settings.locales.tenantLocalesFound"
          values={{ count: tenantLocales.length }}
        />
      </Layout>
      <MultiColumnList
        interactive={false}
        contentData={tenantLocales}
        visibleColumns={['selected', 'name', 'defaultDateFormat']}
        columnWidths={{ selected: '5%', name: '70%', defaultDateFormat: '25%' }}
        columnMapping={{
          selected: (
            <Checkbox
              name="selected-all"
              checked={Object.values(this.state.selection).includes(false) !== true}
              onChange={this.onToggleBulkSelection}
            />
          ),
          name: intl.formatMessage({ id: 'ui-tenant-settings.settings.locales.column.label' }),
          defaultDateFormat: intl.formatMessage({ id: 'ui-tenant-settings.settings.locales.column.defaultDateFormat' }),
        }}
        formatter={{
          selected: ul => <Checkbox
            name={`selected-${ul.value}`}
            checked={!!(this.state.selection[ul.value])}
            onChange={() => this.onToggleSelection(ul)}
          />,
          name: ul => <Icon icon={flags[ul.value] ? flags[ul.value] : 'flag'}>{getLocaleLabel(ul.value, intl)}</Icon>,
          defaultDateFormat: ul => ul.defaultDateFormat,
        }}
        isSelected={({ item }) => this.state.selection[item.value]}
      />
    </Modal>
  );
}
}

export default injectIntl(TenantLocalesModal);

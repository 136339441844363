/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  Icon,
  InfoPopover,
  MultiColumnList,
  Tooltip,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { IfPermission } from '@folio/stripes-core';
import Highlighter from 'react-highlight-words';
import { parseInt } from 'lodash';
import { getLocaleLabel } from '../../utils/utils';
import ResultsActions from './ResultsActions';
import EditUserNamesManager from '../EditUserNames/EditUserNamesManager';
import EditTranslationsManager from '../EditTranslations/EditTranslationsManager';
import useQueryParams from '../../Hooks/useQueryParams';

function ResultsPane(props) {
  const intl = useIntl();
  const [openSingleEditModal, setOpenSingleEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { selection, setSelection } = props;

  const [keyWord, setKeyWord] = useQueryParams('keyWord', '');
  const [itemToView, setItemToView] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useQueryParams('count', '50');
  const [currentPage, setcurrentPage] = useQueryParams('page', '1');
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    if (currentPage === '1') setcurrentPage('1');
    if (rowsPerPage === '50') setRowsPerPage('50');
    if (props.contentData.length !== Object.values(selection).length) {
      const selectionData = {};
      props.contentData.forEach((trans) => {
        selectionData[
          props.category === 'usersNames'
            ? trans.translationKey.lastName
            : trans.translationKey
        ] = false;
      });
      setSelection(selectionData);
    }

    const parsedCurrentPage = parseInt(currentPage, 10);
    const parsedRowsPerPage = parseInt(rowsPerPage, 10);

    const indexOfLastData = parsedCurrentPage * parsedRowsPerPage;
    const indexOfFirstData = indexOfLastData - parsedRowsPerPage;
    setCurrentData(props.contentData.slice(indexOfFirstData, indexOfLastData));
  }, [props.contentData, currentPage, rowsPerPage]);

  const onToggleBulkSelection = () => {
    const select = Object.values(selection).includes(false);
    const selectionData = {};

    props.contentData.forEach((trans) => {
      selectionData[
        props.category === 'usersNames'
          ? trans.translationKey.lastName
          : trans.translationKey
      ] = select;
    });
    setSelection(selectionData);
  };

  const onToggleSelection = (trans) => {
    setSelection({
      ...selection,
      [props.category === 'usersNames'
        ? trans.translationKey.lastName
        : trans.translationKey]:
        !selection[
          props.category === 'usersNames'
            ? trans.translationKey.lastName
            : trans.translationKey
        ],
    });
  };

  const renderTranslationsEditModal = () => {
    return props.category !== 'usersNames' ? (
      <EditTranslationsManager
        onClose={() => setOpenSingleEditModal(false)}
        open={openSingleEditModal}
        currentLocale={props.currentLocale}
        item={selectedItem}
        category={props.category}
      />
    ) : (
      <EditUserNamesManager
        onClose={() => setOpenSingleEditModal(false)}
        open={openSingleEditModal}
        currentLocale={props.currentLocale}
        item={selectedItem}
        category={props.category}
      />
    );
  };

  const stringHighlighter = (textToHighlight) => {
    return (
      <Highlighter
        highlightClassName='YourHighlightClass'
        searchWords={[props.searchTerm]}
        autoEscape
        textToHighlight={textToHighlight}
      />
    );
  };

  const actionsFormatter = (item) => {
    return (
      <IfPermission perm='ui-translations.create'>
        <Tooltip
          id='translate-one-tooltip'
          text={
            <FormattedMessage
              id='stripes-smart-components.buttons.tooltip.translate'
              defaultMessage='Translate to {localeName}'
              values={{
                localeName: getLocaleLabel(props.currentLocale, intl),
              }}
            />
          }
        >
          {({ ref, ariaIds }) => (
            <div style={{ paddingRight: '0.25em', paddingLeft: '0.25em' }}>
              <Button
                aria-labelledby={ariaIds.text}
                ref={ref}
                buttonStyle={item.localeTranslation ? 'primary' : 'default'}
                marginBottom0
                id='clickable-translate-one-item'
                onClick={() => {
                  setOpenSingleEditModal(true);
                  setSelectedItem(item);
                }}
                onMouseDown={(e) => e.preventDefault()}
                disabled={Object.values(selection).includes(true)}
              >
                {item.localeTranslation ? (
                  <FormattedMessage id='ui-translations.buttons.editItem' />
                ) : (
                  <FormattedMessage id='ui-translations.buttons.translate' />
                )}
              </Button>
            </div>
          )}
        </Tooltip>
      </IfPermission>
    );
  };

  const translationKeyFormatter = (item) => {
    return props.searchActive === 'true' &&
      props.selectedIndex === 'translationKey' ? (
      stringHighlighter(item.translationKey)
    ) : (
      <InfoPopover content={item.translationKey} icon='key' iconSize='medium' />
    );
  };

  return (
    <>
      <div>
        <ResultsActions
          rowsPerPage={parseInt(rowsPerPage, 10)}
          setRowsPerPage={setRowsPerPage}
          currentPage={parseInt(currentPage, 10)}
          setcurrentPage={setcurrentPage}
          totalCount={props.contentData.length}
          pagesCount={props.contentData.length / rowsPerPage}
          currentData={currentData}
        />
      </div>
      <MultiColumnList
        {...props}
        interactive={false}
        visibleColumns={[
          'rowIndex',
          'selected',
          'translationKey',
          'originTranslation',
          'localeTranslation',
          'Actions',
        ]}
        columnWidths={
          props.searchActive === 'true' &&
          props.selectedIndex === 'translationKey'
            ? {
                selected: '5%',
                rowIndex: '5%',
                translationKey: '30%',
                originTranslation: '20%',
                localeTranslation: '20%',
                Actions: '15%',
              }
            : {
                selected: '5%',
                rowIndex: '5%',
                translationKey: '5%',
                originTranslation: '33%',
                localeTranslation: '35%',
                Actions: '22%',
              }
        }
        columnMapping={{
          rowIndex: '#',
          selected: (
            <Checkbox
              name='selected-all'
              checked={Object.values(selection).includes(false) !== true}
              onChange={onToggleBulkSelection}
              onMouseDown={(e) => e.preventDefault()}
            />
          ),
          translationKey: <Icon icon='key' />,
          Actions: intl.formatMessage({
            id: 'stripes-components.paneMenuActionsToggleLabel',
          }),
          originTranslation: intl.formatMessage({
            id: 'ui-translations.localeTranslations.originTranslation',
          }),
          localeTranslation: intl.formatMessage({
            id: 'ui-translations.localeTranslations.localeTranslation',
          }),
        }}
        contentData={currentData}
        formatter={{
          selected: (trans) => (
            <Checkbox
              name={`selected-${
                props.category === 'usersNames'
                  ? trans.translationKey.lastName
                  : trans.translationKey
              }`}
              checked={
                !!selection[
                  props.category === 'usersNames'
                    ? trans.translationKey.lastName
                    : trans.translationKey
                ]
              }
              onChange={() => {
                onToggleSelection(trans);
                setKeyWord('');
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
          ),
          rowIndex: (item) =>
            props.contentData.findIndex(
              (index) => index.translationKey === item.translationKey,
            ) + 1,
          translationKey: (item) => translationKeyFormatter(item),
          Actions: (item) => actionsFormatter(item),
          originTranslation: (item) =>
            props.searchActive === 'true' &&
            props.selectedIndex === 'originTranslation'
              ? stringHighlighter(item.originTranslation)
              : item.originTranslation,

          localeTranslation: (item) =>
            props.searchActive === 'true' &&
            props.selectedIndex === 'localeTranslation'
              ? stringHighlighter(item.localeTranslation)
              : item.localeTranslation,
        }}
        id='Apps-Translations-Multi-Column-List'
        autosize
        selectedRow={props.contentData.find(
          (key) => key.originTranslation === keyWord,
        )}
      />
      {renderTranslationsEditModal()}
    </>
  );
}

ResultsPane.propTypes = {
  category: PropTypes.string,
  currentLocale: PropTypes.string,
  searchTerm: PropTypes.string,
  selectedIndex: PropTypes.string,
  searchActive: PropTypes.string,
  selection: PropTypes.object,
  setSelection: PropTypes.func,
  contentData: PropTypes.arrayOf(PropTypes.object),
};

export default ResultsPane;

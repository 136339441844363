import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '@folio/stripes-components';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import EditUserNamesForm from './EditUserNamesForm';

function EditUserNamesManager({
  open,
  onClose,
  item,
  currentLocale,
  category,
  stripes,
  resources,
  mutator,
}) {
  const intl = useIntl();
  const onFormSubmit = (values) => {
    const newTrans = {
      [item.translationKey.lastName]: values.lastName,
      [item.translationKey.firstName]: values.firstName,
      [item.translationKey.middleName]: values.middleName,
      [item.translationKey.preferredFirstName]: values.preferredFirstName,
    };

    const record = ((resources.translations || {}).records || []).find(
      (loc) => loc.localeValue === currentLocale && loc.categoryName === category
    );
    mutator.translationId.replace(record.id);
    record.messages = Object.assign(record.messages, newTrans);
    mutator.translations.PUT(record).then(() => {
      onClose();
      if (intl.locale === currentLocale) {
        stripes.setTranslations(newTrans);
      }
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      label={<FormattedMessage id="ui-translations.category.usersNames" />}
    >
      <EditUserNamesForm
        onSubmit={onFormSubmit}
        initialValues={{
          lastName: item?.translatedData?.lastName,
          firstName: item?.translatedData?.firstName,
          middleName: item?.translatedData?.middleName,
          preferredFirstName: item?.translatedData?.preferredFirstName,
        }}
        onClose={onClose}
        item={item}
        currentLocale={currentLocale}
      />
    </Modal>
  );
}

EditUserNamesManager.propTypes = {
  stripes: PropTypes.object,
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  currentLocale: PropTypes.string,
  category: PropTypes.string.isRequired,
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
  mutator: PropTypes.shape({
    translations: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
};

EditUserNamesManager.manifest = {
  translations: {
    type: 'okapi',
    path: 'translations?limit=1000',
    records: 'translations',
    // resourceShouldRefresh: true,
    POST: {
      path: 'translations',
    },
    PUT: {
      path: 'translations',
    },
  },
  translationId: '',
};

export default stripesConnect(withStripes(EditUserNamesManager));

/* kware added new */

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { NavList, NavListSection, NavListItem, Icon, Badge, DropdownMenu, Dropdown } from '@folio/stripes-components';
import { getLocaleLabel, ImportLocalesIcones } from './LocalesServices';
import NavButton from '../NavButton';

const propTypes = {
  stripes: PropTypes.shape({
    setLocale: PropTypes.func,
    setDateformat: PropTypes.func,
    okapi: PropTypes.object,
    locale: PropTypes.string,
    userPreferredLocale: PropTypes.string,
    tenantDefaultLocale: PropTypes.string,
    userLocales: PropTypes.arrayOf(object),
    tenantLocales: PropTypes.arrayOf(object),
  }).isRequired,
};

const UserLocalesMenu = (props) => {
  const intl = useIntl();
  const LocalesIcons = ImportLocalesIcones();

  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const setLocale = (locale) => {
    if (locale) {
      props.stripes.setLocale(locale.value);
      props.stripes.setDateformat(locale.defaultDateFormat);
      toggleDropdown();
    }
  };

  const getDropdownContent = () => {
    const { stripes: { userPreferredLocale, locale, tenantDefaultLocale, showLocaleIcon } } = props;
    let { stripes: { userLocales, tenantLocales } } = props;

    if (userLocales && userLocales.length && (userPreferredLocale !== locale)) {
      userLocales = userLocales.filter(ul => ul.value !== locale);
    }

    if (tenantLocales && tenantLocales.length && (tenantDefaultLocale !== locale)) {
      tenantLocales = tenantLocales.filter(ul => ul.value !== locale);
    }

    return (
      <div>
        <NavList>
          <NavListSection>
            {!userLocales || userLocales.length === 0 ?
              (tenantLocales && tenantLocales.map(tl => (
                <NavListItem
                  id={`clickable-locale-${tl.value}`}
                  type="button"
                  onClick={() => setLocale(tl)}
                  disabled={tl.value === locale}
                >
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: '0.30em' }}>
                    <div>
                      {showLocaleIcon ?
                        <Icon icon={LocalesIcons[tl.value] ? LocalesIcons[tl.value] : 'flag'}>
                          {getLocaleLabel(tl.value, intl)}
                        </Icon>
                        : getLocaleLabel(tl.value, intl)}
                    </div>
                    {(tenantDefaultLocale && (tl.value === tenantDefaultLocale)) &&
                      <div>
                        <Badge><FormattedMessage id="stripes-core.languageSwitcher.tenantDefaultLocale" /></Badge>
                      </div>}
                  </div>
                </NavListItem>
              )))
              : (userLocales && userLocales.map(ul => (
                (ul &&
                <NavListItem
                  id={`clickable-locale-${ul.value}`}
                  type="button"
                  onClick={() => setLocale(ul)}
                  disabled={ul.value === locale}
                >
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', padding: '0.30em' }}>
                    <div>
                      {showLocaleIcon ?
                        <Icon icon={LocalesIcons[ul.value] ? LocalesIcons[ul.value] : 'flag'}>
                          {getLocaleLabel(ul.value, intl)}
                        </Icon>
                        : getLocaleLabel(ul.value, intl)}
                    </div>
                    {(userPreferredLocale && (ul.value === userPreferredLocale)) &&
                    <div>
                      <Icon icon={LocalesIcons.star} />
                    </div>}
                    {(tenantDefaultLocale && (ul.value === tenantDefaultLocale)) &&
                    <div>
                      <Badge><FormattedMessage id="stripes-core.languageSwitcher.tenantDefaultLocale" /></Badge>
                    </div>}
                  </div>
                </NavListItem>)
              )))
        }
          </NavListSection>
        </NavList>
      </div>
    );
  };

  const renderLocalesTrigger = ({ getTriggerProps, open }) => {
    const { stripes: { locale, showLocaleIcon } } = props;

    const labelElement = (
      <Icon iconPosition="end" icon={open ? 'caret-up' : 'caret-down'}>
        {locale.split('-')[0]}
      </Icon>
    );

    const icon = (showLocaleIcon ? LocalesIcons[locale] ? <Icon icon={LocalesIcons[locale]} /> : <Icon icon="flag" /> : null);

    return (
      <FormattedMessage id="stripes-core.mainnav.myProfileAriaLabel">
        {label => (
          <NavButton
            label={labelElement}
            data-role="toggle"
            ariaLabel={label}
            selected={open}
            icon={icon}
            navbar="lib"
            {...getTriggerProps()}
          />)
        }
      </FormattedMessage>
    );
  };

  const renderLocalesMenu = ({ open }) => (
    <DropdownMenu open={open}>
      {getDropdownContent()}
    </DropdownMenu>
  );


  return (
    <>
      <Dropdown
        id="userLanguageSwitcherDropdown"
        renderTrigger={renderLocalesTrigger}
        renderMenu={renderLocalesMenu}
        open={DropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        usePortal={false}
        relativePosition
        disabled={!props.stripes.tenantLocales}
        focusHandlers={{ open: () => null }}
      />
    </>);
};

UserLocalesMenu.propTypes = propTypes;

export default UserLocalesMenu;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { branding, okapi } from 'stripes-config';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TextField,
  Button,
  Row,
  Col,
  Headline,
  Select
} from '@folio/stripes-components';
import { Field } from 'react-final-form';
import styles from './Login.css';
import LoginLocalesDropdowen from '../MainNav/UserLocalesSwitcher/LoginLocalesDropdowen/LoginLocalesDropdowen';
import OrganizationLogo from '../OrganizationLogo';
import SSOLogin from '../SSOLogin';
import FieldLabel from '../CreateResetPassword/components/FieldLabel';
import AuthErrorsContainer from '../AuthErrorsContainer';

function Login({
  pristine,
  submitting,
  handleSubmit,
  submitSucceeded,
  form,
  ssoActive,
  authErrors,
  handleSSOLogin,
  checkSSOStatusByTenantName
}) {
  const intl = useIntl();
  const [tenantList, setTenantList] = useState([]);

  useEffect(() => {
    fetch(`${okapi.url}/_/proxy/tenants`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then((res) => res.json())
      .then((list) => {
        setTenantList(list.filter((li) => li.name !== 'supertenant'));
      });
  }, []);

  useEffect(() => {
    checkSSOStatusByTenantName();
  }, [form.getState().values.tenant]);

  const getTenantDataOptions = () => {
    return [
      ...tenantList.map((listItem) => ({
        label: intl.formatMessage({
          id: `stripes-core.tenant.label.${listItem.id}`
        }),
        value: listItem.id
      }))
    ];
  };

  const required = (value) =>
    value ? undefined : (
      <strong>
        <FormattedMessage
          id="ui-translations.validation.required"
          defaultMessage="required"
        />
      </strong>
    );

  return (
    <main>
      <div className={styles.wrapper} style={branding.style?.login ?? {}}>
        <div
          style={{
            alignItems: 'flex-end'
          }}
        >
          <LoginLocalesDropdowen tenantName={form.getState().values.tenant} />
        </div>
        <div className={styles.container}>
          <Row center="xs">
            <Col xs={6}>
              <OrganizationLogo tenant={form.getState().values.tenant} />
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={6}>
              <div className={styles.formGroup}>
                {ssoActive && <SSOLogin handleSSOLogin={handleSSOLogin} />}
              </div>
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={6}>
              <Headline size="xx-large" tag="h1" data-test-h1 />
            </Col>
          </Row>
          <Row>
            <form
              className={styles.form}
              onSubmit={(data) =>
                handleSubmit(data).then(() =>
                  form.change('password', undefined)
                )
              }
            >
              <div data-test-tenant-name-field>
                <Row center="xs">
                  <Col xs={6}>
                    <Row between="xs" bottom="xs">
                      <Col>
                        <FieldLabel htmlFor="menu-tenantList">
                          <FormattedMessage id="stripes-core.tenant.label" />
                        </FieldLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row center="xs">
                  <Col xs={6}>
                    <Field
                      id="menu-tenantList"
                      name="tenant"
                      dataOptions={getTenantDataOptions()}
                      component={Select}
                      placeholder={intl.formatMessage({
                        id: 'stripes-core.tenant.placeholder'
                      })}
                      selectClass={styles.select}
                      marginBottom0
                      fullWidth
                      autoFocus
                      required
                    />
                  </Col>
                </Row>
              </div>
              <div data-test-new-username-field>
                <Row center="xs">
                  <Col xs={6}>
                    <Row between="xs" bottom="xs">
                      <Col>
                        <FieldLabel htmlFor="input-username">
                          <FormattedMessage id="stripes-core.username" />
                        </FieldLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row center="xs">
                  <Col xs={6}>
                    <Field
                      id="input-username"
                      name="username"
                      type="text"
                      component={TextField}
                      inputClass={styles.input}
                      autoComplete="username"
                      autoCapitalize="none"
                      validationEnabled={false}
                      hasClearIcon={false}
                      marginBottom0
                      fullWidth
                      autoFocus
                      required
                      //   validate={required}
                    />
                  </Col>
                </Row>
              </div>
              <div data-test-new-username-field>
                <Row center="xs">
                  <Col xs={6}>
                    <Row between="xs" bottom="xs">
                      <Col>
                        <FieldLabel htmlFor="input-password">
                          <FormattedMessage id="stripes-core.password" />
                        </FieldLabel>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row center="xs">
                  <Col xs={6}>
                    <Field
                      id="input-password"
                      component={TextField}
                      name="password"
                      type="password"
                      value=""
                      marginBottom0
                      fullWidth
                      inputClass={styles.input}
                      validationEnabled={false}
                      hasClearIcon={false}
                      autoComplete="current-password"
                      required
                      //   validate={required}
                    />
                  </Col>
                </Row>
              </div>
              <Row center="xs">
                <Col xs={6}>
                  <div className={styles.formGroup}>
                    <Button
                      buttonStyle="primary"
                      id="clickable-login"
                      type="submit"
                      buttonClass={styles.submitButton}
                      disabled={
                        submitting ||
                        pristine ||
                        !form.getState().values.username ||
                        !form.getState().values.password
                      }
                      fullWidth
                      marginBottom0
                    >
                      <FormattedMessage
                        id={`stripes-core.${
                          submitting || submitSucceeded ? 'loggingIn' : 'login'
                        }`}
                      />
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className={styles.linksWrapper} center="xs">
                <Col xs={6}>
                  <Row between="xs">
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      data-test-new-forgot-password-link
                    >
                      <Button
                        to="/forgot-password"
                        buttonClass={styles.link}
                        type="button"
                        buttonStyle="link"
                      >
                        <FormattedMessage id="stripes-core.button.forgotPassword" />
                      </Button>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      data-test-new-forgot-username-link
                    >
                      <Button
                        to="/forgot-username"
                        buttonClass={styles.link}
                        type="button"
                        buttonStyle="link"
                      >
                        <FormattedMessage id="stripes-core.button.forgotUsername" />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row center="xs">
                <Col xs={6}>
                  <div className={styles.authErrorsWrapper}>
                    <AuthErrorsContainer errors={authErrors} />
                  </div>
                </Col>
              </Row>
            </form>
            {ssoActive && <form id="ssoForm" />}
          </Row>
        </div>
      </div>
    </main>
  );
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
  ssoActive: PropTypes.bool,
  authErrors: PropTypes.arrayOf(PropTypes.object),
  submitSucceeded: PropTypes.bool,
  handleSSOLogin: PropTypes.func.isRequired,
  checkSSOStatusByTenantName: PropTypes.func
};

Login.defaultProps = {
  authErrors: [],
  ssoActive: false
};

export default stripesFinalForm({
  mutators: {},
  validateOnBlur: true,
  subscription: {
    values: true
  }
})(Login);

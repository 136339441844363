/* eslint-disable react/sort-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';

const WithLocaleDateFormat = WrappedComponent => class WithLocaleDateFormatComponent extends React.Component {
    static manifest = {
      ...WrappedComponent.manifest,
      recordId: {},
      locales: {
        type: 'okapi',
        path: 'locales?limit=1000',
        records: 'locales',
      },
    };

    static propTypes = {
      parentResources: PropTypes.object,
      resources: PropTypes.shape({
        locales: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
      mutator: PropTypes.shape({
        locales: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
        }),
      }),
      stripes: PropTypes.object.isRequired,
    };

    state = {
      locales: [],
    };

    static getDerivedStateFromProps(props, state) {
      const locales = get(props.resources.locales, ['records'], []);

      if (!isEqual(locales, state.locales)) {
        return {
          locales,
        };
      }

      return null;
    }

    getDefaultDateFormat = (locale) => {
      const defaultDateFormat = this.state.locales.filter(loc => loc.value === locale);
      return get(defaultDateFormat, [0, 'defaultDateFormat'], undefined);
    };

    render() {
      return (
        <>
          <WrappedComponent
            getDefaultDateFormat={this.getDefaultDateFormat}
            {...this.props}
          />
        </>
      );
    }
};

export default WithLocaleDateFormat;

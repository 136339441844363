/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Col,
  Icon,
  Label,
  Row,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function ResultsActions({
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  setcurrentPage,
  totalCount,
  pagesCount,
}) {
  const renderRowsPerPageSelect = () => {
    const dataOptions = [
      {
        label: 20,
        value: 20,
        disabled: totalCount < 20
      },
      {
        label: 50,
        value: 50,
        disabled: totalCount < 50
      },
      {
        label: 70,
        value: 70,
        disabled: totalCount < 70
      },
      {
        label: 100,
        value: 100,
        disabled: totalCount < 100
      },
      {
        label: 120,
        value: 120,
        disabled: totalCount < 120
      },
      {
        label: 150,
        value: 150,
        disabled: totalCount < 150
      },
      {
        label: 170,
        value: 170,
        disabled: totalCount < 170
      },
      {
        label: 200,
        value: 200,
        disabled: totalCount < 200
      },
    ];

    return (
      <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
        <Badge size="small">
          <FormattedMessage id="ui-translations.ResultsPane.rowsPerpage.info" />
        </Badge>
        <Select
          id="rows-per-page-select"
          marginBottom0
          value={rowsPerPage}
          dataOptions={dataOptions}
          onChange={(e) => setRowsPerPage(e.target.value)}
          disabled={totalCount < 20}
        />
      </div>
    );
  };

  const renderShowNowBadge = () => {
    const pageIndex = currentPage * rowsPerPage;
    const startPage = currentPage === 1 ? 1 : pageIndex - rowsPerPage + 1;
    const lastPage = pageIndex >= totalCount ? totalCount : pageIndex;
    return (
      <div>
        <div style={{ display: 'flex', flexFlow: 'row' }}>
          <Badge marginBottom0 size="small">
            <FormattedMessage
              id="ui-translations.ResultsPane.page.info"
              values={{ startPage: totalCount < 1 ? 0 : startPage, lastPage, totalCount }}
            />
          </Badge>
        </div>
      </div>
    );
  };

  const renderCurrentPageButtons = () => {
    return (
      <div>
        <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
          <Button
            buttonStyle="slim"
            marginBottom0
            id="clickable-prev-page-result"
            onClick={() => setcurrentPage((currentPage - 1).toString())}
            onMouseDown={(e) => e.preventDefault()}
            disabled={currentPage === 1}
          >
            <Icon icon="caret-left" size="small" />
            {/* <FormattedMessage
              id="ui-translations.ResultsPane.page.prevBtn.label"
            /> */}
          </Button>
          <Badge size="small">
            <FormattedMessage
              id="ui-translations.ResultsPane.page.label"
              values={{ currentPage }}
            />
          </Badge>
          <Button
            buttonStyle="slim"
            marginBottom0
            id="clickable-next-page-result"
            onClick={() => setcurrentPage((currentPage + 1).toString())}
            onMouseDown={(e) => e.preventDefault()}
            disabled={currentPage >= pagesCount}
          >
            {/* <FormattedMessage
              id="ui-translations.ResultsPane.page.nextBtn.label"
            /> */}
            <Icon icon="caret-right" size="small" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
      }}
    >
      {renderShowNowBadge()}
      {renderCurrentPageButtons()}
      {renderRowsPerPageSelect()}
    </div>
  );
}

ResultsActions.propTypes = {};

export default ResultsActions;

import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { Button, Pane, PaneFooter, Paneset } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import UserLocales from './components/UserLocales';
import UserPreferredLocale from './components/UserPreferredLocale';
import UserNumbersShape from './components/UserNumbersShape';
import UserDateTimeFormats from './components/UserDateTimeFormats';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

function UserLocalesForm({ pristine, submitting, handleSubmit, form }) {
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={(
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-usertLocales"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
              )}
        renderStart={(
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
              )}
      />
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-user-locales-form"
          paneTitle={<FormattedMessage id="ui-myprofile.settings.userLocales.label" />}
        >
          <form
            id="form-user-locales"
            onSubmit={handleSubmit}
          >
            <UserLocales
              userPreferredLocale={form.getState().values.userPreferredLocale}
            />
            <UserPreferredLocale
              userLocales={form.getState().values.userLocales}
            />
            <UserNumbersShape
              userLocales={form.getState().values.userLocales}
            />
            <UserDateTimeFormats />
          </form>
        </Pane>
      </Paneset>
    </>
  );
}

UserLocalesForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  validateOnBlur: true,
})(UserLocalesForm);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';

import {
  Button,
  Col,
  KeyValue,
  Modal,
  ModalFooter,
  Row,
} from '@folio/stripes/components';
/** kware start edit */
import { getFullNameValue } from '@folio/stripes/core';
/** kware end edit */
import { getPatronName } from '../../util';
import {
  ACTUAL_COST_CONFIRM_MODAL_DEFAULT,
  ACTUAL_COST_DEFAULT,
  ACTUAL_COST_RECORD_FIELD_NAME,
  ACTUAL_COST_RECORD_FIELD_PATH,
  ACTUAL_COST_TYPES,
  ACTUAL_COST_PROP_TYPES,
  DEFAULT_VALUE,
} from '../../../../../constants';

import css from './ActualCostConfirmModal.css';

const ActualCostConfirmModal = ({
  setActualCostModal,
  actualCostConfirmModal,
  setActualCostConfirmModal,
  actualCost,
  setActualCost,
  billRecord,
  cancelRecord,
}) => {
  const { isOpen } = actualCostConfirmModal;
  /** kware start edit */
  const intl = useIntl();
  /** kware end edit */
  const {
    type,
    actualCostRecord,
    additionalInfo: {
      actualCostToBill,
      additionalInformationForStaff,
      additionalInformationForPatron,
    },
  } = actualCost;
  const patronName = getPatronName(actualCostRecord);
  const instanceTitle = get(
    actualCostRecord,
    ACTUAL_COST_RECORD_FIELD_PATH[ACTUAL_COST_RECORD_FIELD_NAME.INSTANCE_TITLE],
    DEFAULT_VALUE
  );
  const materialType = get(
    actualCostRecord,
    ACTUAL_COST_RECORD_FIELD_PATH[ACTUAL_COST_RECORD_FIELD_NAME.MATERIAL_TYPE],
    DEFAULT_VALUE
  );
  const isBillType = type === ACTUAL_COST_TYPES.BILL;
  const onKeepEditing = () => {
    setActualCostConfirmModal(ACTUAL_COST_CONFIRM_MODAL_DEFAULT);
    setActualCostModal({
      isOpen: true,
    });
  };
  const onConfirm = () => {
    if (isBillType) {
      billRecord(actualCost);
    } else {
      cancelRecord(actualCost);
    }
    setActualCostConfirmModal(ACTUAL_COST_CONFIRM_MODAL_DEFAULT);
    setActualCost(ACTUAL_COST_DEFAULT);
  };
  const renderFooter = (
    <ModalFooter>
      <Button
        id="confirmActualCost"
        data-testid="confirmActualCost"
        buttonStyle="primary"
        onClick={onConfirm}
      >
        <FormattedMessage id="ui-users.lostItems.modal.button.confirm" />
      </Button>
      <Button
        id="keepEditingActualCost"
        data-testid="keepEditingActualCost"
        onClick={onKeepEditing}
      >
        <FormattedMessage id="ui-users.lostItems.modal.button.keepEditing" />
      </Button>
    </ModalFooter>
  );
  const getTitle = () => (isBillType ? (
    <FormattedMessage
      id="ui-users.lostItems.modal.bill.confirm.title"
        // values={{ patronName }}
        /** kware start edit */
      values={{ patronName: getFullNameValue(actualCostRecord?.user, intl) }}
    />
    /** kware end edit */
  ) : (
    <FormattedMessage
      id="ui-users.lostItems.modal.doNotBill.confirm.title"
        // values={{ patronName }}
        /** kware start edit */
      values={{ patronName: getFullNameValue(actualCostRecord?.user, intl) }}
    />
  ));

  return (
    <Modal
      id="actualCost"
      data-testid="actualCost"
      label={getTitle()}
      dismissible
      open={isOpen}
      onClose={onKeepEditing}
      footer={renderFooter}
    >
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <p className={css.breakLongText}>
                {isBillType && (
                  <FormattedMessage
                    id="ui-users.lostItems.modal.bill.confirm.text"
                    values={{
                      actualCostToBill,
                      // patronName,
                      /** kware start edit */
                      patronName: getFullNameValue(
                        actualCostRecord?.user,
                        intl
                      ),
                      materialType: (
                        <FormattedMessage
                          id={`ui-inventory.materialTypes.name.${materialType}`}
                          defaultMessage={materialType}
                        />
                      ),
                      /** kware end edit */
                      instanceTitle,
                      // materialType,
                    }}
                  />
                )}
                {type === ACTUAL_COST_TYPES.DO_NOT_BILL && (
                  <FormattedMessage
                    id="ui-users.lostItems.modal.doNotBill.confirm.text"
                    values={{
                      // patronName,
                      /** kware start edit */
                      patronName: getFullNameValue(
                        actualCostRecord?.user,
                        intl
                      ),
                      materialType: (
                        <FormattedMessage
                          id={`ui-inventory.materialTypes.name.${materialType}`}
                          defaultMessage={materialType}
                        />
                      ),
                      /** kware end edit */
                      instanceTitle,
                      // materialType,
                    }}
                  />
                )}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <br />
          <KeyValue
            label={
              <FormattedMessage id="ui-users.lostItems.modal.additionalInformationForStaff" />
            }
            value={additionalInformationForStaff}
          />
        </Col>
      </Row>
      {isBillType && (
        <Row>
          <Col xs={12}>
            <br />
            <KeyValue
              label={
                <FormattedMessage id="ui-users.lostItems.modal.additionalInformationForPatron" />
              }
              value={additionalInformationForPatron}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

ActualCostConfirmModal.propTypes = {
  setActualCostModal: PropTypes.func.isRequired,
  actualCostConfirmModal: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
  }),
  setActualCostConfirmModal: PropTypes.func.isRequired,
  actualCost: ACTUAL_COST_PROP_TYPES,
  setActualCost: PropTypes.func.isRequired,
  billRecord: PropTypes.func.isRequired,
  cancelRecord: PropTypes.func.isRequired,
};

export default ActualCostConfirmModal;

/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  Col,
  Icon,
  MultiColumnList,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import css from './style.css';

const propTypes = {};

function ExportPreview(props) {
  const intl = useIntl();

  const renderExportPreview = () => {
    return (
      <Col xs={9}>
        <Card
          id="exportTranslations-preview-card"
          cardStyle="positive"
          roundedBorder
          headerStart={
            <span className={css.cardHeader}>
              <Icon icon="preview">
                <FormattedMessage id="ui-translations.exportTranslations.cardHeader.preview" />
              </Icon>
            </span>
          }
          headerEnd={
            <FormattedMessage
              id="ui-translations.exportTranslations.cardPreview.noOfTranslations"
              values={{
                count: <Badge>{Object.keys(props.contentData).length}</Badge>,
              }}
            />
          }
        >
          <MultiColumnList
            interactive={false}
            contentData={props.contentData}
            visibleColumns={String(props.PreviewColumns).split('+')}
            columnWidths={{
              translationKey: '50%',
              originTranslation: '50%',
              localeTranslation: '50%',
            }}
            columnMapping={{
              translationKey: intl.formatMessage({
                id: 'ui-translations.settings.keyName',
              }),
              originTranslation: intl.formatMessage({
                id: 'ui-translations.localeTranslations.originTranslation',
              }),
              localeTranslation: intl.formatMessage({
                id: 'ui-translations.localeTranslations.localeTranslation',
              }),
            }}
            id="Apps-renderExportPreview-Multi-Column-List"
            // autosize
            maxHeight={500}
            // virtualize
            // pageAmount={100}
            // totalCount={props.contentData.length}
            // pagingType="click"
          />
        </Card>
      </Col>
    );
  };

  return <>{renderExportPreview()}</>;
}

ExportPreview.propTypes = propTypes;

export default ExportPreview;

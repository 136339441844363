import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isEqual, sortBy } from 'lodash';

import { AppIcon, stripesConnect } from '@folio/stripes-core';
import { Button, Icon, Pane, Row } from '@folio/stripes/components';
import DashboardsActionsMenu from './DashboardsActionsMenu';
import DashboardsMenu from './DashboardsMenu';
import DashboardsContext from '../../../Context/dashboards/DashboardsContext';
import AppConfigListContext from '../../../Context/appConfigList/AppConfigListContext';
import css from './style.css';
import Widget from '../widgetForm/components/Widget';

function DashboardWidgets(props) {
  const dashboards = useContext(DashboardsContext);
  const defaultDashboard = useContext(AppConfigListContext);
  const [currentDashboard, setCurrentDashboard] = useState({});

  const [widgetsList, setWidgetsList] = useState([]);

  useEffect(() => {
    if (defaultDashboard?.defaultDashboard?.configValue) {
      setCurrentDashboard(defaultDashboard?.defaultDashboard?.configValue);
    }
  }, [defaultDashboard]);

  useEffect(() => {
    const nextWidgets = sortBy((props.resources.widgets || {}).records || [], [
      '',
    ])?.filter((widget) => widget.dashboardId === currentDashboard.id);

    if (!isEqual(nextWidgets, widgetsList)) {
      setWidgetsList(nextWidgets);
    }
  }, [currentDashboard, props.resources.widgets, widgetsList]);

  const onDeleteWidget = (widgetId) => {
    props.mutator.widgets.DELETE({
      id: widgetId,
    });
  };

  return (
    <>
      <Pane
        id="pane-dashboard-widgets"
        // defaultWidth="fill"
        appIcon={<AppIcon app="reports" />}
        // fluidContentWidth
        // noOverflow
        padContent={false}
        paneTitle={
          <DashboardsMenu
            dataOptions={dashboards}
            currentDashboard={currentDashboard}
            setCurrentDashboard={setCurrentDashboard}
          />
        }
        // paneSub={
        //   <FormattedMessage
        //     id="stripes-smart-components.searchResultsCountHeader"
        //     values={{
        //       count: widgetsList?.length
        //     }}
        //   />
        // }
        // firstMenu={
        //   <DashboardsMenu
        //     dataOptions={dashboards}
        //     currentDashboard={currentDashboard}
        //     setCurrentDashboard={setCurrentDashboard}
        //   />
        // }
        lastMenu={<DashboardsActionsMenu currentDashboard={currentDashboard} />}
      >
        {!widgetsList.length ? (
          <div className={css.emptyList}>
            <div style={{ paddingBottom: '10px' }}>
              <FormattedMessage
                id="ui-reports.dashboards.widgets.noWidgets.message"
                defaultMessage="No widgets found!"
              />
            </div>
            <Button
              marginBottom0
              id="dashboards-widgets-list-new-widget-btn"
              //   buttonStyle="primary"
              to={{
                pathname: `/reports/dashboards/${currentDashboard.name}/widgets/create`,
              }}
              // onMouseDown={(e) => e.preventDefault()}
            >
              <Icon icon="plus-sign">
                <FormattedMessage
                  id="ui-reports.dashboards.widgets.addNewWidget.btn"
                  defaultMessage="Create new widget"
                />
              </Icon>
            </Button>
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <Row>
              {widgetsList?.map((widget, index) => (
                <Widget
                  widget={widget}
                  index={index}
                  onDeleteWidget={onDeleteWidget}
                />
              ))}
            </Row>
          </div>
        )}
      </Pane>
    </>
  );
}

DashboardWidgets.propTypes = {
  resources: PropTypes.shape({
    widgets: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  mutator: PropTypes.shape({
    widgets: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
      DELETE: PropTypes.func.isRequired,
    }),
  }),
};

DashboardWidgets.manifest = Object.freeze({
  widgets: {
    type: 'okapi',
    records: 'widgets',
    path: 'widgets?limit=10000',
    PUT: {
      path: 'widgets',
    },
    DELETE: {
      path: 'widgets',
    },
  },
});

export default stripesConnect(DashboardWidgets);

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Icon, Layout, Loading } from '@folio/stripes/components';
import { useCubeQuery } from '@cubejs-client/react';
import css from './widget.css';
import WidgetActionsMenu from './WidgetActionsMenu';
import ReportsContext from '../../../../Context/reports/ReportsContext';
import DisplayMethodSwitcher from '../../../queryResultsPreview/DisplayMethodSwitcher';

function Widget({ widget, index, onDeleteWidget }) {
  const reports = useContext(ReportsContext);

  const [reportData, setReportData] = useState({});

  const { resultSet, error, isLoading } = useCubeQuery(
    widget?.queriesMetadata[0],
    {
      subscribe: true,
    }
  );

  useEffect(() => {
    const report = reports.find((rep) => rep.id === widget.reportsIds[0]);
    setReportData(report);
  }, [reports, widget.reportsIds]);

  console.log(reportData);

  if (error) {
    return <pre>{error.toString()}</pre>;
  }

  return (
    <>
      <Col xs={6}>
        <Card
          id={`card-new-widget-${index}`}
          cardClass={css.cardClass}
          headerClass={css.headerClass}
          // bodyClass={css.bodyClass}
          roundedBorder
          headerStart={<Icon icon="report">{widget?.name}</Icon>}
          headerEnd={
            <WidgetActionsMenu
              onDeleteWidget={onDeleteWidget}
              widget={widget}
            />
          }
          marginBottom0
        >
          {isLoading ? (
            <Layout
              className="centered full"
              style={{ maxWidth: '15rem', height: '8rem' }}
            >
              &nbsp;
              <Loading size="xlarge" />
            </Layout>
          ) : (
            <DisplayMethodSwitcher
              displayMethod={reportData?.defaultDisplayMethod}
              resultSet={resultSet || []}
              reportType={reportData.reportType}
            />
          )}
        </Card>
      </Col>
    </>
  );
}

Widget.propTypes = {
  widget: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onDeleteWidget: PropTypes.func.isRequired,
};

export default Widget;

/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Select } from '@folio/stripes-components';
import DateFormats from '../utils/DateFormats';

const required = value =>
  value ? (
    undefined
  ) : (
    <strong>
      <FormattedMessage id="stripes-smart-components.TranslationsModal.validation.required" defaultMessage="required" />
    </strong>
  );

function UserDateTimeFormats(props) {
  const intl = useIntl();

  const dataOptions = [
    ...DateFormats.map(option => ({ label: option.label, value: option.value, disabled: option.disabled }
    ))
  ];

  const timeformatList = [
    {
      value: 'h:mm A',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.timeformatList.12Hour',
        defaultMessage: '12 Hour',
      }),
    },
    {
      value: 'HH:mm',
      label: intl.formatMessage({
        id: 'ui-tenant-settings.settings.tenantLocalizations.timeformatList.24Hour',
        defaultMessage: '24 Hour',
      }),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={12} id="select-time-format">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.locales.column.defaultDateFormat" />}
            component={Select}
            id="dateformat"
            name="dateformat"
            placeholder="---"
            dataOptions={dataOptions}
            required
            validate={required}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="select-time-format">
          <Field
            label={<FormattedMessage id="ui-tenant-settings.settings.timeformat" defaultMessage="Time format:" />}
            component={Select}
            id="timeformat"
            name="timeformat"
            placeholder="---"
            dataOptions={timeformatList}
            required
            validate={required}
          />
        </Col>
      </Row>
    </>
  );
}

UserDateTimeFormats.propTypes = {};

export default UserDateTimeFormats;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layout,
  Loading,
  Pane,
  PaneFooter,
  Tooltip,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import { useCubeQuery } from '@cubejs-client/react';
import NewReportActions from '../actionsMenus/NewReportActions';
import DisplayMethodsMenu from '../queryResultsPreview/DisplayMethodsMenu';
import ErrorMessage from '../common/errorMessage/ErrorMessage';
import DisplayMethodSwitcher from '../queryResultsPreview/DisplayMethodSwitcher';

function QueryResultSet(props) {
  const { resultSet, error, isLoading } = useCubeQuery(
    props.currentReport?.queryMetadata || {},
    {
      subscribe: true,
    }
  );

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <>
            <Button
              // buttonStyle="primary"
              id="clickable-closeNoSaveBtn-reports-"
              marginBottom0
              onClick={props.handleClose}
            >
              <FormattedMessage
                id="ui-reports.newReport.reportPreviewPane.closeBtn"
                defaultMessage="Close"
              />
            </Button>
          </>
        }
      />
    );
  };

  const renderFirstMenu = () => {
    return (
      <>
        {!props.showFiltersPane ? (
          <Tooltip
            id="report-preview-pane-tooltip-show-query-builder"
            text={
              <FormattedMessage
                id="ui-reports.newReport.reportPreviewPane.tooltip.showQueryBuilderPane"
                defaultMessage="Show query filters panel"
              />
            }
          >
            {({ ref, ariaIds }) => (
              <Button
                buttonStyle="primary"
                id="report-preview-pane-showQueryBuilder-btn"
                marginBottom0
                onClick={() => props.setShowFiltersPane(!props.showFiltersPane)}
                aria-labelledby={ariaIds.text}
                ref={ref}
              >
                {/* <Icon icon="chevron-double-right" size="large" /> */}
                <FormattedMessage
                  id="ui-reports.queryFilters.showFilterBtn"
                  defaultMessage="Filters"
                />
              </Button>
            )}
          </Tooltip>
        ) : (
          <div />
        )}
        {props.currentReport?.displayMethods && (
          <DisplayMethodsMenu
            displayMethods={props.currentReport?.displayMethods}
            currentDisplayMethod={props.displayMethod}
            setCurrentDisplayMethod={props.setDisplayMethod}
          />
        )}
      </>
    );
  };

  const renderResultSet = () => {
    if (error) {
      return <ErrorMessage errorMessage={error.toString()} />;
    }

    return (
      <>
        {isLoading ? (
          <Layout
            className="centered full"
            style={{ maxWidth: '15rem', height: '8rem' }}
          >
            &nbsp;
            <Loading size="xlarge" />
          </Layout>
        ) : (
          <DisplayMethodSwitcher
            displayMethod={props.displayMethod}
            resultSet={resultSet || []}
            reportType={props.currentReport?.reportType}
          />
        )}
      </>
    );
  };

  return (
    <>
      <Pane
        appIcon={<Icon icon="preview" size="large" />}
        fluidContentWidth
        noOverflow
        firstMenu={renderFirstMenu()}
        lastMenu={<NewReportActions />}
        footer={renderPaneFooter()}
        id="pane-report-resultset"
        centeredContent
        paneTitle={props.currentReport?.name}
        paneSub={
          <FormattedMessage
            id="stripes-smart-components.searchResultsCountHeader"
            values={{
              count:
                !isLoading && resultSet
                  ? resultSet?.loadResponses[0]?.data?.length
                  : 0,
            }}
          />
        }
      >
        {renderResultSet()}
      </Pane>
    </>
  );
}

QueryResultSet.propTypes = {
  handleClose: PropTypes.func,
  showFiltersPane: PropTypes.bool,
  setShowFiltersPane: PropTypes.func,
  currentReport: PropTypes.object,
  displayMethod: PropTypes.string,
  setDisplayMethod: PropTypes.func,
};

export default QueryResultSet;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Pane, PaneFooter, Paneset } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function GlossarySettings(props) {
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            // disabled={pristine || submitting}
            disabled
            id="clickable-tenantLocales"
            marginBottom0
            type="submit"
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            // disabled={pristine || submitting}
            disabled
            id="clickable-cancel"
            marginBottom0
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };
  return (
    <>
      <Paneset>
        <Pane
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-translation-languages-form"
          paneTitle={
            <FormattedMessage id="ui-translations.settings.glossarySettings.lable" />
          }
        >
          <FormattedMessage id="ui-translations.settings.glossarySettings.lable" />
        </Pane>
      </Paneset>
    </>
  );
}

GlossarySettings.propTypes = {};

export default GlossarySettings;

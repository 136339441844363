/* eslint-disable no-sequences */
/* eslint-disable import/no-extraneous-dependencies */

import { createIntlCache } from '@formatjs/intl';
import { get, isNaN } from 'lodash';
import { createIntl } from 'react-intl';

export function getFullName(user) {
  const lastName = get(user, 'personal.lastName', '');
  const firstName = get(user, 'personal.firstName', '');
  const middleName = get(user, 'personal.middleName', '');

  return `${lastName}${firstName ? ', ' : ' '}${firstName}${
    middleName ? ' ' : ''
  }${middleName}`;
}

export const getLocaleLabel = (localeValue, intl) => {
  const cache = createIntlCache();
  const locale = { intl: createIntl({
    locale: localeValue,
    messages: {}
  }, cache) };

  return intl.formatDisplayName(localeValue, { type: 'language' }) !== undefined ?
    `${intl.formatDisplayName(localeValue, { type: 'language' })} - ${locale.intl.formatDisplayName(localeValue, { type: 'language' })}` :
    intl.formatMessage({ id: `stripes-core.ul.button.languageName.${localeValue}` });
};

export const ImportLocalesIcones = () => {
  const req = require.context('!!react-svg-loader!./localesIcons/', true, /\.svg$/);
  return req.keys().reduce((images, path) => Object.assign(images, {
    [path.slice(2, path.length - 4)]: req(path).default,
  }), {});
};

export const CSVToJSON = (data, delimiter = ',') => {
  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
  return data
    .slice(data.indexOf('\n') + 1)
    .split('\n')
    .map(v => {
      const values = v.split(delimiter);
      // eslint-disable-next-line no-return-assign
      return titles.reduce((obj, title, index) => ((obj[title] = values[index]), obj), {});
    });
};

export const CSVToArray = (data, delimiter = ',', omitFirstRow = false) => data
  .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
  .split('\n')
  .map(v => v.split(delimiter));

export const arrayToCSV = (arr, delimiter = ',') => arr
  .map(v => v.map(x => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(delimiter))
  .join('\n');


export const JSONtoCSV = (arr, columns, delimiter = ',') => [
  columns.join(delimiter),
  ...arr.map(obj => columns.reduce(
    (acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`,
    ''
  )),
].join('\n');

export const exportFile = (filename, data, fileType) => {
  const blob =
    fileType === 'json'
      ? new Blob([data], { type: 'application/json' })
      : new Blob([data], { type: 'text/csv' });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const groupArrayByKey = (array, propName) => {
  return array.reduce((acc, obj) => {
    const key = obj[propName];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewMetaData } from '@folio/stripes/smart-components';
import { sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import TranslationWrapper from '../components/TranslationWrapper/TranslationWrapper';
import useQueryParams from '../Hooks/useQueryParams';
import WithTranslations from '../components/Wrappers/WithTranslations';
import CurrentLocaleContext from '../Context/CurrentLocale/CurrentLocaleContext';

const manifest = Object.freeze({
  permissions: {
    type: 'okapi',
    records: 'permissions',
    path: (queryParams, pathComponents, resourceData, config, props) => {
      const {
        stripes: {
          config: { listInvisiblePerms },
        },
      } = props;
      const query = [
        ...(listInvisiblePerms ? [] : ['visible==true']),
        ...['mutable==false'],
      ];
      const queryString = query.length ? `query=(${query.join(' and ')})` : '';

      return `perms/permissions?length=10000&${queryString}`;
    },
    // accumulate: 'true',
    // fetch: false,
    resourceShouldRefresh: true,
  },
});

const propTypes = {
  resources: PropTypes.shape({
    permissions: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  mutator: PropTypes.shape({
    permissions: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
    }),
  }),
};

function PermsTranslationsRoute(props) {
  const [currentLocale, setCurrentLocale] = useQueryParams('locale', '');

  const getAllPerms = () => {
    return sortBy((props.resources.permissions || {}).records || [], [
      'displayName',
    ]).map((perm) => {
      delete perm.childOf;
      delete perm.grantedTo;
      delete perm.dummy;
      return perm;
    });
  };

  const getOriginTranslations = () => {
    const allPerms = sortBy((props.resources.permissions || {}).records || [], [
      'displayName',
    ]);
    const OriginTranslations = {};
    allPerms.forEach((perm) => {
      OriginTranslations[
        `ui-users.permissions.displayName.${perm.displayName ||
          perm.permissionName}`
      ] = perm.displayName || perm.permissionName;
    });
    return OriginTranslations;
  };

  const getCurrentLocaleTranslations = () => {
    return props.getTranslations(currentLocale, 'permsNames') || {};
  };

  const getTranslationsByApp = (app) => {
    if (app === 'allApps') return getOriginTranslations();
    else {
      const appTrans = {};
      getAllPerms().forEach((key) => {
        if (key.permissionName.startsWith(app)) {
          appTrans[
            `ui-users.permissions.displayName.${key.displayName ||
              key.permissionName}`
          ] = key.displayName || key.permissionName;
        }
      });
      return appTrans;
    }
  };

  const getApps = () => {
    const APPs = [];
    getAllPerms().forEach((perm) => {
      if (perm && perm.permissionName) {
        APPs.push(
          perm.permissionName.substr(0, perm.permissionName.indexOf('.'))
        );
      }
    });

    return [...new Set(APPs)].filter((e) => {
      return e;
    });
  };

  // useEffect(() => {
  //   props.history.push({
  //     pathname: '/translations/permsNames',
  //     search: props.location.search,
  //     state: { backPathname: props.location.pathname },
  //   });
  //   return () => {
  //     setCurrentLocale('');
  //   };
  // }, []);

  return (
    <>
      <TranslationWrapper
        {...props}
        categoryName="permsNames"
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
        originTranslations={getOriginTranslations()}
        localeTranslations={getCurrentLocaleTranslations()}
        showAppStats
        checkLocaleTranslators={props.getLocaleTranslatorsByValue}
        activeFilters={['locale', 'app', 'status', 'user']}
        ViewMetaData={
          <ViewMetaData
            metadata={props.getTranslationsMetadata(currentLocale, 'permsNames')}
          />
        }
        paneTitle={
          <FormattedMessage id="ui-translations.category.permsNames" />
        }
        getAppTranslations={getTranslationsByApp}
        apps={getApps()}
      />
    </>
  );
}

PermsTranslationsRoute.propTypes = propTypes;
PermsTranslationsRoute.manifest = manifest;

export default withStripes(
  stripesConnect(WithTranslations(PermsTranslationsRoute))
);

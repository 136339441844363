/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import {
  Button,
  Icon,
  IconButton,
  MultiColumnList,
  Pane,
  PaneFooter,
  PaneMenu,
  Paneset,
  TextArea,
  TextField,
  Tooltip
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { AppIcon, IfPermission } from '@folio/stripes-core';
import googleIcon from '../../../icons/google-translate.svg';
import ChooseDefaultMT from './components/ChooseDefaultMT';
import ShowTranslationsSuggestions from './components/ShowTranslationsSuggestions';
import BulkTranslationComponent from './components/BulkTranslationComponent';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onChangeActionMode: PropTypes.func
};

const doFetch = async (locale, text) => {
  let response;
  let loading;
  let error;
  const abortController = new AbortController();
  const signal = abortController.signal;
  try {
    const res = await fetch(
      'https://translate.googleapis.com/translate_a/single?client=gtx&sl=' +
        'en' +
        '&tl=' +
        locale +
        '&dt=t&ie=UTF-8&oe=UTF-8&q=' +
        encodeURIComponent(text),
      {
        // mode: 'no-cors',
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
          // 'Access-Control-Allow-Headers': 'Content-Type',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    const json = await res.json();
    if (!signal.aborted) {
      response = json;
    }
  } catch (e) {
    if (!signal.aborted) {
      error = e;
    }
  } finally {
    if (!signal.aborted) {
      loading = false;
    }
  }
  return response;
};

function MultiEditForm({
  currentLocale,
  pristine,
  submitting,
  handleSubmit,
  handleClose,
  form,
  paneTitle,
  selectedTranslations
}) {
  const [MTSuggestions, setMTSuggestions] = useState({});

  useEffect(() => {
    const biuldFieldsMTSuggestionsStates = () => {
      const fields = {};
      selectedTranslations.forEach((trans) => {
        fields[trans.translationKey] = false;
      });
      return fields;
    };
    setMTSuggestions(biuldFieldsMTSuggestionsStates());
  }, []);

  const renderFirstMenu = () => {
    return (
      <PaneMenu>
        <FormattedMessage id="ui-translations.createNewkeys.close">
          {(ariaLabel) => (
            <IconButton
              aria-label={ariaLabel}
              icon="times"
              id="close-translations-form-button"
              onClick={handleClose}
            />
          )}
        </FormattedMessage>
      </PaneMenu>
    );
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-translations-createNewkeys"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-components.saveAndClose" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            id="clickable-cancel"
            marginBottom0
            onClick={handleClose}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  const renderLastMenu = () => {
    return (
      <>
        <Tooltip
          id="empty-all-button-tooltip"
          text={
            <FormattedMessage
              id="ui-translations.emptyAll.tooltip"
              defaultMessage="Empty all"
            />
          }
        >
          {({ ref, ariaIds }) => (
            <Button
              aria-labelledby={ariaIds.text}
              ref={ref}
              marginBottom0
              id="clickable-empty-all-multi-selections"
              buttonStyle="slim"
              onClick={() => {
                selectedTranslations.forEach((item) => {
                  form.mutators.resetFieldTranslations(
                    item.translationKey.replaceAll('.', '__'),
                    ''
                  );
                });
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              <Icon icon="times-circle-solid" size="large" />
            </Button>
          )}
        </Tooltip>
        <Tooltip
          id="reset-all-button-tooltip"
          text={
            <FormattedMessage
              id="ui-translations.reset-all.tooltip"
              defaultMessage="Reset all"
            />
          }
        >
          {({ ref, ariaIds }) => (
            <Button
              aria-labelledby={ariaIds.text}
              ref={ref}
              marginBottom0
              id="clickable-reset-all-multi-selections"
              buttonStyle="slim"
              onClick={() => form.reset()}
              // onMouseDown={(e) => e.preventDefault()}
              disabled={pristine || submitting}
            >
              <Icon icon="undo" size="large" />
            </Button>
          )}
        </Tooltip>
        <BulkTranslationComponent
          locale={currentLocale}
          mutators={form.mutators}
          selectedTranslations={selectedTranslations}
        />
      </>
    );
  };

  const actionsFormatter = (item) => {
    return (
      <>
        <Tooltip
          id="undo-tooltip"
          text={
            <FormattedMessage
              id="ui-translations.buttons.tooltip.undo"
              defaultMessage="Undo"
            />
          }
        >
          {({ ref, ariaIds }) => (
            <Button
              aria-labelledby={ariaIds.text}
              ref={ref}
              buttonStyle="slim"
              marginBottom0
              id={`clickable-undo-translate-single-item-${item.translationKey}`}
              onClick={() =>
                form.mutators.resetFieldTranslations(
                  item.translationKey.replaceAll('.', '__'),
                  item.localeTranslation
                )
              }
              onMouseDown={(e) => e.preventDefault()}
              // disabled={Object.values(this.state.selection).includes(true)}
            >
              <Icon icon="undo" />
            </Button>
          )}
        </Tooltip>
        <Tooltip
          id="google-translate-button-tooltip"
          text={
            <FormattedMessage
              id="ui-translations.googleTranslatebutton.tooltip"
              defaultMessage="Fetch translation from google translate"
            />
          }
        >
          {({ ref, ariaIds }) => (
            <Button
              aria-labelledby={ariaIds.text}
              ref={ref}
              buttonStyle="slim"
              marginBottom0
              id={`clickable-translate-single-item-${item.translationKey}`}
              onClick={() => {
                const getTranslatedText = doFetch(
                  currentLocale,
                  item.originTranslation
                );
                Promise.resolve(getTranslatedText).then((res) => {
                  form.mutators.renderMachineTranslator(
                    item.translationKey.replaceAll('.', '__'),
                    res ? res[0][0][0] : ''
                  );
                  console.log(res ? res[0][0][0] : '');
                });
              }}
              // onClick={() =>
              //   // console.log(Object.assign(MTSuggestions, {
              //   //   [item.translationKey]:
              //   //       !MTSuggestions[item.translationKey],
              //   // }))
              //   setMTSuggestions(
              //     Object.assign(MTSuggestions, {
              //       [item.translationKey]:
              //         !MTSuggestions[item.translationKey],
              //     })
              //   )
              // }
              onMouseDown={(e) => e.preventDefault()}
              // disabled={Object.values(this.state.selection).includes(true)}
            >
              <img
                src={googleIcon}
                alt="googleIcon"
                width="20px"
                height="20px"
              />
            </Button>
          )}
        </Tooltip>
      </>
    );
  };

  const localeTranslationFormatter = (item) => {
    return (
      <div style={{ width: '100%' }}>
        <Field
          id={`localeTranslation-${item.translationKey}`}
          component={TextField}
          marginBottom0
          name={item.translationKey.replaceAll('.', '__')}
          dirty
        />
        {/* {MTSuggestions[item.translationKey] && (
          <ShowTranslationsSuggestions />
        )} */}
      </div>
    );
  };

  const renderMultiTranslations = () => {
    return (
      <MultiColumnList
        interactive={false}
        visibleColumns={[
          'rowIndex',
          'originTranslation',
          'localeTranslation',
          'Actions'
        ]}
        columnWidths={{
          rowIndex: '5%',
          originTranslation: '35%',
          localeTranslation: '50%',
          Actions: '10%'
        }}
        columnMapping={{
          rowIndex: '#',
          originTranslation: (
            <FormattedMessage id="ui-translations.localeTranslations.originTranslation" />
          ),
          localeTranslation: (
            <FormattedMessage id="ui-translations.localeTranslations.localeTranslation" />
          ),
          Actions: (
            <FormattedMessage id="stripes-components.paneMenuActionsToggleLabel" />
          )
        }}
        contentData={selectedTranslations}
        formatter={{
          rowIndex: (item) => item.rowIndex + 1,
          localeTranslation: (item) => localeTranslationFormatter(item),
          Actions: (item) => actionsFormatter(item)
        }}
        id="Apps-Translations-Multi-Column-List"
        autosize
      />
    );
  };

  return (
    <form id="form-selected-translations" onSubmit={handleSubmit}>
      <Paneset>
        <Pane
          appIcon={<AppIcon app="translations" />}
          fluidContentWidth
          noOverflow
          firstMenu={renderFirstMenu()}
          lastMenu={renderLastMenu()}
          footer={renderPaneFooter()}
          id="pane-import-translations-form"
          centeredContent
          paneTitle={
            <FormattedMessage
              id="ui-translations.multiSelectionEditForm.header"
              values={{
                paneTitle: (
                  <FormattedMessage
                    id={`ui-translations.category.${paneTitle}`}
                  />
                )
              }}
            />
          }
        >
          {renderMultiTranslations()}
        </Pane>
      </Paneset>
    </form>
  );
}

MultiEditForm.propTypes = propTypes;

export default stripesFinalForm({
  mutators: {
    renderMachineTranslator: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    },
    resetFieldTranslations: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    }
  },
  validateOnBlur: true
})(MultiEditForm);

/* eslint-disable import/prefer-default-export */

export function getPermissionLabelString(permission, formatMessage, showRaw) {
  const { permissionName } = permission;
  // const displayName = permission.displayName ?? permissionName;

  // const [pPrefix, ...pName] = permissionName.split('.');
  // const i18nKey = `${pPrefix}.permission.${pName.join('.')}`;
  // const label = formatMessage({ id: i18nKey, defaultMessage: displayName });
  /* Kware start editing */
  const label = formatMessage({
    id: `ui-users.permissions.displayName.${permission.displayName || permission.permissionName}`,
    defaultMessage: permission.displayName || permission.permissionName,
  });
  /* Kware start editing */
  return showRaw ? `${permissionName} (${label})` : label;
}

import React from 'react';
import PropTypes from 'prop-types';
import ExportTranslationsManager from '../components/ExportTranslations/ExportTranslationsManager';

function ExportTranslationsRoute(props) {
  const handleClose = () => {
    props.history.goBack();
  };
  return (
    <ExportTranslationsManager
      handleClose={handleClose}
      contentData={props.location.state.contentData}
      selectedTranslations={props.location.state.selectedTranslations}
      currentLocale={props.location.state.currentLocale}
      categoryName={props.location.state.categoryName}
      fileName={props.location.state.fileName}
    />
  );
}

ExportTranslationsRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default ExportTranslationsRoute;

/* eslint-disable react/sort-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  Modal,
  ModalFooter,
  Button,
  Icon,
  Layout,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

const ConfirmExportEmptyModal = ({
  onClose,
  open,
  currentLocale,
  categoryName,
  contentData,
  selectedTranslations,
  fileName,
  currentStatus,
}) => {
  const renderFooter = () => {
    return (
      <ModalFooter>
        {currentStatus !== 'notTranslated' && (
          <Button
            id="clickable-exportTranslation-file"
            buttonStyle="primary"
            to={{
              pathname: '/translations/export-translations',
              state: {
                contentData,
                selectedTranslations,
                currentLocale,
                categoryName,
                fileName: selectedTranslations.length === 0 ? fileName : '',
              },
            }}
          >
            <Icon icon="save">
              <FormattedMessage id="stripes-core.button.continue" />
            </Icon>
          </Button>
        )}

        <Button buttonStyle="slim" onClick={onClose}>
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.cancel" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        footer={renderFooter()}
        open={open}
        onClose={onClose}
        label={
          <Icon icon="info" size="large" color="red">
            <FormattedMessage id="ui-translations.confirmExportEmptyModal.label" />
          </Icon>
        }
      >
        {currentStatus !== 'notTranslated' ? (
          <Layout>
            <FormattedMessage id="ui-translations.exportEmptyModal.containsEmpty.message" />
          </Layout>
        ) : (
          <Layout className="textCentered">
            <FormattedMessage id="ui-translations.exportEmptyModal.empty.message" />
          </Layout>
        )}
      </Modal>
    );
  };

  return <>{renderConfirmationModal()}</>;
};

export default ConfirmExportEmptyModal;

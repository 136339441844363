/* eslint-disable import/no-extraneous-dependencies */
import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
/* Kware Start Editing */
import { FormattedMessage, useIntl } from 'react-intl';
/* Kware end Editing */
import {
  Accordion,
  Col,
  FormattedUTCDate,
  Headline,
  KeyValue,
  Row,
} from '@folio/stripes/components';

/* Kware Start Editing */
import { withStripes } from '@folio/stripes-core';
import HandleDateTime from '@folio/stripes-core/util/kware-utils/HandleDateTime';
import { handleDateTimeFormat } from '../../util';
/* Kware end Editing */

import { requestPreferencesShape } from '../../../shapes';

import RequestPreferencesView from './components/RequestPreferencesView';

const ExtendedInfo = (props) => {
  const {
    accordionId,
    expanded,
    onToggle,
    user,
    requestPreferences,
    defaultServicePointName,
    defaultDeliveryAddressTypeName,
    departments,
  } = props;

  /* Kware start editing */
  const intl = useIntl();
  const translatedDepartments = [];
  if (departments.length) {
    departments.map((dep) => translatedDepartments.push(
      intl.formatMessage({
        id: `ui-users.departments.name.${dep}`,
        defaultMessage: dep,
      })
    ));
  }
  /* Kware end editing */

  return (
    <Accordion
      id={accordionId}
      label={<Headline size="large" tag="h3"><FormattedMessage id="ui-users.extended.extendedInformation" /></Headline>}
      onToggle={onToggle}
      open={expanded}
    >
      <Row>
        <Col xs={12} md={3}>
          <KeyValue label={<FormattedMessage id="ui-users.extended.dateEnrolled" />}>
            {user.enrollmentDate ?
            /* <FormattedUTCDate value={user.enrollmentDate} /> */
            /* Kware start editing */
              /* handleDateTimeFormat(user.enrollmentDate, props.stripes, intl, 'date') */
              <HandleDateTime dateTimeValue={user.enrollmentDate} handleType="date" />
              /* Kware end editing */
              : '-'}
          </KeyValue>
        </Col>
        <Col xs={12} md={3}>
          <KeyValue label={<FormattedMessage id="ui-users.extended.birthDate" />}>
            {user.personal?.dateOfBirth ?
            /* <FormattedUTCDate value={user.personal.dateOfBirth} timeZone="UTC" /> */
            /* Kware start editing */
            /* handleDateTimeFormat(user.personal.dateOfBirth, props.stripes, intl, 'date') */
              <HandleDateTime dateTimeValue={user.personal.dateOfBirth} handleType="date" />
              /* Kware end editing */
              : '-'}
          </KeyValue>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <KeyValue label={<FormattedMessage id="ui-users.extended.folioNumber" />}>
            {get(user, ['id'], '-')}
          </KeyValue>
        </Col>
        <Col xs={12} md={6}>
          <KeyValue label={<FormattedMessage id="ui-users.extended.externalSystemId" />}>
            {get(user, ['externalSystemId'], '-')}
          </KeyValue>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={9}>
          <RequestPreferencesView
            requestPreferences={requestPreferences}
            defaultServicePointName={defaultServicePointName}
            defaultDeliveryAddressTypeName={defaultDeliveryAddressTypeName}
          />
        </Col>
      </Row>
      {/* {departments.length */}
      {/* Kware start editing */}
      {translatedDepartments.length
      /* Kware end editing */
        ? (
          <Row>
            <Col xs={12} md={6}>
              <KeyValue label={<FormattedMessage id="ui-users.extended.department.name" />}>
                <span data-test-department-name>
                  {/* {departments.join(', ')} */}
                  {/* Kware start editing */}
                  {translatedDepartments.join(intl.locale === 'ar' ? '، ' : ', ')}
                  {/* Kware end editing */}
                </span>
              </KeyValue>
            </Col>
          </Row>
        )
        : null
      }
      <Row>
        <Col xs={12} md={6}>
          <KeyValue label={<FormattedMessage id="ui-users.information.username" />}>
            {get(user, ['username'], '')}
          </KeyValue>
        </Col>
      </Row>
    </Accordion>
  );
};

ExtendedInfo.propTypes = {
  accordionId: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onToggle: PropTypes.func,
  user: PropTypes.object,
  defaultServicePointName: PropTypes.string,
  requestPreferences: requestPreferencesShape,
  defaultDeliveryAddressTypeName: PropTypes.string.isRequired,
  departments: PropTypes.arrayOf(PropTypes.string).isRequired,
};

// export default ExtendedInfo;
/* Kware Start Editing */
export default withStripes(ExtendedInfo);
/* Kware End Editing */

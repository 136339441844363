/* kware added new */

const DateFormats = [
  { value: 'YYYY/MM/DD', label: 'YYYY/MM/DD' },
  { value: 'YYYY/M/D', label: 'YYYY/M/D' },
  { value: 'YY/MM/DD', label: 'YY/MM/DD' },
  { value: 'YY/M/D', label: 'YY/M/D' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'M/D/YYYY', label: 'M/D/YYYY' },
  { value: 'MM/DD/YY', label: 'MM/DD/YY' },
  { value: 'DD/MM/YY', label: 'DD/MM/YY' },
  { value: 'D/M/YY', label: 'D/M/YY' },
  { value: 'M/D/YY', label: 'M/D/YY' },
  { value: '-', label: '--------------------------', disabled: true },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'YYYY-M-D', label: 'YYYY-M-D' },
  { value: 'YY-MM-DD', label: 'YY-MM-DD' },
  { value: 'YY-M-D', label: 'YY-M-D' },
  { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
  { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' },
  { value: 'M-D-YYYY', label: 'M-D-YYYY' },
  { value: 'MM-DD-YY', label: 'MM-DD-YY' },
  { value: 'DD-MM-YY', label: 'DD-MM-YY' },
  { value: 'D-M-YY', label: 'D-M-YY' },
  { value: 'M-D-YY', label: 'M-D-YY' },
  { value: '-', label: '--------------------------', disabled: true },
  { value: 'YYYY.MM.DD', label: 'YYYY.MM.DD' },
  { value: 'YYYY.M.D', label: 'YYYY.M.D' },
  { value: 'YY.MM.DD', label: 'YY.MM.DD' },
  { value: 'YY.M.D', label: 'YY.M.D' },
  { value: 'DD.MM.YYYY', label: 'DD.MM.YYYY' },
  { value: 'MM.DD.YYYY', label: 'MM.DD.YYYY' },
  { value: 'M.D.YYYY', label: 'M.D.YYYY' },
  { value: 'MM.DD.YY', label: 'MM.DD.YY' },
  { value: 'DD.MM.YY', label: 'DD.MM.YY' },
  { value: 'D.M.YY', label: 'D.M.YY' },
  { value: 'M.D.YY', label: 'M.D.YY' },
];

export default DateFormats;

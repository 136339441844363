import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MultiSelection } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';

function SourcesListField({ sourcesList }) {
  const intl = useIntl();

  const localizedSourcesList = useMemo(() => {
    return sourcesList?.map((option) => ({
      label: option.name,
      value: option.name,
    }));
  }, [sourcesList]);

  const formatter = ({ option }) => (
    <FormattedMessage
      id={`ui-inventory.targetProfiles.name.${option.value}`}
      defaultMessage={option.value}
    />
  );

  const validate = (value) => (value?.length ? undefined : (
    <strong>
      <FormattedMessage
        id="ui-reports.validation.required"
        defaultMessage="required"
      />
    </strong>
  ));

  return (
    <Field
      id="copycat-query-builder-form-sources-list-multiselect-field"
      name="sourcesList"
      component={MultiSelection}
      label={
        <FormattedMessage
          id="ui-inventory.copycat.queryBuilder.form.fields.sourcesList.label"
          defaultMessage="Sources list"
        />
      }
      placeholder={intl.formatMessage({
        id: 'ui-inventory.copycat.queryBuilder.form.fields.sourcesList.placeholder',
        defaultMessage: 'Choose source',
      })}
      dataOptions={localizedSourcesList}
      formatter={formatter}
      required
      validate={validate}
    />
  );
}

SourcesListField.propTypes = {
  sourcesList: PropTypes.arrayOf(PropTypes.object),
};

export default SourcesListField;

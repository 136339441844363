/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { CubeContext } from '@cubejs-client/react';

export default function useQuery(query, subscribe = false) {
  const { cubejsApi } = useContext(CubeContext);

  const [resultSet, setResultSet] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const doFetch = async () => {
    setIsLoading(true);
    try {
      const res = await cubejsApi.load(query);
      if (!signal.aborted) {
        setResultSet(res);
      }
    } catch (e) {
      if (!signal.aborted) {
        setError(e);
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (query !== '') {
      doFetch();
    } else {
      setResultSet(null);
      setError(null);
      setIsLoading(false);
    }
    return () => {
      abortController.abort();
    };
  }, [query]);

  return [resultSet, error, isLoading];
}

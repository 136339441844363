import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';

import { Icon, MultiColumnList } from '@folio/stripes/components';
import { effectiveCallNumber } from '@folio/stripes/util';

import ReturnedTime from './components/ReturnedTime';

import css from './CheckedInListItems.css';
/* kware start edit */
import { getTranslatedItemStatus } from '../../util';
/* kware end edit */
export const COLUMNS_NAME = {
  TIME_RETURNED: 'timeReturned',
  TITLE: 'title',
  BARCODE: 'barcode',
  EFFECTIVE_CALL_NUMBER: 'effectiveCallNumber',
  LOCATION: 'location',
  IN_HOUSE_USE: 'inHouseUse',
  STATUS: 'status',
  ACTION: ' ',
};
export const visibleColumns = [
  COLUMNS_NAME.TIME_RETURNED,
  COLUMNS_NAME.TITLE,
  COLUMNS_NAME.BARCODE,
  COLUMNS_NAME.EFFECTIVE_CALL_NUMBER,
  COLUMNS_NAME.LOCATION,
  COLUMNS_NAME.IN_HOUSE_USE,
  COLUMNS_NAME.STATUS,
  COLUMNS_NAME.ACTION,
];
export const columnWidths = {
  [COLUMNS_NAME.TIME_RETURNED]: { max: 120 },
  [COLUMNS_NAME.TITLE]: { max: 300 },
  [COLUMNS_NAME.BARCODE]: { max: 200 },
  [COLUMNS_NAME.EFFECTIVE_CALL_NUMBER]: { max: 200 },
  [COLUMNS_NAME.LOCATION]: { max: 200 },
  [COLUMNS_NAME.IN_HOUSE_USE]: { max: 80 },
  [COLUMNS_NAME.STATUS]: { max: 120 },
  [COLUMNS_NAME.ACTION]: { max: 80 },
};
export const columnMapping = {
  [COLUMNS_NAME.TIME_RETURNED]: (
    <FormattedMessage id="ui-checkin.timeReturned" />
  ),
  [COLUMNS_NAME.TITLE]: <FormattedMessage id="ui-checkin.title" />,
  [COLUMNS_NAME.BARCODE]: <FormattedMessage id="ui-checkin.barcode" />,
  [COLUMNS_NAME.EFFECTIVE_CALL_NUMBER]: (
    <FormattedMessage id="ui-checkin.effectiveCallNumber" />
  ),
  [COLUMNS_NAME.LOCATION]: <FormattedMessage id="ui-checkin.location" />,
  [COLUMNS_NAME.IN_HOUSE_USE]: <FormattedMessage id="ui-checkin.inHouseUse" />,
  [COLUMNS_NAME.STATUS]: <FormattedMessage id="ui-checkin.status" />,
  [COLUMNS_NAME.ACTION]: <FormattedMessage id="ui-checkin.actions" />,
};
export const getItemListFormatter = (mutator, renderActions) => ({
  [COLUMNS_NAME.TIME_RETURNED]: (loan) => (
    <ReturnedTime loan={loan} mutator={mutator} />
  ),
  // [COLUMNS_NAME.TITLE]: (loan) => {
  //   const title = `${get(loan, ['item', 'title'])}`;
  //   const materialType = `${get(loan, ['item', 'materialType', 'name'])}`;

  //   return `${title} (${materialType})`;
  // },
  [COLUMNS_NAME.BARCODE]: (loan) => `${get(loan, ['item', 'barcode'])}`,
  [COLUMNS_NAME.LOCATION]: (loan) => `${get(loan, ['item', 'location', 'name'])}`,
  [COLUMNS_NAME.IN_HOUSE_USE]: (loan) => {
    return get(loan, 'inHouseUse') ? (
      <Icon icon="house" iconClassName={css.houseIcon} />
    ) : (
      ''
    );
  },
  [COLUMNS_NAME.STATUS]: (loan) => {
    const status = `${get(loan, ['item', 'status', 'name'])}`;
    const inTransitSp = get(loan, [
      'item',
      'inTransitDestinationServicePoint',
      'name',
    ]);

    return inTransitSp ? `${status} - ${inTransitSp}` : status;
  },
  [COLUMNS_NAME.EFFECTIVE_CALL_NUMBER]: (loan) => effectiveCallNumber(loan),
  [COLUMNS_NAME.ACTION]: (loan) => renderActions(loan),
});

class CheckedInListItems extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    scannedItems: PropTypes.arrayOf(PropTypes.object),
    mutator: PropTypes.object.isRequired,
    renderActions: PropTypes.func.isRequired,
    /* kware start edit */
    intl: PropTypes.object,
    /* kware end edit */
  };

  render() {
    const { loading, scannedItems, mutator, renderActions } = this.props;
    const emptyMessage = loading ? null : (
      <FormattedMessage id="ui-checkin.noItems" />
    );

    // const itemListFormatter = getItemListFormatter(mutator, renderActions);

    /* kware start edit */
    const customFormatter = {
      [COLUMNS_NAME.TITLE]: (loan) => {
        const title = `${get(loan, ['item', 'title'])}`;
        const materialType = `${get(loan, ['item', 'materialType', 'name'])}`;
        return `${title} (${this.props.intl.formatMessage({
          id: `ui-inventory.materialTypes.name.${materialType}`,
          defaultMessage: materialType,
        })})`;
      },
      [COLUMNS_NAME.LOCATION]: (loan) => {
        const locName = get(loan, ['item', 'location', 'name']);
        return locName ? this.props.intl.formatMessage({
          id: `ui-tenant-settings.locations.name.${locName}`,
          defaultMessage: locName,
        })
          : '-';
      },
      [COLUMNS_NAME.STATUS]: (loan) => {
        const inTransitSp = get(loan, [
          'item',
          'inTransitDestinationServicePoint',
          'name',
        ])
          ? this.props.intl.formatMessage({
            id: `ui-tenant-settings.ServicePoint.name.${get(loan, [
              'item',
              'inTransitDestinationServicePoint',
              'name',
            ])}`,
            defaultMessage: get(loan, [
              'item',
              'inTransitDestinationServicePoint',
              'name',
            ]),
          })
          : undefined;
        return inTransitSp
          ? `${this.props.intl.formatMessage({
            id: 'ui-inventory.item.status.inTransit',
          })} - ${inTransitSp}`
          : getTranslatedItemStatus(get(loan, ['item', 'status', 'name']));
      },
    };

    const itemListFormatter = {
      ...getItemListFormatter(mutator, renderActions),
      ...customFormatter,
    };
    /* kware start edit */

    return (
      <>
        {loading && (
          <Icon
            data-testid="listItemsLoading"
            icon="spinner-ellipsis"
            width="10px"
          />
        )}
        <div data-test-checked-in-items data-testid="listItems">
          <MultiColumnList
            id="list-items-checked-in"
            fullWidth
            visibleColumns={visibleColumns}
            columnMapping={columnMapping}
            columnWidths={columnWidths}
            columnOverflow={{ ' ': true }}
            rowMetadata={['id']}
            interactive={false}
            contentData={scannedItems}
            formatter={itemListFormatter}
            isEmptyMessage={emptyMessage}
          />
        </div>
      </>
    );
  }
}

/* kware start edit */
export default injectIntl(CheckedInListItems);
/* kware end edit */

/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Card, Tooltip } from '@folio/stripes-components';
import MarcField from '@folio/quick-marc/src/QuickMarcView/MarcField';
import { FormattedMessage } from 'react-intl';
import { MarcRecord } from '@natlibfi/marc-record';
import styles from './MarcContent.css';

function SourceView({ itemToView }) {
  const [textDir, setTextDir] = useState('ltr');

  const parseMarcRecordJson = () => {
    return itemToView.marcRecordJson && JSON.parse(itemToView.marcRecordJson);
  };

  // const record = new MarcRecord();
  // MarcRecord.fromString(itemToView?.marcRecordString, {});
  // console.log(itemToView.marcRecordString);
  // console.log('MarcRecord: ', MarcRecord.fromString(itemToView?.marcRecordString, {}));

  const renderChangeTextDir = () => {
    return (
      <Tooltip
        id="copycat-results-preview-view-source-card-changeDir-tooltip"
        text={
          textDir === 'ltr' ? (
            <FormattedMessage
              id="ui-inventory.copycat.resultsPreview.viewSourceModal.tooltip.changeDir.right.msg"
              defaultMessage="Change text dir to right"
            />
          ) : (
            <FormattedMessage
              id="ui-inventory.copycat.resultsPreview.viewSourceModal.tooltip.changeDir.left.msg"
              defaultMessage="Change text dir to left"
            />
          )
        }
      >
        {({ ref, ariaIds }) => (
          <Button
            buttonStyle="primary"
            marginBottom0
            id="copycat-results-preview-view-source-card-changeDir-btn"
            onClick={() => setTextDir(textDir === 'ltr' ? 'rtl' : 'ltr')}
            onMouseDown={(e) => e.preventDefault()}
            aria-labelledby={ariaIds.text}
            ref={ref}
          >
            {textDir === 'ltr' ? (
              <FormattedMessage
                id="ui-inventory.copycat.buttons.changeToRtl"
                defaultMessage="To right"
              />
            ) : (
              <FormattedMessage
                id="ui-inventory.copycat.buttons.changeToLtr"
                defaultMessage="To left"
              />
            )}
          </Button>
        )}
      </Tooltip>
    );
  };

  return (
    <section dir={textDir} className={styles.marcWrapper}>
      <Card
        id="copycat-results-preview-view-source-card"
        headerStart={
          <span>
            <strong>
              <Icon icon="document" zise="large">
                {itemToView?.title}
              </Icon>
            </strong>
          </span>
        }
        headerEnd={renderChangeTextDir()}
      >
        {parseMarcRecordJson() && (
          <table className={styles.marc}>
            <tbody>
              <tr>
                <td colSpan="4">{`LEADER ${parseMarcRecordJson().leader}`}</td>
              </tr>
              {parseMarcRecordJson().fields.map((field, idx) => (
                <MarcField
                  isPrint={false}
                  field={field}
                  key={idx}
                  showLinkIcon={false}
                />
              ))}
            </tbody>
          </table>
        )}
      </Card>
    </section>
  );
}

SourceView.propTypes = {
  itemToView: PropTypes.object,
};

export default SourceView;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCubeMeta } from '@cubejs-client/react';
import QueryBuilderForm from './QueryBuilderForm';

function QueryBuilderManger(props) {
  const cubeMetaData = useCubeMeta();

  const getFieldValues = (values) => {
    const columns = [];
    for (const key in values) {
      if (key) {
        columns.push(...values[key]);
      }
    }
    return columns.map((col) => col.value);
  };

  const getFiltersValues = (values) => {
    const filtersValues = [];
    values.map((val) => {
      if (val.member) {
        filtersValues.push({
          member: val?.member,
          operator: val?.operator,
          values: typeof val.values === 'string' ? val?.values?.split(',') : [],
        });
      }
      return null;
    });
    return filtersValues;
  };

  const getOrdersValues = (orders) => {
    const orderObject = {};
    orders.forEach((order) => {
      orderObject[order.fieldName] = order.sortType;
    });
    return orderObject;
  };

  const onFormSubmit = (values) => {
    props.setQuery({
      measures:
        values?.metrics.length !== 0
          ? values?.metrics?.map((met) => met.value)
          : [],
      dimensions: Object.keys(values.columns).length
        ? getFieldValues(values.columns)
        : [],
      filters:
        values?.dimFilters?.length || values?.metFilters?.length
          ? getFiltersValues([
              ...(values.dimFilters || []),
              ...(values.metFilters || []),
            ])
          : [],
      timeDimensions:
        values?.timeDimentions?.timeColumn && values?.timeDimentions?.timeUnit
          ? [
              {
                dimension: values?.timeDimentions?.timeColumn
                  ? values.timeDimentions.timeColumn
                  : '',
                granularity: values?.timeDimentions?.timeUnit
                  ? values.timeDimentions.timeUnit
                  : '',
              },
            ]
          : [],
      limit: 50000,
      offset: 0,
      // ungrouped: !values?.metrics.length,
      ungrouped: false,
      order: values?.orders?.length ? getOrdersValues(values.orders) : [],
    });
  };

  return (
    <>
      <QueryBuilderForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={props.initialValues}
        cubeMetaData={cubeMetaData?.response}
        setQuery={props.setQuery}
        reportType={props.reportType}
        setReportType={props.setReportType}
      />
    </>
  );
}

QueryBuilderManger.propTypes = {
  handleClose: PropTypes.func.isRequired,
  showQueryBuilderPane: PropTypes.bool.isRequired,
  setShowQueryBuilderPane: PropTypes.func.isRequired,
  setQuery: PropTypes.func.isRequired,
  setQueryString: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
  setReportType: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default QueryBuilderManger;

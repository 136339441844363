/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { Button, Pane, PaneFooter, Paneset } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import ListsManager from './components/ListsManager';

function UserPreferredAppsForm({ pristine, submitting, handleSubmit, form, apps }) {
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-user-image-upload"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-user-image-upload-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          noOverflow
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-user-preferred-apps-form"
          paneTitle={<FormattedMessage id="ui-myprofile.settings.UserPreferredApps.label" />}
        >
          <form id="form-user-preferred-apps" onSubmit={handleSubmit}>
            <ListsManager apps={apps} onChangeUserPreferredApps={form.mutators.renderUserPreferredAppsChange} />
          </form>
        </Pane>
      </Paneset>
    </>
  );
}

UserPreferredAppsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    renderUserPreferredAppsChange: (args, state) => {
      const field = state.fields.userPreferredApps;

      field.change(args[0]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(UserPreferredAppsForm);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEqual, sortBy } from 'lodash';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import moment from 'moment';
import BackupSettingsForm from './BackupSettingsForm';
import { JSONtoCSV } from '../../../utils/utils';

function BackupSettingsManager(props) {
  const [translationsList, setTranslationsList] = useState([]);
  const zip = new JSZip();

  useEffect(() => {
    const translations = sortBy(
      (props.resources.translations || {}).records || [],
      ['localeValue']
    );
    if (!isEqual(translations, translationsList)) {
      setTranslationsList(translations);
    }
  }, [props.resources.translations]);

  const groupTranslationsByCategory = (transArray, category) => {
    return transArray.reduce((acc, obj) => {
      const key = obj[category];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const onFormSubmit = (values) => {
    const translationsGroups = groupTranslationsByCategory(
      translationsList,
      'categoryName'
    );
    Object.keys(translationsGroups).forEach((trans) => {
      const folderName = zip.folder(trans);
      translationsGroups[trans].forEach((transFile) => {
        // folderName.file(
        //   values.fileType === 'json'
        //     ? (`${transFile.localeValue}.${values.fileType}`,
        //     JSON.stringify(transFile.messages, null, ' '))
        //     : JSONtoCSV(transFile.messages)
        // );
        folderName.file(
          `${transFile.localeValue}.${values.fileType}`,
          JSON.stringify(transFile.messages, null, ' ')
        );
      });
    });

    return zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(
        content,
        `${values.fileName} _ ${moment()}.zip`
      );
    });
  };

  return (
    <BackupSettingsForm
      {...props}
      onSubmit={onFormSubmit}
      initialValues={{
        fileType: '',
        fileName: 'translations_backup',
        savePath: 'localPc',
        translations: true,
        languages: true,
        translators: true,
        configs: true
      }}
    />
  );
}

BackupSettingsManager.propTypes = {
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
  mutator: PropTypes.shape({
    translations: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
};

BackupSettingsManager.manifest = Object.freeze({
  translationsId: '',
  translations: {
    type: 'okapi',
    records: 'translations',
    path: 'translations?limit=1000',
    // resourceShouldRefresh: true,
    POST: {
      path: 'translations',
    },
    DELETE: {
      path: 'translations',
    },
  },
});

export default BackupSettingsManager;

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Pane,
  PaneFooter,
  Tooltip,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
import stripesFinalForm from '@folio/stripes-final-form';
import SourcesListField from './Fields/SourcesListField';
import SearchOptionsFields from './Fields/SearchOptionsFields';
import SearchConfigsFields from './Fields/SearchConfigsFields';

function CopycatQueryBuilderForm({
  pristine,
  submitting,
  handleSubmit,
  showQueryPane,
  setShowQueryPane,
  sourcesList,
  resetQueryForm,
  isDisabledResetBtn,
}) {
  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary"
            disabled={pristine || submitting}
            id="copycat-query-builder-pane-footer-search-btn"
            marginBottom0
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.pane.footer.searchBtn"
              defaultMessage="Search"
            />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="primary"
            disabled={isDisabledResetBtn}
            id="copycat-query-builder-pane-footer-reset-btn"
            marginBottom0
            onClick={() => {
              resetQueryForm();
            }}
          >
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.pane.footer.resethBtn"
              defaultMessage="Reset"
            />
          </Button>
        }
      />
    );
  };

  return (
    <>
      {showQueryPane ? (
        <Pane
          id="copycat-query-builder-pane"
          appIcon={<Icon icon="search" size="large" />}
          defaultWidth="25%"
          paneTitle={
            <FormattedMessage
              id="ui-inventory.copycat.queryBuilder.pane.paneTitle"
              defaultMessage="Search in external sources"
            />
          }
          fluidContentWidth
          centeredContent
          noOverflow
          footer={renderPaneFooter()}
          lastMenu={
            <Tooltip
              id="copycat-query-builder-pane-hidePane-tooltip"
              text={
                <FormattedMessage
                  id="ui-inventory.copycat.queryBuilder.pane.tooltip.hidePane.msg"
                  defaultMessage="Hide query builder pane"
                />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  buttonStyle="dropdownItem"
                  id="copycat-query-builder-pane-hidePane-btn"
                  marginBottom0
                  onClick={() => setShowQueryPane(!showQueryPane)}
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  <Icon icon="chevron-double-left" />
                </Button>
              )}
            </Tooltip>
          }
        >
          <form id="copycat-query-builder-form" onSubmit={handleSubmit}>
            <SourcesListField sourcesList={sourcesList} />
            <SearchOptionsFields />
            <SearchConfigsFields />
          </form>
        </Pane>
      ) : (
        <div />
      )}
    </>
  );
}

CopycatQueryBuilderForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  // form: PropTypes.object.isRequired,
  showQueryPane: PropTypes.bool.isRequired,
  setShowQueryPane: PropTypes.func.isRequired,
  sourcesList: PropTypes.arrayOf(PropTypes.object),
  resetQueryForm: PropTypes.func,
  isDisabledResetBtn: PropTypes.bool,
};

export default stripesFinalForm({
  // mutators: {},
  // validateOnBlur: true,
  // navigationCheck: true,
  // keepDirtyOnReinitialize: true,
  // subscription: {},
})(CopycatQueryBuilderForm);

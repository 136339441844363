import React, { useCallback, useEffect, useState } from 'react';

const getSearchParams = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const parseJsonParam = (objValue) => {
  const obj = {};
  objValue.split(',').forEach((key) => {
    const split = key.split('.');
    obj[split[0]] = split[1];
  });
  return obj;
};

const serializeJsonParams = (object) => {
  return Object.keys(object)
    .map((key) => {
      return [key, object[key]].join('.');
    })
    .join(',');
};

const parseKeyParam = (key, type) => {
  const params = getSearchParams();

  if (params.get(key)) {
    switch (type) {
      case 'string':
        return params.get(key);
      case 'array':
        return params.get(key).split(',');
      case 'json':
        return parseJsonParam(params.get(key));
      case 'number':
        return JSON.parse(params.get(key));
      default:
        return params.get(key);
    }
  }
  return null;
};

function useURLParams(key, defaultVal, type = 'string') {
  const [currentValue, setCurrentValue] = useState(
    parseKeyParam(key, type) || defaultVal
  );

  const updateUrl = useCallback(() => {
    const params = getSearchParams();

    switch (type) {
      case 'string':
        params.set(key, currentValue);
        break;
      case 'array':
        params.set(key, currentValue.join(','));
        break;
      case 'json':
        params.set(key, serializeJsonParams(currentValue));
        break;
      case 'number':
        params.set(key, currentValue);
        break;
      default:
        params.set(key, currentValue);
        break;
    }

    if (typeof window !== 'undefined') {
      const { protocol, pathname, host } = window.location;

      const newUrl = `${protocol}//${host}${pathname}?${params.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
  }, [currentValue, key]);

  useEffect(() => {
    updateUrl();
  }, [updateUrl]);

  return [currentValue, setCurrentValue];
}

export default useURLParams;

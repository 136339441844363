const INSTANCE = {
  name: 'instance',
  recordType: 'INSTANCE',
  mappingFields: [{
    name: 'discoverySuppress',
    enabled: true,
    path: 'instance.discoverySuppress',
    value: '',
    subfields: [],
  }, {
    name: 'staffSuppress',
    enabled: true,
    path: 'instance.staffSuppress',
    value: '',
    subfields: [],
  }, {
    name: 'previouslyHeld',
    enabled: true,
    path: 'instance.previouslyHeld',
    value: '',
    subfields: [],
  }, {
    name: 'hrid',
    enabled: false,
    path: 'instance.hrid',
    value: '',
    subfields: [],
  }, {
    name: 'source',
    enabled: false,
    path: 'instance.source',
    value: '',
    subfields: [],
  }, {
    name: 'catalogedDate',
    enabled: true,
    path: 'instance.catalogedDate',
    value: '',
    subfields: [],
  }, {
    name: 'statusId',
    enabled: true,
    path: 'instance.statusId',
    value: '',
    subfields: [],
    acceptedValues: {},
  }, {
    name: 'modeOfIssuanceId',
    enabled: false,
    path: 'instance.modeOfIssuanceId',
    value: '',
    subfields: [],
  }, {
    name: 'statisticalCodeIds',
    enabled: true,
    path: 'instance.statisticalCodeIds[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.statisticalCodeIds[]',
      fields: [{
        name: 'statisticalCodeId',
        enabled: true,
        path: 'instance.statisticalCodeIds[]',
        value: '',
        acceptedValues: {},
      }],
    }],
  }, {
    name: 'administrativeNotes',
    enabled: true,
    path: 'instance.administrativeNotes[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.administrativeNotes[]',
      fields: [{
        name: 'administrativeNote',
        enabled: true,
        path: 'instance.administrativeNotes[]',
        value: '',
      }],
    }],
  }, {
    name: 'title',
    enabled: false,
    path: 'instance.title',
    value: '',
    subfields: [],
  }, {
    name: 'alternativeTitles',
    enabled: false,
    path: 'instance.alternativeTitles[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.alternativeTitles[]',
      fields: [{
        name: 'alternativeTitleTypeId',
        enabled: false,
        path: 'instance.alternativeTitles[].alternativeTitleTypeId',
        value: '',
      }, {
        name: 'alternativeTitle',
        enabled: false,
        path: 'instance.alternativeTitles[].alternativeTitle',
        value: '',
      }],
    }],
  }, {
    name: 'indexTitle',
    enabled: false,
    path: 'instance.indexTitle',
    value: '',
    subfields: [],
  }, {
    name: 'series',
    enabled: false,
    path: 'instance.series[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.series[]',
      fields: [{
        name: 'source',
        enabled: false,
        path: 'instance.series[]',
        value: '',
      }],
    }],
  }, {
    name: 'precedingTitles',
    enabled: false,
    path: 'instance.precedingTitles[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.precedingTitles[]',
      fields: [{
        name: 'precedingTitlesTitle',
        enabled: true,
        path: 'instance.precedingTitles[].title',
        value: '',
      }, {
        name: 'precedingTitlesHrid',
        enabled: true,
        path: 'instance.precedingTitles[].hrid',
        value: '',
      }, {
        name: 'precedingTitlesIsbn',
        enabled: true,
        path: 'instance.precedingTitles[].identifiers[].value',
        value: '',
      }, {
        name: 'precedingTitlesIssn',
        enabled: true,
        path: 'instance.precedingTitles[].identifiers[].value',
        value: '',
      }],
    }],
  }, {
    name: 'succeedingTitles',
    enabled: false,
    path: 'instance.succeedingTitles[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.succeedingTitles[]',
      fields: [{
        name: 'succeedingTitlesTitle',
        enabled: true,
        path: 'instance.succeedingTitles[].title',
        value: '',
      }, {
        name: 'succeedingTitlesHrid',
        enabled: true,
        path: 'instance.succeedingTitles[].hrid',
        value: '',
      }, {
        name: 'succeedingTitlesIsbn',
        enabled: true,
        path: 'instance.succeedingTitles[].identifiers[].value',
        value: '',
      }, {
        name: 'succeedingTitlesIssn',
        enabled: true,
        path: 'instance.succeedingTitles[].identifiers[].value',
        value: '',
      }],
    }],
  }, {
    name: 'identifiers',
    enabled: false,
    path: 'instance.identifiers[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.identifiers[]',
      fields: [{
        name: 'identifierTypeId',
        enabled: false,
        path: 'instance.identifiers[].identifierTypeId',
        value: '',
      }, {
        name: 'value',
        enabled: false,
        path: 'instance.identifiers[].value',
        value: '',
      }],
    }],
  }, {
    name: 'contributors',
    enabled: false,
    path: 'instance.contributors[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.contributors[]',
      fields: [{
        name: 'contributorName',
        enabled: false,
        path: 'instance.contributors[].name',
        value: '',
      }, {
        name: 'contributorNameTypeId',
        enabled: false,
        path: 'instance.contributors[].contributorNameTypeId',
        value: '',
      }, {
        name: 'contributorTypeId',
        enabled: false,
        path: 'instance.contributors[].contributorTypeId',
        value: '',
      }, {
        name: 'contributorTypeText',
        enabled: false,
        path: 'instance.contributors[].contributorTypeText',
        value: '',
      }, {
        name: 'primary',
        enabled: false,
        path: 'instance.contributors[].primary',
        value: '',
      }],
    }],
  }, {
    name: 'publication',
    enabled: false,
    path: 'instance.publication[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.publication[]',
      fields: [{
        name: 'publisher',
        enabled: false,
        path: 'instance.publication[].publisher',
        value: '',
      }, {
        name: 'role',
        enabled: false,
        path: 'instance.publication[].role',
        value: '',
      }, {
        name: 'place',
        enabled: false,
        path: 'instance.publication[].place',
        value: '',
      }, {
        name: 'dateOfPublication',
        enabled: false,
        path: 'instance.publication[].dateOfPublication',
        value: '',
      }],
    }],
  }, {
    name: 'editions',
    enabled: false,
    path: 'instance.editions[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.editions[]',
      fields: [{
        name: 'edition',
        enabled: false,
        path: 'instance.editions[]',
        value: '',
      }],
    }],
  }, {
    name: 'physicalDescriptions',
    enabled: false,
    path: 'instance.physicalDescriptions[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.physicalDescriptions[]',
      fields: [{
        name: 'physicalDescription',
        enabled: false,
        path: 'instance.physicalDescriptions[]',
        value: '',
      }],
    }],
  }, {
    name: 'instanceTypeId',
    enabled: false,
    path: 'instance.instanceTypeId',
    value: '',
    subfields: [],
  }, {
    name: 'natureOfContentTermIds',
    enabled: true,
    path: 'instance.natureOfContentTermIds[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.natureOfContentTermIds[]',
      fields: [{
        name: 'natureOfContentTermId',
        enabled: true,
        path: 'instance.natureOfContentTermIds[]',
        value: '',
        acceptedValues: {},
      }],
    }],
  }, {
    name: 'instanceFormatIds',
    enabled: false,
    path: 'instance.instanceFormatIds[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.instanceFormatIds[]',
      fields: [{
        name: 'instanceFormatId',
        enabled: false,
        path: 'instance.instanceFormatIds[]',
        value: '',
      }],
    }],
  }, {
    name: 'languages',
    enabled: false,
    path: 'instance.languages[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.languages[]',
      fields: [{
        name: 'languageId',
        enabled: false,
        path: 'instance.languages[]',
        value: '',
      }],
    }],
  }, {
    name: 'publicationFrequency',
    enabled: false,
    path: 'instance.publicationFrequency[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.publicationFrequency[]',
      fields: [{
        name: 'publicationFrequency',
        enabled: false,
        path: 'instance.publicationFrequency[]',
        value: '',
      }],
    }],
  }, {
    name: 'publicationRange',
    enabled: false,
    path: 'instance.publicationRange[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.publicationRange[]',
      fields: [{
        name: 'publicationRange',
        enabled: false,
        path: 'instance.publicationRange[]',
        value: '',
      }],
    }],
  }, {
    name: 'notes',
    enabled: false,
    path: 'instance.notes[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.notes[]',
      fields: [{
        name: 'noteType',
        enabled: false,
        path: 'instance.notes[].instanceNoteTypeId',
        value: '',
      }, {
        name: 'note',
        enabled: false,
        path: 'instance.notes[].note',
        value: '',
      }, {
        name: 'staffOnly',
        enabled: false,
        path: 'instance.notes[].staffOnly',
        value: null,
      }],
    }],
  }, {
    name: 'electronicAccess',
    enabled: false,
    path: 'instance.electronicAccess[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.electronicAccess[]',
      fields: [{
        name: 'relationshipId',
        enabled: false,
        path: 'instance.electronicAccess[].relationshipId',
        value: '',
        acceptedValues: {},
      }, {
        name: 'uri',
        enabled: false,
        path: 'instance.electronicAccess[].uri',
        value: '',
      }, {
        name: 'linkText',
        enabled: false,
        path: 'instance.electronicAccess[].linkText',
        value: '',
      }, {
        name: 'materialsSpecification',
        enabled: false,
        path: 'instance.electronicAccess[].materialsSpecification',
        value: '',
      }, {
        name: 'publicNote',
        enabled: false,
        path: 'instance.electronicAccess[].publicNote',
        value: '',
      }],
    }],
  }, {
    name: 'subjects',
    enabled: false,
    path: 'instance.subjects[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.subjects[]',
      fields: [{
        name: 'subject',
        enabled: false,
        path: 'instance.subjects[]',
        value: '',
      }],
    }],
  }, {
    name: 'classifications',
    enabled: false,
    path: 'instance.classifications[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.classifications[]',
      fields: [{
        name: 'classificationTypeId',
        enabled: false,
        path: 'instance.classifications[].classificationTypeId',
        value: '',
      }, {
        name: 'classificationNumber',
        enabled: false,
        path: 'instance.classifications[].classificationNumber',
        value: '',
      }],
    }],
  }, {
    name: 'parentInstances',
    enabled: true,
    path: 'instance.parentInstances[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.parentInstances[]',
      fields: [{
        name: 'superInstanceId',
        enabled: true,
        path: 'instance.parentInstances[].superInstanceId',
        value: '',
      }, {
        name: 'instanceRelationshipTypeId',
        enabled: true,
        path: 'instance.parentInstances[].instanceRelationshipTypeId',
        value: '',
        acceptedValues: {},
      }],
    }],
  }, {
    name: 'childInstances',
    enabled: true,
    path: 'instance.childInstances[]',
    value: '',
    subfields: [{
      order: 0,
      path: 'instance.childInstances[]',
      fields: [{
        name: 'subInstanceId',
        enabled: true,
        path: 'instance.childInstances[].subInstanceId',
        value: '',
      }, {
        name: 'instanceRelationshipTypeId',
        enabled: true,
        path: 'instance.childInstances[].instanceRelationshipTypeId',
        value: '',
        acceptedValues: {},
      }],
    }],
  }],
};

export default INSTANCE;

/* eslint-disable react/prop-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { Field } from 'react-final-form';
import { Card, Checkbox, Col, Icon, Select } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStripes } from '@folio/stripes-core';
import css from './style.css';
import ImportFile from './ImportFile';
import StripesAppsCategory from './StripesAppsCategory';
import { getLocaleLabel } from '../../../utils/utils';
import SupportedLocales from '../../../utils/SupportedLocales';

const propTypes = {
  stripes: PropTypes.shape({
    tenantLocales: PropTypes.arrayOf(object),
  }).isRequired,
  onLoadTranslations: PropTypes.func.isRequired,
};

const required = (value) =>
  value ? (
    undefined
  ) : (
    <strong>
      <FormattedMessage
        id="ui-translations.validation.required"
        defaultMessage="required"
      />
    </strong>
  );

function ImportOptions({
  onLoadTranslations,
  stripes,
  currentCategory,
  currentLocale,
  setImportFileName,
  onChangeLocaleValue,
}) {
  const intl = useIntl();
  const [isOriginTrans, setIsOriginTrans] = useState(false);

  useEffect(() => {
    if (currentCategory === 'libTranslations' && isOriginTrans) onChangeLocaleValue('en');
    else onChangeLocaleValue('');
  }, [currentCategory, isOriginTrans]);

  const getLocaleDataOptions = () => {
    const dataOptions = [
      // ...stripes.tenantLocales.map((loc) => ({
      ...SupportedLocales.map((loc) => ({
        label: getLocaleLabel(loc.localeValue, intl),
        value: loc.localeValue,
        // disabled: false,
      })),
    ];
    return dataOptions;
  };

  const getCategoriesDataOptions = () => {
    const dataOptions = [
      {
        value: 'libTranslations',
        label: intl.formatMessage({
          id: 'ui-translations.categoryMenu.libTranslations',
        }),
        disabled: false,
      },
      {
        value: 'stripesApps',
        label: intl.formatMessage({
          id: 'ui-translations.categoryMenu.stripesApps',
        }),
        disabled: false,
      },
      {
        value: 'usersNames',
        label: intl.formatMessage({
          id: 'ui-translations.categoryMenu.usersNames',
        }),
        disabled: false,
      },
      {
        value: 'permsNames',
        label: intl.formatMessage({
          id: 'ui-translations.categoryMenu.permsNames',
        }),
        disabled: false,
      },
      // {
      //   value: 'contenet',
      //   label: intl.formatMessage({
      //     id: 'ui-translations.categoryMenu.contenet',
      //   }),
      //   disabled: false,
      // },
    ];
    return dataOptions;
  };

  return (
    <Col xs={3}>
      <Card
        id="card-import-translation-ImportOptions"
        // cardStyle="positive"
        roundedBorder
        headerStart={
          <span className={css.cardHeader}>
            <Icon icon="gear">
              <FormattedMessage
                id="ui-translations.importTranslations.cardHeader.ImportOptions"
                defaultMessage="Import Options"
              />
            </Icon>
          </span>
        }
      >
        <div>
          <Field
            id="select-category-type"
            name="categoryValue"
            component={Select}
            label={
              <FormattedMessage
                id="ui-translations.importTranslations.categoryType"
                defaultMessage="Category"
              />
            }
            placeholder={intl.formatMessage({
              id: 'ui-translations.importTranslations.chooseCategory',
              defaultMessage: 'Choose Category',
            })}
            dataOptions={getCategoriesDataOptions()}
            required
            validate={required}
          />
          {currentCategory === 'libTranslations' && (
            <div style={{ paddingBottom: '20px' }}>
              <Checkbox
                label={
                  <div style={{ fontWeight: 'normal' }}>
                    <FormattedMessage
                      id="ui-translations.importTranslations.SaveAsOriginTranslations"
                      defaultMessage="Save as origin translations"
                    />
                  </div>
                }
                checked={isOriginTrans}
                onChange={() => setIsOriginTrans(!isOriginTrans)}
                onMouseDown={(e) => e.preventDefault()}
              />
            </div>
          )}
          <Field
            id="select-chooseLocale"
            name="localeValue"
            component={Select}
            label={
              <FormattedMessage
                id="ui-translations.importTranslations.Locale"
                defaultMessage="Locale"
              />
            }
            placeholder={intl.formatMessage({
              id: 'ui-translations.filters.LocaleFilter.selectuserLocales',
            })}
            dataOptions={getLocaleDataOptions()}
            required
            validate={required}
            disabled={currentCategory === 'libTranslations' && isOriginTrans}
            // marginBottom0
          />
        </div>
        {currentCategory === 'stripesApps' && (
          <StripesAppsCategory
            currentLocale={currentLocale}
            onLoadTranslations={onLoadTranslations}
          />
        )}
        <ImportFile
          onloadend={onLoadTranslations}
          setImportFileName={setImportFileName}
        />
      </Card>
    </Col>
  );
}

ImportOptions.propTypes = propTypes;

export default withStripes(ImportOptions);

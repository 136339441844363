/* kware added new */

/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Modal, Row } from '@folio/stripes-components';

class ChooseLocalesModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    setLocale: PropTypes.func,
    userLocales: PropTypes.array,
    intl: PropTypes.object,
  };

  render() {
    const { userLocales, open, onClose } = this.props;
    return (
      <Modal
        open={open}
        onClose={onClose}
        closeOnBackgroundClick
        dismissible
        label={<FormattedMessage id="stripes-core.ul.modal.switchUserlocalesModal.titel" />}
      >
        <Col xs={12}>
          <Row>
            {
              sortBy(userLocales, ['value']).map((ul, index) => (
                <Button
                  buttonStyle={(this.props.intl.locale === ul.value) ? 'primary' : 'default'}
                  key={ul.id}
                  id={`ul-btn-${index}`}
                  fullWidth
                  onClick={() => this.props.setLocale(ul)}
                >
                  <FormattedMessage id={`stripes-core.ul.button.languageName.${ul.value}`} />
                </Button>
              ))
            }
          </Row>
        </Col>
      </Modal>
    );
  }
}

export default injectIntl(ChooseLocalesModal);

/* eslint-disable indent */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
} from 'recharts';
import EmptyData from '../../../common/emptyData/EmptyData';

export const COLORS = [
  '#7DB3FF',
  '#49457B',
  '#FF7C78',
  '#FED3D0',
  '#6F76D9',
  '#9ADFB4',
  '#2E7987',
];

const PieChartPreview = ({ resultSet }) => {
  if (Object.keys(resultSet).length !== 0) {
    return (
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={resultSet.chartPivot()}
            nameKey="x"
            dataKey={resultSet.seriesNames()[0].key}
            fill="#8884d8"
          >
            {resultSet.chartPivot().map((e, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  return <EmptyData />;
};

export default PieChartPreview;

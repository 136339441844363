/* kware added new */
/* eslint-disable react/prop-types */
import React from 'react';
import { useIntl } from 'react-intl';

function HandleUserName({ user, withMiddleName = true }) {
  const intl = useIntl();

  const getFullName = () => {
    let lastName;
    let firstName;
    let middleName;

    if (user && user.personal) {
      firstName = user.personal.firstName
        ? intl.formatMessage({
          id: `ui-users.userInfo.firstName.${user.personal.firstName}`,
          defaultMessage: `${user.personal.firstName}`,
        })
        : '';
      middleName = user.personal.middleName
        ? intl.formatMessage({
          id: `ui-users.userInfo.middleName.${user.personal.middleName}`,
          defaultMessage: `${user.personal.middleName}`,
        })
        : '';
      lastName = user.personal.lastName
        ? intl.formatMessage({
          id: `ui-users.userInfo.lastName.${user.personal.lastName}`,
          defaultMessage: `${user.personal.lastName}`,
        })
        : '';
    } else {
      lastName = user?.lastName
        ? intl.formatMessage({
          id: `ui-users.userInfo.lastName.${user.lastName}`,
          defaultMessage: `${user.lastName}`,
        })
        : '';
      firstName = user?.firstName
        ? intl.formatMessage({
          id: `ui-users.userInfo.firstName.${user.firstName}`,
          defaultMessage: `${user.firstName}`,
        })
        : '';
      middleName = user?.middleName
        ? intl.formatMessage({
          id: `ui-users.userInfo.middleName.${user.middleName}`,
          defaultMessage: `${user.middleName}`,
        })
        : '';
    }

    const arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

    const delimeter = arabicAlphabetDigits.test(lastName) ? '، ' : ', ';
    return `${lastName}${firstName ? delimeter : ' '}${firstName}${
      middleName ? ' ' : ''
    }${withMiddleName ? middleName : ''}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      {getFullName()}
    </div>
  );
}

export default HandleUserName;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paneset } from '@folio/stripes-components';
import { isEqual, sortBy } from 'lodash';
import { stripesConnect } from '@folio/stripes-core';
import CopycatQueryBuilder from './components/CopycatQueryBuilder/CopycatQueryBuilder';
import CopycatResultsPreview from './components/CopycatResultsPreview/CopycatResultsPreview';
import useURLParams from './hooks/useURLParams';
import withLocation from '../../withLocation';

function Copycat(props) {
  const [resultSets, setResultSets] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showQueryPane, setShowQueryPane] = useState(true);
  const [sourcesList, setSourcesList] = useState([]);

  const [querySources, setQuerySources] = useURLParams(
    'sources',
    ['Koha Demo', 'Library of Congress'],
    'array'
  );
  const [currentPage, setCurrentPage] = useURLParams('page', 1, 'number');
  const [pageSize, setPageSize] = useURLParams('size', 50, 'number');


  useEffect(() => {
    const copycatProfiles = sortBy(
      (props.resources.copycatProfiles || {}).records || [],
      ['']
    );

    if (!isEqual(sourcesList, copycatProfiles)) {
      setSourcesList(
        copycatProfiles && copycatProfiles.length
          ? copycatProfiles[0].profiles
          : undefined
      );
    }
  }, [props.resources]);

  const handleClose = () => {
    props.goTo('/inventory?filters=&sort=title');
  };

  return (
    <>
      <Paneset>
        <CopycatQueryBuilder
          {...props}
          showQueryPane={showQueryPane}
          setShowQueryPane={setShowQueryPane}
          sourcesList={sourcesList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setResultSets={setResultSets}
          setIsLoading={setIsLoading}
          setError={setError}
          isLoading={isLoading}
          querySources={querySources}
          setQuerySources={setQuerySources}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
        <CopycatResultsPreview
          showQueryPane={showQueryPane}
          setShowQueryPane={setShowQueryPane}
          searchResponse={{ resultSets, error, isLoading }}
          selectedSources={querySources}
          sourcesList={sourcesList}
          handleClose={handleClose}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </Paneset>
    </>
  );
}

Copycat.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  resources: PropTypes.shape({
    copycatProfiles: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  }),
  mutator: PropTypes.object.isRequired,
  goTo: PropTypes.func,
};

Copycat.manifest = Object.freeze({
  copycatProfiles: {
    type: 'okapi',
    path: 'copycat/profiles?query=enabled=true sortby name&limit=1000',
  },
});

export default stripesConnect(withLocation(Copycat));

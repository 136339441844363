/* kware added new */
/* eslint-disable react/prop-types */
import React from 'react';
import { useIntl } from 'react-intl';

function FormattedLanguageName({ languages, code }) {
  const intl = useIntl();

  const getLanguageName = () => {
    const language = find(
      languages,
      (entry) => entry.alpha3 === code || entry.alpha2 === code
    );
    if (language === undefined) {
      return intl.formatMessage({ id: 'stripes-components.languages.und' });
    }
    const translationId = `stripes-components.languages.${language.alpha3}`;
    const translatedName = intl.formatMessage({ id: translationId });

    if (translatedName !== translationId) return translatedName;
    // If no translation is found, use the English name from the list above
    else return language.name;
  };
  return getLanguageName();
}

export default FormattedLanguageName;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isArray, isEmpty, mergeWith } from 'lodash';
import { stripesConnect, useOkapiKy } from '@folio/stripes/core';
import { buildQueryByIds } from '../../../../../../utils';
import RenderImportModal from './RenderImportModal';

function ImportSingleRecordModal({
  onClose,
  open,
  itemToImport,
  sourcesList,
  mutator,
  importedList,
  setImportedList,
}) {
  const okapiKy = useOkapiKy();

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [checkForDuplicates, setCheckForDuplicates] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [isDuplicatedError, setIsDuplicatedError] = useState(false);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const [currentProfile, setCurrentProfile] = useState({});
  const [jobProfiles, setJobProfiles] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    const profile = sourcesList.find(
      (source) => source.name === itemToImport?.serverName
    );
    if (profile) {
      setCurrentProfile(profile);
    }
  }, [sourcesList, itemToImport?.serverName]);

  const getJobProfilesOptions = (arr = []) => {
    const sortedOptions = [...arr];
    const defaultProfileId = currentProfile.createJobProfileId;
    const defaultProfile = sortedOptions.find(
      (profile) => profile.value === defaultProfileId
    );
    defaultProfile.label = intl.formatMessage(
      { id: 'ui-inventory.copycat.defaultJobProfile' },
      { jobProfile: defaultProfile.label }
    );
    const indexOfDefault = sortedOptions.findIndex(
      (option) => option.value === defaultProfileId
    );

    sortedOptions.splice(indexOfDefault, 1);
    sortedOptions.unshift(defaultProfile);

    return sortedOptions;
  };

  useEffect(() => {
    const allowedJobProfileIds = currentProfile?.allowedCreateJobProfileIds;

    if (!isEmpty(allowedJobProfileIds)) {
      mutator.jobProfiles
        .GET({
          params: {
            query: `${buildQueryByIds(allowedJobProfileIds)} sortBy name`,
          },
        })
        .then((resp) => {
          const optionsForSelect = resp.jobProfiles?.map((profile) => ({
            value: profile.id,
            label: profile.name,
          }));

          if (!isEmpty(optionsForSelect)) {
            const sortedOptions = getJobProfilesOptions(optionsForSelect);
            setJobProfiles(sortedOptions);
          }
        });
    }
  }, [currentProfile?.allowedCreateJobProfileIds]);

  /** Field 245 must be not repeatable(NR), so we need to merge it into one field if it is repeated. */
  const getDistinctTitleField = (marcRecordJson) => {
    const title = marcRecordJson.fields.filter((field) => Object.keys(field).includes('245'));
    return mergeWith(...title, ...title, (objValue, srcValue) => {
      if (isArray(objValue)) {
        return [...new Set(objValue.concat(srcValue))];
      }
      return undefined;
    });
  };

  /** Removes duplicates of field 245 */
  const removeTitleDuplicates = () => {
    const marcRecordJson = JSON.parse(itemToImport.marcRecordJson);
    const marcFields = marcRecordJson.fields.filter((field) => !Object.keys(field).includes('245'));
    marcRecordJson.fields = [...marcFields, getDistinctTitleField(marcRecordJson)];
    return JSON.stringify(marcRecordJson);
  };

  const loadExternalRecord = async (queryOptions) => {
    setIsLoading(true);
    try {
      const res = await okapiKy
        .post('copycat/imports', {
          timeout: 30000,
          method: 'POST',
          json: {
            profileId: queryOptions.profileId,
            selectedJobProfileId: queryOptions.jobprofileid,
            record: queryOptions.record,
          },
        })
        .json();
      if (!signal.aborted) {
        setResponse(res);
      }
    } catch (err) {
      if (!signal.aborted) {
        const res = err.response;
        const text = await res.text();

        let detail = text;
        if (res.headers.get('content-type') === 'application/json') {
          const obj = JSON.parse(text);
          detail = obj.errors[0].message;
        }
        setError(detail);
      }
    } finally {
      if (!signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  const onFormSubmit = (values) => {
    const queryOptions = {
      profileId: currentProfile.id,
      selectedJobProfileId: values.selectedJobProfileId,
      record: {
        json: removeTitleDuplicates(),
      },
    };
    loadExternalRecord(queryOptions);
  };

  const onCloseModal = () => {
    onClose();
    setIsLoading(false);
    setResponse(null);
    setError(null);
    setCheckForDuplicates(false);
    setIsDuplicated(false);
    setIsDuplicatedError(false);
  };

  return (
    <RenderImportModal
      onSubmit={onFormSubmit}
      initialValues={{
        selectedJobProfileId: jobProfiles[0]?.value,
      }}
      jobProfiles={jobProfiles}
      onClose={onCloseModal}
      open={open}
      isLoading={isLoading}
      response={response}
      error={error}
      itemToImport={itemToImport}
      importedList={importedList}
      setImportedList={setImportedList}
      setCheckForDuplicates={setCheckForDuplicates}
      checkForDuplicates={checkForDuplicates}
      isDuplicated={isDuplicated}
      setIsDuplicated={setIsDuplicated}
      isDuplicatedError={isDuplicatedError}
      setIsDuplicatedError={setIsDuplicatedError}
    />
  );
}

ImportSingleRecordModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  itemToImport: PropTypes.object,
  sourcesList: PropTypes.arrayOf(PropTypes.object),
  mutator: PropTypes.shape({
    jobProfiles: PropTypes.object,
    query: PropTypes.shape({
      update: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
  }),
  importedList: PropTypes.arrayOf(PropTypes.object),
  setImportedList: PropTypes.func,
};

ImportSingleRecordModal.manifest = Object.freeze({
  jobProfiles: {
    type: 'okapi',
    path: 'data-import-profiles/jobProfiles',
    accumulate: true,
  },
});

export default stripesConnect(ImportSingleRecordModal);

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import { Icon } from '@folio/stripes-components';
import css from './style.css';

const propTypes = {
  onloadend: PropTypes.func.isRequired,
  setImportFileName: PropTypes.func.isRequired,
};

let fileReader;

function ImportFile(props) {
  const onReadTranslationFileContenet = () => {
    props.onloadend(JSON.parse(fileReader.result));
  };

  const onChooseImportedFile = (e) => {
    fileReader = new FileReader();
    fileReader.onloadend = onReadTranslationFileContenet;
    fileReader.readAsText(e);
    props.setImportFileName(e.name);
  };

  return (
    <>
      <br />
      <Dropzone
        accept=".json"
        multiple={false}
        onDrop={(acceptedFiles) => {
          // do nothing if no files
          if (acceptedFiles.length === 0) {
            return;
          }
          onChooseImportedFile(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            className={css.dropzone}
            {...getRootProps({ onFocus: (e) => e.preventDefault() })}
          >
            <input {...getInputProps()} />
            <Icon icon="import" size="large" />
            <br />
            <FormattedMessage
              id="ui-translations.importTranslations.dropzoneMessage"
              defaultMessage="Drag and drop file here, or click to select file"
            />
          </div>
        )}
      </Dropzone>
    </>
  );
}

ImportFile.propTypes = propTypes;

export default ImportFile;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Icon,
  Pane,
  Paneset,
  Tooltip,
} from "@folio/stripes-components";
import { FormattedMessage, useIntl } from "react-intl";
import { AppIcon } from "@folio/stripes-core";
import TranslationsStats from "../Statistics/TranslationsStats";
import useQueryParams from "../../Hooks/useQueryParams";
import ActiveFilters from "../Filters/ActiveFilters";
import CategoryMenu from "../CategoryMenu/CategoryMenu";
import ActionsMenu from "../ActionsMenu/ActionsMenu";
import { getLocaleLabel } from "../../utils/utils";
import SearchBox from "../SearchBox/SearchBox";
import ResultsPane from "../ResultsPane/ResultsPane";
import css from "./TranslationWrapper.css";

function TranslationWrapper({
  activeFilters,
  ViewMetaData,
  currentLocale,
  setCurrentLocale,
  source,
  setSource,
  originTranslations,
  localeTranslations,
  userNamesTranslation,
  categoryName,
  stripes,
  paneTitle,
  getAppTranslations,
  apps,
}) {
  const [searchActive, setSearchActive] = useQueryParams("search", "false");
  const [searchTerm, setSearchTerm] = useQueryParams("term", "");
  const [selectedIndex, setSelectedIndex] = useQueryParams(
    "index",
    "localeTranslation"
  );

  const [currentApp, setCurrentApp] = useQueryParams("app", "allApps");
  const [currentTable, setCurrentTable] = useQueryParams("table", "allTables");
  const [currentUser, setCurrentUser] = useQueryParams("locale", "");
  const [currentStatus, setCurrentStatus] = useQueryParams(
    "currentStatus",
    "allTranslations"
  );

  const [ContentData, setContentData] = useState([]);
  const [selection, setSelection] = useState({});
  const [showFilters, setShowFilters] = useState(true);
  const [showStats, setShowStats] = useState(true);

  const [searchResults, setSearchResults] = useState([]);

  const [filteredOrgTranslations, setOrgTranslations] = useState([]);

  const intl = useIntl();

  const getApps = () => {
    const APPs = [];
    Object.keys(originTranslations).forEach((key) =>
      APPs.push(key.substr(0, key.indexOf(".")))
    );
    return [...new Set(APPs)].filter((e) => {
      return e;
    });
  };

  const filterOriginTranslations = () => {
    const filterTrans = {};
    Object.keys(originTranslations).forEach((key) => {
      if (originTranslations[key]) {
        filterTrans[key] = originTranslations[key];
      }
    });
    return filterTrans;
  };

  const getTranslationsByApp = () => {
    if (getAppTranslations) return getAppTranslations(currentApp);
    else if (currentApp === "allApps") return originTranslations;
    else {
      const appTrans = {};
      Object.keys(originTranslations).forEach((key) => {
        if (key.startsWith(currentApp) && originTranslations[key]) {
          appTrans[key] = originTranslations[key];
        }
      });
      return appTrans;
    }
  };

  const getTranslationsByTable = () => {
    if (currentTable === "allTables") return getTranslationsByApp();
    else {
      const appTrans = {};
      Object.keys(getTranslationsByApp()).forEach((key) => {
        if (key.split(".", 2).join(".").endsWith(currentTable)) {
          appTrans[key] = getTranslationsByApp()[key];
        }
      });
      return appTrans;
    }
  };

  const getAppTables = () => {
    const Taples = [];
    Object.keys(getTranslationsByApp()).forEach((key) =>
      Taples.push(key.split(".", 2)[1])
    );
    return [...new Set(Taples)].filter((e) => {
      return e;
    });
  };

  const getContentData = () => {
    switch (currentStatus) {
      case "allTranslations":
        return ContentData;
      case "translated":
        return ContentData.filter(
          (trans) => trans.localeTranslation !== undefined
        );
      case "notTranslated":
        return ContentData.filter(
          (trans) => trans.localeTranslation === undefined
        );
      case "englishOnly":
        return ContentData.filter(
          (trans) => trans.localeTranslation === trans.originTranslation
        );
      case "noneEnglish":
        return ContentData.filter(
          (trans) => trans.localeTranslation !== trans.originTranslation
        );
      default:
        return ContentData;
    }
  };

  const getResultTotalCount = () => {
    return (
      <FormattedMessage
        id="stripes-smart-components.searchResultsCountHeader"
        values={{
          count:
            searchActive === "true"
              ? searchResults.length
              : getContentData().length,
        }}
      />
    );
  };

  const resetAllFilters = () => {
    setCurrentLocale("");
    setCurrentApp("allApps");
    setCurrentTable("allTables");
    setCurrentStatus("allTranslations");
    setSearchActive("false");
    setSearchTerm("");
    setSearchResults([]);
    setContentData([]);
    setSelectedIndex("localeTranslation");
    setShowStats(true);
    if (setSource) setSource("merged");
  };

  const getSearchResultsByValues = (array, string, index) => {
    console.log({ string, index, array });
    if (array) {
      return array.filter((obj) => {
        const str = obj[index] ? obj[index].toLowerCase() : "";
        return str.includes(string.toLowerCase());
      });
    }
  };

  const onUpdateResults = () => {
    const keyTranslations = activeFilters.includes("table")
      ? getTranslationsByTable()
      : getTranslationsByApp();
    const contentData = [];
    for (const key in keyTranslations) {
      if (key && currentLocale) {
        contentData.push({
          originTranslation: originTranslations[key],
          localeTranslation: localeTranslations[key],
          translationKey: key,
        });
      }
    }
    if (searchActive === "true") {
      setSearchResults(
        getSearchResultsByValues(contentData, searchTerm, selectedIndex)
      );
    } else setContentData(contentData);
  };

  const onUpdateUserNamesResults = () => {
    const contentData = [];
    userNamesTranslation.forEach((userName) => {
      const newData = {};
      Object.keys(userName).forEach((key) => {
        if (key === "fullName") {
          newData.originTranslation = userName[key].originFullName;
          newData.localeTranslation = userName[key].translatedFullName;
        } else {
          newData.originData = userName[key].originData;
          newData.translatedData = userName[key].translatedData;
          newData.translationKey = userName[key].translationKey;
        }
      });
      contentData.push(newData);
    });
    if (contentData && contentData.length !== 0) setContentData(contentData);
  };

  useEffect(() => {
    if (categoryName !== "usersNames") {
      onUpdateResults();
    } else {
      onUpdateUserNamesResults();
    }
    // if (currentLocale !== '') setCurrentLocale(currentLocale);
  }, [
    currentLocale,
    currentApp,
    currentTable,
    currentStatus,
    originTranslations,
    localeTranslations,
    selectedIndex,
    searchActive,
  ]);

  const renderShowFiltersButton = () => {
    return (
      <>
        {!showFilters ? (
          <Tooltip
            id="Translation-showFilters-tooltip"
            text={
              <FormattedMessage id="ui-translations.buttons.tooltip.showFilters" />
            }
          >
            {({ ref, ariaIds }) => (
              <Button
                buttonStyle="dropdownItem"
                id="clickable-translations-showFilter"
                marginBottom0
                onClick={() => setShowFilters(!showFilters)}
                aria-labelledby={ariaIds.text}
                ref={ref}
              >
                <Icon icon="chevron-double-right" />
              </Button>
            )}
          </Tooltip>
        ) : (
          <div />
        )}
      </>
    );
  };

  const renderShowTranslationsTools = () => {
    const contentData =
      searchActive === "true" ? searchResults : getContentData();
    const selectedTrans = contentData.filter(
      (trans) => selection[trans.translationKey]
    );

    const translatedAppName =
      currentApp !== "allApps"
        ? currentApp.startsWith("ui-")
          ? intl.formatMessage({
              id: `${currentApp}.meta.title`,
              defaultMessage: `${currentApp.replace("ui-", "")}`,
            })
          : intl.formatMessage({
              id: `ui-translations.appName.${currentApp}`,
              defaultMessage: `${currentApp}`,
            })
        : intl.formatMessage({
            id: "ui-translations.filters.AppFilter.allApps",
          });

    return (
      <>
        {
          <ActionsMenu
            currentLocale={currentLocale}
            categoryName={categoryName}
            currentStatus={currentStatus}
            contentData={
              searchResults.length ? searchResults : getContentData()
            }
            selectedTranslations={
              selectedTrans.length !== 0 ? selectedTrans : []
            }
            originTranslations={originTranslations}
            fileName={`${intl.formatMessage({
              id: `ui-translations.category.${categoryName}`,
            })} - ${getLocaleLabel(
              currentLocale,
              intl
            )} - ${translatedAppName}`}
          />
        }
        {!showStats ? (
          <div style={{ paddingLeft: "0.7em", paddingRight: "0.7em" }}>
            <Tooltip
              id="Translation-showStats-tooltip"
              text={
                <FormattedMessage id="ui-translations.buttons.tooltip.showStats" />
              }
            >
              {({ ref, ariaIds }) => (
                <Button
                  buttonStyle="dropdownItem"
                  id="clickable-translations-showStats"
                  marginBottom0
                  onClick={() => setShowStats(!showStats)}
                  aria-labelledby={ariaIds.text}
                  ref={ref}
                >
                  {/* <Icon icon="chevron-double-left" /> */}
                  <AppIcon app="erm-usage" />
                </Button>
              )}
            </Tooltip>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  const renderResultsPane = () => {
    return (
      <ResultsPane
        contentData={searchActive === "true" ? searchResults : getContentData()}
        searchResults={searchResults}
        searchActive={searchActive}
        searchTerm={searchTerm}
        selectedIndex={selectedIndex}
        currentLocale={currentLocale}
        category={categoryName}
        ViewMetaData={ViewMetaData}
        paneTitle={paneTitle}
        selection={selection}
        setSelection={setSelection}
      />
    );
  };

  return (
    <>
      <Paneset>
        {showFilters ? (
          <Pane
            defaultWidth="20%"
            paneTitle={<CategoryMenu categoryName={categoryName} />}
            // fluidContentWidth
            noOverflow
            lastMenu={
              <Tooltip
                id="Translation-hideFilters-tooltip"
                text={
                  <FormattedMessage id="ui-translations.buttons.tooltip.hideFilters" />
                }
              >
                {({ ref, ariaIds }) => (
                  <Button
                    buttonStyle="dropdownItem"
                    id="clickable-translations-hideFilters"
                    marginBottom0
                    onClick={() => setShowFilters(!showFilters)}
                    aria-labelledby={ariaIds.text}
                    ref={ref}
                  >
                    <Icon icon="chevron-double-left" />
                  </Button>
                )}
              </Tooltip>
            }
          >
            {
              <SearchBox
                ContentData={getContentData()}
                currentLocale={currentLocale}
                searchTerm={searchTerm}
                onChangeSearchTerm={setSearchTerm}
                searchActive={searchActive}
                onActivateSearch={setSearchActive}
                onChangeSearchResults={setSearchResults}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                setShowStats={setShowStats}
              />
            }
            {
              <ActiveFilters
                activeFilters={activeFilters}
                currentLocale={currentLocale}
                setCurrentLocale={setCurrentLocale}
                currentApp={currentApp}
                setCurrentApp={(loc) => {
                  setCurrentApp(loc);
                  setCurrentTable("allTables");
                }}
                currentTable={currentTable}
                setCurrentTable={setCurrentTable}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
                source={source}
                setSource={setSource}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                resetAllFilters={resetAllFilters}
                stripes={stripes}
                // checkLocaleTranslators={checkLocaleTranslators}
                apps={apps || getApps()}
                tables={getAppTables()}
                originTranslations={originTranslations}
              />
            }
          </Pane>
        ) : (
          <div />
        )}
        <Pane
          appIcon={<AppIcon app="translations" />}
          fluidContentWidth
          noOverflow
          paneTitle={paneTitle}
          paneSub={getResultTotalCount()}
          firstMenu={renderShowFiltersButton()}
          lastMenu={renderShowTranslationsTools()}
        >
          {currentLocale === "" ? (
            <div className={css.noLanguageSelected}>
              <Icon icon="arrow-left">
                {intl.formatMessage({
                  id: "ui-translations.localeTranslations.noLanguageSelected",
                })}
              </Icon>
            </div>
          ) : (
            renderResultsPane()
          )}
        </Pane>
        {showStats ? (
          <Pane
            appIcon={<AppIcon app="erm-usage" />}
            defaultWidth="20%"
            fluidContentWidth
            paneTitle={
              <FormattedMessage id="ui-translations.pane.Statistics" />
            }
            firstMenu={
              showStats ? (
                <Tooltip
                  id="Translation-hideStats-tooltip"
                  text={
                    <FormattedMessage id="ui-translations.buttons.tooltip.hideStats" />
                  }
                >
                  {({ ref, ariaIds }) => (
                    <Button
                      buttonStyle="dropdownItem"
                      id="clickable-translations-hideStats"
                      marginBottom0
                      onClick={() => setShowStats(!setShowStats)}
                      aria-labelledby={ariaIds.text}
                      ref={ref}
                    >
                      <Icon icon="chevron-double-right" />
                    </Button>
                  )}
                </Tooltip>
              ) : (
                <div />
              )
            }
          >
            <TranslationsStats
              currentLocale={currentLocale}
              currentApp={currentApp}
              CategoryName={categoryName}
              // type="stripes"
              originTranslations={originTranslations}
              currentLocaleTranslations={localeTranslations}
              appTranslations={getTranslationsByApp()}
              apps={apps || getApps()}
              showAppStats
              ViewMetaData={ViewMetaData}
              /** for making untranslated as a hyper link */
              // setCurrentStatus={setCurrentStatus}
              // setContentData={setContentData}
            />
          </Pane>
        ) : (
          <div />
        )}
      </Paneset>
    </>
  );
}

export default TranslationWrapper;

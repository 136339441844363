/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import {
  Accordion,
  Col,
  FilterAccordionHeader,
  MultiSelection,
  Row,
  Select,
  Selection,
} from '@folio/stripes/components';
import { FormattedMessage, useIntl } from 'react-intl';
import ReportsContext from '../../../../Context/reports/ReportsContext';

function WidgetReportData({ currentReport, setCurrentReport }) {
  const intl = useIntl();
  const reports = useContext(ReportsContext);
  const [filterToggle, setFilterToggle] = useState(true);

  return (
    <>
      <Accordion
        id="widget-form.widget-report-data-accordion"
        label={
          <FormattedMessage
            id="ui-reports.dashboards.widgetForm.reportData.accordion.header"
            defaultMessage="Report Data"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator={false}
        header={FilterAccordionHeader}
      >
        <Row>
          <Col xs={6}>
            <Selection
              id="widget-form-report-data-report-name-selection-field"
              // name="widgetName"
              label={
                <FormattedMessage
                  id="ui-reports.dashboards.widgetForm.reportData.nameField.label"
                  defaultMessage="Report name"
                />
              }
              value={currentReport?.id}
              dataOptions={reports.map((report) => ({
                label: report.name,
                value: report.id,
              }))}
              onChange={(report) =>
                setCurrentReport(reports.find((rep) => rep.id === report))
              }
            />
          </Col>
        </Row>
        {currentReport?.id && (
          <Row>
            <Col xs={6}>
              <Field
                id="widget-form-report-data-report-visable-columns-selection-field"
                name="visableColumns"
                component={MultiSelection}
                label={
                  <FormattedMessage
                    id="ui-reports.dashboards.widgetForm.reportData.visableColumnsField.label"
                    defaultMessage="Visable columns"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-reports.dashboards.widgetForm.reportData.visableColumnsField.placeholder',
                  defaultMessage: 'Select visable columns',
                })}
                // required
                // validate={required}
                dataOptions={currentReport?.queryMetadata?.measures?.map(
                  (col) => ({
                    label: col,
                    value: col,
                  })
                )}
              />
            </Col>
          </Row>
        )}
      </Accordion>
    </>
  );
}

WidgetReportData.propTypes = {
  currentReport: PropTypes.object.isRequired,
  setCurrentReport: PropTypes.func.isRequired,
};

export default WidgetReportData;

/* eslint-disable react/prop-types */
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { Badge } from '@folio/stripes-components';
import { getListStyle } from './utils';
import AppItem from './AppItem';
import css from './style.css';

function DroppableList({ apps, totalApps, droppableId }) {
  return (
    <div className={css.listContainer}>
      <div className={css.listLabel}>
        <strong>
          <FormattedMessage
            id={`ui-myprofile.settings.userPreferredApps.${droppableId}`}
            defaultMessage={droppableId}
          />
        </strong>
        <Badge>{totalApps}</Badge>
      </div>
      <div>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            // <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            <div ref={provided.innerRef} className={snapshot.isDraggingOver ? css.draggingOver : css.dragging}>
              {apps.map((app, index) => (
                <AppItem app={app} index={index} key={app.module} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}

export default DroppableList;

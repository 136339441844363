/* kware added new */

/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const WithTenantDefualtLocale = WrappedComponent => class WithTenantDefualtLocaleComponent extends Component {
  static manifest = Object.freeze({
    ...WrappedComponent.manifest,
    recordId: {},
    settings: {
      type: 'okapi',
      records: 'configs',
      path: 'configurations/entries?query=(module=ORG and configName=localeSettings)',
      accumulate: true,
      POST: {
        path: 'configurations/entries',
      },
      PUT: {
        path: 'configurations/entries',
      },
      DELETE: {
        path: 'configurations/entries'
      }
    },
  });

  static propTypes = {
    stripes: PropTypes.shape({
      connect: PropTypes.func.isRequired,
    }).isRequired,
    resources: PropTypes.shape({
      settings: PropTypes.shape({
        records: PropTypes.arrayOf(PropTypes.object),
      }),
    }).isRequired,
    mutator: PropTypes.shape({
      recordId: PropTypes.shape({
        replace: PropTypes.func,
      }),
      settings: PropTypes.shape({
        GET: PropTypes.func.isRequired,
        POST: PropTypes.func.isRequired,
        PUT: PropTypes.func.isRequired,
        DELETE: PropTypes.func.isRequired,
      }),
    }),
  };

  componentDidMount() {
    this.props.mutator.settings.GET();
  }

  getTenantDefualtLocale = () => {
    const localeSetting = this.props.resources.settings.records[0] || [];
    const localeValue = localeSetting.value ? JSON.parse(localeSetting.value) : undefined;
    return localeValue ? localeValue.locale : undefined;
  }

  getTenantTimeZone = () => {
    const localeSetting = this.props.resources.settings.records[0] || [];
    const localeValue = localeSetting.value ? JSON.parse(localeSetting.value) : undefined;
    return localeValue ? localeValue.timezone : undefined;
  }

  getTenantCurrency = () => {
    const localeSetting = this.props.resources.settings.records[0] || [];
    const localeValue = localeSetting.value ? JSON.parse(localeSetting.value) : undefined;
    return localeValue ? localeValue.currency : undefined;
  }

  geLocaleIconStatus = () => {
    const localeSetting = this.props.resources.settings.records[0] || [];
    const localeValue = localeSetting.value ? JSON.parse(localeSetting.value) : undefined;
    return localeValue ? localeValue.showLocaleIcon : false;
  }

  getLocaleSettings = () => {
    const localeSetting = this.props.resources.settings.records[0] || [];
    const localeValue = localeSetting.value ? JSON.parse(localeSetting.value) : undefined;
    return localeValue || {};
  }

  onChangeTenantDefualtLocale = (localeSetting) => {
    const record = this.props.resources.settings.records[0];
    if (record) {
      const localeValues = JSON.parse(record.value);

      localeValues.locale = localeSetting.locale;
      localeValues.timezone = localeSetting.timezone;
      localeValues.currency = localeSetting.currency;
      localeValues.showLocaleIcon = localeSetting.showLocaleIcon;
      localeValues.timeformat = localeSetting.timeformat;
      localeValues.primaryInputLanguage = localeSetting.primaryInputLanguage;

      this.props.mutator.recordId.replace(record.id);
      record.value = JSON.stringify(localeValues);
      this.props.mutator.settings.PUT(record);
    } else {
      this.props.mutator.settings.POST({
        module: 'ORG',
        config_name: 'localeSettings',
        value: localeSetting,
      });
    }
  }

  render() {
    return (
      <WrappedComponent
        getTenantDefualtLocale={this.getTenantDefualtLocale}
        getTenantTimeZone={this.getTenantTimeZone}
        getTenantCurrency={this.getTenantCurrency}
        geLocaleIconStatus={this.geLocaleIconStatus}
        onChangeTenantDefualtLocale={this.onChangeTenantDefualtLocale}
        getLocaleSettings={this.getLocaleSettings}
        {...this.props}
      />
    );
  }
};

export default WithTenantDefualtLocale;

/* eslint-disable react/prop-types */
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import DndContext from './DndContext';

export default function ListsManager({ apps, onChangeUserPreferredApps }) {
  const renderAppList = ({ fields }) => {
    const filterdApps = apps.filter(app => {
      return !fields.value.some(usrApp => {
        return app.module === usrApp.module;
      });
    });

    return (
      <DndContext apps={filterdApps} userApps={fields.value} totalInstalledApps={apps.length} onChangeUserPreferredApps={onChangeUserPreferredApps} />
    );
  };

  return <FieldArray name="userPreferredApps" component={renderAppList} />;
}

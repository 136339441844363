import React from 'react';
import { JSONtoCSV } from '../../utils/utils';
import ExportTranslationsForm from './ExportTranslationsForm';

const propTypes = {};

function ExportTranslationsManager(props) {
  const exportFile = (filename, data, fileType) => {
    const blob =
      fileType === 'json'
        ? new Blob([data], { type: 'application/json' })
        : new Blob([data], { type: 'text/csv' });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  const getEportedData = (exportedFields, fileType) => {
    const dataToBeExport =
      props.selectedTranslations.length === 0
        ? props.contentData
        : props.selectedTranslations;

    if (fileType === 'json') {
      const filteredData = {};
      dataToBeExport.forEach((trans) => {
        if (exportedFields === 'translationKey+localeTranslation') {
          filteredData[trans.translationKey] = trans.localeTranslation;
        } else if (exportedFields === 'translationKey+originTranslation') {
          filteredData[trans.translationKey] = trans.originTranslation;
        } else if (exportedFields === 'originTranslation+localeTranslation') {
          filteredData[trans.originTranslation] = trans.localeTranslation;
        }
      });

      return filteredData;
    } else {
      return JSONtoCSV(dataToBeExport, String(exportedFields).split('+'));
    }
  };

  const onFormSubmit = (values) => {
    if (values.fileType === 'csv') {
      exportFile(
        `${values.fileName}.${values.fileType}`,
        getEportedData(values.exportFields, values.fileType),
        values.fileType
      );
    } else {
      exportFile(
        `${values.fileName}.${values.fileType}`,
        JSON.stringify(
          getEportedData(values.exportFields, values.fileType),
          null,
          ' '
        ),
        values.fileType
      );
    }
  };

  return (
    <>
      <ExportTranslationsForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={{
          fileName: props.fileName,
          fileType: 'json',
          exportFields: 'translationKey+localeTranslation',
        }}
      />
    </>
  );
}

ExportTranslationsManager.propTypes = propTypes;

export default ExportTranslationsManager;

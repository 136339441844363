// /* eslint-disable react/no-unused-state */
// /* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ViewMetaData } from '@folio/stripes/smart-components';
import { get, sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import TranslationWrapper from '../components/TranslationWrapper/TranslationWrapper';
import useQueryParams from '../Hooks/useQueryParams';
import WithTranslations from '../components/Wrappers/WithTranslations';
import CurrentLocaleContext from '../Context/CurrentLocale/CurrentLocaleContext';

const manifest = Object.freeze({
  users: {
    type: 'okapi',
    records: 'users',
    path: 'users?limit=1000',
    // accumulate: 'true',
    // fetch: false,
    resourceShouldRefresh: true,
  },
});

const propTypes = {
  resources: PropTypes.shape({
    users: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  mutator: PropTypes.shape({
    users: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
    }),
  }),
};
function UserNamesRoute(props) {
  const [currentLocale, setCurrentLocale] = useQueryParams('locale', '');

  const getCurrentLocaleTranslations = () => {
    return props.getTranslations(currentLocale, 'usersNames') || {};
  };

  const getTranslatedFullName = (user) => {
    const firstName = user.firstName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.firstName.${user.firstName}`
      ] || ''
      : '';
    const middleName = user.middleName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.middleName.${user.middleName}`
      ] || ''
      : '';
    const lastName = user.lastName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.lastName.${user.lastName}`
      ] || ''
      : '';
    const preferredFirstName = user.preferredFirstName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.preferredFirstName.${user.preferredFirstName}`
      ] || ''
      : '';

    const arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;
    const delimeter = arabicAlphabetDigits.test(lastName) ? '، ' : ', ';
    const TranslatedFullName = `${lastName}${
      firstName ? delimeter : ''
    } ${firstName}${preferredFirstName ? ` (${preferredFirstName}) ` : ''}${
      middleName ? ' ' : ''
    }${middleName}`;
    return TranslatedFullName === ' ' ? undefined : TranslatedFullName;
  };

  const getTranslatedUserData = (user) => {
    const firstName = user.firstName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.firstName.${user.firstName}`
      ] || ''
      : '';
    const middleName = user.middleName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.middleName.${user.middleName}`
      ] || ''
      : '';
    const lastName = user.lastName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.lastName.${user.lastName}`
      ] || ''
      : '';
    const preferredFirstName = user.preferredFirstName
      ? getCurrentLocaleTranslations()[
        `ui-users.userInfo.preferredFirstName.${user.preferredFirstName}`
      ] || ''
      : '';

    return { lastName, firstName, middleName, preferredFirstName };
  };

  const getUserTranslationKeys = (user) => {
    const firstName = user.firstName
      ? `ui-users.userInfo.firstName.${user.firstName}` || ''
      : '';
    const middleName = user.middleName
      ? `ui-users.userInfo.middleName.${user.middleName}` || ''
      : '';
    const lastName = user.lastName
      ? `ui-users.userInfo.lastName.${user.lastName}` || ''
      : '';
    const preferredFirstName = user.preferredFirstName
      ? `ui-users.userInfo.preferredFirstName.${user.preferredFirstName}` || ''
      : '';

    return { lastName, firstName, middleName, preferredFirstName };
  };

  const getOriginFullName = (user) => {
    const lastName = get(user, 'lastName', '');
    const firstName = get(user, 'firstName', '');
    const middleName = get(user, 'middleName', '');
    const preferredFirstName = get(user, 'preferredFirstName', '');

    return `${lastName}${firstName ? ',' : ' '} ${firstName}${
      preferredFirstName ? ` (${preferredFirstName})` : ''
    }${middleName ? ' ' : ''}${middleName}`;
  };

  const getUserNamesTranslation = () => {
    const allUsers = sortBy((props.resources.users || {}).records || [], [
      'firstName',
    ])
      .map((user) => user.personal)
      .filter(
        (user) => !user.lastName.includes('System') &&
          !user.lastName.includes('Superuser')
      );
    const localeTranslation = [];
    if (allUsers) {
      allUsers.forEach((user) => {
        const trans = {};
        trans.fullName = {
          originFullName: getOriginFullName(user),
          translatedFullName: getTranslatedFullName(user),
        };
        trans.userData = {
          originData: {
            lastName: get(user, 'lastName', ''),
            firstName: get(user, 'firstName', ''),
            middleName: get(user, 'middleName', ''),
            preferredFirstName: get(user, 'preferredFirstName', ''),
          },
          translatedData: getTranslatedUserData(user),
          translationKey: getUserTranslationKeys(user),
        };
        localeTranslation.push(trans);
      });
    }
    return localeTranslation;
  };

  const getOriginTranslations = () => {
    return getUserNamesTranslation().map(
      (trans) => trans.fullName.originFullName
    );
  };

  const getLocaleTranslation = () => {
    return getUserNamesTranslation().map(
      (trans) => trans.fullName.translatedFullName
    );
  };

  // useEffect(() => {
  //   props.history.push({
  //     pathname: '/translations/usersNames',
  //     search: props.location.search,
  //     state: { backPathname: props.location.pathname },
  //   });
  //   return () => {
  //     setCurrentLocale('');
  //   };
  // }, []);

  return (
    <>
      <TranslationWrapper
        {...props}
        categoryName="usersNames"
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
        originTranslations={getOriginTranslations()}
        localeTranslations={getLocaleTranslation()}
        userNamesTranslation={getUserNamesTranslation()}
        // showAppStats={false}
        checkLocaleTranslators={props.getLocaleTranslatorsByValue}
        activeFilters={['locale', 'status', 'user']}
        ViewMetaData={
          <ViewMetaData
            metadata={props.getTranslationsMetadata(
              currentLocale,
              'usersNames'
            )}
          />
        }
        paneTitle={
          <FormattedMessage id="ui-translations.category.usersNames" />
        }
        // will be merged later
        statsProps={{
          originTranslations: getOriginTranslations(),
          localeTranslations: getLocaleTranslation(),
          activeTabs: ['category', 'locale', 'user'],
          activeLabels: [],
        }}
      />
    </>
  );
}

UserNamesRoute.propTypes = propTypes;
UserNamesRoute.manifest = manifest;

export default withStripes(stripesConnect(WithTranslations(UserNamesRoute)));

/* kware added new */

/* eslint-disable import/prefer-default-export */
import { createIntlCache, createIntl } from 'react-intl';

export const getLocaleLabel = (localeValue, intl) => {
  const cache = createIntlCache();
  const locale = { intl: createIntl({
    locale: localeValue,
    messages: {}
  }, cache) };

  return intl.formatDisplayName(localeValue, { type: 'language' }) !== undefined ?
    `${intl.formatDisplayName(localeValue, { type: 'language' })} - ${locale.intl.formatDisplayName(localeValue, { type: 'language' })}` :
    intl.formatMessage({ id: `stripes-core.ul.button.languageName.${localeValue}` });
};

export const ImportLocalesIcones = () => {
  const req = require.context('!!react-svg-loader!../UserLocalesSwitcher/localesIcons/', true, /\.svg$/);
  return req.keys().reduce((images, path) => Object.assign(images, {
    [path.slice(2, path.length - 4)]: req(path).default,
  }), {});
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Select } from '@folio/stripes/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { filtersOperators } from '../../utils';

function OperatorField({ field, index, disabled }) {
  const intl = useIntl();

  return (
    <>
      <Field
        id={`query-builder-form-operatorField-select-${index}`}
        component={Select}
        label={
          <FormattedMessage
            id="ui-reports.queryBuilder.operatorField.label"
            defaultMessage="Operator"
          />
        }
        name={`${field}.operator`}
        placeholder={intl.formatMessage({
          id: 'ui-reports.queryBuilder.operatorField.placeholder',
          defaultMessage: 'Choose operator name',
        })}
        dataOptions={filtersOperators}
        disabled={disabled}
        defaultValue="equals"
      />
    </>
  );
}

OperatorField.propTypes = {
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default OperatorField;

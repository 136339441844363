/* eslint-disable react/prop-types */
import React from 'react';
/* Kware Start Editing */
import { Row, Col, PaneFooter, Button } from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';
/* Kware end Editing */
import ScanTotal from '../ScanTotal';
import css from './ScanFooter.css';

const ScanFooter = (props) => (
  // <div className={css.root}>
  //   <Row>
  //     <Col
  //       xsOffset={8}
  //       xs={4}
  //     >
  //       <Row end="xs">
  //         <ScanTotal
  //           buttonId="clickable-done-footer"
  //           {...props}
  //         />
  //       </Row>
  //     </Col>
  //   </Row>
  // </div>
  /* Kware Start Editing */
  <PaneFooter
    renderEnd={
      <Button
        id="clickable-done-footer"
        buttonStyle="primary mega"
        onClick={props.onSessionEnd}
        marginBottom0
      >
        <FormattedMessage id="ui-checkout.end.session" />
      </Button>
    }
    renderStart={
      props.total > 0 && (
        <>
          <strong>
            <FormattedMessage
              id="ui-checkout.totalItemsScanned"
              values={{ total: props.total }}
            />
          </strong>
        </>
      )
    }
  />
  /* Kware End Editing */
);

export default ScanFooter;

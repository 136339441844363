import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  ContentData: PropTypes.arrayOf(PropTypes.object),
  completed: PropTypes.string,
  bgcolor: PropTypes.string,
};

function TranslationRate(props) {
  const containerStyles = {
    margin: 5,
    height: 22,
    backgroundColor: '#e0e0de',
    borderRadius: 50,
  };

  const fillerStyles = {
    height: '100%',
    width: `${props.completed}%`,
    backgroundColor: props.bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  };

  const labelStyles = {
    // padding: 5,
    color: 'white',
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex'
  };

  return (
    <div>
      {props.ContentData.length ?
        <div>
          <li style={{ paddingTop: '0.40em' }}><FormattedMessage id="ui-translations.Statistics.TranslationRate" /></li>
          <div style={containerStyles}>
            <div style={fillerStyles}>
              <span style={labelStyles}>{`${props.completed}%`}</span>
            </div>
          </div>
        </div> : <div />}
    </div>
  );
}

TranslationRate.propTypes = propTypes;

export default TranslationRate;

/* kware added new */

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NavList,
  NavListSection,
  NavListItem,
  Icon,
  DropdownMenu,
  Dropdown,
  IconButton,
  Button,
} from '@folio/stripes-components';

const propTypes = {
  stripes: PropTypes.shape({
    setLocale: PropTypes.func,
    setDateformat: PropTypes.func,
    okapi: PropTypes.object,
    locale: PropTypes.string,
    userPreferredLocale: PropTypes.string,
    tenantDefaultLocale: PropTypes.string,
    userLocales: PropTypes.arrayOf(object),
    tenantLocales: PropTypes.arrayOf(object),
  }).isRequired,
};

const AppListSettingsMenu = (props) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const getDropdownContent = () => {
    const options = ['preferredOnly', 'preferredFirst', 'default'];

    return (
      <div>
        <NavList>
          <NavListSection>
            {options.map((option) => (
              <NavListItem
                id={`clickable-locale-${option}`}
                type="button"
                onClick={() => {
                  props.setDefaultView(option);
                  toggleDropdown();
                }}
                //   selected={option === props.defaultView}
                disabled={option === props.defaultView}
              >
                <Icon
                  icon={option === props.defaultView ? 'select-all' : 'default'}
                  size="small"
                >
                  <FormattedMessage
                    id={`stripes-core.defaultAppsView.${option}`}
                  />
                </Icon>
                {/* {option === props.defaultView ?
                    <Icon icon="select-all">
                      <FormattedMessage id={`stripes-core.defaultAppsView.${option}`} />
                    </Icon> :
                    <FormattedMessage id={`stripes-core.defaultAppsView.${option}`} />} */}
              </NavListItem>
            ))}
          </NavListSection>
        </NavList>
      </div>
    );
  };

  const renderLocalesTrigger = ({ getTriggerProps, open }) => {
    return (
      <Button
        id="clickable-default-apps-view-btn"
        buttonStyle="slim"
        marginBottom0
        {...getTriggerProps()}
      >
        <div style={{ color: 'white' }}>
          <Icon icon="select-all" />
        </div>
      </Button>
    );
  };

  const renderLocalesMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <>
      <Dropdown
        id="userLanguageSwitcherDropdown"
        renderTrigger={renderLocalesTrigger}
        renderMenu={renderLocalesMenu}
        open={DropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        usePortal={false}
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    </>
  );
};

AppListSettingsMenu.propTypes = propTypes;

export default AppListSettingsMenu;

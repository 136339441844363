import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, sortBy } from 'lodash';
import MtGeneralSettingsForm from './MtGeneralSettingsForm';

function MtGeneralSettingsManager(props) {
  const [generalConfigs, setGeneralConfigs] = useState({});

  useEffect(() => {
    const configsList = sortBy(
      (props.resources.configs || {}).records || [],
      ['configName']
    );
    const nextGeneralConfig = configsList.find(
      (config) => config.configName === 'MtGeneral'
    );

    if (!isEqual(get(nextGeneralConfig, ['configValue'], {}), generalConfigs)) {
      setGeneralConfigs(get(nextGeneralConfig, ['configValue'], {}));
    }
  }, [props.resources.configs]);

  const onFormSubmit = (values) => {
    const record = ((props.resources.configs || {}).records || []).filter(
      (config) => config.configName === 'MtGeneral'
    );

    if (record && record.length !== 0) {
      props.mutator.configId.replace(record[0].id);
      record[0].configValue.defaultMTTranslator = values.defaultMTTranslator;
      props.mutator.configs.PUT(record[0]);
    } else {
      props.mutator.configs.POST({
        configName: 'MtGeneral',
        configCode: 'MT-GENERAL',
        configValue: {
          defaultMTTranslator: values.defaultMTTranslator
        },
      });
    }
  };

  return (
    <MtGeneralSettingsForm
      {...props}
      onSubmit={onFormSubmit}
      initialValues={{
        defaultMTTranslator: generalConfigs.defaultMTTranslator,
      }}
    />
  );
}

MtGeneralSettingsManager.propTypes = {
  resources: PropTypes.shape({
    configs: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
    configId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
  mutator: PropTypes.shape({
    configs: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
    }),
    configId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
};

MtGeneralSettingsManager.manifest = Object.freeze({
  configId: '',
  configs: {
    type: 'okapi',
    records: 'configs',
    path: 'configs?limit=1000',
    // resourceShouldRefresh: true,
    POST: {
      path: 'configs',
    },
    PUT: {
      path: 'configs',
    },
    DELETE: {
      path: 'configs',
    },
  },
});

export default MtGeneralSettingsManager;

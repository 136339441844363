import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Tooltip } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import ImportedListModal from './ImportedListModal';

function ImportedListMenu({ importedList }) {
  const [openImportedListModal, setOpenImportedListModal] = useState(false);

  return (
    <>
      <Tooltip
        id="copycat-results-preview-pane-showImportedList-tooltip"
        text={
          <FormattedMessage
            id="ui-inventory.copycat.resultsPreview.pane.lastMenu.tooltip.showImportedList.msg"
            defaultMessage="Show imported list at the same session"
          />
        }
      >
        {({ ref, ariaIds }) => (
          <Button
            onClick={() => setOpenImportedListModal(true)}
            onMouseDown={(e) => e.preventDefault()}
            buttonStyle="slim"
            marginBottom0
            aria-labelledby={ariaIds.text}
            ref={ref}
          >
            <Icon icon="download" size="large">{importedList.length}</Icon>
          </Button>
        )}
      </Tooltip>
      <ImportedListModal
        open={openImportedListModal}
        onClose={() => setOpenImportedListModal(false)}
        importedList={importedList}
      />
    </>
  );
}

ImportedListMenu.propTypes = {
  importedList: PropTypes.arrayOf(PropTypes.object),
};

export default ImportedListMenu;

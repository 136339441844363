/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Layout,
  LayoutBox,
  Modal,
  ModalFooter,
  MultiColumnList,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import css from './style.css';

const propTypes = {};

function DeleteTranslationsModal({ onSave, onClose, open, selectedTranslations }) {
  const intl = useIntl();

  const renderFooter = () => {
    return (
      <ModalFooter>
        <Button buttonStyle="primary" onClick={onSave}>
          <Icon icon="trash" size="large">
            <FormattedMessage id="ui-translations.button.confirmDelete" />
          </Icon>
        </Button>
        <Button buttonStyle="slim" onClick={onClose}>
          <Icon icon="times-circle-solid" size="large">
            <FormattedMessage id="stripes-core.button.cancel" />
          </Icon>
        </Button>
      </ModalFooter>
    );
  };

  const renderMultiColumnList = () => {
    return (
      <MultiColumnList
        interactive={false}
        visibleColumns={[
          'rowIndex',
          'translationKey',
          'originTranslation',
        ]}
        columnWidths={{
          rowIndex: '10%',
          translationKey: '45%',
          originTranslation: '45%',
        }}
        columnMapping={{
          rowIndex: '#',
          translationKey: intl.formatMessage({
            id: 'ui-translations.settings.keyName',
          }),
          originTranslation: intl.formatMessage({
            id: 'ui-translations.localeTranslations.originTranslation',
          }),
        }}
        contentData={selectedTranslations}
        formatter={{
          rowIndex: (item) => item.rowIndex + 1,
        }}
        id="delete-Translations-keys-Multi-Column-List"
        // autosize
        maxHeight={260}
        virtualize
        // pageAmount={100}
        totalCount={selectedTranslations.length}
      />
    );
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        id="delete-trans-keys-confirmation-modal"
        footer={renderFooter()}
        open={open}
        onClose={onClose}
        label={
          <Icon icon="info" size="large" color="red">
            <FormattedMessage id="ui-translations.button.confirmDelete" />
          </Icon>
        }
      >
        <Layout>
          <FormattedMessage
            id="ui-translations.deleteTranslationKeys.confirmModal.message.header"
          />
        </Layout>
        <hr />
        {renderMultiColumnList()}
        <br />
        <div className={css.layout}>
          <FormattedMessage
            id="ui-translations.deleteTranslationKeys.confirmModal.message.danger"
          />
        </div>
        <br />
        <div>
          <FormattedMessage
            id="ui-translations.deleteTranslationKeys.confirmModal.message.note"
          />
        </div>
      </Modal>
    );
  };

  return (
    <>{renderConfirmationModal()}</>
  );
}

DeleteTranslationsModal.propTypes = propTypes;

export default DeleteTranslationsModal;

export default {
  'Cancelled': 'cancelled',
  'Ceased': 'ceased',
  'Complete': 'complete',
  'Transferred to another publisher': 'transferred',
  'Merged with another title': 'merged',
  'Split into other titles': 'split',
  'Lack of funds': 'lackOfFunds',
  'Lack of use': 'lackOfUse',
  'Duplication': 'duplication',
  'Unresponsive vendor': 'unresponsiveVendor',
  'Licensing terms (unacceptable)': 'licensingTerms',
  'Low quality': 'lowQuality',
  'Unpreferred format': 'unpreferredFormat',
  'Error': 'error',
  "Title won't be published this year": 'titleWontBePublishedThisYear',
  "Title won't be published": 'titleWontBePublished',
  'Title is out of print': 'titleOutOtPrint',
  'Title received as a gift': 'titleRecievedAsGift',
};

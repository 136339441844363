import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Pane } from '@folio/stripes/components';
import { AppIcon, stripesConnect } from '@folio/stripes-core';
import { get, isEqual } from 'lodash';

import MainMetaData from './components/MainMetaData';
import AuthorizedUsers from './components/AuthorizedUsers';
import ReportDisplayMethods from './components/ReportDisplayMethods';
import ReportDetailsActions from './components/ReportDetailsActions';
import QueryMetadata from './components/QueryMetadata';

function ReportDetails({
  open,
  onClose,
  selectedRow,
  currentReportId,
  reportsList,
  mutator,
  resources,
}) {
  const [selectedReport, setSelectedReport] = useState(selectedRow);
  // const [currentReport, setCurrentReport] = useState([]);

  useEffect(() => {
    if (reportsList.length) {
      setSelectedReport(
        reportsList?.find((report) => report.id === currentReportId)
      );
    }
  }, [currentReportId, reportsList]);

  // useEffect(() => {
  //   const report = get(resources.report, ['records', '0', 'reports', '0']);
  //   if (!isEqual(currentReport, report)) {
  //     setCurrentReport(report);
  //   }
  // }, [currentReport, resources.report]);

  // console.log(currentReport);

  const onDeleteReport = () => {
    // mutator.report.DELETE({
    //   id: currentReportId,
    // });
  };

  const renderLastMenu = () => {
    return (
      <>
        <Button
          marginBottom0
          id="report-details-actions-menu-edit-btn"
          buttonStyle="primary"
          to={{
            pathname: `/reports/reports-list/${selectedReport?.id}/run`,
          }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Icon icon="play" size="large" />
        </Button>
        <ReportDetailsActions onDeleteReport={onDeleteReport()} />
      </>
    );
  };

  return (
    <>
      {open ? (
        <Pane
          id="report-details-pane"
          appIcon={<AppIcon app="reports" />}
          defaultWidth="40%"
          paneTitle={selectedReport?.name || ''}
          dismissible
          onClose={() => onClose()}
          lastMenu={renderLastMenu()}
        >
          <MainMetaData selectedReport={selectedReport} />
          <QueryMetadata selectedReport={selectedReport} />
          <ReportDisplayMethods selectedReport={selectedReport} />
          <AuthorizedUsers />
        </Pane>
      ) : (
        <div />
      )}
    </>
  );
}

ReportDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedRow: PropTypes.object.isRequired,
  currentReportId: PropTypes.string.isRequired,
  reportsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  resources: PropTypes.shape({
    report: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  mutator: PropTypes.shape({
    report: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
      DELETE: PropTypes.func.isRequired,
    }),
  }),
};

// ReportDetails.manifest = Object.freeze({
//   report: {
//     type: 'okapi',
//     GET: {
//       path: 'reports',
//       params: {
//         query: 'query=(id==:{id})',
//         limit: '1',
//       },
//     },
//   },
// });

export default ReportDetails;

// Export a 'default' mapping using ISO-396-1 culture codes.
// This is used to convert 2-letter locales (language-only) to 4-letter locales.
// containing region information (used to derive first-day-of-week)

export default {
  af: 'ZA',
  sq: 'AL',
  gsw: 'FR',
  am: 'ET',
  ar: 'SA',
  hy: 'AM',
  as: 'IN',
  az:  'AZ',
  bn: 'IN',
  ba: 'RU',
  eu: 'ES',
  be: 'BY',
  bs:  'BA',
  br: 'FR',
  bg: 'BG',
  my: 'MM',
  ca: 'ES',
  ku: 'IQ',
  chr: 'US',
  zh: 'CN',
  co: 'FR',
  hr: 'HR',
  cs: 'CZ',
  da: 'DK',
  prs: 'AF',
  dv: 'MV',
  nl: 'NL',
  dz: 'BT',
  bin: 'NG',
  en: 'US',
  et: 'EE',
  fo: 'FO',
  fil: 'PH',
  fi: 'FI',
  fr: 'FR',
  fy: 'NL',
  ff:  'NG',
  gl: 'ES',
  ka: 'GE',
  de: 'DE',
  el: 'GR',
  kl: 'GL',
  gn: 'PY',
  gu: 'IN',
  ha:  'NG',
  haw: 'US',
  he: 'IL',
  hi: 'IN',
  hu: 'HU',
  ibb: 'NG',
  is: 'IS',
  ig: 'NG',
  id: 'ID',
  iu: 'CA',
  ga: 'IE',
  xh: 'ZA',
  zu: 'ZA',
  it: 'IT',
  ja: 'JP',
  kn: 'IN',
  kr: 'NG',
  ks: 'IN',
  kk: 'KZ',
  km: 'KH',
  quc: 'GT',
  rw: 'RW',
  sw: 'KE',
  kok: 'IN',
  ko: 'KR',
  ky: 'KG',
  lo: 'LA',
  la: '001',
  lv: 'LV',
  lt: 'LT',
  dsb: 'DE',
  lb: 'LU',
  mk: 'MK',
  ms: 'MY',
  ml: 'IN',
  mt: 'MT',
  mni: 'IN',
  mi: 'NZ',
  arn: 'CL',
  mr: 'IN',
  moh: 'CA',
  mn: 'MN',
  ne: 'NP',
  nb: 'NO',
  nn: 'NO',
  oc: 'FR',
  or: 'IN',
  om: 'ET',
  pap: '029',
  ps: 'AF',
  fa: 'IR',
  pl: 'PL',
  pt: 'PT',
  pa: 'IN',
  quz: 'BO',
  ro: 'RO',
  rm: 'CH',
  ru: 'RU',
  sah: 'RU',
  smn: 'FI',
  smj: 'SE',
  se: 'NO',
  sms: 'FI',
  sma: 'SE',
  sa: 'IN',
  gd: 'GB',
  sr:  'RS',
  nso: 'ZA',
  tn: 'ZA',
  sd: 'PK',
  si: 'LK',
  sk: 'SK',
  sl: 'SI',
  so: 'SO',
  st: 'ZA',
  es: 'ES',
  sv: 'SE',
  syr: 'SY',
  tg: 'TJ',
  tzm: 'DZ',
  ta: 'IN',
  tt: 'RU',
  te: 'IN',
  th: 'TH',
  bo: 'CN',
  ti: 'ER',
  ts: 'ZA',
  tr: 'TR',
  tk: 'TM',
  uk: 'UA',
  hsb: 'DE',
  ur: 'PK',
  ug: 'CN',
  uz: 'UZ',
  ve: 'ZA',
  vi: 'VN',
  cy: 'GB',
  wo: 'SN',
  ii: 'CN',
  yi: '001',
  yo: 'NG'
};

export const EXPORT_JOB_STATUSES = ['SCHEDULED', 'IN_PROGRESS', 'SUCCESSFUL', 'FAILED'];

export const EXPORT_JOB_TYPES = [
  'AUTH_HEADINGS_UPDATES',
  'BURSAR_FEES_FINES',
  'CIRCULATION_LOG',
  'E_HOLDINGS',
  'BULK_EDIT',
  'EDIFACT_ORDERS_EXPORT',
];

export const EXPORT_JOB_TYPES_REQUEST_MAP = {
  'AUTH_HEADINGS_UPDATES': ['AUTH_HEADINGS_UPDATES', 'FAILED_LINKED_BIB_UPDATES'],
};

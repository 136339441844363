import coy from './themeCoy.css';
import stalker from './themeStalker.css';

export const themes = {
  coy,
  stalker,
};

export const THEMES = {
  COY: 'coy',
  STALKER: 'stalker',
};

/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Callout } from '@folio/stripes-components';
import { isEqual } from 'lodash';
import { compose } from 'redux';
import WithUserLocales from './Wrappers/WithUserLocales';
import UserLocalesForm from './UserLocalesForm';

class UserLocalesManager extends Component {
  static propTypes = {
    stripes: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    getUserLocales: PropTypes.func.isRequired,
    getUserPreferredLocale: PropTypes.func.isRequired,
    getUserNumbersShape: PropTypes.func.isRequired,
    getUserDateformat: PropTypes.func.isRequired,
    getUserTimeformat: PropTypes.func.isRequired,
    updateUserLocales: PropTypes.func.isRequired,
  };

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     userLocales: props.getUserLocales(),
  //     userPreferredLocale: props.getUserPreferredLocale(),
  //     userNumbersShape: props.getUserNumbersShape(),
  //     dateformat: props.getUserDateformat(),
  //     timeformat: props.getUserTimeformat(),
  //   };
  // }

  // static getDerivedStateFromProps(props, state) {
  //   const userLocales = props.getUserLocales();
  //   const userPreferredLocale = props.getUserPreferredLocale();
  //   const userNumbersShape = props.getUserNumbersShape();

  //   if (!isEqual(userLocales, state.userLocales)) {
  //     return {
  //       userLocales,
  //     };
  //   }

  //   if (!isEqual(userPreferredLocale, state.userPreferredLocale)) {
  //     return {
  //       userPreferredLocale,
  //     };
  //   }

  //   if (!isEqual(userNumbersShape, state.userNumbersShape)) {
  //     return {
  //       userNumbersShape,
  //     };
  //   }

  //   return null;
  // }

  onFormSubmit = values => {
    const {
      stripes: {
        user: { user },
        userPreferredLocale,
      },
    } = this.props;

    const userLocalesIds = values.userLocales.map(ul => ul.id);
    const updatedPreferredLocale = values.userLocales.find(ul => ul.id === values.userPreferredLocale);

    const res = this.props.updateUserLocales(
      userLocalesIds,
      values.userPreferredLocale,
      values.userNumbersShape,
      values.dateformat,
      values.timeformat
    );

    Promise.resolve(res).then(() => {
      this.onShowCallout(
        <div>
          <strong>
            <FormattedMessage id="ui-myprofile.settings.callout.success" />
          </strong>
        </div>
      );
      this.props.stripes.setUserLocales(values.userLocales);
      this.props.stripes.setUserNumbersShape(values.userNumbersShape);

      if (updatedPreferredLocale && updatedPreferredLocale.value !== userPreferredLocale) {
        this.props.stripes.setUserPreferredLocale(updatedPreferredLocale.value);
        this.props.stripes.setLocale(updatedPreferredLocale.value);
        this.props.stripes.setDateformat(updatedPreferredLocale.defaultDateFormat);
        localStorage.setItem(
          'LoginLocale',
          JSON.stringify({
            userId: user.id,
            LoginLocale: updatedPreferredLocale.value,
          })
        );
      }
    });
  };

  onShowCallout = message => {
    if (this.callout) {
      this.callout.sendCallout({ message });
    }
  };

  render() {
    return (
      <>
        <UserLocalesForm
          {...this.props}
          onSubmit={this.onFormSubmit}
          initialValues={{
            userLocales: this.props.getUserLocales(),
            userPreferredLocale: this.props.getUserPreferredLocale(),
            userNumbersShape: this.props.getUserNumbersShape(),
            dateformat: this.props.getUserDateformat(),
            timeformat: this.props.getUserTimeformat(),
          }}
        />
        <Callout
          ref={ref => {
            this.callout = ref;
          }}
        />
      </>
    );
  }
}
export default stripesConnect(compose(WithUserLocales)(injectIntl(withStripes(UserLocalesManager))));

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stripesConnect } from '@folio/stripes-core';
import { ViewMetaData } from '@folio/stripes/smart-components';
import { useIntl } from 'react-intl';
import { get, isEqual, sortBy } from 'lodash';
import TranslationWrapper from '../components/TranslationWrapper/TranslationWrapper';
import CurrentLocaleContext from '../Context/CurrentLocale/CurrentLocaleContext';
import useQueryParams from '../Hooks/useQueryParams';

function LibTranslationsRoute(props) {
  const [currentLocale, setCurrentLocale] = useQueryParams('locale', '');

  const [originTranslations, setOriginTranslations] = useState({});
  const [localeTranslations, setLocaleTranslations] = useState({});
  const [localeMetatdata, setLocaleMetatdata] = useState({});

  const intl = useIntl();

  const getLocalTranslations = (locale) => {
    const allTranslations = sortBy(
      (props.resources.translations || {}).records || [],
      ['localeValue']
    );
    const trans = allTranslations.find(
      (curTrans) => curTrans.localeValue === locale &&
        curTrans.categoryName === 'libTranslations'
    );
    return get(trans, ['messages'], {});
  };

  const getLocaleMetadata = (locale) => {
    const allTranslations = sortBy(
      (props.resources.translations || {}).records || [],
      ['localeValue']
    );
    const trans = allTranslations.find(
      (curTrans) => curTrans.localeValue === locale &&
        curTrans.categoryName === 'libTranslations'
    );
    return get(trans, ['metadata'], {});
  };

  // useEffect(() => {
  //   props.history.push({
  //     pathname: '/translations/libTranslations',
  //     search: props.location.search,
  //     state: { backPathname: props.location.pathname },
  //   });
  //   return () => {
  //     setCurrentLocale('');
  //   };
  // }, []);

  useEffect(() => {
    if (!isEqual(getLocalTranslations('en'), originTranslations)) {
      setOriginTranslations(getLocalTranslations('en'));
    }
    if (!isEqual(getLocaleMetadata(currentLocale), localeMetatdata)) {
      setLocaleMetatdata(getLocaleMetadata(currentLocale));
    }
    if (!isEqual(getLocalTranslations(currentLocale), localeTranslations)) {
      setLocaleTranslations(getLocalTranslations(currentLocale));
    }
  });

  return (
    <TranslationWrapper
      {...props}
      categoryName="libTranslations"
      currentLocale={currentLocale}
      setCurrentLocale={setCurrentLocale}
      originTranslations={originTranslations}
      localeTranslations={localeTranslations}
      showAppStats
      activeFilters={['locale', 'app', 'table', 'status', 'user']}
      ViewMetaData={<ViewMetaData metadata={localeMetatdata} />}
      paneTitle={intl.formatMessage({
        id: 'ui-translations.category.libTranslations',
      })}
    />
  );
}

LibTranslationsRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};
LibTranslationsRoute.manifest = {
  translations: {
    type: 'okapi',
    path: 'translations?limit=1000',
    records: 'translations',
  },
};

export default stripesConnect(LibTranslationsRoute);

/* eslint-disable react/sort-prop-types */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { get, isEqual } from 'lodash';

const WithUserNumbersShape = WrappedComponent => class WithUserNumbersShapeComponent extends React.Component {
    static manifest = {
      ...WrappedComponent.manifest,
      userLocales: {
        type: 'okapi',
        path: 'userLocales?limit=1000',
        records: 'userLocales',
      },
    };

    static propTypes = {
      resources: PropTypes.shape({
        userLocales: PropTypes.shape({
          records: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
      mutator: PropTypes.shape({
        userLocales: PropTypes.shape({
          GET: PropTypes.func.isRequired,
          reset: PropTypes.func.isRequired,
        }),
      }),
      stripes: PropTypes.object.isRequired,
    };

    state = {
      currentUser: [],
    };

    static getDerivedStateFromProps(props, state) {
      const { stripes: { user: { user } } } = props;
      const currentUser = get(props.resources.userLocales, ['records'], [])
        .filter(userLoc => userLoc.userId === user.id);

      if (!isEqual(currentUser, state.currentUser)) {
        return {
          currentUser,
        };
      }

      return null;
    }

    getUserLocales = () => {
      const userLocalesIds = get(this.state.currentUser, [0, 'localesIds'], []);

      return userLocalesIds
        .map(uul => this.props.stripes.tenantLocales.find(ul => ul.id === uul))
        .sort(((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })));
    };

    getUserNumbersShape = () => {
      return get(this.state.currentUser, [0, 'numbersShape'], undefined);
    };

    render() {
      return (
        <>
          <WrappedComponent
            getUserLocales={this.getUserLocales}
            getUserNumbersShape={this.getUserNumbersShape}
            {...this.props}
          />
        </>
      );
    }
};

export default WithUserNumbersShape;

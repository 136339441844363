/* eslint-disable react/prop-types */
import {
  Accordion,
  Button,
  Col,
  FilterAccordionHeader,
  KeyValue,
  Loading,
  Row,
} from "@folio/stripes-components";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AppConfigListContext from "../../../Context/AppConfigList/AppConfigListContext";
import useFetch from "../../../Hooks/useFetch";
import { okapi } from "stripes-config";
import { stripesConnect, withStripes } from "@folio/stripes-core";

function TranslationSuggestions({
  originText,
  currentLocale,
  handleUseTranslation,
  stripes,
}) {
  const [filterToggle, setFilterToggle] = useState(true);
  const appConfigContext = useContext(AppConfigListContext);

  const [googleTrans, googleTransLoading, googleTransError] = useFetch(
    `${
      okapi.url
    }/google-translate?text=${originText.toLowerCase()}&from=en&to=${
      currentLocale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": stripes.okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  );

  const [libreTrans, libreTransLoading, libreTransError] = useFetch(
    `${okapi.url}/libre-translate?text=${originText.toLowerCase()}&from=en&to=${
      currentLocale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": stripes.okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  );

  const [bingTrans, bingTransLoading, bingTransError] = useFetch(
    `${okapi.url}/bing-translate?text=${originText.toLowerCase()}&from=en&to=${
      currentLocale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": stripes.okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  );

  console.log({ bingTrans, bingTransError });

  const [myMemoryTrans, myMemoryLoading, myMemoryError] = useFetch(
    `${
      okapi.url
    }/mymemo-translate?text=${originText.toLowerCase()}&from=en&to=${
      currentLocale.split("-")[0]
    }`,
    {
      headers: {
        "X-Okapi-Tenant": stripes.okapi.tenant,
        "X-Okapi-Token": stripes.okapi.token,
        "Content-Type": "application/json",
      },
    }
  );

  const renderUseTranslationButton = (translatedText, error) => {
    return (
      <>
        {!error && (
          <Button
            buttonStyle="link"
            onClick={() => handleUseTranslation(translatedText)}
            onMouseDown={(e) => e.preventDefault()}
            marginBottom0
          >
            <FormattedMessage
              id="ui-translations.translationSuggestions.buttons.useThis"
              defaultMessage="Use this translations"
            />
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Accordion
        id="translation-translationSuggestions-accordion"
        label={
          <FormattedMessage id="ui-translations.translationSuggestions.Accordion.header" />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator={false}
        header={FilterAccordionHeader}
      >
        <Row>
          <Col xs={9}>
            <KeyValue
              label={
                <FormattedMessage id="ui-translations.translationSuggestions.googleTranslate" />
              }
            >
              {!googleTransLoading ? (
                <span style={{ color: "red" }}>
                  {googleTransError ? (
                    <FormattedMessage id="ui-translations.translationSuggestions.notAvailable" />
                  ) : googleTrans ? (
                    googleTrans?.data?.translatedText
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                <Loading />
              )}
            </KeyValue>
          </Col>
          <Col xs={3}>
            {renderUseTranslationButton(
              googleTrans ? googleTrans?.data?.translatedText : "",
              googleTransError
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={9}>
            <KeyValue
              label={
                <FormattedMessage id="ui-translations.translationSuggestions.bingTranslate" />
              }
            >
              {!bingTransLoading ? (
                <span style={{ color: "red" }}>
                  {bingTransError ? (
                    <FormattedMessage id="ui-translations.translationSuggestions.notAvailable" />
                  ) : (
                    bingTrans?.data?.translatedText
                  )}
                </span>
              ) : (
                <Loading />
              )}
            </KeyValue>
          </Col>
          <Col xs={3}>
            {renderUseTranslationButton(
              bingTrans?.data?.translatedText,
              bingTransError
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={9}>
            <KeyValue
              label={
                <FormattedMessage
                  id="ui-translations.translationSuggestions.mymemoTranslate"
                  defaultMessage="My memory Translator:"
                />
              }
            >
              {!myMemoryLoading ? (
                <span style={{ color: "red" }}>
                  {myMemoryError ? (
                    <FormattedMessage id="ui-translations.translationSuggestions.notAvailable" />
                  ) : (
                    myMemoryTrans?.data?.translations?.responseData
                      ?.translatedText
                  )}
                </span>
              ) : (
                <Loading />
              )}
            </KeyValue>
          </Col>
          <Col xs={3}>
            {renderUseTranslationButton(
              myMemoryTrans?.data?.translations?.responseData?.translatedText,
              myMemoryError
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={9}>
            <KeyValue
              label={
                <FormattedMessage id="ui-translations.translationSuggestions.libreTranslate" />
              }
            >
              {!libreTransLoading ? (
                <span style={{ color: "red" }}>
                  {libreTransError ? (
                    <FormattedMessage id="ui-translations.translationSuggestions.notAvailable" />
                  ) : libreTrans ? (
                    libreTrans.data.translatedText
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                <Loading />
              )}
            </KeyValue>
          </Col>
          <Col xs={3}>
            {renderUseTranslationButton(
              libreTrans ? libreTrans.data.translatedText : "",
              libreTransError
            )}
          </Col>
        </Row>
      </Accordion>
    </>
  );
}

export default withStripes(stripesConnect(TranslationSuggestions));

import kwareLogo from '../../icons/kware_test.png';
import kfnlLogo from '../../icons/kfnl_logo.png';
import imamuLogo from '../../icons/imamu_logo.png';

const TenantsConfig = {
  default_tenant: 'kware_test',
  tenants: [
    {
      id: 'kware_test',
      colors: {
        headerBg: '#c99e2d',
      },
      logo: {
        src: kwareLogo,
        alt: 'kware_test library logo',
        width: '150px',
        height: '50px',
      },
      default_locale: 'ar',
      default_locales: [
        {
          name: 'Arabic',
          value: 'ar',
        },
        {
          name: 'English - United States',
          value: 'en-US',
        },
        {
          name: 'French - France',
          value: 'fr-FR',
        },
        {
          name: 'Spanish',
          value: 'es',
        },
        {
          name: 'Chinese Simplified',
          value: 'zh-CN',
        },
      ],
      superUser: {
        userName: 'kware_admin',
        password: 'Kware@2023#',
      },
    },
    {
      id: 'kfnl_lib',
      colors: {
        headerBg: '#2e9381',
      },
      logo: {
        src: kfnlLogo,
        alt: 'kfnl_lib library logo',
        width: '150px',
        height: '50px',
      },
      default_locale: 'ar',
      default_locales: [
        {
          name: 'Arabic',
          value: 'ar',
        },
        {
          name: 'English - United States',
          value: 'en-US',
        },
        {
          name: 'French - France',
          value: 'fr-FR',
        },
        {
          name: 'Italian - Italy',
          value: 'it-IT',
        },
      ],
      superUser: {
        userName: 'kware_admin',
        password: 'Kware@2022#',
      },
    },
    {
      id: 'imamu_lib',
      colors: {
        headerBg: '#3992A4',
      },
      logo: {
        src: imamuLogo,
        alt: 'imamu_lib library logo',
        width: '60px',
        height: '50px',
      },
      default_locale: 'en-US',
      default_locales: [
        {
          name: 'Arabic',
          value: 'ar',
        },
        {
          name: 'English - United States',
          value: 'en-US',
        },
        {
          name: 'French - France',
          value: 'fr-FR',
        },
        {
          name: 'German - Germany',
          value: 'de-DE',
        },
      ],
      superUser: {
        userName: 'kware_admin',
        password: 'Kware@2023#',
      },
    },
  ],
};

export default TenantsConfig;

/* eslint-disable react/prop-types */
/* eslint-disable newline-after-var */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import stripesFinalForm from '@folio/stripes/final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import { Button, Pane, PaneFooter, Paneset, Row, Col, Icon } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import avatar from './avatar.png';
import css from './styles.css';
import ImageUploader from './components/ImageUploader';

function PersonalImageForm({ pristine, submitting, handleSubmit, form }) {
  const [ShowImageCropper, setShowImageCropper] = useState(false);
  const [OutputImageSrc, setOutputImageSrc] = useState(null);

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-user-image-upload"
            marginBottom0
            type="submit"
            onClick={handleSubmit}
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-user-image-upload-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  const renderpersonalImage = ({ fields }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexFlow: 'column' }}>
        <div>
          <img className={css.avatar} src={OutputImageSrc || (fields.value ? fields.value : avatar)} alt="user_image" />
        </div>
        <br />
        <div>
          <Button
            buttonStyle="primary"
            disabled={ShowImageCropper}
            id="clickable-user-image-change"
            // marginBottom0
            onClick={() => setShowImageCropper(true)}
          >
            <FormattedMessage id="ui-myprofile.settings.userAvatar.selectNewImage" />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Paneset>
        <Pane
          fluidContentWidth
          footer={renderPaneFooter()}
          id="pane-user-image-upload-form"
          paneTitle={<FormattedMessage id="ui-myprofile.settings.userAvatar.label" defaultMessage="Personal Image" />}
        >
          <form id="form-user-avatar" onSubmit={handleSubmit}>
            <Row>
              <Col xs={4}>
                <FieldArray name="personalImage" component={renderpersonalImage} />
              </Col>
              <Col xs={8}>
                <Field name="personalImage">
                  {(fieldprops) => (
                    <div>
                      <ImageUploader
                        onClose={() => setOutputImageSrc(null)}
                        open={ShowImageCropper}
                        onCrop={(prev) => {
                          setOutputImageSrc(prev);
                          form.mutators.ChangePersonalImage(prev);
                        }}
                        src={OutputImageSrc}
                      />
                    </div>
                  )}
                </Field>
              </Col>
            </Row>
          </form>
        </Pane>
      </Paneset>
    </>
  );
}
const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

PersonalImageForm.propTypes = propTypes;

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    ChangePersonalImage: (args, state) => {
      const field = state.fields.personalImage;
      field.change(args[0]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(PersonalImageForm);

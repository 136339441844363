import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paneset } from '@folio/stripes/components';
import SaveNewReportManager from '../components/saveNewReport/SaveNewReportManager';
import QueryBuilderManger from '../components/queryBuilder/QueryBuilderManger';
import useURLParams from '../hooks/useURLParams';
import ResultsPreview from '../components/queryResultsPreview/ResultsPreview';

function QueryBuilderRoute(props) {
  const [reportType, setReportType] = useURLParams('rt', 'list');
  const [displayMethod, setDisplayMethod] = useURLParams('dm', 'table');
  // const [selectedTables, setSelectedTables] = useURLParams('st', '');
  const [query, setQuery] = useURLParams('q', '');

  const [selectedTables, setSelectedTables] = useState([]);
  const [showQueryBuilderPane, setShowQueryBuilderPane] = useState(true);
  const [showSavePane, setShowSavePane] = useState(false);

  const [initialValues, setInitialValues] = useState({
    tables: [],
    columns: {},
    dimFilters: [],
    metFilters: [],
    metrics: [],
    orders: [],
    timeDimentions: {
      timeColumn: '',
      timeUnit: 'day',
      dateRange: '',
    },
    segments: '',
  });

  const handleClose = () => {
    props.history.push('/reports/reports-list');
  };

  // useEffect(() => {
  //   if (query !== '') {
  //     // setInitialValues({
  //     //   tables: selectedTables,
  //     //   columns: query.dimensions,
  //     //   dimFilters: [],
  //     //   metFilters: [],
  //     //   metrics: [],
  //     //   orders: [],
  //     //   timeDimentions: {
  //     //     timeColumn: '',
  //     //     timeUnit: 'day',
  //     //     dateRange: '',
  //     //   },
  //     //   segments: '',
  //     // });
  //   }
  // }, [query]);

  return (
    <>
      <Paneset>
        <QueryBuilderManger
          {...props}
          reportType={reportType}
          setReportType={setReportType}
          showQueryBuilderPane={showQueryBuilderPane}
          setShowQueryBuilderPane={setShowQueryBuilderPane}
          handleClose={handleClose}
          initialValues={initialValues}
          setQuery={setQuery}
          selectedTables={selectedTables}
          setSelectedTables={setSelectedTables}
        />
        <ResultsPreview
          {...props}
          showQueryBuilderPane={showQueryBuilderPane}
          setShowQueryBuilderPane={setShowQueryBuilderPane}
          showSavePane={showSavePane}
          setShowSavePane={setShowSavePane}
          handleClose={handleClose}
          query={query}
          reportType={reportType}
          displayMethod={displayMethod}
          setDisplayMethod={setDisplayMethod}
        />
        <SaveNewReportManager
          {...props}
          showQueryBuilderPane={showQueryBuilderPane}
          setShowQueryBuilderPane={setShowQueryBuilderPane}
          showSavePane={showSavePane}
          setShowSavePane={setShowSavePane}
          handleClose={handleClose}
          query={query}
          reportType={reportType}
        />
      </Paneset>
    </>
  );
}

QueryBuilderRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default QueryBuilderRoute;

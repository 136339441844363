import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Badge, Button, Card, FilterAccordionHeader, Headline, Icon } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
// import { Link } from 'react-router-dom';
import TranslationRate from './TranslationRate';
import css from './Stats.css';
import { getLocaleLabel, ImportLocalesIcones } from '../../../utils/utils';

const propTypes = {
  localeStats: PropTypes.object,
  // setCurrentStatus: PropTypes.func,
};

function LocaleStats(props) {
  const [filterToggle, setFilterToggle] = useState(true);

  const untranslated = props.localeStats.availableTranslations.filter(
    (trans) => trans.localeTranslation
  );
  const translated = props.localeStats.availableTranslations.filter(
    (trans) => !trans.localeTranslation
  );
  const completed = Math.floor(
    (100 * untranslated.length) / props.localeStats.availableTranslations.length
  );
  const {
    localeStats: { localeValue, totalApps, availableTranslations },
  } = props;
  const intl = useIntl();
  const flags = ImportLocalesIcones();

  return (
    // <Card
    //   id="tenant-locales"
    //   cardStyle="default"
    //   roundedBorder
    //   headerStart={
    //     <div style={{ fontWeight: '550' }}>
    //       <Icon icon={flags[localeValue] ? flags[localeValue] : 'flag'}>
    //         <FormattedMessage
    //           id="ui-translations.Statistics.localeStatistics"
    //           values={{
    //             localeValue: localeValue
    //               ? getLocaleLabel(localeValue, intl)
    //               : '',
    //           }}
    //         />
    //       </Icon>
    //     </div>
    //   }
    // >
    //   {props.localeStats.availableTranslations.length ? (
    //     <div>
    //       {/* <li>
    //         <FormattedMessage id="ui-translations.Statistics.total.Apps" />
    //         <Badge>{totalApps}</Badge>
    //       </li>
    //       <li className={css.li}>
    //         <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
    //         <Badge>{availableTranslations.length}</Badge>
    //       </li> */}
    //       <li className={css.li}>
    //         <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.translated" />
    //         <Badge>{availableTranslations.length - translated.length}</Badge>
    //       </li>
    //       <li className={css.li}>
    //         <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.notTranslated" />
    //         <Badge>{translated.length}</Badge>
    //         {/** for making untranslated as a hyper link */}
    //         {/* <Button
    //           id="clickable-statistics-total-notTranslated"
    //           buttonStyle="link"
    //           onClick={() => props.setCurrentStatus('notTranslated')}
    //         >
    //           {translated.length}
    //         </Button> */}
    //       </li>
    //       <li className={css.li}>
    //         <FormattedMessage id="ui-translations.Statistics.total.userAssigning" />
    //         {/* <Badge>{0}</Badge> */}
    //         <Badge>{props.localeStats.totalTranslators}</Badge>
    //       </li>
    //       <TranslationRate
    //         ContentData={availableTranslations}
    //         completed={completed}
    //         bgcolor="#6a1b9a"
    //       />
    //     </div>
    //   ) : (
    //     <FormattedMessage id="ui-translations.Statistics.localeStats.noLocales" />
    //   )}
    // </Card>
    <>
      <Accordion
        id="locale-stats-accordion"
        label={
          <div style={{ fontWeight: '550' }}>
            <Icon icon={flags[localeValue] ? flags[localeValue] : 'flag'}>
              <FormattedMessage
                id="ui-translations.Statistics.localeStatistics"
                values={{
                  localeValue: localeValue
                    ? getLocaleLabel(localeValue, intl)
                    : '',
                }}
              />
            </Icon>
          </div>
      }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        // separator
        header={FilterAccordionHeader}
      >
        <br />
        {props.ViewMetaData}
        {props.localeStats.availableTranslations.length ? (
          <div>
            {/* <li>
            <FormattedMessage id="ui-translations.Statistics.total.Apps" />
            <Badge>{totalApps}</Badge>
          </li>
          <li className={css.li}>
            <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.avialableTranslations" />
            <Badge>{availableTranslations.length}</Badge>
          </li> */}
            <li className={css.li}>
              <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.translated" />
              <Badge>{availableTranslations.length - translated.length}</Badge>
            </li>
            <li className={css.li}>
              <FormattedMessage id="ui-translations.Statistics.StripesLocales.total.notTranslated" />
              <Badge>{translated.length}</Badge>
              {/** for making untranslated as a hyper link */}
              {/* <Button
              id="clickable-statistics-total-notTranslated"
              buttonStyle="link"
              onClick={() => props.setCurrentStatus('notTranslated')}
            >
              {translated.length}
            </Button> */}
            </li>
            <li className={css.li}>
              <FormattedMessage id="ui-translations.Statistics.total.userAssigning" />
              {/* <Badge>{0}</Badge> */}
              <Badge>{props.localeStats.totalTranslators}</Badge>
            </li>
            <TranslationRate
              ContentData={availableTranslations}
              completed={completed < 1 ? 1 : completed}
              bgcolor="#6a1b9a"
            />
          </div>
        ) : (
          <FormattedMessage id="ui-translations.Statistics.localeStats.noLocales" />
        )}
      </Accordion>
      <br />
    </>
  );
}

LocaleStats.propTypes = propTypes;

export default LocaleStats;

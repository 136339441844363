import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MultiColumnList } from '@folio/stripes/components';
import { calculateColumnWidths } from '../../../../helpers/utils';
import EmptyData from '../../../common/emptyData/EmptyData';

function ListPreview(props) {
  const [contentData, setContentData] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    const data = [];
    if (
      props.resultSet &&
      Object.keys(props.resultSet).length !== 0 &&
      props.resultSet.loadResponses.length !== 0
    ) {
      props.resultSet.loadResponses[0].data.map((result) => {
        const obj = {};
        for (const key in result) {
          if (key) {
            obj[key.replace('.', '_')] = result[key];
          }
        }
        data.push(obj);
        return null;
      });
      setContentData(data);
      const columns = Object.keys(props.resultSet?.loadResponses[0]?.data[0]);
      setVisibleColumns(columns.map((col) => col.replace('.', '_')));
    } else {
      setContentData([]);
    }
  }, [props.resultSet]);

  return (
    <>
      {visibleColumns.length !== 0 ? (
        <MultiColumnList
          id="new-report-result-preview-Multi-Column-List"
          interactive={false}
          visibleColumns={['rowIndex', ...visibleColumns]}
          contentData={contentData}
          columnMapping={{
            rowIndex: '#',
          }}
          columnWidths={calculateColumnWidths(visibleColumns)}
          formatter={{
            rowIndex: (item) => item.rowIndex + 1,
          }}
          autosize={props.displaySetting?.autosize}
          maxHeight={
            props.displaySetting?.autosize
              ? null
              : props.displaySetting.maxHeight
          }
          virtualize={props.displaySetting?.autosize}
        />
      ) : (
        <EmptyData />
      )}
    </>
  );
}

ListPreview.propTypes = {
  resultSet: PropTypes.object.isRequired,
  displaySetting: PropTypes.object,
};

ListPreview.defaultProps = {
  displaySetting: { autosize: true, maxHeight: null },
};

export default ListPreview;

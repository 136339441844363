import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layer, Paneset } from '@folio/stripes/components';
import SearchAndFilters from '../components/searchAndFilters/SearchAndFilters';
import ReportsList from '../components/ReportsList/ReportsList';
import RoutesTabs from './RoutesTabs';

function ReportsListRoute(props) {
  const [showFilters, setShowFilters] = useState(true);
  // const [reportsList, setReportsList] = useState([]);

  return (
    <>
      <Paneset>
        <RoutesTabs currentTab="reportsList" />

        <SearchAndFilters
          {...props}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          // setReportsList={setReportsList}
        />
        <ReportsList
          {...props}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          // reportsList={reportsList}
          // setReportsList={setReportsList}
        />
      </Paneset>
    </>
  );
}

ReportsListRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

export default ReportsListRoute;

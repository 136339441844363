/* kware added new */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConfigManager } from '@folio/stripes-smart-components';
import ThemeSwitcherForm from './ThemeSwitcherForm';

class NavbarThemeSettings extends Component {
  static propTypes = {
    stripes: PropTypes.shape({
      connect: PropTypes.func.isRequired,
      logger: PropTypes.shape({
        log: PropTypes.func.isRequired,
      }).isRequired,
      setdefaultTheme: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.configManager = props.stripes.connect(ConfigManager);
    this.setdefaultTheme = this.setdefaultTheme.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
    this.beforeSave = this.beforeSave.bind(this);
  }

  getInitialValues(settings) {
    const value = settings.length === 0 ? '' : settings[0].value;
    const defaultConfig = {
      locale: 'en-US',
      timezone: 'UTC',
      currency: 'USD',
      showLocaleIcon: true,
      defaultTheme: 'kwareTheme',
    };
    let config;

    try {
      config = { ...defaultConfig, ...JSON.parse(value) };
    } catch (e) {
      config = defaultConfig;
    }

    return config;
  }

  setdefaultTheme(setting) {
    const otherSettings = JSON.parse(setting.value);
    const { defaultTheme } = otherSettings;
    setTimeout(() => {
      if (defaultTheme) this.props.stripes.setdefaultTheme(defaultTheme);
    }, 2000);
  }

  // eslint-disable-next-line class-methods-use-this
  beforeSave(data) {
    const { locale, timezone, currency, showLocaleIcon, defaultTheme } = data;
    return JSON.stringify({
      locale,
      timezone,
      currency,
      showLocaleIcon,
      defaultTheme,
    });
  }

  render() {
    return (
      <this.configManager
        label={
          <FormattedMessage
            id="ui-tenant-settings.settings.NavbarThemeSettings.label"
            defaultMessage="Themes Switcher"
          />
        }
        moduleName="ORG"
        getInitialValues={this.getInitialValues}
        configName="localeSettings"
        onBeforeSave={this.beforeSave}
        onAfterSave={this.setdefaultTheme}
        configFormComponent={ThemeSwitcherForm}
      />
    );
  }
}

export default injectIntl(NavbarThemeSettings);

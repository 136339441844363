/* kware added new */

const SupportedLocales = [
  { name: 'Arabic', localeValue: 'ar', status: 'Enabled' },
  { name: 'English - United States', localeValue: 'en-US', status: 'Enabled' },
  { name: 'English - Great Britain', localeValue: 'en-GB', status: 'Enabled' },
  { name: 'English - Sweden', localeValue: 'en-SE', status: 'Enabled' },
  { name: 'German - Germany', localeValue: 'de-DE', status: 'Enabled' },
  { name: 'Italian - Italy', localeValue: 'it-IT', status: 'Enabled' },
  { name: 'Spanish', localeValue: 'es', status: 'Enabled' },
  { name: 'Spanish - Latin America', localeValue: 'es-419', status: 'Enabled' },
  { name: 'Spanish - Spain', localeValue: 'es-ES', status: 'Enabled' },
  { name: 'Portuguese - Brazil', localeValue: 'pt-BR', status: 'Enabled' },
  { name: 'Portuguese - Portugal', localeValue: 'pt-PT', status: 'Enabled' },
  { name: 'Danish', localeValue: 'da-DK', status: 'Enabled' },
  { name: 'Hungarian', localeValue: 'hu-HU', status: 'Enabled' },
  { name: 'Chinese Simplified', localeValue: 'zh-CN', status: 'Enabled' },
  { name: 'Chinese Traditional', localeValue: 'zh-TW', status: 'Enabled' },
  { name: 'French - France', localeValue: 'fr-FR', status: 'Enabled' },
  { name: 'Hebrew', localeValue: 'he', status: 'Enabled' },
  { name: 'Japanese', localeValue: 'ja', status: 'Enabled' },
  { name: 'Russian', localeValue: 'ru', status: 'Enabled' },
  { name: 'Urdu', localeValue: 'ur', status: 'Enabled' },
  { name: 'Hindi, india', localeValue: 'hi-IN', status: 'Enabled' },
  { name: 'Korean', localeValue: 'ko', status: 'Enabled' },
  { name: 'Norwegian bokmål', localeValue: 'nb', status: 'Enabled' },
  { name: 'Norwegian nynorsk', localeValue: 'nn', status: 'Enabled' },
  { name: 'Polish', localeValue: 'pl', status: 'Enabled' },
  { name: 'Swedish', localeValue: 'sv', status: 'Enabled' },
];

export default SupportedLocales;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Pane, PaneFooter, Paneset, Select, TextField } from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import stripesFinalForm from '@folio/stripes/final-form';
import { required } from '../../../utils/Validations';

function MtGeneralSettingsForm({ handleSubmit, pristine, submitting, form }) {
  const intl = useIntl();
  const dataOptions = [
    {
      label: intl.formatMessage({ id: 'ui-translations.translationSuggestions.googleTranslate' }),
      value: 'googleTranslate',
    },
    {
      label: intl.formatMessage({ id: 'ui-translations.translationSuggestions.bingTranslate' }),
      value: 'bingTranslate',
    },
    {
      label: intl.formatMessage({ id: 'ui-translations.translationSuggestions.mymemoTranslate' }),
      value: 'myMemoTranslate',
    },
    {
      label: intl.formatMessage({ id: 'ui-translations.translationSuggestions.libreTranslate' }),
      value: 'libreTranslate',
    },
  ];

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-MtGeneral-form-submit-btn"
            marginBottom0
            type="submit"
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-MtGeneral-form-cancel-btn"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <form
        id="form-MtGeneral-settings"
        onSubmit={handleSubmit}
        style={{ width: 'inherit' }}
      >
        <Paneset>
          <Pane
            fluidContentWidth
            footer={renderPaneFooter()}
            id="pane-MtGeneral-settings-form"
            paneTitle={
              <FormattedMessage id="ui-translations.settings.machineTranslations.general.lable" />
            }
          >
            <Field
              id="textField-MtGeneral-defaultMTTranslator"
              name="defaultMTTranslator"
              component={Select}
              dataOptions={dataOptions}
              label={
                <FormattedMessage id="ui-translations.settings.machineTranslations.general.defaultMTTranslator" />
              }
              placeholder={intl.formatMessage({
                id: 'ui-translations.settings.machineTranslations.general.defaultMTTranslator.placeholder',
              })}
              required
              validate={required}
            />
          </Pane>
        </Paneset>
      </form>
    </>
  );
}

MtGeneralSettingsForm.propTypes = {};

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    resetZipFileField: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(MtGeneralSettingsForm);

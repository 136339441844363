/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

function AppFilter({
  currentApp,
  setCurrentApp,
  setCurrentTabel,
  dataOptions,
  originTranslations,
  disabled,
}) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);

  const getTranslationsByApp = (app) => {
    const appTrans = {};
    Object.keys(originTranslations).forEach((key) => {
      if (key.startsWith(app)) appTrans[key] = originTranslations[key];
    });
    return appTrans;
  };

  const getAppFacets = () => {
    const facets = [];
    dataOptions.map((app) => facets.push({
      appName: app.startsWith('ui-')
        ? intl.formatMessage({
          id: `${app}.meta.title`,
          defaultMessage: `${app.replace('ui-', '')}`,
        })
        : intl.formatMessage({
          id: `ui-translations.appName.${app}`,
          defaultMessage: `${app}`,
        }),
      count: Object.keys(getTranslationsByApp(app)).length,
    }));
    return facets;
  };

  const onChangeApp = (event) => {
    setCurrentApp(event.target.value);
    if (setCurrentTabel) setCurrentTabel('allTables');
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const renderFilterByApp = () => {
    const options = [
      {
        value: 'allApps',
        label: intl.formatMessage({
          id: 'ui-translations.filters.AppFilter.allApps',
        }),
        disabled: false,
      },
      { value: '-', label: '--------------------------', disabled: true },
      ...dataOptions.map((app) => ({
        label: app.startsWith('ui-')
          ? intl.formatMessage({
            id: `${app}.meta.title`,
            defaultMessage: `${app.replace('ui-', '')}`,
          })
          : intl.formatMessage({
            id: `ui-translations.appName.${app}`,
            defaultMessage: `${app}`,
          }),
        value: app,
        disabled: false,
      })),
    ];

    return (
      <div>
        <Select
          id="app-filter-select"
          name="locale-filter"
          value={currentApp}
          dataOptions={options}
          onChange={onChangeApp}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
  // <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
    <Accordion
      id="app-filter-accordion"
      label={
        <FormattedMessage id="ui-translations.filters.AppFilter.Accordion" />
        }
      onToggle={handleFilterToggle}
      open={filterToggle}
        // separator={false}
      header={FilterAccordionHeader}
    >
      {renderFilterByApp()}
      {/* {getAppFacets().map((app) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
              <div>{app.appName}</div>
              <Badge>{app.count}</Badge>
            </div>
          );
        })} */}
    </Accordion>
  // </div>
  );
}

export default AppFilter;

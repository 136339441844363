import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import { Settings } from '@folio/stripes/smart-components';
import { stripesShape } from '@folio/stripes/core';

import ChangePassword from './ChangePassword';

/** kware start editing */
import UserLocalesManager from './UserLocalesSettings/UserLocalesManager';
import UserPreferredAppsManager from './UserPreferredApps/UserPreferredAppsManager';
import PersonalImageManager from './PersonalImageSettings/PersonalImageManager';
/** kware end editing */

class MyProfile extends Component {
  static isLocalLogin(stripes) {
    return !stripes.okapi.ssoEnabled;
  }

  static propTypes = {
    actAs: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    showSettings: PropTypes.bool.isRequired,
    stripes: stripesShape.isRequired,
  };

  constructor(props) {
    super(props);

    // this.pages = this.createSettingsPages();
    /** kware start editing */
    this.pages = [
      {
        route: 'password',
        label: <FormattedMessage id="ui-myprofile.settings.changePassword.label" />,
        component: ChangePassword,
        perm: 'ui-myprofile.settings.change-password',
      },
      {
        route: 'user-locales',
        label: <FormattedMessage id="ui-myprofile.settings.userLocales.label" />,
        component: UserLocalesManager,
        perm: 'ui-myprofile.settings.change-password',
      },
      // {
      //   route: 'user-image',
      //   label: <FormattedMessage id="ui-myprofile.settings.userAvatar.label" />,
      //   component: UserAvatarManager,
      //   perm: 'ui-myprofile.settings.change-password',
      // },
      {
        route: 'personal-image',
        label: <FormattedMessage id="ui-myprofile.settings.userAvatar.label" />,
        component: PersonalImageManager,
        perm: 'ui-myprofile.settings.change-password',
      },
      {
        route: 'user-apps',
        label: <FormattedMessage id="ui-myprofile.settings.UserPreferredApps.label" />,
        component: UserPreferredAppsManager,
        perm: 'ui-myprofile.settings.change-password',
      },
    ];
    /** kware end editing */
  }

  // createSettingsPages() {
  //   return []
  //     .concat(
  //       this.registerChangePasswordPage()
  //     )
  //     .filter((page) => page);
  // }

  // registerChangePasswordPage() {
  //   const { ssoEnabled } = this.props.stripes.okapi;
  //   const showPasswordPage = !ssoEnabled;

  //   /* istanbul ignore if  */
  //   if (!showPasswordPage) {
  //     return undefined;
  //   }

  //   return {
  //     route: 'password',
  //     label: <FormattedMessage id="ui-myprofile.settings.changePassword.label" />,
  //     component: ChangePassword,
  //     perm: 'ui-myprofile.settings.change-password',
  //   };
  // }

  render() {
    /* istanbul ignore if  */
    if (!this.pages.length) return null;

    const {
      actAs,
      location,
      match,
      showSettings,
      stripes,
    } = this.props;

    return (
      <Settings
        actAs={actAs}
        location={location}
        match={match}
        showSettings={showSettings}
        stripes={stripes}
        pages={this.pages}
        paneTitle={<FormattedMessage id="ui-myprofile.settings.index.paneTitle" />}
      />
    );
  }
}

export default MyProfile;

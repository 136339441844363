/* eslint-disable react/prop-types */
import { AppIcon } from '@folio/stripes-core';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import css from './style.css';

function AppItem({ app, index }) {
  const intl = useIntl();

  return (
    <Draggable draggableId={app.module} index={index}>
      {provided => (
        <div className={css.appItem} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <AppIcon alt="" app={app.module.replace('@folio/', '')} icon={app.iconData} />
          <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
            {intl.formatMessage({
              id: `ui-${app.module.replace('@folio/', '')}.meta.title`,
              defaultMessage: `${app.module.replace('@folio/', '')}`,
            })}
          </div>
        </div>
      )}
    </Draggable>
  );
}

export default AppItem;

import React, { useState } from 'react';
import {
  Accordion,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

function UserFilter({ currentUser, setCurrentUser, disabled }) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);

  const onChangeUser = (event) => {
    setCurrentUser(event.target.value);
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const renderFilterByTable = () => {
    const dataOptions = [
      {
        value: 'allUsers',
        label: intl.formatMessage({
          id: 'ui-translations.filters.UserFilter.allUsers',
        }),
        disabled: false,
      },
      { value: '-', label: '--------------------------', disabled: true },
    ];

    return (
      <div>
        <Select
          id="user-filter-select"
          name="locale-filter"
          value={currentUser}
          dataOptions={dataOptions}
          onChange={onChangeUser}
          disabled={disabled}
        />
      </div>
    );
  };
  return (
    <>
      <Accordion
        id="user-filter-accordion"
        label={
          <FormattedMessage id="ui-translations.filters.UserFilter.Accordion" />
        }
        onToggle={handleFilterToggle}
        open={filterToggle}
        // separator={false}
        header={FilterAccordionHeader}
      >
        {renderFilterByTable()}
      </Accordion>
    </>
  );
}

export default UserFilter;

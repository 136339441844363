import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Callout } from '@folio/stripes-components';
import { stripesConnect } from '@folio/stripes-core';
import { get } from 'lodash';
import ImportTranslationsForm from './ImportTranslationsForm';

function ImportTranslationsManager(props) {
  let callout;

  const onShowCallout = (message) => {
    if (callout) {
      callout.sendCallout({ message });
    }
  };

  const getLanguageId = (localeValue, category) => {
    return get(
      ((props.resources.translations || {}).records || []).find(
        (loc) => loc.localeValue === localeValue && loc.categoryName === category
      ),
      ['id'],
      ''
    );
  };

  const onFormSubmit = (values) => {
    if (
      values.localeValue === 'en' &&
      values.categoryValue === 'libTranslations' &&
      getLanguageId('en', 'libTranslations') === ''
    ) {
      props.mutator.translations
        .POST({
          categoryName: 'libTranslations',
          localeValue: 'en',
          messages: values.importedTranslations,
        })
        .then(
          () => {
            onShowCallout(
              <div>
                <strong>
                  <FormattedMessage id="ui-myprofile.settings.callout.success" />
                </strong>
              </div>
            );
          },
          async (response) => {
            const { errors } = await response.json();
            return onShowCallout(<div>{errors[0].message}</div>);
          }
        );
    } else {
      const record = ((props.resources.translations || {}).records || []).find(
        (loc) => loc.localeValue === values.localeValue &&
          loc.categoryName === values.categoryValue
      );
      props.mutator.translationId.replace(record.id);
      record.messages = Object.assign(
        record.messages,
        values.importedTranslations
      );
      props.mutator.translations.PUT(record).then(
        () => {
          onShowCallout(
            <div>
              <strong>
                <FormattedMessage id="ui-myprofile.settings.callout.success" />
              </strong>
            </div>
          );
        },
        async (response) => {
          const { errors } = await response.json();
          return onShowCallout(<div>{errors[0].message}</div>);
        }
      );
    }
  };

  return (
    <>
      <ImportTranslationsForm
        {...props}
        onSubmit={onFormSubmit}
        initialValues={{
          localeValue: '',
          categoryValue: '',
          importedTranslations: {},
        }}
      />
      <Callout
        ref={(ref) => {
          callout = ref;
        }}
      />
    </>
  );
}

ImportTranslationsManager.propTypes = {
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
  mutator: PropTypes.shape({
    translations: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired,
    }),
    translationId: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }),
  }),
};
ImportTranslationsManager.manifest = Object.freeze({
  translationId: '',
  translations: {
    type: 'okapi',
    records: 'translations',
    path: 'translations?limit=1000',
    // resourceShouldRefresh: true,
    POST: {
      path: 'translations',
    },
    PUT: {
      path: 'translations',
    },
  },
});

export default stripesConnect(ImportTranslationsManager);

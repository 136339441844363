import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Tooltip } from '@folio/stripes-components';
import AppConfigListContext from '../../../Context/AppConfigList/AppConfigListContext';
import {
  fetchTranslationsFromBing,
  fetchTranslationsFromGoogle,
  fetchTranslationsFromLibre,
  fetchTranslationsFromMyMemoAPI,
} from './FetchTranslations';
import googleIcon from '../../../../icons/google-translate.svg';
import libreIcon from '../../../../icons/libreTranslate-Icon.png';
import bingIcon from '../../../../icons/Bing_Translator.png';
import mymemoIcon from '../../../../icons/mym_logo.svg';

import MachineTranslatorMenu from './MachineTranslatorMenu';
import { stripesConnect, withStripes } from '@folio/stripes-core';

function BulkTranslationComponent({ selectedTranslations, locale, mutators, stripes }) {
  const appConfigContext = useContext(AppConfigListContext);
  const [defaultMTranslator, setDefaultMTranslator] = useState({});
  const intl = useIntl();
  const MTDataOptions = [
    {
      label: intl.formatMessage({
        id: 'ui-translations.translationSuggestions.googleTranslate',
      }),
      value: 'googleTranslate',
      icon: googleIcon,
      onClick: (item, lang, mutator, config, stripes) => fetchTranslationsFromGoogle(item, lang, mutator, config, stripes),
    },
    {
      label: intl.formatMessage({
        id: 'ui-translations.translationSuggestions.bingTranslate',
      }),
      value: 'bingTranslate',
      icon: bingIcon,
      onClick: (item, lang, mutator, config, stripes) => fetchTranslationsFromBing(item, lang, mutator, config, stripes),
    },
    {
      label: intl.formatMessage({
        id: 'ui-translations.translationSuggestions.libreTranslate',
      }),
      value: 'libreTranslate',
      icon: libreIcon,
      onClick: (item, lang, mutator, config, stripes) => fetchTranslationsFromLibre(item, lang, mutator, config, stripes),
    },
    {
      label: intl.formatMessage({
        id: 'ui-translations.translationSuggestions.mymemoTranslate',
      }),
      value: 'myMemoTranslate',
      icon: mymemoIcon,
      onClick: (item, lang, mutator, config, stripes) => fetchTranslationsFromMyMemoAPI(item, lang, mutator, config, stripes),
    },
  ];

  useEffect(() => {
    let defaultMt;
    if (appConfigContext.MtGeneral?.configValue?.defaultMTTranslator) {
      defaultMt = MTDataOptions.find(
        (mt) => mt.value ===
          appConfigContext.MtGeneral?.configValue?.defaultMTTranslator
      );
    } else {
      defaultMt = MTDataOptions.find(
        (mt) => mt.value === 'bingTranslate'
      );
    }
    setDefaultMTranslator(defaultMt);
  }, []);

  return (
    <>
      <MachineTranslatorMenu
        dataOptions={MTDataOptions}
        defaultMTranslator={defaultMTranslator}
        setDefaultMTranslator={setDefaultMTranslator}
      />
      <Tooltip
        id="google-translate-all-button-tooltip"
        text={
          <FormattedMessage
            id="ui-translations.googleTranslateAll.button.tooltip"
            defaultMessage="Translate all from google translate"
          />
        }
      >
        {({ ref, ariaIds }) => (
          <Button
            aria-labelledby={ariaIds.text}
            ref={ref}
            id="clickable-google-translate-all-multi-selections"
            buttonStyle="slim"
            onClick={async () => {
              for await (const item of selectedTranslations) {
                defaultMTranslator.onClick(item, locale, mutators, appConfigContext, stripes);
              }
            }}
            onMouseDown={(e) => e.preventDefault()}
            marginBottom0
          >
            <img
              src={defaultMTranslator.icon}
              alt="googleIcon"
              width="22px"
              height="22px"
            />
          </Button>
        )}
      </Tooltip>
    </>
  );
}

BulkTranslationComponent.propTypes = {
  selectedTranslations: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string,
  mutators: PropTypes.object,
};

export default withStripes(stripesConnect(BulkTranslationComponent));

/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ViewMetaData } from "@folio/stripes-smart-components";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import useQueryParams from "../Hooks/useQueryParams";
import TranslationWrapper from "../components/TranslationWrapper/TranslationWrapper";
import AppsTranslationsContext from "../Context/AppsTranslations/AppsTranslationsContext";
import CurrentLocaleContext from "../Context/CurrentLocale/CurrentLocaleContext";

function AppsTranslationRoute(props) {
  // const history = useHistory();

  const { local, stripes } = useContext(AppsTranslationsContext);
  const { currentLocale, setCurrentLocale } = useContext(CurrentLocaleContext);
  const [source, setSource] = useQueryParams("source", "merged");
  const [localeTranslations, setLocaleTranslations] = useState({});

  // useEffect(() => {
  //   props.history.push({
  //     pathname: "/translations/stripesApps",
  //     search: props.location.search,
  //     state: { backPathname: props.location.pathname },
  //   });
  //   return () => {
  //     setCurrentLocale("");
  //   };
  // }, []);

  const getOriginTranslations = () => {
    const originTranslations = {};
    const origin = stripes?.en;
    if (origin)
      Object.keys(origin).forEach((key) => {
        if (typeof origin[key] === "string") {
          originTranslations[key] = origin[key];
        } else if (origin[key] && origin[key].length) {
          originTranslations[key] = origin[key][0]?.value;
        }
      });
    return originTranslations;
  };

  const getLocaleTranslations = (translation) => {
    const locTrans = {};
    if (translation)
      Object.keys(translation).forEach((key) => {
        locTrans[key] =
          typeof translation[key] === "string"
            ? translation[key]
            : translation[key][0]?.value;
      });
    return locTrans;
  };

  useEffect(() => {
    switch (source) {
      case "merged":
        setLocaleTranslations(
          getLocaleTranslations(
            Object.assign(
              {},
              stripes[currentLocale.replace("-", "_")],
              local[currentLocale]?.messages
            )
          )
        );
        break;
      case "static":
        setLocaleTranslations(
          getLocaleTranslations(stripes[currentLocale.replace("-", "_")])
        );
        break;
      case "local":
        setLocaleTranslations(
          getLocaleTranslations(local[currentLocale]?.messages)
        );
        break;
      default:
        setLocaleTranslations(
          getLocaleTranslations(
            Object.assign(
              stripes[currentLocale.replace("-", "_")],
              local[currentLocale]?.messages
            )
          )
        );
    }
  }, [source, currentLocale, local, stripes]);

  return (
    <>
      <TranslationWrapper
        {...props}
        categoryName="stripesApps"
        currentLocale={currentLocale}
        setCurrentLocale={setCurrentLocale}
        source={source}
        setSource={setSource}
        originTranslations={getOriginTranslations() || {}}
        localeTranslations={localeTranslations}
        showAppStats
        // checkLocaleTranslators={props.getLocaleTranslatorsByValue}
        activeFilters={["locale", "app", "status", "source", "user"]}
        ViewMetaData={
          <ViewMetaData
            metadata={
              source === "local"
                ? local[currentLocale]?.metadata
                : { createdByUserId: "system" }
            }
          />
        }
        paneTitle={
          <FormattedMessage id="ui-translations.category.stripesApps" />
        }
      />
    </>
  );
}

AppsTranslationRoute.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default AppsTranslationRoute;

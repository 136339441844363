/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual, find } from 'lodash';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import localforage from 'localforage';

import { branding } from 'stripes-config';

import { Button, Icon, Tooltip } from '@folio/stripes-components';

import { withModules } from '../Modules';
import { LastVisitedContext } from '../LastVisited';
import { clearOkapiToken, clearCurrentUser } from '../../okapiActions';
import { resetStore } from '../../mainActions';
import { getLocale } from '../../loginServices';
import {
  updateQueryResource,
  getLocationQuery,
  updateLocation,
  getCurrentModule,
  isQueryResourceModule,
  getQueryResourceState,
} from '../../locationService';

import css from './MainNav.css';
import NavButton from './NavButton';
import NavDivider from './NavDivider';
import { CurrentAppGroup } from './CurrentApp';
import ProfileDropdown from './ProfileDropdown';
import AppList from './AppList';
import { SkipLink } from './components';
import { packageName } from '../../constants';

import settingsIcon from './settings.svg';

/* kware start editing */
import AppListSettingsMenu from './AppListSettings/AppListSettingsMenu';
import LibNav from './LibNav/LibNav';
import WithPreferredApps from '../../../util/kware-utils/WithPreferredApps';
import TenantsConfig from '../../../util/kware-utils/tenantsConfigs';
/* kware end editing */

class MainNav extends Component {
  static propTypes = {
    intl: PropTypes.object,
    stripes: PropTypes.shape({
      config: PropTypes.shape({
        showPerms: PropTypes.bool,
        helpUrl: PropTypes.string,
      }),
      store: PropTypes.shape({
        dispatch: PropTypes.func.isRequired,
      }),
      hasPerm: PropTypes.func.isRequired,
      withOkapi: PropTypes.bool,
    }),
    history: PropTypes.shape({
      listen: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    modules: PropTypes.shape({
      app: PropTypes.arrayOf(PropTypes.object),
    }),
    /* kware start editing */
    defaultTheme: PropTypes.string,
    userPreferredApps: PropTypes.arrayOf(PropTypes.object),
    /* kware end editing */
  };

  constructor(props) {
    super(props);
    this.state = {
      userMenuOpen: false,
      /* kware start editing */
      showLibNav: true,
      defaultView: 'preferredFirst',
      /* kware end editing */
    };
    this.store = props.stripes.store;
    this.logout = this.logout.bind(this);
    this.getAppList = this.getAppList.bind(this);
  }

  componentDidMount() {
    let curQuery = getLocationQuery(this.props.location);
    const prevQueryState = {};

    this._unsubscribe = this.store.subscribe(() => {
      const { history, location } = this.props;
      const module = this.curModule;
      const state = this.store.getState();

      // If user has timed out, force them to log in again.
      if (
        state?.okapi?.token &&
        state.okapi.authFailure &&
        find(state.okapi.authFailure, { type: 'error', code: 'user.timeout' })
      ) {
        this.returnToLogin();
      }

      if (module && isQueryResourceModule(module, location)) {
        const { moduleName } = module;
        const queryState = getQueryResourceState(module, this.store);

        // only update location if query state has changed
        if (!isEqual(queryState, prevQueryState[moduleName])) {
          curQuery = updateLocation(
            module,
            curQuery,
            this.store,
            history,
            location
          );
          prevQueryState[moduleName] = queryState;
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { modules, location } = this.props;
    this.curModule = getCurrentModule(modules, location);
    if (this.curModule && !isEqual(location, prevProps.location)) {
      updateQueryResource(location, this.curModule, this.store);
    }
  }

  componentWillUnmount() {
    this._unsubscribe();
  }

  toggleUserMenu() {
    const isOpen = this.state.userMenuOpen;
    this.setState({
      userMenuOpen: !isOpen,
    });
  }

  // Return the user to the login screen, but after logging in they will return to their previous activity.
  returnToLogin() {
    const { okapi } = this.store.getState();

    return getLocale(okapi.url, this.store, okapi.tenant).then(() => {
      this.store.dispatch(clearOkapiToken());
      this.store.dispatch(clearCurrentUser());
      this.store.dispatch(resetStore());
      localforage.removeItem('okapiSess');
    });
  }

  // return the user to the login screen, but after logging in they will be brought to the default screen.
  logout() {
    console.clear(); // eslint-disable-line no-console
    this.returnToLogin().then(() => {
      this.props.history.push('/');
    });
  }

  /* kware start editing */
  getDefaultAppsView = () => {
    const ids = new Set(
      this.props.stripes.userPreferredApps.map((d) => d.module)
    );
    switch (this.state.defaultView) {
      case 'preferredOnly':
        return this.props.stripes.userPreferredApps;
      case 'preferredFirst':
        return [
          ...this.props.stripes.userPreferredApps,
          ...this.props.modules.app.filter((d) => !ids.has(d.module)),
        ];
      case 'default':
        return this.props.modules.app;
      default:
        return this.props.modules.app;
    }
  };

  setDefaultView = (newState) => {
    this.setState({
      defaultView: newState,
    });
  };
  /* kware end editing */

  getAppList(lastVisited) {
    const {
      stripes,
      location: { pathname },
      modules,
      intl: { formatMessage },
    } = this.props;

    /* kware start editing */
    const appsToView = this.props.stripes.userPreferredApps
      ? this.getDefaultAppsView()
      : this.props.modules.app;
    /* kware end editing */

    // const apps = modules.app
    const apps = appsToView /* kware start editing */
      .map((entry) => {
        const name = entry.module.replace(packageName.PACKAGE_SCOPE_REGEX, '');
        const perm = `module.${name}.enabled`;

        if (!stripes.hasPerm(perm)) {
          return null;
        }

        const id = `clickable-${name}-module`;

        const pathRoot = pathname.split('/')[1];
        const entryRoot = entry.route.split('/')[1];
        const active = pathRoot === entryRoot;

        const last = lastVisited[name];
        const home = entry.home || entry.route;
        const href = active || !last ? home : lastVisited[name];

        return {
          id,
          href,
          active,
          name,
          ...entry,
        };
      })
      .filter((app) => app);

    /** kware start editing */
    // According to the impact that has occurred since the cancellation of the IntlProvider key prop,
    // may be re-translation of the names of the applications here a quick solution to avoid this effect.
    apps.forEach((app) => {
      if (app.href) {
        app.displayName = formatMessage({
          id: `ui-${app.name}.meta.title`,
          defaultMessage: `${app.name}`,
        });
      }
    });
    /** kware end editing */

    /**
     * Add Settings to apps array manually
     * until Settings becomes a standalone app
     */

    if (stripes.hasPerm('settings.enabled')) {
      apps.push({
        displayName: formatMessage({ id: 'stripes-core.settings' }),
        id: 'clickable-settings',
        href: lastVisited.x_settings || '/settings',
        active: pathname.startsWith('/settings'),
        description: 'FOLIO settings',
        iconData: {
          src: settingsIcon,
          alt: 'Tenant Settings',
          title: 'Settings',
        },
        route: '/settings',
      });
    }

    return apps;
  }

  /* kware start editing */
  setShowLibNav = (newState) => {
    this.setState({ showLibNav: newState });
    localStorage.setItem('showLibNav', newState);
  };

  getHelpeUrl = (selectedApp) => {
    const locale = this.props.intl.locale.split('-')[0];
    const appName = selectedApp?.name || '';

    switch (true) {
      case [
        'checkin',
        'checkout',
        'circulation-log',
        'courses',
        'requests',
      ].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/access/${appName}`;
      case ['users'].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/users-management/${appName}`;
      case [
        'finance',
        'invoice',
        'orders',
        'organizations',
        'receiving',
      ].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/acquisitions/${appName}`;
      case ['data-export', 'data-import', 'inventory'].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/metadata/${appName}`;
      case [
        'agreements',
        'eholdings',
        'eusage',
        'licenses',
        'local-kb-admin',
      ].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/erm/${appName}`;
      case ['translations'].includes(appName):
        return `http://wiki.kwaretech.com/${locale}/folio/docs/localization/${appName}`;
      case this.props.location.pathname.startsWith('/settings') &&
        selectedApp?.href !== '/settings':
        return `http://wiki.kwaretech.com/${locale}/folio/docs/settings/${
          this.props.location.pathname.split('/')[2]
        }`;
      case selectedApp?.href === '/settings':
        return `http://wiki.kwaretech.com/${locale}/folio/docs/settings`;
      default:
        return `http://wiki.kwaretech.com/${locale}/home`;
    }
  };
  /* kware end editing */

  render() {
    const { stripes, intl } = this.props;
    const tenantColors = TenantsConfig.tenants.find(
      (ten) => ten.id === stripes.okapi.tenant
    );

    return (
      <LastVisitedContext.Consumer>
        {({ lastVisited }) => {
          const apps = this.getAppList(lastVisited);
          const selectedApp = apps.find((entry) => entry.active);
          const helpUrl = stripes.config.helpUrl ?? 'https://docs.folio.org';

          return (
            <>
              {/* kware start editing */}
              {stripes.defaultTheme === 'kwareTheme' &&
                this.state.showLibNav && (
                  <LibNav
                    selectedApp={selectedApp}
                    settingsApps={this.props.modules.settings}
                    onSetShowLibNav={this.setShowLibNav}
                    stripes={stripes}
                  />
              )}
              {/* kware end editing */}
              <header
                className={css.navRoot}
                // style={branding.style?.mainNav ?? {}}
                style={{ backgroundColor: tenantColors?.colors?.headerBg || '#242628' }}
              >
                <div className={css.startSection}>
                  <SkipLink />
                  <CurrentAppGroup
                    selectedApp={selectedApp}
                    config={stripes.config}
                  />
                </div>
                <nav
                  aria-label={intl.formatMessage({
                    id: 'stripes-core.mainnav.topLevelLabel',
                  })}
                  className={css.endSection}
                >
                  <AppList
                    apps={apps}
                    /* kware start editing */
                    key={
                      this.state.defaultView ||
                      intl.locale ||
                      stripes.defaultTheme
                    }
                    /* kware end editing */
                    selectedApp={selectedApp}
                    dropdownToggleId="app-list-dropdown-toggle"
                  />
                  <NavDivider md="hide" />
                  <NavButton
                    aria-label="Help button"
                    data-test-item-help-button
                    // href={helpUrl}
                    /* kware start editing */
                    href={this.getHelpeUrl(selectedApp)}
                    /* kware end editing */
                    icon={<Icon icon="question-mark" size="large" />}
                    id="helpButton"
                    target="_blank"
                  />
                  {/* <NavDivider md="hide" />
                  <ProfileDropdown onLogout={this.logout} stripes={stripes} /> */}
                  {/* kware start editing */}
                  <NavDivider md="hide" />
                  <AppListSettingsMenu
                    defaultView={this.state.defaultView}
                    setDefaultView={this.setDefaultView}
                  />
                  {!this.state.showLibNav && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <NavDivider md="hide" />
                      {/* <Button
                        buttonStyle="slim"
                        marginBottom0
                        onClick={() => this.setShowLibNav(true)}
                      >
                        <div style={{ color: 'white' }}>
                          <Icon icon="triangle-down" />
                        </div>
                      </Button> */}
                      <Tooltip
                        id="show-lib-nav-tooltip"
                        text={
                          <FormattedMessage id="stripes-core.buttons.tooltip.ShowLibNav" />
                        }
                      >
                        {({ ref, ariaIds }) => (
                          <Button
                            id="btn-show-lib-nav"
                            aria-labelledby={ariaIds.text}
                            ref={ref}
                            buttonStyle="slim"
                            marginBottom0
                            onClick={() => this.setShowLibNav(true)}
                          >
                            <div style={{ color: 'white' }}>
                              <Icon icon="triangle-down" size="medium" />
                            </div>
                          </Button>
                        )}
                      </Tooltip>
                    </div>
                  )}
                  {stripes.defaultTheme === 'standardTheme' && (
                    <ProfileDropdown onLogout={this.logout} stripes={stripes} />
                  )}
                  {/* kware end editing */}
                </nav>
              </header>
            </>
          );
        }}
      </LastVisitedContext.Consumer>
    );
  }
}

export default compose(
  injectIntl,
  withRouter,
  /* kware start editing */
  WithPreferredApps,
  /* kware end editing */
  withModules
)(MainNav);

export const QUERY_DETAILS_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const QUERY_KEYS = {
  QUERY_PLUGIN_CONTENT_DATA: 'QUERY_PLUGIN_CONTENT_DATA',
  QUERY_PLUGIN_ENTITY_TYPE: 'QUERY_PLUGIN_ENTITY_TYPE',
  QUERY_PLUGIN_PARAMS_SOURCE: 'QUERY_PLUGIN_PARAMS_SOURCE',
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Settings } from '@folio/stripes/smart-components';
import LanguagesManager from './Languages/LanguagesManager';
import LanguagesTranslatorsManager from './LanguagesTranslators/LanguagesTranslatorsManager';
import Restore from './BackupAndRestore/Restore';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import GoogleTranslateSettings from './MachineTranslationsSettings/GoogleTranslateSettings';
import S3Settings from './StorageSettings/S3Settings';
import GlossarySettings from './GeneralSettings/GlossarySettings';
import BackupSettingsManager from './BackupAndRestore/BackupSettings/BackupSettingsManager';
import GithubSettings from './SyncSettings/GithubSettings';
import LibreSettingsManager from './MachineTranslationsSettings/LibreTranslate/LibreSettingsManager';
import MtGeneralSettingsManager from './MachineTranslationsSettings/GeneralSettings/MtGeneralSettingsManager';
import RestoreSettingsManager from './BackupAndRestore/RestoreSettings/RestoreSettingsManager';

export default class TranslationsSettings extends React.Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        label: <FormattedMessage id="ui-translations.settings.general.lable" />,
        pages: [
          {
            route: 'general',
            label: (
              <FormattedMessage id="ui-translations.settings.generalSettings.lable" />
            ),
            component: GeneralSettings,
            perm: 'ui-translations.create',
          },
          {
            route: 'glossary',
            label: (
              <FormattedMessage id="ui-translations.settings.glossarySettings.lable" />
            ),
            component: GlossarySettings,
            perm: 'ui-translations.create',
          },
        ],
      },
      {
        label: <FormattedMessage id="ui-translations.settings.languagesAndTranslators.lable" />,
        pages: [
          {
            route: 'languages',
            label: (
              <FormattedMessage id="ui-translations.settings.languages.lable" />
            ),
            component: LanguagesManager,
            perm: 'ui-translations.create',
          },
          {
            route: 'languages-translators',
            label: (
              <FormattedMessage id="ui-translations.settings.languagesTranslators.lable" />
            ),
            component: LanguagesTranslatorsManager,
            perm: 'ui-translations.settings.localesTranslators',
          },
        ],
      },
      {
        label: <FormattedMessage id="ui-translations.settings.BackupAndRestoreSettings.lable" />,
        pages: [
          {
            route: 'backup',
            label: (
              <FormattedMessage id="ui-translations.settings.backupSettings.lable" />
            ),
            component: BackupSettingsManager,
            perm: 'ui-translations.create',
          },
          {
            route: 'restore',
            label: (
              <FormattedMessage id="ui-translations.settings.restoreSettings.lable" />
            ),
            component: RestoreSettingsManager,
            perm: 'ui-translations.settings.localesTranslators',
          },
        ],
      },
      {
        label: <FormattedMessage id="ui-translations.settings.machineTranslationsSettings.lable" />,
        pages: [
          {
            route: 'mt/general',
            label: (
              <FormattedMessage id="ui-translations.settings.general.lable" />
            ),
            component: MtGeneralSettingsManager,
            perm: 'ui-translations.create',
          },
          // {
          //   route: 'mt/google',
          //   label: (
          //     <FormattedMessage id="ui-translations.settings.machineTranslations.google.lable" />
          //   ),
          //   component: GoogleTranslateSettings,
          //   perm: 'ui-translations.create',
          // },
          // {
          //   route: 'mt/libre',
          //   label: (
          //     <FormattedMessage id="ui-translations.settings.machineTranslations.libre.lable" />
          //   ),
          //   component: LibreSettingsManager,
          //   perm: 'ui-translations.settings.localesTranslators',
          // },
        ],
      },
      {
        label: <FormattedMessage id="ui-translations.settings.storageSettings.lable" />,
        pages: [
          {
            route: 's3-settings',
            label: (
              <FormattedMessage id="ui-translations.settings.storage.S3Settings.lable" />
            ),
            component: S3Settings,
            perm: 'ui-translations.create',
          },
        ],
      },
      {
        label: <FormattedMessage id="ui-translations.settings.syncSettings.lable" />,
        pages: [
          {
            route: 'github-settings',
            label: (
              <FormattedMessage id="ui-translations.settings.sync.githubSettings.lable" />
            ),
            component: GithubSettings,
            perm: 'ui-translations.settings.localesTranslators',
          },
        ],
      },
    ];
  }

  render() {
    return (
      <Settings
        {...this.props}
        sections={this.sections}
        paneTitle={<FormattedMessage id="ui-translations.meta.title" />}
        navPaneWidth="20%"
      />
    );
  }
}

// eslint-disable-next-line import/prefer-default-export
export const timeUnitDataOptions = [
  {
    label: 'second',
    value: 'second',
  },
  {
    label: 'minute',
    value: 'minute',
  },
  {
    label: 'hour',
    value: 'hour',
  },
  {
    label: 'day',
    value: 'day',
  },
  {
    label: 'week',
    value: 'week',
  },
  {
    label: 'month',
    value: 'month',
  },
  {
    label: 'quarter',
    value: 'quarter',
  },
  {
    label: 'year',
    value: 'year',
  },
];

export const filtersOperators = [
  {
    label: 'Equals',
    value: 'equals',
  },
  {
    label: 'Not equals',
    value: 'notEquals',
  },
  {
    label: 'Contains',
    value: 'contains',
  },
  {
    label: 'Not contains',
    value: 'notContains',
  },
  {
    label: 'Starts with',
    value: 'startsWith',
  },
  {
    label: 'Ends with',
    value: 'endsWith',
  },
  {
    label: 'Greater than',
    value: 'gt',
  },
  {
    label: 'Greater than or equal to',
    value: 'gte',
  },
  {
    label: 'Less than',
    value: 'lt',
  },
  {
    label: 'Less than or equal to',
    value: 'lte',
  },
  {
    label: 'Set',
    value: 'set',
  },
  {
    label: 'Not set',
    value: 'notSet',
  },
  {
    label: 'In date range',
    value: 'inDateRange',
  },
  {
    label: 'Not in date range',
    value: 'notInDateRange',
  },
  {
    label: 'Before date',
    value: 'beforeDate',
  },
  {
    label: 'After date',
    value: 'afterDate',
  },
];

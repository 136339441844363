import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { stripesConnect } from '@folio/stripes-core';
import WidgetForm from './WidgetForm';
import DashboardsContext from '../../../Context/dashboards/DashboardsContext';

function WidgetFormManager(props) {
  const dashboards = useContext(DashboardsContext);

  const [initialValues, setInitialValues] = useState({});
  const [currentReport, setCurrentReport] = useState({});

  const currentDashboard = dashboards.find(
    dash => dash.name === props.location.pathname.split('/')[3]
  );

  useEffect(() => {
    if (props.location?.pathname?.endsWith('/edit')) {
      setInitialValues({
        reportId: '',
        wedgetName: 'Total Instances',
        wedgetDesc: 'Total Instances',
        visableColumns: [],
        viewSettings: {}
      });
    }
  }, [props.location]);

  console.log({ currentReport });

  const getFieldValues = values => {
    const columns = [];
    values.map(val => columns.push(val.value));

    return columns;
  };

  const onFormSubmit = values => {
    console.log({ values });
    if (Object.keys(initialValues).length) {
      const widgetId = props.location?.pathname?.split('/')[4];
      props.mutator.widgetId.replace(widgetId);
      // record.name = values.reportName;
      // record.desc = values.reportDesc;
      // record.queryMetadata = resultsSet?.loadResponses[0]?.query;
      // record.queryString = {
      //   sqlCommand: queryString.replace(/(\r\n|\n|\r|\")/gm, ' '),
      // };
      // record.viewSettings = viewSettings;
      // mutator.reports.PUT(record);
    } else {
      props.mutator.widgets.POST({
        name: values.widgetName,
        desc: values.widgetDesc,
        reportsIds: [currentReport?.id],
        dashboardId: currentDashboard?.id,
        // visibleColumns:
        //   values?.visableColumns && values?.visableColumns?.length !== 0
        //     ? getFieldValues(values.visableColumns)
        //     : [],
        // visibleColumns: [],
        queriesMetadata: [currentReport.queryMetadata]
        // chartMetadata: {}
      });
    }
  };

  return (
    <>
      <WidgetForm
        handleClose={props.handleClose}
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        editMode={Object.keys(initialValues).length}
        currentDashName={props.location.pathname.split('/')[3]}
        currentReport={currentReport}
        setCurrentReport={setCurrentReport}
      />
    </>
  );
}

WidgetFormManager.propTypes = {
  handleClose: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.object,
  resources: PropTypes.shape({
    widgets: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object)
    }),
    widgetId: PropTypes.shape({
      replace: PropTypes.func.isRequired
    })
  }),
  mutator: PropTypes.shape({
    widgets: PropTypes.shape({
      GET: PropTypes.func.isRequired,
      reset: PropTypes.func.isRequired,
      POST: PropTypes.func.isRequired,
      PUT: PropTypes.func.isRequired
    }),
    widgetId: PropTypes.shape({
      replace: PropTypes.func.isRequired
    })
  })
};

WidgetFormManager.manifest = Object.freeze({
  widgets: {
    type: 'okapi',
    records: 'widgets',
    path: 'widgets?limit=1000',
    POST: {
      path: 'widgets'
    },
    PUT: {
      path: 'widgets'
    },
    DELETE: {
      path: 'widgets'
    }
  }
});

export default stripesConnect(WidgetFormManager);

/* eslint-disable max-len */
/* eslint-disable react/sort-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Callout,
  Dropdown,
  DropdownMenu,
  Button,
  Icon,
  NavListSection,
  NavListItem,
  NavList
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { stripesConnect, IfPermission } from '@folio/stripes-core';
import { isEqual } from 'lodash';
import ImportConfirmationModal from './ImportConfirmationModal';
import WithTranslations from './WithTranslations';
import CreateNewKeysModal from './CreateNewKeysModal';

const propTypes = {
  appName: PropTypes.string.isRequired,
  columnMapping: PropTypes.object,
  instanceName: PropTypes.string.isRequired,
  contentData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileName: PropTypes.string.isRequired,
  primaryField: PropTypes.string.isRequired,
  dataFormat: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  translatableFields: PropTypes.arrayOf(PropTypes.string),
  visibleFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  getTranslations: PropTypes.func,
  updateTranslations: PropTypes.func.isRequired,
  stripes: PropTypes.object.isRequired,
  generateNewTranslationKey: PropTypes.func.isRequired,
};

const ActionsMenu = props => {
  const intl = useIntl();
  let fileReader;
  // let callout = useRef(null);
  const inputRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const [openNewKeysModal, setOpenNewKeysModal] = useState(false);

  const [importedFileContent, setImportedFileContent] = useState([]);
  const [importedFileName, setImportedFileName] = useState('');

  const toggleDropdown = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const getExportedContentData = contentData => {
    return contentData.map(item => {
      return Object.keys(item)
        .filter(key => !key.startsWith('translated'))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});
    });
  };

  const exportFile = (filename, data) => {
    const blob = new Blob([data], { type: 'application/json' });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  const onHandeleExport = () => {
    // fileName may be return [object, object] becuase <FormattedMessage /> return span
    const fileName =
      typeof props.fileName === 'object'
        ? intl.formatMessage({ id: props.fileName.props.id })
        : props.fileName;
    exportFile(
      `${fileName}.json`,
      JSON.stringify(getExportedContentData(props.contentData), null, ' ')
    );
  };

  const onReadFileContenet = () => {
    setImportedFileContent(JSON.parse(fileReader.result));
    setOpenModal(true);
  };

  const onChooseImportedFile = e => {
    const fileList = e.target.files[0];
    fileReader = new FileReader();
    fileReader.onloadend = onReadFileContenet;
    fileReader.readAsText(fileList);
    setImportedFileName(fileList.name);
  };

  // const onShowCallout = message => {
  //   if (callout) {
  //     callout.sendCallout({ message });
  //   }
  // };

  // execlude ['id', 'metadat'] from Comparison
  const filterContentData = contentData => {
    const data = Array.isArray(contentData) ? contentData : [contentData];
    return data.map(item => {
      return Object.keys(item)
        .filter(key => !['id', 'metadata'].includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});
    });
  };

  // push current id of updated data to make an update
  const handleUpdatedtData = (contentData, updatedData) => {
    const data = Array.isArray(contentData) ? contentData : [contentData];
    const handeledData = [];
    updatedData.map(updata => {
      data.map(item => {
        if (item[props.primaryField] === updata[props.primaryField]) {
          updata.id = item.id;
          handeledData.push(updata);
        }
      });
    });
    return handeledData;
  };

  const onSaveImportedFileContent = () => {
    const { contentData, onCreate, onUpdate } = props;
    const currentData = filterContentData(contentData);
    const importedData = filterContentData(importedFileContent);

    const newData = importedData.filter(impData => {
      return !currentData.some(orgData => {
        return impData[props.primaryField] === orgData[props.primaryField];
      });
    });

    const updatedData = importedData.filter(impData => {
      return !currentData.some(currData => {
        return isEqual(impData, currData);
      });
    });

    if (newData.length) {
      Promise.resolve(newData.map(item => onCreate(item))).then(() => {
        // onShowCallout(
        //   <FormattedMessage
        //     id="ui-tenant-settings.callout.setTenantDefaultLocale.success"
        //     values={{ localeName: '' }}
        //   />
        // );
      });
    }

    if (updatedData.length) {
      Promise.resolve(
        handleUpdatedtData(contentData, updatedData).map(item => onUpdate(item))
      ).then(() => {
        // onShowCallout(
        //   <FormattedMessage
        //     id="ui-tenant-settings.callout.setTenantDefaultLocale.success"
        //     values={{ localeName: '' }}
        //   />
        // );
      });
    }
    setOpenModal(false);
  };

  const renderImportConfirmationModal = () => {
    const { visibleFields, columnMapping, dataFormat } = props;

    return (
      <ImportConfirmationModal
        onClose={() => setOpenModal(false)}
        onSave={onSaveImportedFileContent}
        open={openModal}
        contentData={filterContentData(importedFileContent)}
        visibleFields={visibleFields}
        dataFormat={dataFormat}
        fileName={importedFileName}
        columnMapping={columnMapping}
      />
    );
  };

  const renderImportFileButton = () => {
    return (
      <input
        ref={inputRef}
        type="file"
        onChange={(e) => onChooseImportedFile(e)}
        // to allow input type=file to select the same file again we need to reset the value every time we click on it
        onClick={(e) => {
          e.target.value = null;
        }}
        id="importFiles"
        // multiple
        accept=".json"
        style={{ display: 'none' }}
      />
    );
  };

  const generateNewTranslationsKeys = () => {
    const { translatableFields, appName, instanceName } = props;
    const newTrans = {};

    for (const item of filterContentData(props.contentData)) {
      if (translatableFields && translatableFields.length !== 0) {
        translatableFields.forEach(trans => {
          if (item[trans]) {
            newTrans[`${appName}.${instanceName}.${trans}.${item[trans]}`] = item[trans];
          }
        });
      }
    }
    return newTrans;
  };

  const onGenerateNewTranslationKey = async () => {
    const newTransKeys = await Promise.resolve(generateNewTranslationsKeys());
    await props.updateTranslations('en', 'libTranslations', newTransKeys);
    setOpenNewKeysModal(false);
  };

  const renderCreateNewKeysModal = () => {
    return (
      <CreateNewKeysModal
        onClose={() => setOpenNewKeysModal(false)}
        onSave={onGenerateNewTranslationKey}
        open={openNewKeysModal}
        contentData={generateNewTranslationsKeys()}
        instanceName={props.instanceName}
        fileName={props.fileName}
      />
    );
  };

  const getDropdownContent = () => {
    return (
      <>
        <NavList>
          <NavListSection>
            <NavListItem
              id="clickable-import-file"
              type="button"
              onClick={() => inputRef.current.click()}
            >
              <Icon icon="import">
                <FormattedMessage
                  id="stripes-smart-components.buttons.import"
                  values={{ paneTitel: props.fileName }}
                />
              </Icon>
            </NavListItem>

            <NavListItem
              id="clickable-export-file"
              type="button"
              onClick={onHandeleExport}
            >
              <Icon icon="export">
                <FormattedMessage
                  id="stripes-smart-components.buttons.export"
                  values={{ paneTitel: props.fileName }}
                />
              </Icon>
            </NavListItem>

            <hr />
            <NavListItem
              id="clickable-create-new-translation-keys"
              type="button"
              onClick={() => setOpenNewKeysModal(true)}
            >
              <Icon icon="plus-sign">
                <FormattedMessage
                  id="stripes-smart-components.buttons.createTranslationKeys"
                  defaultMessage="Create translations keys"
                />
              </Icon>
            </NavListItem>
          </NavListSection>
        </NavList>
      </>
    );
  };

  const renderActionsMenuTrigger = ({ getTriggerProps, open }) => {
    return (
      <FormattedMessage id="stripes-core.mainnav.myProfileAriaLabel">
        {label => (
          <div style={{ paddingRight: '0.25em', paddingLeft: '0.25em' }}>
            <Button
              data-test-pane-header-actions-button
              buttonStyle="primary"
              marginBottom0
              ariaLabel={label}
              type="button"
              {...getTriggerProps()}
            >
              <Icon icon="ellipsis" size="large" />
            </Button>
          </div>
        )}
      </FormattedMessage>
    );
  };

  const renderActionsMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  const renderDropdownComponent = () => {
    return (
      <Dropdown
        id="editableList-actionsMenu-Dropdown"
        renderTrigger={renderActionsMenuTrigger}
        renderMenu={renderActionsMenu}
        open={dropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    );
  };

  return (
    <>
      {renderImportFileButton()}
      {renderImportConfirmationModal()}
      {renderCreateNewKeysModal()}
      {renderDropdownComponent()}
      {/* <Callout
        ref={ref => {
          callout = ref;
        }}
      /> */}
    </>
  );
};

ActionsMenu.propTypes = propTypes;

export default stripesConnect(WithTranslations(ActionsMenu));

import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-router-dom/Switch';
import Route from 'react-router-dom/Route';
import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { Redirect } from 'react-router';
import { Paneset } from '@folio/stripes/components';
import WebSocketTransport from '@cubejs-client/ws-transport';
import Settings from './settings';
import PrefferedReportsRoute from './routes/PrefferedReportsRoute';
import ScheduledReportsRoute from './routes/ScheduledReportsRoute';
import DashboardsProvider from './Context/dashboards/DashboardsProvider';
import AppConfigListProvider from './Context/appConfigList/AppConfigListProvider';
import WidgetFormRoute from './routes/WidgetFormRoute';
import DashboardsRoute from './routes/DashboardsRoute';
import ReportsProvider from './Context/reports/ReportsProvider';
import QueryBuilderRoute from './routes/QueryBuilderRoute';
import ReportsListRoute from './routes/ReportsListRoute';
import QueryResultSetRoute from './routes/QueryResultSetRoute';
import DashboardsRouteTest from './routes/DashboardsTestRoute';

function Reports(props) {
  const cubejsApi = cubejs(
    '1ef06b123908163668dcc89c6cf947265114a0bd26c496b9c9a7197c691710ce323534afee282dda37e04b8b2b3b39b361876571460528ee4c97dd706667f97e',
    {
      apiUrl: 'http://34.248.171.30:8000/cubejs-api/v1',
      transport: new WebSocketTransport({
        authorization:
          '1ef06b123908163668dcc89c6cf947265114a0bd26c496b9c9a7197c691710ce323534afee282dda37e04b8b2b3b39b361876571460528ee4c97dd706667f97e',
        apiUrl: 'ws://34.248.171.30:8000/',
      }),
    },
  );

  const {
    actAs,
    match: { path },
  } = props;
  if (actAs === 'settings') {
    return <Settings {...props} />;
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AppConfigListProvider>
        <DashboardsProvider>
          <ReportsProvider>
            <Paneset isRoot>
              {/* <RoutesTabs /> */}
              <Switch>
                <Redirect exact from={path} to={`${path}/dashboards`} />
                <Route
                  path={`${path}/dashboards`}
                  exact
                  component={DashboardsRoute}
                />

                {/* // test route */}
                <Route
                  path={`${path}/dashboards-test`}
                  exact
                  component={DashboardsRouteTest}
                />

                <Route
                  path={`${path}/dashboards/:dashName/widgets/create`}
                  exact
                  component={WidgetFormRoute}
                />
                <Route
                  path={`${path}/dashboards/:dashName/:widgetId/edit`}
                  component={WidgetFormRoute}
                />

                <Route
                  path={`${path}/reports-list`}
                  exact
                  component={ReportsListRoute}
                />
                <Route
                  path={`${path}/reports-list/:id/view`}
                  exact
                  component={ReportsListRoute}
                />

                <Route
                  path={`${path}/reports-list/:id/run`}
                  exact
                  component={QueryResultSetRoute}
                />

                <Route
                  path={`${path}/preffered-reports`}
                  exact
                  component={PrefferedReportsRoute}
                />
                <Route
                  path={`${path}/scheduled-reports`}
                  exact
                  component={ScheduledReportsRoute}
                />
                <Route
                  path={`${path}/query-builder`}
                  exact
                  component={QueryBuilderRoute}
                />
              </Switch>
            </Paneset>
          </ReportsProvider>
        </DashboardsProvider>
      </AppConfigListProvider>
    </CubeProvider>
  );
}

Reports.propTypes = {
  match: PropTypes.object.isRequired,
  showSettings: PropTypes.bool,
  stripes: PropTypes.shape({
    connect: PropTypes.func,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  actAs: PropTypes.string.isRequired,
};

export default Reports;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  NavList,
  NavListItem,
  Icon,
  DropdownMenu,
  Dropdown,
  Button,
} from '@folio/stripes-components';

const propTypes = {
  dataOptions: PropTypes.arrayOf(object),
  defaultMTranslator: PropTypes.object,
  setDefaultMTranslator: PropTypes.func,
};

const MachineTranslatorMenu = ({
  dataOptions,
  defaultMTranslator,
  setDefaultMTranslator,
}) => {
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const getDropdownContent = () => {
    return (
      <div>
        <NavList>
          {dataOptions.map((translator) => (
            <NavListItem
              id={`machine-translator-menu-${translator.value}`}
              buttonStyle={`${
                defaultMTranslator.value === translator.value ? 'primary' : 'default'
              }`}
              selected={defaultMTranslator.value === translator.value}
              onClick={() => {
                setDefaultMTranslator(translator);
                toggleDropdown();
              }}
            >
              {translator.label}
            </NavListItem>
          ))}
        </NavList>
      </div>
    );
  };

  const renderLocalesTrigger = ({ getTriggerProps, open }) => {
    return (
      <FormattedMessage id="ui-translations.MachineTranslatorMenu">
        {(label) => (
          <Button
            data-role="toggle"
            ariaLabel={label}
            buttonStyle="slim"
            selected={open}
            marginBottom0
            {...getTriggerProps()}
          >
            <Icon iconPosition="end" icon={open ? 'caret-up' : 'caret-down'}>
              {defaultMTranslator.label}
            </Icon>
          </Button>
        )}
      </FormattedMessage>
    );
  };

  const renderLocalesMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <>
      <Dropdown
        id="userLanguageSwitcherDropdown"
        renderTrigger={renderLocalesTrigger}
        renderMenu={renderLocalesMenu}
        open={DropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        // usePortal={false}
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    </>
  );
};

MachineTranslatorMenu.propTypes = propTypes;

export default MachineTranslatorMenu;

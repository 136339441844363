/* eslint-disable react/prop-types */
import React from 'react';
import { branding } from 'stripes-config';
import styles from './OrganizationLogo.css';
import TenantsConfig from '../../../util/kware-utils/tenantsConfigs';

const OrganizationLogo = ({ tenant }) => {
  /** kware start edit */
  const tenantlogo = TenantsConfig.tenants.find((ten) => ten.id === tenant);
  /** kware end edit */
  return (
    branding && (
      <div className={styles.logo}>
        {/** kware start edit */}
        <img alt={tenantlogo?.logo?.alt} src={tenantlogo?.logo?.src} />
        {/** kware end edit */}
      </div>
    )
  );
};

export default OrganizationLogo;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocaleLabel } from '../../../utils/utils';
import AssignedLanguagesContext from '../../../Context/AssignedLanguages/AssignedLanguagesContext';

function LocaleFilter({
  currentLocale,
  setCurrentLocale,
  disabled,
}) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);
  const languages = useContext(AssignedLanguagesContext);

  const onChangeLocale = (event) => {
    setCurrentLocale(event.target.value);
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const getLocaleDataOptions = () => {
    return [
      ...languages.map((lang) => ({
        label: getLocaleLabel(lang.localeValue, intl),
        value: lang.localeValue,
      })),
    ];
  };

  return (
    <>
      <Accordion
        id="locale-filter-accordion"
        label={
          <FormattedMessage id="ui-translations.filters.LocaleFilter.Accordion" />
        }
        onToggle={handleFilterToggle}
        open={filterToggle}
        separator={false}
        header={FilterAccordionHeader}
      >
        <Select
          id="locale-filter-select"
          name="locale-filter"
          value={currentLocale}
          placeholder={intl.formatMessage({
            id: 'ui-translations.filters.LocaleFilter.selectuserLocales',
          })}
          dataOptions={getLocaleDataOptions()}
          onChange={onChangeLocale}
          disabled={disabled}
        />
        {' '}

      </Accordion>
    </>
  );
}

export default LocaleFilter;

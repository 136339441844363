/* eslint-disable react/prop-types */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import PropTypes, { object } from 'prop-types';
import { Field } from 'react-final-form';
import {
  Card,
  Col,
  Icon,
  Select,
  TextArea,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { withStripes } from '@folio/stripes-core';
import css from './style.css';
import { getLocaleLabel } from '../../../utils/utils';

const propTypes = {};

const required = (value) =>
  value ? (
    undefined
  ) : (
    <strong>
      <FormattedMessage
        id="ui-translations.validation.required"
        defaultMessage="required"
      />
    </strong>
  );

function ExportOptions(props) {
  const intl = useIntl();

  const getFileTypeOptions = () => {
    return [
      {
        value: 'json',
        label: intl.formatMessage({
          id: 'ui-translations.fileType.options.json',
        }),
        disabled: false,
      },
      {
        value: 'csv',
        label: intl.formatMessage({
          id: 'ui-translations.fileType.options.csv',
        }),
        disabled: false,
      },
    ];
  };

  const getExportFieldsOptions = () => {
    return [
      {
        value: 'translationKey+localeTranslation',
        label: intl.formatMessage({
          id: 'ui-translations.exportFields.options.localeTrans',
        }),
        disabled: false,
      },
      {
        value: 'translationKey+originTranslation',
        label: intl.formatMessage({
          id: 'ui-translations.exportFields.options.originTrans',
        }),
        disabled: false,
      },
      {
        value: 'originTranslation+localeTranslation',
        label: intl.formatMessage({
          id: 'ui-translations.exportFields.options.localeOrigin',
        }),
        disabled: false,
      },
    ];
  };

  return (
    <Col xs={3}>
      <>
        <Card
          id="card-export-translation-exportOptions"
          // cardStyle="positive"
          roundedBorder
          headerStart={
            <span className={css.cardHeader}>
              <Icon icon="gear">
                <FormattedMessage id="ui-translations.exportTranslations.cardHeader.exportOptions" />
              </Icon>
            </span>
          }
        >
          <div>
            <FormattedMessage
              id="ui-translations.exportTranslations.category"
              values={{
                category: (
                  <FormattedMessage
                    id={`ui-translations.categoryMenu.${props.categoryName}`}
                  />
                ),
              }}
            />
          </div>
          <br />
          <div>
            <FormattedMessage
              id="ui-translations.exportTranslations.locale"
              values={{ locale: getLocaleLabel(props.currentLocale, intl) }}
            />
          </div>
          <hr />
          <br />
          <div>
            <Field
              id="select-file-ypeo"
              name="fileType"
              component={Select}
              label={
                <FormattedMessage id="ui-translations.exportTranslations.fileType" />
              }
              dataOptions={getFileTypeOptions()}
              required
              validate={required}
            />
            <Field
              id="select-export-fields"
              name="exportFields"
              component={Select}
              label={
                <FormattedMessage id="ui-translations.exportTranslations.exportFields" />
              }
              dataOptions={getExportFieldsOptions()}
              required
              validate={required}
              // marginBottom0
            />
            <Field
              id="select-file-name"
              name="fileName"
              component={TextArea}
              label={
                <FormattedMessage id="ui-translations.exportTranslations.fileName" />
              }
              placeholder={intl.formatMessage({
                id: 'ui-translations.exportTranslations.placeholder.fileName',
              })}
              // required
              // validate={required}
            />
          </div>
        </Card>
      </>
      {props.currentExportedFields === 'originTranslation+localeTranslation' && (
        <div className={css.layout}>
          <FormattedMessage id="ui-translations.exportTranslations.confirmMessage.danger" />
        </div>
      )}
    </Col>
  );
}

ExportOptions.propTypes = propTypes;

export default withStripes(ExportOptions);

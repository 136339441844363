import React from 'react';
import { FormattedMessage } from 'react-intl';
import _, { every } from 'lodash';
import { NoValue } from '@folio/stripes/components';
/* Kware Start Editing */
import moment from 'moment';
/* Kware end Editing */
import { requestStatuses, sortTypes } from '../../constants';

/**
 * getFullName
 * return "last, first middle", derived from user.personal
 * preferred first name should be shown instead first name if present.
 *
 * @param {object} user
 * @returns string
 */
// export function getFullName(user) {
//   let fullName = user?.personal?.lastName ?? '';
//   let givenName = user?.personal?.preferredFirstName ?? user?.personal?.firstName ?? '';
//   const middleName = user?.personal?.middleName ?? '';
//   if (middleName) {
//     givenName += `${givenName ? ' ' : ''}${middleName}`;
//   }

//   if (givenName) {
//     fullName += `${fullName ? ', ' : ''}${givenName}`;
//   }

//   return fullName;
// }

/* Kware Start Editing */
export function getFullName(user, intl) {
  let lastName;
  let firstName;
  let middleName;

  if (intl) {
    firstName = user.personal.firstName
      ? intl.formatMessage({
        id: `ui-users.userInfo.firstName.${user.personal.firstName}`,
        defaultMessage: `${user.personal.firstName}`,
      })
      : '';
    middleName = user.personal.middleName
      ? intl.formatMessage({
        id: `ui-users.userInfo.middleName.${user.personal.middleName}`,
        defaultMessage: `${user.personal.middleName}`,
      })
      : '';
    lastName = user.personal.lastName
      ? intl.formatMessage({
        id: `ui-users.userInfo.lastName.${user.personal.lastName}`,
        defaultMessage: `${user.personal.lastName}`,
      })
      : '';
  } else {
    lastName = _.get(user, 'personal.lastName', '');
    firstName = _.get(user, 'personal.firstName', '');
    middleName = _.get(user, 'personal.middleName', '');
  }

  const arabicAlphabetDigits =
    /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

  const delimeter = arabicAlphabetDigits.test(lastName) ? '، ' : ', ';
  return `${lastName}${firstName ? delimeter : ' '}${firstName}${
    middleName ? ' ' : ''
  }${middleName}`;
}
/* Kware Editing  End */

/* Kware Start Editing */
export function getTranslatedItemStatus(itemStatus) {
  switch (itemStatus) {
    case 'Aged to lost':
      return <FormattedMessage id="ui-inventory.item.status.agedToLost" />;
    case 'Available':
      return <FormattedMessage id="ui-inventory.item.status.available" />;
    case 'Awaiting pickup':
      return <FormattedMessage id="ui-inventory.item.status.awaitingPickup" />;
    case 'Awaiting delivery':
      return (
        <FormattedMessage id="ui-inventory.item.status.awaitingDelivery" />
      );
    case 'Checked out':
      return <FormattedMessage id="ui-inventory.item.status.checkedOut" />;
    case 'In process':
      return <FormattedMessage id="ui-inventory.item.status.inProcess" />;
    case 'In transit':
      return <FormattedMessage id="ui-inventory.item.status.inTransit" />;
    case 'Missing':
      return <FormattedMessage id="ui-inventory.item.status.missing" />;
    case 'On order':
      return <FormattedMessage id="ui-inventory.item.status.onOrder" />;
    case 'Paged':
      return <FormattedMessage id="ui-inventory.item.status.paged" />;
    case 'Declared lost':
      return <FormattedMessage id="ui-inventory.item.status.declaredLost" />;
    case 'Withdrawn':
      return <FormattedMessage id="ui-inventory.item.status.withdrawn" />;
    case 'Claimed returned':
      return <FormattedMessage id="ui-inventory.item.status.claimedReturned" />;
    case 'Lost and paid':
      return <FormattedMessage id="ui-inventory.item.status.lostAndPaid" />;
    case 'Order closed':
      return <FormattedMessage id="ui-inventory.item.status.orderClosed" />;
    default:
      return itemStatus;
  }
}
/* Kware Editing  End */

/** kware start edit */
export function getTranslatedRenewalMessages(errMessage, intl) {
  const message = errMessage.split(',');
  if (errMessage === '') return '';
  return `${intl.formatMessage({
    id: `ui-users.brd.failedRenewal.errorMessage.${message[0]}`,
    defaultMessage: message[0],
  })} ${
    message[1]
      ? ` ,${intl.formatMessage({
        id: `ui-users.failedRenewal.errorMessage.${message[1]}`,
        defaultMessage: message[1],
      })}`
      : ''
  }`;
}
/** kware end edit */

/* Kware Start Editing */
export function getTranslatedPaymentStatus(paymentStatus) {
  return (
    <FormattedMessage
      id={`ui-users.payment.status.${paymentStatus}`}
      defaultMessage={paymentStatus}
    />
  );
}
/* Kware Editing  End */

/* Kware Start Editing */
export function parseNumbersToArabic(string) {
  if (typeof string === 'string' && string !== '') {
    const ArabicNumbersShape = [
      '٠',
      '١',
      '٢',
      '٣',
      '٤',
      '٥',
      '٦',
      '٧',
      '٨',
      '٩',
    ];
    return string.replace(/[0-9]/g, str => {
      return ArabicNumbersShape[+str];
    });
  }
  return string;
}
/* Kware Editing  End */

/* Kware Start Editing */
export function parseNumbersToEnglish(string) {
  if (typeof string === 'string' && string !== '') {
    return string
      .replace(/[\u0660-\u0669]/g, c => {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, c => {
        return c.charCodeAt(0) - 0x06f0;
      });
  }
  return string;
}
/* Kware End Editing */

/* Kware Start Editing */
export function handleDateTimeFormat(dateTimeValue, stripes, intl, handleType) {
  const numbersShape = stripes.userNumbersShape || stripes.locale;
  switch (handleType) {
    case 'date':
      return stripes.locale === 'ar'
        ? moment(dateTimeValue).locale(numbersShape).format(stripes.dateformat)
        : moment(dateTimeValue)
          .locale(stripes.locale)
          .format(stripes.dateformat);
    case 'time':
      return stripes.locale === 'ar' && numbersShape === 'ar'
        ? parseNumbersToArabic(intl.formatTime(dateTimeValue))
        : intl.formatTime(dateTimeValue);
    case 'datetime':
      return stripes.locale === 'ar'
        ? numbersShape === 'ar'
          ? `${moment(dateTimeValue)
            .locale(numbersShape)
            .format(stripes.dateformat)} ، ${parseNumbersToArabic(
            intl.formatTime(dateTimeValue)
          )}`
          : `${moment(dateTimeValue)
            .locale(numbersShape)
            .format(stripes.dateformat)} , ${intl.formatTime(dateTimeValue)}`
        : `${moment(dateTimeValue)
          .locale(stripes.locale)
          .format(stripes.dateformat)} , ${intl.formatTime(dateTimeValue)}`;
    default:
      return moment(dateTimeValue)
        .locale(numbersShape)
        .format(stripes.dateformat);
  }
}
/* Kware End Editing */

export const formatActionDescription = action => {
  return (
    action.typeAction +
    (action.paymentMethod ? '-' + action.paymentMethod : ' ')
  );
};

export const formatCurrencyAmount = (amount = 0) => parseFloat(amount).toFixed(2);

export const formatDateAndTime = (date, formatter) => {
  return date
    ? formatter(date, { day: 'numeric', month: 'numeric', year: 'numeric' })
    : '';
};

export const getServicePointOfCurrentAction = (action, servicePoints = []) => {
  const servicePoint = servicePoints.find(sp => sp.id === action.createdAt);
  const createAtValue = servicePoint ? servicePoint.name : action.createdAt;
  return createAtValue || <NoValue />;
};

export const calculateRemainingAmount = remaining => (parseFloat(remaining) * 100) / 100;

export function validate(item, index, items, field, label) {
  const error = {};
  for (let i = 0; i < items.length; i++) {
    const obj = items[i];
    if (
      index !== i &&
      (obj[field] || '').localeCompare(item[field], 'sv', {
        sensitivity: 'base',
      }) === 0
    ) {
      error[field] = (
        <FormattedMessage id="ui-users.duplicated" values={{ field: label }} />
      );
    }
  }
  return error;
}

export function getRecordObject(resources, ...args) {
  const res = {};
  args.forEach(resource => {
    res[resource] = resources[resource].records;
  });
  return res;
}

export function retrieveNoteReferredEntityDataFromLocationState(state) {
  if (state) {
    return {
      name: state.entityName,
      type: state.entityType,
      id: state.entityId,
    };
  }

  return null;
}

export function getFilterStatusesString(statuses) {
  return statuses.map(status => `requestStatus.${status}`).join(',');
}

export function getClosedRequestStatusesFilterString() {
  const closedStatusesArr = [
    requestStatuses.PICKUP_EXPIRED,
    requestStatuses.CANCELLED,
    requestStatuses.FILLED,
    requestStatuses.UNFILLED,
  ];

  return getFilterStatusesString(closedStatusesArr);
}

export function getOpenRequestStatusesFilterString() {
  const openStatusesArr = [
    requestStatuses.AWAITING_PICKUP,
    requestStatuses.AWAITING_DELIVERY,
    requestStatuses.IN_TRANSIT,
    requestStatuses.NOT_YET_FILLED,
  ];

  return getFilterStatusesString(openStatusesArr);
}

export function getOpenRequestsPath(itemId) {
  const filterString = getOpenRequestStatusesFilterString();

  return `/requests?filters=${filterString}&query=${itemId}&sort=Request Date`;
}

export function getChargeFineToLoanPath(userId, loanId) {
  return `/users/${userId}/charge/${loanId}`;
}

export function calculateSortParams({
  sortOrder,
  sortValue,
  sortDirection,
  secondarySortOrderIndex = 0,
  secondarySortDirectionIndex = 0,
}) {
  const sortParams = {};

  if (sortOrder[0] !== sortValue) {
    sortParams.sortOrder = [sortValue, sortOrder[secondarySortOrderIndex]];
    sortParams.sortDirection = [
      sortTypes.ASC,
      sortDirection[secondarySortDirectionIndex],
    ];
  } else {
    const direction =
      sortDirection[0] === sortTypes.DESC ? sortTypes.ASC : sortTypes.DESC;

    sortParams.sortDirection = [direction, sortDirection[1]];
  }

  return sortParams;
}

// Return true if every item in loans has the status itemStatus
export function hasEveryLoanItemStatus(loans, itemStatus) {
  return every(loans, loan => loan?.item?.status?.name === itemStatus);
}

// Return true if every item in loans has one of the statuses in the itemStatuses array
export function hasAnyLoanItemStatus(loans, itemStatuses) {
  return every(loans, loan => itemStatuses.includes(loan?.item?.status?.name));
}

export function accountsMatchStatus(accounts, status) {
  return every(
    accounts,
    account => account.status.name.toLowerCase() === status.toLowerCase()
  );
}

export function getValue(value) {
  return value || '';
}

// Given a user record, test whether the user is active. Checking the `active` property ought to
// be sufficient, but test the expiration date as well just to be sure.
export function checkUserActive(user) {
  if (user.expirationDate == null || user.expirationDate === undefined) {
    return user.active;
  }
  return user.active && new Date(user.expirationDate) >= new Date();
}

export const getContributors = (account, instance) => {
  const contributors = account?.contributors || instance?.contributors;

  return contributors && contributors.map(({ name }) => name);
};

import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  /* Kware Start Editing */
  injectIntl,
  /* Kware end Editing */
} from 'react-intl';
import {
  Row,
  Col,
  KeyValue,
  Accordion,
  Headline,
  NoValue,
} from '@folio/stripes/components';

import { ViewMetaData } from '@folio/stripes/smart-components';
/* Kware Start Editing */
import { withStripes } from '@folio/stripes-core';
import HandleDateTime from '@folio/stripes-core/util/kware-utils/HandleDateTime';
import { handleDateTimeFormat } from '../../util';
/* Kware end Editing */
import css from './UserInfo.css';
import appIcon from '../../../../icons/app.png';

class UserInfo extends React.Component {
  static propTypes = {
    expanded: PropTypes.bool,
    stripes: PropTypes.object.isRequired,
    onToggle: PropTypes.func,
    accordionId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    patronGroup: PropTypes.object.isRequired,
    settings: PropTypes.arrayOf(PropTypes.object).isRequired,
    /* Kware Start Editing */
    intl: PropTypes.object
    /* Kware end Editing */
  };

  constructor(props) {
    super(props);

    this.cViewMetaData = props.stripes.connect(ViewMetaData);
  }

  render() {
    const {
      /* Kware Start Editing */
      user: { personal: { lastName, firstName, middleName, preferredFirstName }, metadata },
      stripes,
      intl,
      /* Kware end Editing */
      user,
      patronGroup,
      settings,
      expanded,
      accordionId,
      onToggle,
    } = this.props;
    const userStatus = (user?.active ?
      <FormattedMessage id="ui-users.active" /> :
      <FormattedMessage id="ui-users.inactive" />);
    const hasProfilePicture = (settings.length && settings[0].value === 'true');

    return (
      <Accordion
        open={expanded}
        id={accordionId}
        onToggle={onToggle}
        label={(
          <Headline
            size="large"
            tag="h3"
          >
            <FormattedMessage id="ui-users.information.userInformation" />
          </Headline>)}
      >
        <Row>
          <Col xs={12}>
            <this.cViewMetaData metadata={user.metadata} />
          </Col>
        </Row>
        <Row>
          <Col xs={hasProfilePicture ? 9 : 12}>
            <Row>
              {/* <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.lastName" />}
                  value={get(user, ['personal', 'lastName'], '')}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.firstName" />}
                  value={get(user, ['personal', 'firstName'], '')}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.middleName" />}
                  value={get(user, ['personal', 'middleName'], '')}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.preferredName" />}
                  value={get(user, ['personal', 'preferredFirstName']) || <NoValue />}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.patronGroup" />}
                  value={patronGroup.group}
                />
              </Col> */}
              {/* Kware Start Editing */}
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.lastName" />}
                  value={lastName ? <FormattedMessage id={`ui-users.userInfo.lastName.${lastName}`} defaultMessage={lastName} /> : '-'}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.firstName" />}
                  value={firstName ? <FormattedMessage id={`ui-users.userInfo.firstName.${firstName}`} defaultMessage={firstName} /> : '-'}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.middleName" />}
                  value={middleName ? <FormattedMessage id={`ui-users.userInfo.middleName.${middleName}`} defaultMessage={middleName} /> : '-'}
                />
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.preferredName" />}
                  value={preferredFirstName ? <FormattedMessage id={`ui-users.userInfo.preferredFirstName.${preferredFirstName}`} defaultMessage={preferredFirstName} /> : '-'}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.patronGroup" />}
                  value={patronGroup.group ? <FormattedMessage id={`ui-users.patronGroups.group.${patronGroup.group}`} defaultMessage={patronGroup.group} /> : '-'}
                />
              </Col>
              {/* Kware end Editing */}
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.status" />}
                  value={userStatus}
                />
              </Col>
              <Col xs={3}>
                {/* <KeyValue
                  label={<FormattedMessage id="ui-users.information.expirationDate" />}
                  // value={user.expirationDate ? <FormattedUTCDate value={user.expirationDate} /> : '-'}
                  value={user.expirationDate ? handleDateTimeFormat(user.expirationDate, stripes, intl, 'date') : '-'}
                /> */}
                <KeyValue label={<FormattedMessage id="ui-users.information.expirationDate" />}>
                  {user.expirationDate ?
                  /* Kware start editing */
                  /* handleDateTimeFormat(user.enrollmentDate, props.stripes, intl, 'date') */
                    <HandleDateTime dateTimeValue={user.expirationDate} handleType="date" />
                  /* Kware end editing */
                    : '-'}
                </KeyValue>
              </Col>
              <Col xs={3}>
                <KeyValue
                  label={<FormattedMessage id="ui-users.information.barcode" />}
                  value={get(user, ['barcode'], '')}
                />
              </Col>
            </Row>
          </Col>

          {hasProfilePicture === true &&
            <Col xs={3}>
              <Row>
                <Col xs={12}>
                  <img className={`floatEnd ${css.profilePlaceholder}`} src={appIcon} alt="presentation" />
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </Accordion>
    );
  }
}

// export default UserInfo;
/* Kware Start Editing */
export default withStripes(injectIntl(UserInfo));
/* Kware end Editing */

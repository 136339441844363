import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, FilterAccordionHeader } from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';

function QueryParams(props) {
  const [filterToggle, setFilterToggle] = useState(false);

  return (
    <>
      <Accordion
        id="save-new-report-query-params-accordion"
        label={
          <FormattedMessage
            id="ui-reports.newReport.saveReportPane.QueryParams.accordion.header"
            defaultMessage="Query params"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator
        header={FilterAccordionHeader}
      >
        {/* <FieldArray
          addLabel="Add to category"
          component={RepeatableField}
          name="categories"
          onAdd={(fields) => fields.push('')}
          renderField={renderCategoriesList}
        /> */}
      </Accordion>
    </>
  );
}

QueryParams.propTypes = {};

export default QueryParams;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Accordion,
  Card,
  FilterAccordionHeader,
  Headline,
} from '@folio/stripes-components';
import { AppIcon } from '@folio/stripes-core';

const propTypes = {};

function UserStats(props) {
  const [filterToggle, setFilterToggle] = useState(false);

  return (
    <>
      {/* <Card
        id="tenant-locales"
        cardStyle="default"
        roundedBorder
        headerStart={
          <span>
            <div style={{ fontWeight: '550' }}>
              <AppIcon size="small" app="users">
                <FormattedMessage id="ui-translations.Statistics.userStatistics" />
              </AppIcon>
            </div>
          </span>}
      >
        <FormattedMessage id="ui-translations.Statistics.userStats.noStats" />
      </Card> */}
      <Accordion
        id="user-stats-accordion"
        label={
          <span>
            <div style={{ fontWeight: '550' }}>
              <AppIcon size="small" app="users">
                <FormattedMessage id="ui-translations.Statistics.userStatistics" />
              </AppIcon>
            </div>
          </span>
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        // separator={false}
        header={FilterAccordionHeader}
      >
        <FormattedMessage id="ui-translations.Statistics.userStats.noStats" />
      </Accordion>
    </>
  );
}

UserStats.propTypes = propTypes;

export default UserStats;

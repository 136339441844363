import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import {
  Accordion,
  Card,
  FilterAccordionHeader,
  Icon,
  RepeatableField,
  Select,
} from '@folio/stripes/components';
import { FormattedMessage } from 'react-intl';

function AddToCategory(props) {
  const [filterToggle, setFilterToggle] = useState(true);

  const renderCategoriesList = (field, index) => {
    return (
      <>
        <Field
          component={Select}
          label={
            <FormattedMessage
              id="ui-reports.newReport.saveReportPane.addToCategory.field.categoryName"
              defaultMessage="Category name"
            />
          }
          name={`${field}.values`}
          //   disabled={disabled}
        />
      </>
    );
  };
  return (
    <>
      <Accordion
        id="save-new-report-add-to-categories-accordion"
        label={
          <FormattedMessage
            id="ui-reports.newReport.saveReportPane.addToCategory.accordion.header"
            defaultMessage="Categories"
          />
        }
        onToggle={() => setFilterToggle(!filterToggle)}
        open={filterToggle}
        separator
        header={FilterAccordionHeader}
      >
        <FieldArray
          addLabel="Add to category"
          component={RepeatableField}
          name="categories"
          onAdd={(fields) => fields.push('')}
          renderField={renderCategoriesList}
        />
      </Accordion>
    </>
  );
}

AddToCategory.propTypes = {};

export default AddToCategory;

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Icon,
  Pane,
  PaneFooter,
  Paneset,
  Select,
  TextField,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import stripesFinalForm from '@folio/stripes/final-form';
import { Field } from 'react-final-form';
import css from './BackupSettingsForm.css';

const required = (value) => (value ? (
  undefined
) : (
  <strong>
    <FormattedMessage
      id="ui-translations.validation.required"
      defaultMessage="required"
    />
  </strong>
));

function BackupSettingsForm({ pristine, submitting, handleSubmit, form }) {
  const intl = useIntl();

  const getFileTypeOptions = () => {
    return [
      {
        value: 'json',
        label: 'json',
        disabled: false,
      },
      {
        value: 'csv',
        label: 'csv',
        disabled: false,
      },
    ];
  };

  const getFilePathOptions = () => {
    return [
      {
        value: 'localPc',
        label: intl.formatMessage({
          id: 'ui-translations.settings.backupSettings.savePath.options.localPc',
        }),
        disabled: false,
      },
      {
        value: 'localServer',
        label: intl.formatMessage({
          id: 'ui-translations.settings.backupSettings.savePath.options.fileServer',
        }),
        disabled: false,
      },
      {
        value: 'S3bucket',
        label: intl.formatMessage({
          id: 'ui-translations.settings.backupSettings.savePath.options.S3bucket',
        }),
        disabled: false,
      },
    ];
  };

  const renderPaneFooter = () => {
    return (
      <PaneFooter
        renderEnd={
          <Button
            buttonStyle="primary mega"
            disabled={pristine || submitting}
            id="clickable-trans-backup-form-submit"
            marginBottom0
            type="submit"
          >
            <FormattedMessage id="stripes-core.button.save" />
          </Button>
        }
        renderStart={
          <Button
            buttonStyle="default mega"
            disabled={pristine || submitting}
            id="clickable-trans-backup-form-cancel"
            marginBottom0
            onClick={form.reset}
          >
            <FormattedMessage id="stripes-components.cancel" />
          </Button>
        }
      />
    );
  };

  return (
    <>
      <form
        id="form-translations-backup"
        onSubmit={handleSubmit}
        className={css.backupSettingsForm}
      >
        <Paneset>
          <Pane
            fluidContentWidth
            footer={renderPaneFooter()}
            id="pane-trans-backup-form"
            paneTitle={
              <FormattedMessage id="ui-translations.settings.backupSettings.lable" />
            }
          >
            <Card
              id="card-backup-translation-backupOptions"
              cardStyle="positive"
              roundedBorder
              headerStart={
                <span className={css.cardHeader}>
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.cardHeader.instancesOptions"
                  />
                </span>
              }
            >
              <Field
                component={Checkbox}
                type="checkbox"
                name="translations"
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.instancesOptions.translations"
                  />
                }
                disabled
              />
              <Field
                component={Checkbox}
                type="checkbox"
                name="languages"
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.instancesOptions.languages"
                  />
                }
                disabled
              />
              <Field
                component={Checkbox}
                type="checkbox"
                name="translators"
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.instancesOptions.translators"
                  />
                }
                disabled
              />
              <Field
                component={Checkbox}
                type="checkbox"
                name="configs"
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.instancesOptions.configs"
                  />
                }
                disabled
              />
            </Card>
            <Card
              id="card-backup-translation-backupOptions"
              cardStyle="positive"
              roundedBorder
              headerStart={
                <span className={css.cardHeader}>
                  <Icon icon="gear">
                    <FormattedMessage
                      id="ui-translations.settings.backupSettings.cardHeader.backupOptions"
                    />
                  </Icon>
                </span>
              }
            >
              <Field
                id="select-file-name"
                name="fileName"
                component={TextField}
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.fileName.label"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-translations.settings.backupSettings.fileName.placeholder',
                })}
                dataOptions={getFileTypeOptions()}
                required
                validate={required}
              />
              <Field
                id="select-file-type"
                name="fileType"
                component={Select}
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.fileType.label"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-translations.settings.backupSettings.fileType.placeholder',
                })}
                dataOptions={getFileTypeOptions()}
                required
                validate={required}
              />
              <Field
                id="select-save-path"
                name="savePath"
                component={Select}
                label={
                  <FormattedMessage
                    id="ui-translations.settings.backupSettings.savePath.label"
                  />
                }
                placeholder={intl.formatMessage({
                  id: 'ui-translations.settings.backupSettings.savePath.placeholder',
                })}
                dataOptions={getFilePathOptions()}
                required
                validate={required}
                disabled
              />
            </Card>
          </Pane>
        </Paneset>
      </form>
    </>
  );
}

BackupSettingsForm.propTypes = {};

export default stripesFinalForm({
  navigationCheck: true,
  subscription: {
    values: true,
  },
  mutators: {
    resetZipFileField: (args, state) => {
      const field = state.fields[args[0]];
      field.change(args[1]);
      state.formState.submitFailed = true;
    },
  },
  validateOnBlur: true,
})(BackupSettingsForm);

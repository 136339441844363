import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect as reduxConnect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';

import { ConnectContext } from '@folio/stripes-connect';
import {
  getSSOEnabled,
  requestLogin,
  requestSSOLogin
} from '../../loginServices';
import { setAuthError } from '../../okapiActions';
import Login from './Login';
/** kware start edit */
import TenantsConfig from '../../../util/kware-utils/tenantsConfigs';
/** kware end edit */

/** kware start edit */
const storedTenant = localStorage.getItem('tenantName');
/** kware end edit */

class LoginCtrl extends Component {
  static propTypes = {
    authFailure: PropTypes.arrayOf(PropTypes.object),
    ssoEnabled: PropTypes.bool,
    autoLogin: PropTypes.shape({
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired
    }),
    clearAuthErrors: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  };

  static contextType = ConnectContext;

  constructor(props) {
    super(props);
    this.sys = require('stripes-config'); // eslint-disable-line global-require
    this.okapiUrl = this.sys.okapi.url;
    // this.tenant = this.sys.okapi.tenant;
    /** kware start edit */
    this.tenant = storedTenant || this.sys.okapi.tenant;
    /** kware end edit */
    if (props.autoLogin && props.autoLogin.username) {
      this.handleSubmit(props.autoLogin);
    }
  }

  componentWillUnmount() {
    this.props.clearAuthErrors();
  }

  // handleSuccessfulLogin = () => {
  //   if (matchPath(this.props.location.pathname, '/login')) {
  //     this.props.history.push('/');
  //   }
  // };

  // handleSubmit = (data) => {
  //   return requestLogin(this.okapiUrl, this.context.store, this.tenant, data)
  //     .then(this.handleSuccessfulLogin)
  //     .catch((e) => {
  //       console.error(e); // eslint-disable-line no-console
  //     });
  // };

  // handleSSOLogin = () => {
  //   requestSSOLogin(this.okapiUrl, this.tenant);
  // }

  /** kware start edit */
  handleSuccessfulLogin = (tenantName) => {
    if (matchPath(this.props.location.pathname, '/login')) {
      this.props.history.push('/');
    }
    localStorage.setItem('tenantName', tenantName);
  };

  handleSubmit = (data) => {
    return requestLogin(
      this.okapiUrl,
      this.context.store,
      data.tenant,
      data
    ).then(this.handleSuccessfulLogin(data.tenant));
  };

  handleSSOLogin = () => {
    requestSSOLogin(
      this.okapiUrl,
      this.context.store.getState().okapi.tenant
    ).then(() => {
      localStorage.setItem(
        'tenantName',
        this.context.store.getState().okapi.tenant
      );
    });
  };

  checkSSOStatusByTenantName = () => {
    return getSSOEnabled(
      this.okapiUrl,
      this.context.store,
      this.context.store.getState().okapi.tenant
    );
  };
  /** kware end edit */

  render() {
    const { authFailure, ssoEnabled } = this.props;
    // /** kware start edit */
    // const storedTenant = localStorage.getItem('tenantName');
    // /** kware end edit */

    return (
      <Login
        onSubmit={this.handleSubmit}
        /** kware start edit */
        initialValues={{
          tenant:
            this.context.store.getState().okapi.tenant ||
            storedTenant ||
            TenantsConfig.default_tenant
        }}
        checkSSOStatusByTenantName={() => this.checkSSOStatusByTenantName()}
        /** kware end edit */
        authErrors={authFailure}
        handleSSOLogin={this.handleSSOLogin}
        ssoActive={ssoEnabled}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authFailure: state.okapi.authFailure,
  ssoEnabled: state.okapi.ssoEnabled
});
const mapDispatchToProps = (dispatch) => ({
  clearAuthErrors: () => dispatch(setAuthError([]))
});

export default reduxConnect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginCtrl));

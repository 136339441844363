/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Icon, Tooltip } from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';

function FullScreenMode(props) {
  // const [isFullScreen, setIsFullScreen] = useState(props.isFullScreen);
  const activateFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen(); // W3C spec
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    }
    props.setIsFullScreen(true);
  };

  const deactivateFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    props.setIsFullScreen(false);
  };

  return (
    <>
      {/* {!props.isFullScreen && (
        <Tooltip
          id="go-full-screen-tooltip"
          text={
            <FormattedMessage id="stripes-core.buttons.tooltip.goFullScreen" />
          }
          placement="right-start"
        >
          {({ ref, ariaIds }) => (
            <Button
              id="btn-go-full-screen"
              aria-labelledby={ariaIds.text}
              ref={ref}
              buttonStyle="slim"
              marginBottom0
              onClick={() => activateFullscreen(document.documentElement)}
            >
              <Icon icon="fullscreen" size="large" />
            </Button>
          )}
        </Tooltip>
      )} */}
      {!props.isFullScreen && (
      <Button
        id="btn-go-full-screen"
        buttonStyle="slim"
        // marginBottom0
        onClick={() => activateFullscreen(document.documentElement)}
      >
        <Icon icon="fullscreen" size="small" />
      </Button>
      )}
      {props.isFullScreen && (
        <Tooltip
          id="exit-full-screen-tooltip"
          text={
            <FormattedMessage id="stripes-core.buttons.tooltip.exitFullScreen" />
          }
        >
          {({ ref, ariaIds }) => (
            <Button
              id="btn-exit-full-screen"
              aria-labelledby={ariaIds.text}
              ref={ref}
              buttonStyle="slim"
              marginBottom0
              onClick={() => deactivateFullscreen()}
            >
              <Icon icon="defullscreen" size="large" />
            </Button>
          )}
        </Tooltip>
      )}
    </>
  );
}

export default FullScreenMode;

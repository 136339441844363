import React, { useState } from 'react';
import {
  Accordion,
  FilterAccordionHeader,
  Select,
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';

function TranslationStatusFilter({
  currentStatus,
  setCurrentStatus,
  disabled,
}) {
  const intl = useIntl();
  const [filterToggle, setFilterToggle] = useState(true);

  const onChangeStatus = (event) => {
    setCurrentStatus(event.target.value);
  };

  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };

  const renderFilterByStatus = () => {
    const dataOptions = [
      {
        value: 'allTranslations',
        label: intl.formatMessage({
          id: 'ui-translations.filters.status.allTranslations',
        }),
        disabled: false,
      },
      {
        value: 'translated',
        label: intl.formatMessage({
          id: 'ui-translations.filters.status.translated',
        }),
        disabled: false,
      },
      {
        value: 'notTranslated',
        label: intl.formatMessage({
          id: 'ui-translations.filters.status.notTranslated',
        }),
        disabled: false,
      },
      {
        value: 'englishOnly',
        label: intl.formatMessage({
          id: 'ui-translations.filters.status.englishOnly',
        }),
        disabled: false,
      },
      {
        value: 'noneEnglish',
        label: intl.formatMessage({
          id: 'ui-translations.filters.status.noneEnglish',
        }),
        disabled: false,
      },
    ];

    return (
      <div>
        <Select
          id="app-filter-select"
          name="locale-filter"
          value={currentStatus}
          dataOptions={dataOptions}
          onChange={onChangeStatus}
          disabled={disabled}
        />
      </div>
    );
  };

  return (
    <>
      <Accordion
        id="translation-status-filter-accordion"
        label={
          <FormattedMessage id="ui-translations.filters.TranslationStatusFilter.Accordion" />
        }
        onToggle={handleFilterToggle}
        open={filterToggle}
        // separator={false}
        header={FilterAccordionHeader}
      >
        {renderFilterByStatus()}
      </Accordion>
    </>
  );
}

export default TranslationStatusFilter;

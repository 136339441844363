/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  Button,
  Icon,
  MenuSection,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { IfPermission, stripesConnect } from '@folio/stripes-core';
import { isEmpty } from 'lodash';
import WithTranslations from '../Wrappers/WithTranslations';
import DeleteTranslationsModal from '../DeleteTranslations/DeleteTranslationsModal';
import ConfirmExportEmptyModal from '../ExportTranslations/components/ConfirmExportEmptyModal';

const ActionsMenu = ({
  currentLocale,
  categoryName,
  contentData,
  selectedTranslations,
  fileName,
  updateTranslations,
  getCategoryLocales,
  currentStatus,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const [openConfirmEmptyModal, setOpenConfirmEmptyModal] = useState(false);

  const onConfirmDeleteTranslationKeys = () => {
    const keysToBeDelete = selectedTranslations.map(
      (trans) => trans.translationKey
    );
    const categoryLocales = getCategoryLocales(categoryName);
    if (categoryLocales) {
      categoryLocales.forEach((loc) => {
        if (loc.messages) {
          keysToBeDelete.forEach((key) => {
            delete loc.messages[key];
          });
          updateTranslations(loc.localeValue, categoryName, loc.messages);
        }
      });
    }
    setOpenConfirmDeleteModal(false);
  };

  const toggleDropdown = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const renderDeleteTranslationModal = () => {
    return (
      <DeleteTranslationsModal
        open={openConfirmDeleteModal}
        onClose={() => setOpenConfirmDeleteModal(false)}
        onSave={onConfirmDeleteTranslationKeys}
        selectedTranslations={selectedTranslations}
      />
    );
  };

  const renderConfirmEmptyModal = () => {
    return (
      <ConfirmExportEmptyModal
        open={openConfirmEmptyModal}
        onClose={() => setOpenConfirmEmptyModal(false)}
        selectedTranslations={selectedTranslations}
        contentData={contentData}
        currentLocale={currentLocale}
        categoryName={categoryName}
        currentStatus={currentStatus}
        fileName={selectedTranslations.length === 0 ? fileName : ''}
      />
    );
  };

  const getExportTranslationsActions = () => {
    let isContainsEmpty;

    (selectedTranslations.length ? selectedTranslations : contentData).forEach(
      (trans) => {
        if (!trans.localeTranslation) {
          isContainsEmpty = true;
        }
      }
    );

    return (
      <IfPermission perm="ui-translations.create">
        <br />
        {isContainsEmpty ? (
          <Button
            id="clickable-exportTranslation-file"
            buttonStyle="dropdownItem"
            onClick={() => setOpenConfirmEmptyModal(true)}
            disabled={isEmpty(
              selectedTranslations.length ? selectedTranslations : contentData
            )}
          >
            <Icon icon="export">
              <FormattedMessage id="ui-translations.buttons.exportTranslations" />
            </Icon>
          </Button>
        ) : (
          <Button
            id="clickable-exportTranslation-file"
            buttonStyle="dropdownItem"
            to={{
              pathname: '/translations/export-translations',
              state: {
                contentData,
                selectedTranslations,
                currentLocale,
                categoryName,
                fileName: selectedTranslations.length ? '' : fileName,
              },
            }}
            disabled={isEmpty(
              selectedTranslations.length ? selectedTranslations : contentData
            )}
          >
            <Icon icon="export">
              <FormattedMessage id="ui-translations.buttons.exportTranslations" />
            </Icon>
          </Button>
        )}
      </IfPermission>
    );
  };

  const getDropdownContent = () => {
    return (
      <>
        <MenuSection>
          <IfPermission perm="ui-translations.create">
            {selectedTranslations.length !== 0 && (
              <Button
                // marginBottom0
                id="clickable-edit-translations"
                buttonStyle="dropdownItem"
                to={{
                  pathname: '/translations/edit-translations',
                  state: {
                    contentData: selectedTranslations,
                    currentLocale,
                    paneTitle: categoryName,
                  },
                }}
                // onMouseDown={(e) => e.preventDefault()}
                disabled={!selectedTranslations.length}
              >
                <Icon icon="edit">
                  <FormattedMessage id="ui-translations.buttons.translateAll" />
                </Icon>
              </Button>
            )}
          </IfPermission>

          <IfPermission perm="ui-translations.delete">
            {selectedTranslations.length !== 0 && (
              <div>
                <br />
                <Button
                  id="clickable-delete-translations"
                  buttonStyle="dropdownItem"
                  onClick={() => setOpenConfirmDeleteModal(true)}
                  disabled={!selectedTranslations.length}
                >
                  <Icon icon="trash">
                    <FormattedMessage id="ui-translations.TranslationsDeleteModal.heading" />
                  </Icon>
                </Button>
              </div>
            )}
          </IfPermission>

          {contentData.length !== 0 && getExportTranslationsActions()}

          <IfPermission perm="ui-translations.create">
            {contentData.length !== 0 && <hr />}
            <Button
              id="clickable-import-appsOriginTranslation-file"
              buttonStyle="dropdownItem"
              to="/translations/import-translations"
            >
              <Icon icon="import">
                <FormattedMessage id="ui-translations.importTranslations" />
              </Icon>
            </Button>
          </IfPermission>
        </MenuSection>
      </>
    );
  };

  const renderActionsMenuTrigger = ({ getTriggerProps, open }) => {
    return (
      <FormattedMessage id="ui-translations.importTranslations">
        {(label) => (
          <div style={{ paddingRight: '0.25em', paddingLeft: '0.25em' }}>
            <Button
              data-test-pane-header-actions-button
              buttonStyle="primary"
              marginBottom0
              ariaLabel={label}
              type="button"
              {...getTriggerProps()}
            >
              <Icon icon="ellipsis" size="large" />
            </Button>
          </div>
        )}
      </FormattedMessage>
    );
  };

  const renderActionsMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  const renderDropdownComponent = () => {
    return (
      <Dropdown
        id="ui-translation-actionsMenu-Dropdown"
        renderTrigger={renderActionsMenuTrigger}
        renderMenu={renderActionsMenu}
        open={dropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    );
  };

  return (
    <>
      {renderDropdownComponent()}
      {renderDeleteTranslationModal()}
      {renderConfirmEmptyModal()}
    </>
  );
};

export default stripesConnect(WithTranslations(ActionsMenu));

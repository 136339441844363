/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  NavList,
  NavListSection,
  NavListItem,
  Icon,
  Badge,
  DropdownMenu,
  Dropdown
} from '@folio/stripes-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConnectContext } from '@folio/stripes-connect';
import SupportedLocales from './SupportedLocales';
import { getLocaleLabel, ImportLocalesIcones } from '../LocalesServices';
import NavButton from '../../NavButton';
import { loadTranslations } from '../../../../loginServices';
import TenantsConfig from '../../../../../util/kware-utils/tenantsConfigs';
import { setTenant } from '../../../../okapiActions';

function getHeaders(tenant, token) {
  return {
    'X-Okapi-Tenant': tenant,
    'X-Okapi-Token': token,
    'Content-Type': 'application/json'
  };
}

function LoginLocalesDropdowen({ tenantName }) {
  // const storedTenantLocales = localStorage.getItem('TenantConfigs');

  const intl = useIntl();
  const context = useContext(ConnectContext);
  const LocalesIcons = ImportLocalesIcones();

  const [DropdownOpen, setDropdownOpen] = useState(false);
  const [tenantLocales, setTenantLocales] = useState(
    TenantsConfig.tenants.find((ten) => ten.id === tenantName).default_locales
  );

  const [selectedLocale, setSelectedLocale] = useState(
    context.store.getState().okapi.locale
  );

  const [currentTenantConfigs, setCurrentTenantConfigs] = useState(
    TenantsConfig.tenants.find((ten) => ten.id === tenantName)
  );

  useEffect(() => {
    const locale = TenantsConfig.tenants.find(
      (ten) => ten.id === tenantName
    ).default_locale;
    if (locale) {
      loadTranslations(context.store, locale);
      setSelectedLocale(locale);
    }
  }, []);

  useEffect(() => {
    context.store.dispatch(setTenant(tenantName));
    setCurrentTenantConfigs(
      TenantsConfig.tenants.find((ten) => ten.id === tenantName)
    );
  }, [tenantName]);

  useEffect(() => {
    fetch(
      `${
        context.store.getState().okapi.url
      }/bl-users/login?expandPermissions=true&fullPermissions=true`,
      {
        method: 'POST',
        headers: {
          'X-Okapi-Tenant': context.store.getState().okapi.tenant,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: currentTenantConfigs.superUser.password,
          username: currentTenantConfigs.superUser.userName
        })
      }
    ).then((resp) => {
      const token = resp.headers.get('X-Okapi-Token');

      fetch(`${context.store.getState().okapi.url}/locales?limit=1000`, {
        headers: getHeaders(context.store.getState().okapi.tenant, token)
      }).then((locales) => {
        if (locales.status === 200) {
          locales.json().then((loc) => {
            if (loc.locales.length) {
              setTenantLocales(loc.locales);
            } else {
              setTenantLocales(currentTenantConfigs.default_locales);
            }
          });
        }
      });
    });
  }, [context.store.getState().okapi.tenant]);

  const toggleDropdown = () => {
    setDropdownOpen(!DropdownOpen);
  };

  const setLocale = (locale) => {
    if (locale) {
      loadTranslations(context.store, locale.value);
      setSelectedLocale(locale.value);
      //   props.stripes.setLocale(locale.value);
      //   props.stripes.setDateformat(locale.defaultDateFormat);
      toggleDropdown();
    }
  };

  const getDropdownContent = () => {
    return (
      <div>
        <NavList>
          <NavListSection>
            {tenantLocales.map((tl) => (
              <NavListItem
                key={`clickable-locale-${tl.value}`}
                type="button"
                onClick={() => setLocale(tl)}
                disabled={tl.value === selectedLocale}
                selected={tl.value === selectedLocale}
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    padding: '0.30em'
                  }}
                >
                  <div>
                    <Icon
                      icon={
                        LocalesIcons[tl.value] ? LocalesIcons[tl.value] : 'flag'
                      }
                    >
                      {getLocaleLabel(tl.value, intl)}
                    </Icon>
                  </div>
                </div>
              </NavListItem>
            ))}
          </NavListSection>
        </NavList>
      </div>
    );
  };

  const renderLocalesTrigger = ({ getTriggerProps, open }) => {
    const labelElement = (
      <Icon iconPosition="end" icon={open ? 'caret-up' : 'caret-down'}>
        {selectedLocale !== undefined ? selectedLocale.split('-')[0] : ''}
      </Icon>
    );

    const icon = LocalesIcons[selectedLocale] ? (
      <Icon icon={LocalesIcons[selectedLocale]} />
    ) : (
      <Icon icon="flag" />
    );

    return (
      <div>
        <FormattedMessage id="stripes-core.mainnav.myProfileAriaLabel">
          {(label) => (
            <NavButton
              label={labelElement}
              data-role="toggle"
              ariaLabel={label}
              selected={open}
              icon={icon}
              navbar="lib"
              {...getTriggerProps()}
            />
          )}
        </FormattedMessage>
      </div>
    );
  };

  const renderLocalesMenu = ({ open }) => (
    <div>
      <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
    </div>
  );

  return (
    <div>
      <Dropdown
        id="userLanguageSwitcherDropdown"
        renderTrigger={renderLocalesTrigger}
        renderMenu={renderLocalesMenu}
        open={DropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        usePortal={false}
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    </div>
  );
}

LoginLocalesDropdowen.propTypes = {
  tenantName: PropTypes.string
};

export default LoginLocalesDropdowen;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  Button,
  Icon,
  MenuSection,
} from '@folio/stripes-components';
import { FormattedMessage } from 'react-intl';
import { IfPermission } from '@folio/stripes-core';

const NewReportActions = (props) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setdropdownOpen(!dropdownOpen);
  };

  const getDropdownContent = () => {
    return (
      <>
        <MenuSection>
          {/* <IfPermission perm="ui-reports.create-report"> */}
          <Button
            marginBottom0
            id="new-report-actions-export-btn"
            buttonStyle="dropdownItem"
            onClick={() => {}}
            // onMouseDown={(e) => e.preventDefault()}
          >
            <Icon icon="source">
              <FormattedMessage
                id="ui-reports.actionsMenu.newReportActions.exportBtn"
                defaultMessage="Export report"
              />
            </Icon>
          </Button>
          {/* </IfPermission> */}
        </MenuSection>
        <MenuSection>
          {/* <IfPermission perm="ui-reports.create-report"> */}
          <Button
            marginBottom0
            id="new-report-actions-print-btn"
            buttonStyle="dropdownItem"
            onClick={() => {}}
            // onMouseDown={(e) => e.preventDefault()}
          >
            <Icon icon="source">
              <FormattedMessage
                id="ui-reports.actionsMenu.newReportActions.printBtn"
                defaultMessage="Print report"
              />
            </Icon>
          </Button>
          {/* </IfPermission> */}
        </MenuSection>
      </>
    );
  };

  const renderActionsMenuTrigger = ({ getTriggerProps, open }) => {
    return (
      <FormattedMessage id="ui-reports.importreports">
        {(label) => (
          <div style={{ paddingRight: '0.25em', paddingLeft: '0.25em' }}>
            <Button
              data-test-pane-header-actions-button
              buttonStyle="primary"
              marginBottom0
              ariaLabel={label}
              type="button"
              {...getTriggerProps()}
            >
              <Icon icon="ellipsis" size="large" />
            </Button>
          </div>
        )}
      </FormattedMessage>
    );
  };

  const renderActionsMenu = ({ open }) => (
    <DropdownMenu open={open}>{getDropdownContent()}</DropdownMenu>
  );

  return (
    <>
      <Dropdown
        id="ui-translation-actionsMenu-Dropdown"
        renderTrigger={renderActionsMenuTrigger}
        renderMenu={renderActionsMenu}
        open={dropdownOpen}
        onToggle={toggleDropdown}
        placement="bottom-end"
        relativePosition
        focusHandlers={{ open: () => null }}
      />
    </>
  );
};

export default NewReportActions;

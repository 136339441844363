import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEqual, sortBy } from 'lodash';
import { stripesConnect, withStripes } from '@folio/stripes-core';
import { translations } from 'stripes-config';
import AppsTranslationsContext from './AppsTranslationsContext';

function AppsTranslationsProvider(props) {
  const [localTranslations, setLocalTranslations] = useState({});
  const [staticTranslations, setStaticTranslations] = useState({});

  const getLocalTranslations = () => {
    const allTranslations = sortBy(
      (props.resources.translations || {}).records || [],
      ['localeValue']
    );
    const appsTrans = {};
    allTranslations.forEach((curTrans) => {
      if (curTrans.categoryName === 'stripesApps') {
        appsTrans[curTrans.localeValue] = {
          messages: curTrans.messages,
          metadata: curTrans.metadata,
        };
      }
    });
    return appsTrans;
  };

  useEffect(async () => {
    const doFetch = (locale) => {
      const parentLocale = locale.split('-')[0];
      const region = locale.replace('-', '_');
      return fetch(
        translations[region] ? translations[region] : translations[parentLocale]
      )
        .then((res) => res.json())
        .then((trans) => ({ [locale]: trans }));
    };
    const promises = [];

    Object.keys(translations).forEach((locale) => {
      promises.push(doFetch(locale));
    });

    Promise.all(promises)
      .then((prom) => {
        setStaticTranslations(Object.assign({}, ...prom));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (!isEqual(localTranslations, getLocalTranslations())) {
      setLocalTranslations(getLocalTranslations());
    }
  }, [props.resources.translations]);

  return (
    <AppsTranslationsContext.Provider
      value={{
        local: localTranslations,
        stripes: staticTranslations,
      }}
    >
      {props.children}
    </AppsTranslationsContext.Provider>
  );
}

AppsTranslationsProvider.propTypes = {
  children: PropTypes.node,
  resources: PropTypes.shape({
    translations: PropTypes.shape({
      records: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
};

AppsTranslationsProvider.manifest = {
  translations: {
    type: 'okapi',
    path: 'translations?limit=1000',
    records: 'translations',
  },
};

export default withStripes(stripesConnect(AppsTranslationsProvider));

/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* kware added new */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { withRouter } from 'react-router';
import localforage from 'localforage';

import { Button, Icon, Tooltip } from '@folio/stripes-components';
import moment from 'moment';
import { clearOkapiToken, clearCurrentUser } from '../../../okapiActions';
import { resetStore } from '../../../mainActions';

import css from './LibNav.css';
import ProfileDropdown from '../ProfileDropdown';

import UserLocalesMenu from '../UserLocalesSwitcher/UserLocalesMenu';
import FullScreenMode from './FullScreenMode';
import HandleDateTime from '../../../../util/kware-utils/HandleDateTime';
import NavButton from '../NavButton';
import TenantsConfig from '../../../../util/kware-utils/tenantsConfigs';

function LibNav(props) {
  const intl = useIntl();
  const tenantConfig = TenantsConfig.tenants.find(
    (ten) => ten.id === props.stripes.okapi.tenant
  );
  const [isFullScreen, setIsFullScreen] = useState(false);

  // return the user to the login screen, but after logging in they will be brought to the default screen.
  const logout = () => {
    props.stripes.store.dispatch(clearOkapiToken());
    props.stripes.store.dispatch(clearCurrentUser());
    props.stripes.store.dispatch(resetStore());
    localforage.removeItem('okapiSess');
    props.history.push('/');
  };

  const navigateByUrl = (link) => {
    props.history.push(link.route);
  };

  const settingsButtonClick = () => {
    const hasSetting = props.settingsApps.map((app) =>
      app.module.replace('@folio/', '')
    );

    switch (true) {
      case !['agreements', 'courses', 'erm-usage', 'erm-comparisons'].includes(
        props.selectedApp?.name
      ) && hasSetting.includes(props.selectedApp?.name):
        return navigateByUrl({ route: `/settings/${props.selectedApp.name}` });
      case props.selectedApp?.name === 'agreements':
        return navigateByUrl({ route: '/settings/erm' });
      case ['checkout', 'checkin'].includes(props.selectedApp?.name):
        return navigateByUrl({ route: '/settings/circulation' });
      case props.selectedApp?.name === 'courses':
        return navigateByUrl({ route: '/settings/cr' });
      case props.selectedApp?.name === 'erm-usage':
        return navigateByUrl({ route: '/settings/eusage' });
      case props.selectedApp?.name === 'erm-comparisons':
        return navigateByUrl({ route: '/settings/comparisons-erm' });
      default:
        return navigateByUrl({ route: '/settings' });
    }
  };

  const homeButtonClick = () => {
    navigateByUrl({ route: '/' });
  };

  // const getDateTime = () => {
  //   const dateTime = moment()
  //     .locale(intl.locale)
  //     .format(props.stripes.dateformat);
  //   return <div>{dateTime}</div>;
  // };

  useEffect(() => {
    const _unsubscribe = props.stripes.store.subscribe(() => {
      const state = props.stripes.store.getState();

      // If user has timed out, force them to log in again.
      if (
        state?.okapi?.token &&
        state.okapi.authFailure &&
        find(state.okapi.authFailure, { type: 'error', code: 'user.timeout' })
      ) {
        props.stripes.store.dispatch(clearOkapiToken());
        props.stripes.store.dispatch(clearCurrentUser());
        props.stripes.store.dispatch(resetStore());
        localforage.removeItem('okapiSess');
      }
    });

    return () => {
      _unsubscribe();
    };
  }, [props.stripes.store]);

  return (
    <>
      <header className={css.LibNav}>
        <nav className={css.LibNav_nav}>
          <div className={css.LibNav_Logo}>
            {/* <a
              // href="https://www.darah.org.sa/"
              // rel="noopener noreferrer"
              // target="_blank"
            > */}
            <img
              // className={css.LibNav_Logo}
              src={tenantConfig?.logo.src}
              alt={tenantConfig?.logo.alt}
              width={tenantConfig?.logo.width}
              height={tenantConfig?.logo.height}
            />
            {/* </a> */}
          </div>
          <div>
            {/* <img
              className={css.tenantLogo}
              src={tenantlogo?.logo}
              alt="Library logo"
            /> */}
            {/* <FormattedMessage id={`stripes-core.tenant.label.${props.stripes.okapi.tenant}`} /> */}
          </div>
          <div className={css.banner}>
            <FormattedMessage
              id="stripes-core.libNav.banner.message"
              defaultMessage="Site for demo only!"
            />
          </div>

          {/* <div className={css.spaccer} /> */}
          <div className={css.LibNav_nav_items}>
            <ul style={{ paddingTop: '2px', alignItems: 'center' }}>
              <li>
                <Button buttonStyle="slim" onClick={homeButtonClick}>
                  <Icon icon="house" size="small" />
                </Button>
              </li>
              <li>
                <Button buttonStyle="slim" onClick={settingsButtonClick}>
                  <Icon icon="gear" size="small" />
                </Button>
              </li>
              <li>
                <FullScreenMode
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                />
              </li>
              <li>
                <Tooltip
                  id="hide-lib-nav-tooltip"
                  text={
                    <FormattedMessage id="stripes-core.buttons.tooltip.hideLibNav" />
                  }
                >
                  {({ ref, ariaIds }) => (
                    <Button
                      id="btn-hide-lib-nav"
                      aria-labelledby={ariaIds.text}
                      ref={ref}
                      buttonStyle="slim"
                      marginBottom0
                      onClick={() => props.onSetShowLibNav(false)}
                    >
                      <Icon icon="triangle-up" size="small" />
                    </Button>
                  )}
                </Tooltip>
              </li>
            </ul>
            <ul>
              <li>
                {isFullScreen && (
                  <strong
                    style={{
                      color: 'green',
                      fontFamily: 'Roboto',
                      fontWeight: 'bolder',
                      fontSize: '18px',
                    }}
                  >
                    <HandleDateTime
                      dateTimeValue={moment()}
                      stripes={props.stripes}
                      handleType="datetime"
                    />
                  </strong>
                )}
              </li>
              <li>
                <ProfileDropdown onLogout={logout} stripes={props.stripes} />
              </li>
              <li>
                <UserLocalesMenu stripes={props.stripes} />
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}

LibNav.propTypes = {
  stripes: PropTypes.shape({
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }),
    hasPerm: PropTypes.func.isRequired,
    withOkapi: PropTypes.bool,
  }),
  history: PropTypes.shape({
    listen: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  onSetShowLibNav: PropTypes.func,
  selectedApp: PropTypes.object,
};

export default compose(injectIntl, withRouter)(LibNav);
